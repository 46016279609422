import React from 'react'
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from 'react-icons/io';

const Counter = (props: any) => {
    const { count, setCount, add, remove } = props;
    const updateCount: any = (e: any) => {
        if (!e?.target?.value) {
            setCount(0);
        } else {
            setCount(parseInt(e.target.value));
        }
    }
    return (
        <div className='flex justify-around items-center'>
            <IoIosAddCircleOutline className='cursor-pointer' size={25} onClick={add} />
            <p className='px-2 text-2xl pb-2 focus:outline-none mt-2 font-bold'>{count}</p>
            <IoIosRemoveCircleOutline className='cursor-pointer' size={25} onClick={remove} />
        </div>
    )
}

export default Counter