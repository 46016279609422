import React from "react";
import { RiLuggageCartFill } from "react-icons/ri";
import { BsFillPersonFill, BsFillClockFill } from "react-icons/bs";
import { FaRegHandshake } from "react-icons/fa";
import CurrencyMap from "../Utils/currency";

const Car = (props: any) => {
  const {
    car: {
      fleet_image,
      car_class,
      fare,
      carName,
      num_passengers,
      luggage_capacity,
      waiting_time,
      meet_greet,
      model,
      id,
    },
    currency,
    index,
    goToPassenger,
  } = props;
  let currencyIcon: any = CurrencyMap[currency];
  
  return (
    <div
    className={`grid grid-cols-12 gap-4 bg-white rounded-xl p-4 hover:shadow-2xl xl:h-52 lg:h-52 justify-centers  content-center ${
      index !== 0 && "mt-4"
    }`}
    >
    <div className="col-span-12 sm:col-span-12 md:col-span-4 xl:col-span-3 2xl:col-span-3 flex justify-center items-center opacity">
        <img src={fleet_image} alt={model} className="w-72 h-42" />
      </div>
      <div className="col-span-12 sm:col-span-5 md:col-span-5 xl:col-span-6 2xl:col-span-6 ">
        <div className="pt-4">
          <div className="flex justify-between">
            <p className="text-xl font-bold">{car_class.replace("_", " ")} - {model}</p>
          </div>
          <div>
            <p className="mt text-lg text-gray-600">{carName} </p>
            <p className="text-gray-500 text-sm">or similar</p>
          </div>
          <div className="flex justify-between flex-wrap mt-1">
            <div className="flex justify-center items-center">
              <BsFillPersonFill
                className="text-yellow-600 cursor-pointer"
                size={25}
              />
              <p className="text-sm font-bold ml"> {num_passengers}</p>
            </div>
            <div className="flex justify-center items-center">
              <RiLuggageCartFill
                className="text-yellow-600 cursor-pointer"
                size={25}
              />
              <p className="text-sm font-bold ml-2">{luggage_capacity}</p>
            </div>
            
            {meet_greet && (
              <div className="flex justify-center items-center">
                <FaRegHandshake
                  className="text-yellow-600 cursor-pointer"
                  size={28}
                />
                <p className="text-sm font-bold ml-2">Meet & Greet</p>
              </div>
            )}
            {waiting_time && (
              <div className="flex justify-center items-center">
                <BsFillClockFill
                  className="text-yellow-600 cursor-pointer"
                  size={20}
                />
                <p className="text-sm font-bold ml-2">
                  {waiting_time} min. Free Waiting Time
                </p>
                
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-span-12 sm:col-span-3 md:col-span-3 xl:col-span-3 2xl:col-span-3 items-center flex justify-center flex-col">
        <div className="text-center mt-2">
          <p className="text-xl">
            
            {fare}
          </p>
          <p className="text-sm">Includes all taxes</p>
        </div>
        <div className="mt-6">
          <button
            onClick={() => goToPassenger(props.car)}
            className="w-full block bg-gradient-to-r bg-gray-800  px-4 py-2 rounded-lg text-white text-lg font-bold hover:bg-yellow-600"
          >
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Car;
