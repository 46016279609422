import React from "react";
import ScrollTop from "../../Components/ScrollTop";
import NavBarFixed from "../../Components/NavBarFixed";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "../../Components/Admin/AdminNavbar";
import { useParams } from "react-router-dom";
import HomeTable from "../../Components/Admin/HomeTable";
import AdminReports from "../../Components/Admin/AdminReports";
import Agencies from "../../Components/Admin/Agencies";
import Coupons from "../../Components/Admin/Coupons";
import Customers from "../../Components/Admin/Customers";
import Dashboard from "../../Components/Admin/Dashboard";

const AdminHome = (props: any) => {
  const { currency, t } = props;
  let { page } = useParams();
  const navigate: any = useNavigate();
  const renderPage = () => {
    switch (page) {
      case "index":
        return <HomeTable />;
      case "reports":
        return <AdminReports />;
      case "agencies":
        return <Agencies />;
      case "coupons":
        return <Coupons />;
      case "customers":
        return <Customers />;
      case "dashboard":
        return <Dashboard />;
      default:
        return <></>;
    }
  };
  return (
    <div className="flex">
      <ScrollTop />
      <NavBarFixed currency={currency} t={t} />
      <div className="flex-row bg-gray-200 mt-24 h-screen w-full">
        <AdminNavbar page={page} />
        <div className="p-4">{renderPage()}</div>
      </div>
    </div>
  );
};

export default AdminHome;
