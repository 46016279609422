import React, { useState } from "react";
import { GoogleMap, DirectionsRenderer } from "@react-google-maps/api";

interface MapProps {
  defaultLocation: google.maps.LatLngLiteral;
  destination: google.maps.LatLngLiteral;
  origin: google.maps.LatLngLiteral;
}

const Map: React.FC<MapProps> = ({ defaultLocation, destination, origin }) => {
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);

  const onMapLoad = (map: google.maps.Map): void => {
    const directionsService = new google.maps.DirectionsService();
    // Load default origin and destination
    changeDirection(directionsService, origin, destination);
  };

  const changeDirection = (
    directionsService: google.maps.DirectionsService,
    origin: google.maps.LatLngLiteral,
    destination: google.maps.LatLngLiteral
  ): void => {
    directionsService.route(
      {
        origin,
        destination,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          // Set directions state to the result of direction service
          setDirections(result);
        } else {
          console.error(`Error fetching directions ${result}`);
        }
      }
    );
  };

  return (
    <div>
      <GoogleMap
        center={defaultLocation}
        zoom={5}
        onLoad={(map) => onMapLoad(map)}
        mapContainerStyle={{ height: "300px", width: "100%" }}
      >
        {directions && <DirectionsRenderer directions={directions} />}
      </GoogleMap>
    </div>
  );
};

export default Map;
