import { Helmet } from "react-helmet-async";
import Footer from "../Components/Footer";
import NavBarFixed from "../Components/NavBarFixed";

const TermsAndConditions = (props: any) => {
  const { currency, t } = props;

  return (
    <>
      <Helmet>
        <title>Terms And Conditions</title>
      </Helmet>

      <NavBarFixed currency={currency} t={t} />

      <main className="mt-[100px] bg-white p-10">
        <div className="my-5 inline-block ">Terms and conditiosn</div>
      </main>

      <div className="bg-gray-800 w-full col-span-2">
        <Footer />
      </div>
    </>
  );
};

export default TermsAndConditions;
