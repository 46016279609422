import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Axios from "../Utils/Axios";
import { AiOutlineMail } from "react-icons/ai";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { selectUserSettings } from "../store/authSlice";

const LogoContent = (navigate: any) => {
  const userSettings = useSelector(selectUserSettings);

  return (
    <>
      <p className="text-4xl font-bolder text-white">
        Welcome back to {userSettings?.site_name}
      </p>
      <img
        src={userSettings?.site_logo}
        className="h-20 cursor-pointer"
        alt={userSettings?.site_name}
        onClick={() => navigate("/")}
      />
    </>
  );
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const regex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const ForgotPassword = (props: any) => {
  const navigate: any = useNavigate();
  const [email, setEmail] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isMailValid, setIsMailValid] = useState(false);

  let query = useQuery();

  useEffect(() => {
    const emailFromQuery = query.get("email");
    if (emailFromQuery) setEmail(emailFromQuery);
  }, [query]);

  useEffect(() => {
    setIsMailValid(regex.test(email));
  }, [email]);
  const forgotPassword: any = async (e: any) => {
    e.preventDefault();
    const config: any = {
      method: "POST",
      url: "api/v1/forgot-password/",
      data: {
        email: email,
      },
    };
    await Axios(config)
      .then((res: any) => {
        sessionStorage.setItem("reset_pwd_mail", email);
        navigate("/reset-password");
      })
      .catch((e: any) => {
        setError(e.response.data.detail);
      });
  };

  const userSettings = useSelector(selectUserSettings);

  return (
    <div className="h-screen bg-gray-800 grid grid-cols-2">
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <div className="lg:flex hidden flex-col justify-center items-center  text-center bg-gray-800 p-4">
        <p className="text-4xl font-bolder text-white">
          Welcome back to {userSettings?.site_name}
        </p>
        <img
          src={userSettings?.site_logo}
          className="h-20 cursor-pointer"
          alt={userSettings?.site_name}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="col-span-2 lg:col-span-1 flex flex-col justify-center items-center text-center bg-gray-800 p-4">
        <div className="flex-col flex justify-center items-center text-center lg:hidden">
          {LogoContent(navigate)}
        </div>
        <form>
          <div className="bg-white px-6 py-8 rounded-xl mt-6">
            <p className="text-3xl font-bold">Forgot Password</p>
            <div className="py-10 flex mt-10">
              <AiOutlineMail className="w-10 h-10" />
              <input
                required
                type="email"
                name="email"
                defaultValue={email}
                placeholder="Enter your email"
                className="py-2 pl-12 w-80 border-b-2 border-blue-400 focus:outline-0 focus:shadow-lg"
                onChange={(e: any) => {
                  setError(null);
                  setEmail(e?.target.value);
                }}
              />
            </div>
            {error && (
              <div>
                <p className="text-red-500 mt-4">{error}</p>
              </div>
            )}
            <button
              onClick={forgotPassword}
              disabled={!isMailValid}
              className={
                "w-full mt-6 rounded-lg px-10 py-4 text-white font-bold " +
                (isMailValid
                  ? "bg-gray-800 hover:bg-yellow-600"
                  : "bg-gray-400 bg-gradient-to-r")
              }
            >
              Forgot Password
            </button>
          </div>
        </form>
      </div>
      <div className="bg-gray-800 w-full col-span-2">
        <Footer />
      </div>
    </div>
  );
};

export default ForgotPassword;
