import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import BookingOverview from "../Components/BookingOverview";
import Passenger from "../Components/BookingV2/Passenger";
import PaymentV2 from "../Components/BookingV2/PaymentV2";
import Car from "../Components/Car";
import Footer from "../Components/Footer";
import NavBarFixed from "../Components/NavBarFixed";
import ScrollTop from "../Components/ScrollTop";
import { getUserSettings } from "../Layout";
import Axios from "../Utils/Axios";
import { selectAuthToken, selectUserSettings } from "../store/authSlice";
import { nanoid } from "nanoid";
import { passengerUpdate } from "../store/bookingSlice";
import Loading from "../Components/Loading";
import {AllGeodata} from "../store/searchSlice";

const Booking = (props: any) => {
  const { currency, t } = props;
  const [locationDetails, setLocationDetails] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [cars, setCars] = useState<any>([]);
  const [selectedCar, setSelectedCar] = useState<any>({});
  const [userDetails, setUserDetails] = useState<any>({});
  const [paymentView, setPaymentView] = useState<boolean>(false);
  const userSettings = useSelector(selectUserSettings);
  const localData = useSelector(AllGeodata);

  const passengerEdit: any = useRef(null);
  const paymentPage: any = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const goToPassenger = (car: any) => {
    setSelectedCar(car);
    dispatch(passengerUpdate({}));
    setTimeout(() => {
      passengerEdit.current.scrollIntoView();
    }, 500);
  };

  const stripePromise = useMemo(() => {
    if (userSettings?.stripe_key) {
      return loadStripe(userSettings.stripe_key);
    }

    return getUserSettings().then((data) => {
      return loadStripe(data?.spk);
    });
  }, [userSettings]);

  const authToken = useSelector(selectAuthToken);

  async function goToPayment(car: any, userDetails: any) {
    //setSelectedCar(car);
    setUserDetails(userDetails);
    setPaymentView(true);
    setTimeout(() => {
      paymentPage.current.scrollIntoView();
    }, 500);

    setSelectedCar({ ...(selectedCar ?? {}), car_raw: car });
  }
  const fetchCars: any = async (reqObj: any) => {
    setLoading(true);
    const {
      num_passenger,
      num_bags,
      pickup_date,
      booking_currency,
      pickup_time,
      pickup,
      destination,
      booking_type,
      duration,
    } = reqObj;
    let durationValue = duration ?? 1;
    let subqueryparam =
      booking_type === "city_tour" || booking_type === "hourly"
        ? `&perhour=on&timebased=${durationValue}`
        : `&destination=${destination.lat},${destination.lng}`;
    const config: any = {
      method: "GET",
      url: `/api/v1/booking/search?num_passenger=${num_passenger}&num_bags=${num_bags}&pickup_date=${pickup_date}&pickup_time=${pickup_time}&pickup=${pickup.lat},${pickup.lng}&currency=${currency}${subqueryparam}`,
    };
    const bookingResponse: any = await Axios(config).catch((e: any) => {});
    if (bookingResponse?.data?.data?.booking_list?.[0]) {
      const distance = bookingResponse.data.data.booking_list[0].distance;
      const duration = bookingResponse.data.data.booking_list[0].duration;
      setLocationDetails((locationDetails: any) => {
        return { ...locationDetails, distance, duration };
      });
      setCars(bookingResponse.data.data.booking_list);
    }
    setLoading(false);
  };
  const bookingsearch = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const cookieDate = queryParams.get("date");
    const currencyValue = currency;
    const cookieTime = queryParams.get("time");
    const pickupLocation: any = queryParams.get("pickup");
    const dropoffLocation: any = queryParams.get("dropoff");
    const cookieNoOfPass: any = queryParams.get("pass");
    const cookieNoOfBags: any = queryParams.get("bags");
    const pickupLat: any = queryParams.get("pickup_lat");
    const dropoffLat: any = queryParams.get("drop_lat");
    const pickupLan: any = queryParams.get("pickup_lng");
    const dropoffLan: any = queryParams.get("drop_lng");
    const cookieDuration: any = queryParams.get("duration");
    const cookieBookingType: any = queryParams.get("type");

    setLocationDetails({
      pickupDate: cookieDate || null,
      pickupTime: cookieTime || null,
      pickupLocation: { label: pickupLocation },
      dropoffLocation: { label: dropoffLocation },
      passengers: cookieNoOfPass || 0,
      bags: cookieNoOfBags || 0,
      pickupGeometry: { lat: pickupLat, lng: pickupLan } || {},
      dropoffGeometry: { lat: dropoffLat, lng: dropoffLan } || {},
      booking_type: cookieBookingType,
      booking_duration: cookieDuration,
    });

    fetchCars({
      currency: currencyValue,
      num_passenger: cookieNoOfPass,
      num_bags: cookieNoOfBags,
      pickup_time: cookieTime || null,
      pickup_date: moment(cookieDate).format("YYYY-MM-DD"),
      pickup: { lat: pickupLat, lng: pickupLan },
      destination: { lat: dropoffLat, lng: dropoffLan },
      booking_type: cookieBookingType,
      duration: cookieDuration,
    });
  };
  useEffect(() => {
    try {
      setLoading(true);
      bookingsearch();
    } catch (e: any) {}
  }, [currency]);

  const modifySearch = (search: any) => {

    
            localStorage.setItem("pickup_location",JSON.stringify(localData.pickupLocation))
            localStorage.setItem("dropoff_location",JSON.stringify(localData.dropoffLocation))
            localStorage.setItem("pickup_geometry",JSON.stringify(localData.pickupGeometry))
            localStorage.setItem("dropoff_geometry",JSON.stringify(localData.dropoffGeometry))
    setCars([]);
    setLoading(true);
    const queryParams = new URLSearchParams(location.search);
    setLocationDetails({
      pickupDate: search?.date || null,
      pickupTime: search?.time || null,
      pickupLocation: { label: search?.pickup },
      dropoffLocation: { label: search?.dropoff },
      passengers: search?.pass || 0,
      bags: search?.bags || 0,
      pickupGeometry:
        { lat: search?.pickup_lat, lng: search?.pickup_lng } || {},
      dropoffGeometry: { lat: search?.drop_lat, lng: search?.drop_lng } || {},
      booking_type: queryParams.get("type"),
      booking_duration: search?.duration,
    });
    fetchCars({
      currency: currency,
      num_passenger: search?.pass,
      num_bags: search?.bags,
      pickup_time: search?.time || null,
      pickup_date: moment(search?.date).format("YYYY-MM-DD"),
      pickup: { lat: search?.pickup_lat, lng: search?.pickup_lng },
      destination: { lat: search?.drop_lat, lng: search?.drop_lng },
      booking_type: queryParams.get("type"),
      duration: search?.duration.value,
    });
    queryParams.set("date", search?.date);
    queryParams.set("time", search?.time);
    queryParams.set("pickup", search?.pickup);
    queryParams.set("dropoff", search?.dropoff);
    queryParams.set("pass", search?.pass);
    queryParams.set("bags", search?.bags);
    queryParams.set("pickup_lat", search?.pickup_lat);
    queryParams.set("drop_lat", search?.drop_lat);
    queryParams.set("pickup_lng", search?.pickup_lng);
    queryParams.set("drop_lng", search?.drop_lng);
    queryParams.set("duration", search?.duration.value);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
  };

  return (
    <div>
      <ScrollTop />
      <Helmet>
        <title>Booking</title>
      </Helmet>
      <NavBarFixed currency={currency} t={t} />

      {!paymentView && (
        <div className="bg-white items-center mt-32">
          <BookingOverview
            locationDetails={locationDetails!}
            t={t}
            modifyBooking={modifySearch}
          />
          <div className="grid grid-cols-8 p-2 ">
            {/* <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-4 lg:col-span-8 p-2 bg-gray-100">

              <div className="flex justify-center py-2 bg-gray-300">
                <p className="w-full font-bold text-center">Tip Details</p>
              </div>
              <div className="p-2">
                <Map
                  pickupGeometry={locationDetails.pickupGeometry}
                  dropoffGeometry={locationDetails.dropoffGeometry}
                />
              </div>
              <div className="flex justify-start px-8 py-4 space-x-6">
                <div className="w-18 flex">
                  <RiMapPinRangeFill
                    className={`text-red-800 cursor-pointer mr-2`}
                    size={25}
                  />
                  <p>Pickup</p>
                </div>
                <div className="w-18 flex">
                  <RiMapPinRangeFill
                    className={`text-blue-800 cursor-pointer mr-2`}
                    size={25}
                  />
                  <p>Drop off</p>
                </div>
              </div>
            </div> */}
            <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-8 lg:col-span-8 px-4 mb-4">
              {/* <div className="p-4 bg-white rounded-xl h-12 mt-2 mb-4 text-yellow-600 flex items-center space-x-8">
                <div className="bg-blue-600 w-16 p-4 rounded-full ml-8">
                  <AiOutlineSafety
                    className="text-white cursor-pointer mr-2"
                    size={30}
                  />
                </div>
                <p className="font-semibold text-xl">
                  {t("booking_section_title_1")}
                </p>
              </div> */}
              {/* <div className="p-4 bg-white rounded-xl h-30 mb-4">
                <div className="flex  justify-around">
                  <div className="flex flex-col items-center text-center">
                    <FaRegSmile
                      className="text-green-600 cursor-pointer"
                      size={35}
                    />
                    <p className="text-lg mt-2">
                      {t("booking_section_2_title_1")}
                    </p>
                  </div>
                  <div className="flex flex-col items-center w-48 text-center border-l-2">
                    <RiFlightTakeoffFill
                      className="text-yellow-600 cursor-pointer"
                      size={35}
                    />
                    <p className="text-lg mt-2">
                      {t("booking_section_2_title_2")}
                    </p>
                  </div>
                  <div className="flex flex-col items-center w-48 text-center border-l-2 ">
                    <FcOnlineSupport
                      className="text-yellow-600 cursor-pointer"
                      size={35}
                    />
                    <p className="text-lg mt-2">
                      {t("booking_section_2_title_3")}
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="">
                {isLoading ? (
                  <div className="flex justify-center items-center h-96">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {cars?.length ? (
                      cars.map((car: any, index: number) => {
                        return (
                          <div className="justify-center" key={index}>
                            <Car
                              car={car}
                              currency={currency}
                              key={index}
                              index={index}
                              goToPassenger={goToPassenger}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center h-96">
                        <p className="text-lg font-bold ">No Matches Found!</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!paymentView && selectedCar?.model && (
        <div ref={passengerEdit} key={nanoid()} className="p-4 bg-white h-full">
          <Passenger
            reqObj={{ car: selectedCar, locationDetails, currency }}
            goToPayment={goToPayment}
          />
        </div>
      )}

      <div ref={paymentPage}>
        {paymentView && (
          <div key={nanoid()} className="p-4 bg-white">
            <Elements stripe={stripePromise}>
              <PaymentV2
                car={selectedCar}
                currency={currency}
                userDetails={userDetails}
                locationDetails={locationDetails}
                paymentView={paymentView}
              />
            </Elements>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Booking;
