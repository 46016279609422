import React from "react";

function ArrowRightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 28 20"
    >
      <path
        fill="#000"
        d="M18 .667l-1.88 1.88 6.107 6.12H.667v2.666h21.56l-6.12 6.12L18 19.333 27.333 10 18 .667z"
      ></path>
    </svg>
  );
}

export default ArrowRightIcon;
