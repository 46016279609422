import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { Slide, toast } from "react-toastify";
import Footer from "../../Components/Footer";
import NavBarFixed from "../../Components/NavBarFixed";
import Axios from "../../Utils/Axios";
import "./FlightBooking.css";
import AIRPORTS from "./airports.json";
import { flightBookingSchema } from "./flight-booking.schema";
import { FaSpinner } from "react-icons/fa";
import { isLoggedIn } from "../../store/authSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaAngleDown } from "react-icons/fa6";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { AiOutlineMinusCircle } from "react-icons/ai";
import ReactToolTip from "./toolTip";
import { FaCalendar,FaLuggageCart } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import async from "react-select/dist/declarations/src/async/index";

const airportSelectList = AIRPORTS.map((airport) => ({
  label: `${airport.name} - ${airport.city}, ${airport.state}, ${airport.country}`,
  value: airport,
}));

function ErrorText({ error }: { error?: { message?: string } }) {
  if (!error?.message?.length) return null;
  return <span className="text-red-500">{error?.message}</span>;
}

const FlightBooking = (props: any) => {
  const { currency, t } = props;
  const [isTwoWayFlight, setFlightMode] = useState(false);
  const [isDrop, setIsDrop] = useState(false);
  const [flightData, setFlightData] = useState<any[]>([]);
  const [counter, setCounter] = useState<any>({ adult: 0, child: 0 });
  const isLoggedin: boolean = useSelector(isLoggedIn);
  const form = useForm<Zod.infer<typeof flightBookingSchema>>({
    resolver: zodResolver(flightBookingSchema),
  });

  const navigate = useNavigate();

  useEffect(() => {
    getAllFlights();
  }, []);

  useEffect(() => {
    form.setValue("no_of_adults", counter.adult);
    form.setValue("no_of_children", counter.child);
    form.setValue("no_of_passenger", counter.adult + counter.child);
  }, [counter]);

  const getAllFlights = async () => {
    try {
      const resp = await Axios.get("/api/v1/limoairport/flightrequest/");
      // setFlightData(resp)
      console.log(resp);
      setFlightData(resp?.data?.data);
    } catch (error) {
      console.log(error, "error");
      toast.error("Unable to get flights ", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        transition: Slide,
      });
    }
  };

  const errors = form.formState.errors;

  async function submitRequest(values: Zod.infer<typeof flightBookingSchema>) {
    if (isLoggedin) {
      const payload: Record<string, any> = {
        ...values,
        pickup: values.pickup.label.split("-")[0].trim(),
        pickup_code: values.pickup.value.code,
        pickup_data: values.pickup.value,
        destination: values.destination.label.split("-")[0].trim(),
        destination_code: values.destination.value.code,
        destination_data: values.destination.value,
        pickup_date: moment(values.pickup_date).format("YYYY-MM-DD"),
        no_of_adults: values.no_of_adults ?? 0,
        no_of_children: values.no_of_children ?? 0,
        no_of_passenger: values.no_of_passenger ?? 0,
        no_of_luggage: values.no_of_luggage ?? 0,
      };

      if (values.return_date) {
        payload.return_date = moment(values.return_date).format("YYYY-MM-DD");
      }

      try {
        const resp = await Axios.post(
          "/api/v1/limoairport/flightrequest/",
          payload
        );

        if (resp.status === 200 || resp.status === 201) {
          toast.success("Flight request sent successfully", {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          });
        } else {
          toast.error("Flight request cant be sent ", {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          });
        }
      } catch (error) {
        toast.error("Flight request cant be sent ", {
          position: "top-center",
          autoClose: 3000,
          closeOnClick: true,
          transition: Slide,
        });
      }
    } else {
      toast.error("Login for best offers", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        transition: Slide,
      });
    }
  }

  const dropChange = (e: any) => {
    setIsDrop(!isDrop);
  };

  const SetPassengerCounter = (name: string, isPositive: boolean) => {
    if (isPositive) {
      setCounter((prev: any) => ({ ...prev, [name]: prev[name] + 1 }));
    } else {
      setCounter((prev: any) => ({
        ...prev,
        [name]: prev[name] > 0 ? prev[name] - 1 : 0,
      }));
    }
  };
  return (
    <>
      <Helmet>
        <title>Flight Booking</title>
      </Helmet>

      <NavBarFixed currency={currency} t={t} />

      <main className="mt-[100px] bg-white p-10">
        {/* <div className="my-5 inline-block ">Terms and conditiosn</div> */}
        {/* <div className="flight-card">
    <h3 className="h3-title">Best prices for Flights in all countries</h3>
    <div className="flight-inputs">
     <div className="input-wrapper">
        <input type="text" placeholder="From: address, airport, hotel"/>
        </div>
        <div className="input-changer">
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" className="cursor-pointer" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path d="M16.0503 12.0498L21 16.9996L16.0503 21.9493L14.636 20.5351L17.172 17.9988L4 17.9996V15.9996L17.172 15.9988L14.636 13.464L16.0503 12.0498ZM7.94975 2.0498L9.36396 3.46402L6.828 5.9988L20 5.99955V7.99955L6.828 7.9988L9.36396 10.5351L7.94975 11.9493L3 6.99955L7.94975 2.0498Z"></path></svg>
        </div>
        <div className="input-wrapper">
       <input type="text" placeholder="To: address, airport, hotel"/>
       </div>
      </div>
      <div className="btn-get-offer">
       <button>Get offers</button>
      </div>
    </div> */}
        <div className="tabs-button">
          <button
            className={`tabs-btn ${!isTwoWayFlight ? "active" : ""}`}
            onClick={() => setFlightMode(false)}
          >
            One Way
          </button>
          <button
            className={`tabs-btn ${isTwoWayFlight ? "active" : ""}`}
            onClick={() => setFlightMode(true)}
          >
            Two Way
          </button>
        </div>

        <div className="top-search-bar">
          <form
            className="inputs-wrapper-row"
            onSubmit={form.handleSubmit((values) => submitRequest(values))}
          >
            <div className="input-wrap">
              <label>From</label>

              <Controller
                control={form.control}
                name="pickup"
                render={({ field }) => (
                  <Select
                    placeholder={"From"}
                    options={airportSelectList}
                    className="cursor-pointer"
                    required
                    {...field}
                  />
                )}
              />

              <ErrorText error={errors.pickup} />
            </div>

            <div className="input-wrap">
              <label>To</label>

              <Controller
                control={form.control}
                name="destination"
                render={({ field }) => (
                  <Select
                    placeholder={"To"}
                    options={airportSelectList}
                    className="cursor-pointer"
                    required
                    {...field}
                  />
                )}
              />

              <ErrorText error={errors.destination} />
            </div>

            <div className="input-wrap">
              <label>Depart</label>

              <Controller
                control={form.control}
                name="pickup_date"
                render={({ field }) => (
                  <DatePicker
                    value={
                      field.value
                        ? moment(field.value).format("DD MMM, yyyy")
                        : undefined
                    }
                    onChange={(val) => field.onChange(val as Date)}
                    placeholderText={"Depart"}
                    minDate={new Date()}
                    dateFormat="eee, dd MMM, yyyy"
                  />
                )}
              />

              <ErrorText error={errors.pickup_date} />
            </div>

            {isTwoWayFlight && (
              <div className="input-wrap">
                <label>Return</label>

                <Controller
                  control={form.control}
                  name="return_date"
                  render={({ field }) => (
                    <DatePicker
                      value={
                        field.value
                          ? moment(field.value).format("DD MMM, yyyy")
                          : undefined
                      }
                      onChange={(val) => field.onChange(val as Date)}
                      placeholderText={"Depart"}
                      minDate={form.watch("pickup_date")}
                      dateFormat="eee, dd MMM, yyyy"
                    />
                  )}
                />

                <ErrorText error={errors.return_date} />
              </div>
            )}

            <div className="input-wrap" style={{ position: "relative" }}>
              <label>Passengers</label>
              <div className="passengers_drop" onClick={(e) => dropChange(e)}>
                <div>
                  {" "}
                  {counter.adult + counter.child
                    ? counter.adult + counter.child
                    : "Add passengers"}
                </div>
                <div>
                  {" "}
                  <FaAngleDown style={{ color: "hsl(0, 0%, 80%)" }} />
                </div>
              </div>
              {/* <input type="number" {...form.register("no_of_passenger")} />

              <ErrorText error={errors.no_of_passenger} /> */}
              {isDrop && (
                <div className="passenger_drop-main">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {" "}
                    <div>Adults</div>{" "}
                    <div className="counter-part">
                      {" "}
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => SetPassengerCounter("adult", true)}
                      >
                        <AiOutlinePlusCircle />
                      </div>{" "}
                      <div>{counter.adult}</div>{" "}
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => SetPassengerCounter("adult", false)}
                      >
                        <AiOutlineMinusCircle />
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {" "}
                    <div>Child</div>{" "}
                    <div className="counter-part">
                      {" "}
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => SetPassengerCounter("child", true)}
                      >
                        <AiOutlinePlusCircle />
                      </div>{" "}
                      <div>{counter.child}</div>{" "}
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => SetPassengerCounter("child", false)}
                      >
                        <AiOutlineMinusCircle />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

          

            <div className="input-wrap">
              <label>Luggage(in kg)</label>
              <input type="number" {...form.register("no_of_luggage")} />

              <ErrorText error={errors.no_of_luggage} />
            </div>

            <div className="btn-serach">
              <button type="submit" disabled={form.formState.isSubmitting}>
                {form.formState.isSubmitting ? (
                  <FaSpinner className="w-4 h-4 animate-spin" />
                ) : (
                  "Get Offers"
                )}
              </button>
            </div>
          </form>
        </div>
      
        <div className="flight-details-section mt-5">
        </div>
        <div className="flight-card-row">
          {flightData &&
            flightData.map((data) => {
              return (
                <div
                  className="card-flight"
                  style={{ overflow: "scroll" }}
                  key={data.id}
                >
                  <div className="left-wrap" style={{ minWidth: "" }}>
                    <div className="img-star">
                      <img
                        className="plane-img"
                        src={data?.airlineLogo}
                        alt="offer_1"
                      />
                    </div>
                    <div className="card-details">
                     
                      <p className="flight-name">{data?.airlineName}</p>
                    </div>
                  </div>
                  <div className="right-wrap">
                    <div className="flighttiming-details-row">
                      <div className="left-details">
                        <div className="time-div-flight">
                          <h5>{data?.departure_time}</h5>
                          <span style={{ cursor: "pointer" }}>
                            {" "}
                            <ReactToolTip
                              title={data.pickup}
                              style={{ left: "5px" }}
                            >
                              {data.pickup_code}
                            </ReactToolTip>{" "}
                          </span>
                        </div>
                      </div>
                      <div className="middle-details">
                        <span>{data?.durations} </span>
                        <div className="airplane-line">
                          <span className="bordr-line"></span>
                          {data.stoppages?.length ? (
                            <span className="red-dot"></span>
                          ) : (
                            ""
                          )}
                          <img
                            src="https://cdn.icon-icons.com/icons2/3002/PNG/512/airplane_icon_188127.png"
                            className="flight-icon"
                            alt="offer_1"
                          />
                        </div>
                        <p className="bottom-line">
                          {data.stoppages?.length ? <span>Stopover</span> : ""}
                        </p>
                        <p>
                          {" "}
                          {data.stoppages.map((item: any, i: number) => {
                            return (
                              <span>
                                <ReactToolTip
                                  title={item?.stop_data?.name}
                                  style={{ left: "5px" }}
                                >
                                  {(i !== 0 ? "," : "") + item?.stop_data?.code}
                                </ReactToolTip>
                              </span>
                            );
                          })}
                        </p>
                      </div>
                      <div className="right-details">
                        <div className="time-div-flight">
                          <h5>{data?.arrival_time}</h5>
                          <span style={{ cursor: "pointer" }}>
                            {" "}
                            <ReactToolTip
                              title={data.destination}
                              style={{ left: "5px" }}
                            >
                              {data.destination_code}
                            </ReactToolTip>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ex-right">
                    <div className="rate">
                      <p>
                        <b>
                          <span>{data?.currency}</span>
                          {" " + data?.fare}
                        </b>
                      </p>
                      <span className="cancel-link">
                        {data?.cancellation_hour ? (
                          <div>
                            <div>{"Cancellations Policy"}</div>{" "}
                            <div className="text-black">
                              <ReactToolTip
                                title={`Can cancel before ${data?.cancellation_hour} hours`}
                                style={{ left: "5px" }}
                              >
                                <div className="font-bold  cursor-pointer">
                                  {data?.cancellation_hour + "H"}
                                </div>{" "}
                              </ReactToolTip>
                            </div>{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </span>

                     <div style={{display:"flex",gap:"5px"}}>
                     <ReactToolTip  title={`Requested date is ${data?.requestDate}`}
                              style={{ left: "5px" }}> <FaCalendar/></ReactToolTip>
                     
                     <div>
                      <ReactToolTip  title={<div>
                        <div>Total Passengers {data?.no_of_adults + data?.no_of_children}</div>
                        <div>Adults Passengers {data?.no_of_adults }</div>
                        <div>Child Passengers {data?.no_of_children}</div>
                      </div>}
                       style={{ left: "5px" }}><CgProfile/></ReactToolTip>
                      </div>
                     <div>
                        <ReactToolTip  title={`${data?.luggage} KG `}
                              style={{ left: "5px" }}><FaLuggageCart /></ReactToolTip>
                      </div>
                     </div>
                    </div>
                    <div className="btn-book">
                      <div className="btn-get-offer">
                        <button
                          onClick={() =>
                            navigate(
                              `/flight-passengers?child=${data?.no_of_children}&&adult=${data?.no_of_adults}&&flightrequestno=${data?.flightRequestNo}&&agencyRequestNo=${data?.agencyrequestno}`
                            )
                          }
                        >
                          Book
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </main>

      <div className="bg-gray-800 w-full col-span-2">
        <Footer />
      </div>
    </>
  );
};

export default FlightBooking;
