import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import momentTimeZone from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { FcSimCardChip } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import CardsImage from "../../Assets/cards/credit-cards.png";
import Axios from "../../Utils/Axios";
import { useSelector } from "react-redux";
import { selectUser, selectUserSettings } from "../../store/authSlice";
import Loading from "../Loading";
import { StripeCardElementOptions } from "@stripe/stripe-js";

const Payment = (props: any) => {
  // console.log(props)
  const { userDetails, car, paymentView, locationDetails } = props;
  const navigate: any = useNavigate();
  const [cardList, setCardList] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [removingCard, setRemovingCard] = useState<any[]>([]);
  const [isSaveCard, setIsSaveCard] = useState(false);
  const [cardName, setCardName] = useState<string>("");
  const [isPaymentPageReady, setPaymentPageReady] = useState<boolean>(false);
  const [bookingId, setBookingId] = useState<any>(null);
  const [paymentIntendId, setPaymentIntendId] = useState<string>("");
  const stripe: any = useStripe();
  const pickupLocation: any = localStorage.getItem("pickup_location");
  const dropoffLocation: any = localStorage.getItem("dropoff_location");
  const elements: any = useElements();
  let selectedPassenger: any = JSON.parse(
    localStorage.getItem("passenger") ?? "{}"
  );
  const user = useSelector(selectUser);
  const sysId = localStorage.getItem("selected_car");
  const userSettings = useSelector(selectUserSettings);

  var bookingDetailsData = useRef({
    firstName: selectedPassenger.firstName ?? user.first_name,
    lastName: selectedPassenger.lastName ?? user.last_name,
    email: selectedPassenger.email ?? user.email,
    mobile: selectedPassenger.mobile ?? user.mobile,
    flightNumber: selectedPassenger.flightNumber,
    specialInstructions: selectedPassenger.specialInstructions,
    fare: car?.fare,
    bookingId: car?.id,
    passenger: locationDetails?.passengers,
    luggage: locationDetails?.bags,
    waitingTime: car?.waiting_time,
    car: car?.car_class,
    pickupLocation: locationDetails.pickupLocation.label,
    dropLocation: locationDetails?.dropoffLocation.label,
    duration: locationDetails?.duration,
    distance: locationDetails?.distance,
    pickupDate: locationDetails?.pickupDate,
    pickupTime: locationDetails?.pickupTime,
    pin: "",
    car_raw: car.car_raw,
  });

  const initBooking: any = async () => {
    setLoading(true);

    try {
      if (selectedPassenger && sysId) {
        localStorage.setItem("selected_car", car.id);
        const timeZone = momentTimeZone.tz
          .guess()
          .replace("Calcutta", "Kolkata");
        let requestbody = {
          source: "WEB",
          passenger_email: selectedPassenger.email,
          passenger_name: `${selectedPassenger.firstName} ${selectedPassenger.lastName}`,
          passenger_mobile: selectedPassenger.mobile,
          sys_id: sysId,
          timezone: timeZone,
          start_point: JSON.parse(pickupLocation).label,
          end_point: JSON.parse(dropoffLocation).label,
          special_instructions: selectedPassenger?.specialInstructions,
          flight_no: selectedPassenger?.flightNumber,
        };
        if (!requestbody.special_instructions)
          delete requestbody.special_instructions;
        if (!requestbody.flight_no) delete requestbody.flight_no;
        const config: any = {
          method: "POST",
          url: `/api/v1/booking/confirm/`,
          data: requestbody,
        };

        const bookingResponse: any = await Axios(config).catch((e: any) => {});
        if (
          bookingResponse?.data.status === 200 &&
          bookingResponse?.data?.data?.booking_id &&
          bookingResponse?.data?.data?.is_confirm
        ) {
          bookingDetailsData.current.luggage =
            bookingResponse?.data?.data?.data?.traveler?.num_bags ??
            bookingDetailsData.current.luggage;

          // @ts-ignore
          bookingDetailsData.current.cancellationDetails =
            bookingResponse?.data?.data?.data.cancellationDetails;

          setBookingId(bookingResponse.data.data.booking_id);
          initiatePayment(bookingResponse.data.data.booking_id);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  const initiatePayment = async (booking_id: string) => {
    const config: any = {
      method: "POST",
      url: `/api/v1/payment/initiate/`,
      data: {
        booking_id,
      },
    };
    const paymentReqDetails = await Axios(config);

    if (paymentReqDetails?.data?.data?.id) {
      setPaymentIntendId(paymentReqDetails?.data?.data?.id);
    }
    if (paymentReqDetails?.data?.data?.card_list?.data) {
      setCardList(paymentReqDetails.data.data.card_list.data);
    }

    setPaymentPageReady(true);
  };
  useEffect(() => {
    if (paymentView) {
      if (!bookingId) initBooking();
    }
  }, [paymentView]);

  const showError = (message: string) =>
    toast.error(message, {
      position: "top-center",
      autoClose: 3000,
      closeOnClick: true,
      transition: Slide,
    });
  const showSuccess = (message: string) =>
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      closeOnClick: true,
      transition: Slide,
    });
  const billingAddressForStripe = {
    name: `test`,
    phone: "test",
    address: {
      postal_code: "123",
      state: "tn",
      city: "maa",
    },
  };
  const CardElementOptions: StripeCardElementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#000000",
        "::placeholder": {
          color: "#000000",
        },
      },
    },
    hidePostalCode: true,
  };

  const confirmBooking = (card: any) => {
    const orderData: any = {};
    const cardElement = card;

    stripe
      .createToken(cardElement, {
        name: cardName,
      })
      .then(async (tokenResult: any) => {
        if (tokenResult.error) {
          throw new Error(tokenResult.error);
        } else {
          const {
            token: { card, ...tokenObject },
          }: any = tokenResult;

          orderData.paymentMethodId = "NoneType";
          orderData.stripeToken = tokenObject;
          orderData.booking_id = bookingId;
          orderData.card_id = card.id;
          orderData.is_save_card = isSaveCard;
          return await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              email: userDetails?.selectedPassenger.email,
            },
          });
        }
      })
      .then(async (createpaymentdata: any) => {
        const config: any = {
          method: "POST",
          url: `/api/v1/payment/charge/`,
          data: {
            booking_id: bookingId,
            intent_id: paymentIntendId,
            method_id: createpaymentdata?.paymentMethod?.id,
            stripeToken: orderData.stripeToken,
          },
        };
        return await Axios(config);
      })
      .then((paymentData: any) => {
        if (paymentData.data?.data?.requiresAction) {
          stripe
            .confirmCardPayment(paymentData.data?.data?.clientSecret)
            .then(async (data: any) => {
              if (data.error) {
                showError("Your card was not authenticated, please try again");
                setLoading(false);
              } else if (data.paymentIntent.status === "succeeded") {
                bookingDetailsData.current.bookingId =
                  data?.data?.data?.booking_id;
                bookingDetailsData.current.pin = data?.data?.data?.bookings.pin;
                showSuccess(data?.data?.message || "Payment success!");
                navigate("/bookingSuccess", {
                  state: bookingDetailsData.current,
                });
              } else if (
                ["requires_capture", "requires_live_capture"].includes(
                  data.paymentIntent.status
                )
              ) {
                const config: any = {
                  method: "POST",
                  url: `/api/v1/payment/capture/`,
                  data: {
                    paymentIntentId: paymentIntendId,
                  },
                };
                await Axios(config)
                  .then((response: any) => {
                    if (response.error) {
                      showError(response?.data?.message);
                      setLoading(false);
                    } else {
                      bookingDetailsData.current.bookingId =
                        response?.data?.data?.booking_id;
                      bookingDetailsData.current.pin =
                        response?.data?.data?.bookings.pin;
                      showSuccess(
                        response?.data?.message || "Payment success!"
                      );
                      navigate("/bookingSuccess", {
                        state: bookingDetailsData.current,
                      });
                    }
                  })
                  .catch((error: any) => {
                    showError(error?.response.data?.detail);
                    setLoading(false);
                  });
              }
            });
        } else if (paymentData?.data?.status === 200) {
          bookingDetailsData.current.bookingId =
            paymentData?.data?.data?.booking_id;
          bookingDetailsData.current.pin =
            paymentData?.data?.data?.bookings.pin;
          showSuccess(paymentData?.data?.message || "Payment success!");
          navigate("/bookingSuccess", { state: bookingDetailsData.current });
        } else if (paymentData.error) {
          showError(paymentData.error);
          setLoading(false);
        } else {
          showError("Something went wrong!");
          setLoading(false);
        }
      })
      .catch((err: any) => {
        showError(err?.response?.data?.detail || "Something went wrong!");
        setLoading(false);
      });
  };
  const removeCard = async (cardId: string) => {
    setRemovingCard((cards) => [...cards, cardId]);
    let newCardList = cardList.filter((card) => card.id !== cardId);
    setCardList(newCardList);
    setTimeout(async () => {
      const config: any = {
        method: "POST",
        url: `/api/v1/payment/card/remove/`,
        data: {
          booking_id: bookingId,
          card_id: cardId,
          is_save_card: true,
          stripeToken: userSettings.stripe_key,
        },
      };
      await Axios(config);
      initBooking();
    }, 300);
  };
  const confirmBookingSavedCard = (cardId: string) => {
    setCardList((cards: any) =>
      cards.map((card: any) => {
        let isProcessing = false;
        if (card.id === cardId) isProcessing = true;
        card.isProcessing = isProcessing;
        return card;
      })
    );
    const orderData: any = {
      booking_id: bookingId,
      card_id: cardId,
    };
    const config: any = {
      method: "POST",
      url: `/api/v1/payment/charge/savedcard/`,
      data: {
        ...orderData,
      },
    };

    Axios(config)
      .then((paymentData: any) => {
        if (paymentData?.data?.status === 200) {
          showSuccess(paymentData?.data?.message || "Payment success!");
          navigate("/bookingSuccess", { state: bookingDetailsData.current });
        } else {
          showError("Something went wrong!");
          setLoading(false);
        }
      })
      .catch((err: any) => {
        showError("Something went wrong!");
        setLoading(false);
      });
  };
  return (
    <>
      {isPaymentPageReady ? (
        <div className="mt-24">
          {cardList.length > 0 ? (
            <div>
              <div className="p-2">
                <p className="text-xl font-bold">Select a card</p>
              </div>
              <div className="grid grid-cols-12 space-x-2">
                {cardList?.map(
                  ({
                    exp_year,
                    exp_month,
                    brand,
                    last4,
                    name,
                    id,
                    isProcessing,
                  }: any) => (
                    <div className="flex flex-col col-span-12 md:col-span-3 bg-gray-800 p-8 mb-2 rounded-lg text-white font-bold">
                      <div>
                        <p className="text-lg font-mono">{brand}</p>
                      </div>
                      <div className="flex space-x-4 mt-2 items-center">
                        <p>XXXX</p>
                        <p>XXXX</p>
                        <p>XXXX</p>
                        <p>{last4}</p>
                        <div>
                          <FcSimCardChip size={50} />
                        </div>
                      </div>
                      <div className="mt-2">
                        <p>
                          exp: {exp_month}/{exp_year}
                        </p>
                      </div>
                      <div className="mt-4">
                        <p>{name}</p>
                      </div>
                      <div>
                        <div className="mt-4 justify-around flex">
                          <button
                            className={`px-4 py-2 bg-blue-700 rounded-lg ${
                              isLoading && "cursor-not-allowed"
                            }`}
                            onClick={() => {
                              setLoading(true);
                              confirmBookingSavedCard(id);
                            }}
                          >
                            <div className="flex p-2 justify-center items-center">
                              {isProcessing && (
                                <svg
                                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              )}
                              <span>Select & Pay</span>
                            </div>
                          </button>
                          <button
                            className={`px-4 py-2 bg-red-700 rounded-lg ${
                              isLoading && "cursor-not-allowed"
                            }`}
                            onClick={() => removeCard(id)}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="p-2">
            <p className="text-xl font-bold">Add a card and pay</p>
          </div>
          <div className="flex justify-center items-center roinded-lg border-none w-full">
            <div className="border-2 px-8 lg:px-12 xl:px-12 py-8 lg:py-12 xl:py-12 rounded-md lg:w-1/2 xl:w-1/2 w-full">
              <div className="mb-6 border-b-2">
                <p className="text-2xl pb-2">Enter Your Payment Details</p>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Card name"
                  className="outline-none border-b-2"
                  onChange={(e: any) => setCardName(e.target.value)}
                />
              </div>
              <div className="text-center min-w-fit  py-8">
                <CardElement options={CardElementOptions} />

                {/* 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 */}
              </div>
              <div className="w-full flex justify-between">
                <div>
                  <input
                    type="checkbox"
                    defaultChecked={isSaveCard}
                    onChange={() => setIsSaveCard(!isSaveCard)}
                  />{" "}
                  Save my card for faster payments
                </div>

                <img src={CardsImage} className="h-10" />
              </div>
              <div className="mt-6 radio-buttons text-center">
                <button
                  disabled={isLoading}
                  onClick={() => {
                    setLoading(true);
                    confirmBooking(elements.getElement(CardElement));
                  }}
                  className={`${
                    (isLoading || !cardName) && "cursor-not-allowed"
                  } w-52 bg-gradient-to-r text-white mt-1 p-1 rounded-lg text-lg font-bold bg-gray-800  hover:bg-yellow-600`}
                >
                  <div className="flex p-2 justify-center items-center">
                    {isLoading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}

                    <span>Confirm Booking</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center bg-gray-800 h-screen mt-12">
          {/* <p className="text-xl text-white">Loading...</p>{" "} */}
          <Loading />
        </div>
      )}
    </>
  );
};

export default Payment;
