import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const PickupDateTime = (props: any) => {
  const { selectedDate, setSelectedDate, selectedTime, setSelectedTime, t } =
    props;
  return (
    <div className="border border-gray-300 rounded p-4 h-32 lg:col-span-1 ">
      <div className="grid grid-flow-row grid-cols-3  lg:gap-4 xl:gap-4 md:gap-4">
        <div className="col-span-2">
          <div>
            <label className="text-sm">{t("Departure_Date")}</label>
          </div>
          {/* <label className='mt-2 font-bold text-gray-400 text-xl'>Pick your date.</label> */}
          {/* react-datepicker */}
          <div className="mt-4 text-xl font-bold">
            <DatePicker
              value={
                selectedDate ? moment(selectedDate).format("DD MMM, yyyy") : ""
              }
              onChange={(D: Date) =>
                setSelectedDate(moment(D).format("YYYY-MM-DD"))
              }
              placeholderText={t("select_date_placeholder")}
              minDate={new Date()}
              dateFormat="eee, dd MMM, yyyy"
            />
          </div>
        </div>
        <div>
          <label className="text-sm">{t("pickup_time")}</label>
          <div className="mt-4 text-xl font-bold">
            <DatePicker
            
              className="w-[6rem]"
              value={selectedTime}
              onChange={(T: any) =>
                setSelectedTime(moment(T).format("HH:mm"))
              }
              placeholderText="00:00"
              timeFormat="HH:mm"
              // minTime={new Date()}
              timeIntervals={5}
              showTimeSelect
              showTimeSelectOnly
              timeCaption="00:00"
              dateFormat="hh:mm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickupDateTime;
