import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link, Route, Routes } from "react-router-dom";
import Footer from "../Components/Footer";
import NavBarFixed from "../Components/NavBarFixed";
import ScrollTop from "../Components/ScrollTop";

import Axios from "../Utils/Axios";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { selectUserSettings } from "../store/authSlice";
const Activation = (props: any) => {
  const { currency, t } = props;
  const location = useLocation();
  const completeLocation = location.pathname;
  const activationId = completeLocation.replace("/email/activate/", "");
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const GetActivationDetails = async () => {
    try {
      const activationDetails: any = await Axios.get(
        "api/v1/user/email-activate/" + activationId
      ).catch((err: any) => {
        if (err.response.data) setMessage("The Link is Invalid");
      });
      if (activationDetails) {
        setMessage("Your account is activated successfully");
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    } catch (err: any) {
      // debugger
    }
  };
  useEffect(() => {
    GetActivationDetails();
  }, []);

  const userSettings = useSelector(selectUserSettings);

  return (
    <div className="h-screen bg-gray-800 grid">
      <ScrollTop />
      <Helmet>
        <title>Activation</title>
      </Helmet>
      <NavBarFixed currency={currency} t={t} />
      <div className="lg:p-4 lg:mt-10 mb-2">
        <div className="h-3/6	bg-gray-800  grid">
          <div className="flex flex-col justify-center items-center lg:py-10 bg-gray-800 ">
            <p className="lg:text-4xl text-2xl font-bolder text-white">
              Welcome to {userSettings?.site_name}{" "}
            </p>
            {message && (
              <p className="text-2xl mt-5 font-bolder text-yellow-200">
                {message}
              </p>
            )}

            <Link to="/Login">
              {" "}
              <img
                src={userSettings?.site_logo}
                className="h-20 sm:inline-flex md:inline-flex cursor-pointer"
                alt={userSettings?.site_name}
              />
            </Link>
          </div>{" "}
        </div>
        <div className="bg-gray-800 w-full ">
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default Activation;
