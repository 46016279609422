import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUserSettings } from "../store/authSlice";

const City = ({ cityDetails, city }: any) => {
  const userSettings = useSelector(selectUserSettings);

  return (
    <div className="bg_color">
      <div className="c_container_fluid">
        <div className="buchen_container">
          <div className="buchen_left">
            <h1>Book with {userSettings?.site_name}</h1>
            <p>We simplify your airport & city transfers worldwide.</p>
            <Link to="#">Book Now</Link>
          </div>
          <div className="buchen_right">
            <p>How does it work?</p>
            <div className="tools_options">
              <div className="t_items">
                <img src={require("../Assets/seo/1.png")} />
                <span>
                  Enter your pickup location and time and select your car.
                </span>
              </div>
              <div className="t_items">
                <img src={require("../Assets/seo/2.png")} />
                <span>
                  Check the booking details and select your preferred payment
                  method.
                </span>
              </div>
              <div className="t_items">
                <img src={require("../Assets/seo/3.png")} />
                <span>
                  Our {userSettings?.site_name} driver will pick you up at the
                  specified place and time.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="c_container_fluid">
        <div className="px-20 top_links">
          <span>Home</span>
          <span>
            <b>{city && city}</b>
          </span>
        </div>
      </div>

      <div className="c_container_fluid">
        <div className="px-20 full_container">
          <div className="left_mu">
            <ul>
              {cityDetails.sections &&
                cityDetails.sections.map((section: any, index: number) => (
                  <li>
                    <Link to="#">{section.title}</Link>
                  </li>
                ))}
            </ul>
          </div>
          <div className="right_mu">
            {cityDetails.sections &&
              cityDetails.sections.map((section: any, index: number) => (
                <div
                  className="right_content"
                  id={section.title.split(" ").join("-").toLowerCase()}
                >
                  <h3 className="h3_title">{section.title}</h3>

                  <div className="text_img">
                    <div className="text_r">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: section.description,
                        }}
                      ></div>
                      {/* <p>{section.description}</p> */}
                    </div>
                    <div className="img">
                      <img
                        alt={section.image_alt}
                        className=""
                        src={
                          section?.image ??
                          "https://cdn.rydeu.com/static/assets/content/images/europe/switzerland/zurich/zurich_AtaGlanceLake.jpg"
                        }
                      />
                      <div className="img_bottom_text">
                        {/* <span>{section.image_alt}</span> */}
                        {/* <i>Bild von<Link to="#">Susanne Jutzeler, suju-foto</Link> über <Link to="#">Pixabay</Link></i> */}
                      </div>
                    </div>
                  </div>
                  {/* <p>Zürich ist die perfekte Balance zwischen Schönheit und Luxus. Als Finanzhauptstadt des Landes ist sie für ihren üppigen Lebensstil bekannt. In fast jedem Winkel der Stadt finden Sie eine Reihe von High-End-Geschäften, Nischencafés, gehobenen Restaurants und reichhaltigen Schweizer Pralinen. Die Stadt ist bekannt für ihr vielfältiges Angebot an Käsefondue und Raclette. Da es die Heimat der feinen Schweizer Küche ist, sollten Sie das tadellose Zürcher Geschnetzeltes probieren.</p>
                <p>Staunen Sie über die Erhaltung des grotesken Flairs von Zürich und genießen Sie die modernen Schönheitsfehler seiner heutigen Architektur. Die schöne Pracht der Stadt ist nicht alles, Zürich ist auch eine der Top-Städte zum Leben. Der chipblaue Himmel und einige der besten Geschäfte für Mode und Einzelhandel machen diese Stadt zu einem echten Hit für jedermann.</p> */}
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* 
      <div className="c_container_fluid">
        <div className="px-20 beliebte_container">
          <h3 className="h3_title">Popular routes from {city && city}</h3>
          <div className="beliebte_row">
            <div className="belibite_items">
              <Link to="#">Zurich nach Freiburg</Link>
            </div>
            <div className="belibite_items">
              <Link to="#">Zurich nach Freiburg</Link>
            </div>
            <div className="belibite_items">
              <Link to="#">Zurich nach Freiburg</Link>
            </div>
            <div className="belibite_items">
              <Link to="#">Zurich nach Freiburg</Link>
            </div>
          </div>
          <div className="beliebte_row">
            <div className="belibite_items">
              <Link to="#">Zurich nach Freiburg</Link>
            </div>
            <div className="belibite_items">
              <Link to="#">Zurich nach Freiburg</Link>
            </div>
            <div className="belibite_items">
              <Link to="#">Zurich nach Freiburg</Link>
            </div>
            <div className="belibite_items">
              <Link to="#">Zurich nach Freiburg</Link>
            </div>
          </div>
          <div className="beliebte_row last_row">
            <div className="belibite_items">
              <Link to="#">Zurich nach Freiburg</Link>
            </div>
          </div>
        </div>
      </div> */}

      <div className="c_container_fluid">
        <div className="px-20 andere_container">
          <h2 className="h3_title text-center py-8 text-3xl font-bold">
            Other Cities
          </h2>
          <div className="andere_row">
            {cityDetails.other_cities &&
              cityDetails.other_cities.map((city_res: any, index: number) => (
                <div className="andere_card">
                  <img src={city_res.thumbnail_image} />
                  <span className="andere_text">{city_res.city_name}</span>
                  <Link
                    to={`/popular_locations/${city_res.slug?.toLocaleLowerCase()}`}
                    className="andere_link"
                  ></Link>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="px-20 c_container_fluid beli_sate_bg">
        <div className="beliebte_state_container">
          <h3 className="h3_title">POPULAR CITIES</h3>
          <div className="beliebte_state_row">
            <div className="beliebte_state_card">
              <Link to="#">Bodoa</Link>
              <Link to="#">Marseille</Link>
              <Link to="#">Bergen</Link>
              <Link to="#">Geneva</Link>
              <Link to="#">Lyon</Link>
              <Link to="#">Eindhoven</Link>
              <Link to="#">Lanzarote</Link>
              <Link to="#">Naples</Link>
            </div>
            <div className="beliebte_state_card">
              <Link to="#">Bodoa</Link>
              <Link to="#">Marseille</Link>
              <Link to="#">Bergen</Link>
              <Link to="#">Geneva</Link>
              <Link to="#">Lyon</Link>
              <Link to="#">Eindhoven</Link>
              <Link to="#">Lanzarote</Link>
              <Link to="#">Naples</Link>
            </div>
            <div className="beliebte_state_card">
              <Link to="#">Bodoa</Link>
              <Link to="#">Marseille</Link>
              <Link to="#">Bergen</Link>
              <Link to="#">Geneva</Link>
              <Link to="#">Lyon</Link>
              <Link to="#">Eindhoven</Link>
              <Link to="#">Lanzarote</Link>
              <Link to="#">Naples</Link>
            </div>
            <div className="beliebte_state_card">
              <Link to="#">Bodoa</Link>
              <Link to="#">Marseille</Link>
              <Link to="#">Bergen</Link>
              <Link to="#">Geneva</Link>
              <Link to="#">Lyon</Link>
              <Link to="#">Eindhoven</Link>
              <Link to="#">Lanzarote</Link>
              <Link to="#">Naples</Link>
            </div>
            <div className="beliebte_state_card">
              <Link to="#">Bodoa</Link>
              <Link to="#">Marseille</Link>
              <Link to="#">Bergen</Link>
              <Link to="#">Geneva</Link>
              <Link to="#">Lyon</Link>
              <Link to="#">Eindhoven</Link>
              <Link to="#">Lanzarote</Link>
              <Link to="#">Naples</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default City;
