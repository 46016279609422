import { z } from "zod";

export const flightBookingSchema = z
  .object({
    pickup_code: z.any(),
    pickup: z.any(),
    destination: z.any(),
    pickup_date: z.date(),
    return_date: z.date().optional(),
    no_of_passenger: z.coerce
      .number()
      .gt(0, "Passengers must be atleast 1.")
      .transform((val) => Number(val)),

    no_of_adults: z.coerce
      .number()
      .gte(0)
      .transform((val) => Number(val)),

    no_of_children: z.coerce
      .number()
      .gte(0)
      .transform((val) => Number(val)),

    no_of_luggage: z.coerce
      .number()
      .gte(0)
      .transform((val) => Number(val)),
  })
  .refine(
    (data) => data.no_of_adults + data.no_of_children === data.no_of_passenger,
    {
      message: "Please specify correct numbers of children and adults",
      path: ["no_of_adults"],
    }
  )
  .refine(
    (data) => data.no_of_adults + data.no_of_children === data.no_of_passenger,
    {
      message: "Please specify correct numbers of children and adults",
      path: ["no_of_children"],
    }
  );
