import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    authToken: null,
    isLoggedIn: false,
    settings: null,
  },
  reducers: {
    loginSuccess: (state, param) => {
      state.user = param.payload;
      state.isLoggedIn = true;
      state.authToken = param.payload.token?.access_token;
    },
    logout: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.authToken = null;
      state.settings = null;
    },
    updateUsername: (state, param) => {
      state.user.first_name = param.payload.first_name;
      state.user.last_name = param.payload.last_name;
    },
    updateUserSettings: (state, param) => {
      state.settings = {
        ...(state.settings ?? {}),
        ...param.payload,
      };
    },
  },
});

export const { loginSuccess, logout, updateUsername, updateUserSettings } =
  authSlice.actions;
export default authSlice.reducer;

const selectAuth = (state) => state.auth;

export const isLoggedIn = createSelector(selectAuth, (auth) => auth.isLoggedIn);

export const selectAuthToken = createSelector(
  selectAuth,
  (auth) => auth.authToken
);

export const selectUser = createSelector(selectAuth, (auth) => auth.user);

export const selectUserSettings = createSelector(
  selectAuth,
  (auth) => auth.settings
);
