import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineMail, AiOutlineMobile, AiOutlineUser } from "react-icons/ai";
import { BiBuilding } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import Footer from "../Components/Footer";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { selectUserSettings } from "../store/authSlice";

const LogoContent = (navigate: any) => {
  const userSettings = useSelector(selectUserSettings);

  return (
    <>
      <p className="text-4xl font-bolder text-white">
        Welcome back to {userSettings?.site_name}
      </p>
      <img
        src={userSettings?.site_logo}
        className="h-20 cursor-pointer"
        alt={userSettings?.site_name}
        onClick={() => navigate("/")}
      />
    </>
  );
};
const CountryCode = ({ isdCode, setIsdCode }: any) => {
  return (
    <div className="relative">
      <p
        className="absolute -left-2 w-12 z-50 bg-white top-2"
        onClick={() => document.getElementById("isdcode")?.click()}
      >
        {isdCode}
      </p>
      <select
        className="w-16 h-10 -ml-2 absolute cursor-pointer outline-none"
        name="isdcode"
        id="isdcode"
        value={isdCode || "+91"}
        onChange={(e: any) => {
          setIsdCode(e.target.value);
        }}
      >
        <option value="+0">Other Country (+0)</option>

        <option value="+376">Andorra (+376)</option>

        <option value="+971">United Arab Emirates (+971)</option>

        <option value="+93">Afghanistan (+93)</option>

        <option value="+1">Antigua and Barbuda (+1)</option>

        <option value="+1">Anguilla (+1)</option>

        <option value="+355">Albania (+355)</option>

        <option value="+374">Armenia (+374)</option>

        <option value="+244">Angola (+244)</option>

        <option value="+0">Asia/Pacific Region (+0)</option>

        <option value="+0">Antarctica (+0)</option>

        <option value="+54">Argentina (+54)</option>

        <option value="+1">American Samoa (+1)</option>

        <option value="+43">Austria (+43)</option>

        <option value="+61">Australia (+61)</option>

        <option value="+297">Aruba (+297)</option>

        <option value="+358">Aland Islands (+358)</option>

        <option value="+994">Azerbaijan (+994)</option>

        <option value="+387">Bosnia and Herzegovina (+387)</option>

        <option value="+1">Barbados (+1)</option>

        <option value="+880">Bangladesh (+880)</option>

        <option value="+32">Belgium (+32)</option>

        <option value="+226">Burkina Faso (+226)</option>

        <option value="+359">Bulgaria (+359)</option>

        <option value="+973">Bahrain (+973)</option>

        <option value="+257">Burundi (+257)</option>

        <option value="+229">Benin (+229)</option>

        <option value="+590">Saint Bartelemey (+590)</option>

        <option value="+1">Bermuda (+1)</option>

        <option value="+673">Brunei Darussalam (+673)</option>

        <option value="+591">Bolivia (+591)</option>

        <option value="+599">Bonaire, Saint Eustatius and Saba (+599)</option>

        <option value="+55">Brazil (+55)</option>

        <option value="+1">Bahamas (+1)</option>

        <option value="+975">Bhutan (+975)</option>

        <option value="+0">Bouvet Island (+0)</option>

        <option value="+267">Botswana (+267)</option>

        <option value="+375">Belarus (+375)</option>

        <option value="+501">Belize (+501)</option>

        <option value="+1">Canada (+1)</option>

        <option value="+61">Cocos (Keeling) Islands (+61)</option>

        <option value="+243">
          Congo, The Democratic Republic of the (+243)
        </option>

        <option value="+236">Central African Republic (+236)</option>

        <option value="+242">Congo (+242)</option>

        <option value="+41">Switzerland (+41)</option>

        <option value="+225">Cote d'Ivoire (+225)</option>

        <option value="+682">Cook Islands (+682)</option>

        <option value="+56">Chile (+56)</option>

        <option value="+237">Cameroon (+237)</option>

        <option value="+86">China (+86)</option>

        <option value="+57">Colombia (+57)</option>

        <option value="+506">Costa Rica (+506)</option>

        <option value="+53">Cuba (+53)</option>

        <option value="+238">Cape Verde (+238)</option>

        <option value="+599">Curacao (+599)</option>

        <option value="+61">Christmas Island (+61)</option>

        <option value="+357">Cyprus (+357)</option>

        <option value="+420">Czech Republic (+420)</option>

        <option value="+49">Germany (+49)</option>

        <option value="+253">Djibouti (+253)</option>

        <option value="+45">Denmark (+45)</option>

        <option value="+1">Dominica (+1)</option>

        <option value="+1">Dominican Republic (+1)</option>

        <option value="+213">Algeria (+213)</option>

        <option value="+593">Ecuador (+593)</option>

        <option value="+372">Estonia (+372)</option>

        <option value="+20">Egypt (+20)</option>

        <option value="+212">Western Sahara (+212)</option>

        <option value="+291">Eritrea (+291)</option>

        <option value="+34">Spain (+34)</option>

        <option value="+251">Ethiopia (+251)</option>

        <option value="+0">Europe (+0)</option>

        <option value="+358">Finland (+358)</option>

        <option value="+679">Fiji (+679)</option>

        <option value="+500">Falkland Islands (Malvinas) (+500)</option>

        <option value="+691">Micronesia, Federated States of (+691)</option>

        <option value="+298">Faroe Islands (+298)</option>

        <option value="+33">France (+33)</option>

        <option value="+241">Gabon (+241)</option>

        <option value="+44">United Kingdom (+44)</option>

        <option value="+1">Grenada (+1)</option>

        <option value="+995">Georgia (+995)</option>

        <option value="+594">French Guiana (+594)</option>

        <option value="+44">Guernsey (+44)</option>

        <option value="+233">Ghana (+233)</option>

        <option value="+350">Gibraltar (+350)</option>

        <option value="+299">Greenland (+299)</option>

        <option value="+220">Gambia (+220)</option>

        <option value="+224">Guinea (+224)</option>

        <option value="+590">Guadeloupe (+590)</option>

        <option value="+240">Equatorial Guinea (+240)</option>

        <option value="+30">Greece (+30)</option>

        <option value="+0">
          South Georgia and the South Sandwich Islands (+0)
        </option>

        <option value="+502">Guatemala (+502)</option>

        <option value="+1">Guam (+1)</option>

        <option value="+245">Guinea-Bissau (+245)</option>

        <option value="+592">Guyana (+592)</option>

        <option value="+852">Hong Kong (+852)</option>

        <option value="+0">Heard Island and McDonald Islands (+0)</option>

        <option value="+504">Honduras (+504)</option>

        <option value="+385">Croatia (+385)</option>

        <option value="+509">Haiti (+509)</option>

        <option value="+36">Hungary (+36)</option>

        <option value="+62">Indonesia (+62)</option>

        <option value="+353">Ireland (+353)</option>

        <option value="+972">Israel (+972)</option>

        <option value="+44">Isle of Man (+44)</option>

        <option value="+91">India (+91)</option>

        <option value="+246">British Indian Ocean Territory (+246)</option>

        <option value="+964">Iraq (+964)</option>

        <option value="+98">Iran, Islamic Republic of (+98)</option>

        <option value="+354">Iceland (+354)</option>

        <option value="+39">Italy (+39)</option>

        <option value="+44">Jersey (+44)</option>

        <option value="+1">Jamaica (+1)</option>

        <option value="+962">Jordan (+962)</option>

        <option value="+81">Japan (+81)</option>

        <option value="+254">Kenya (+254)</option>

        <option value="+996">Kyrgyzstan (+996)</option>

        <option value="+855">Cambodia (+855)</option>

        <option value="+686">Kiribati (+686)</option>

        <option value="+269">Comoros (+269)</option>

        <option value="+1">Saint Kitts and Nevis (+1)</option>

        <option value="+850">
          Korea, Democratic People's Republic of (+850)
        </option>

        <option value="+82">Korea, Republic of (+82)</option>

        <option value="+965">Kuwait (+965)</option>

        <option value="+1">Cayman Islands (+1)</option>

        <option value="+7">Kazakhstan (+7)</option>

        <option value="+856">Lao People's Democratic Republic (+856)</option>

        <option value="+961">Lebanon (+961)</option>

        <option value="+1">Saint Lucia (+1)</option>

        <option value="+423">Liechtenstein (+423)</option>

        <option value="+94">Sri Lanka (+94)</option>

        <option value="+231">Liberia (+231)</option>

        <option value="+266">Lesotho (+266)</option>

        <option value="+370">Lithuania (+370)</option>

        <option value="+352">Luxembourg (+352)</option>

        <option value="+371">Latvia (+371)</option>

        <option value="+218">Libyan Arab Jamahiriya (+218)</option>

        <option value="+212">Morocco (+212)</option>

        <option value="+377">Monaco (+377)</option>

        <option value="+373">Moldova, Republic of (+373)</option>

        <option value="+382">Montenegro (+382)</option>

        <option value="+590">Saint Martin (+590)</option>

        <option value="+261">Madagascar (+261)</option>

        <option value="+692">Marshall Islands (+692)</option>

        <option value="+389">Macedonia (+389)</option>

        <option value="+223">Mali (+223)</option>

        <option value="+95">Myanmar (+95)</option>

        <option value="+976">Mongolia (+976)</option>

        <option value="+853">Macao (+853)</option>

        <option value="+1">Northern Mariana Islands (+1)</option>

        <option value="+596">Martinique (+596)</option>

        <option value="+222">Mauritania (+222)</option>

        <option value="+1">Montserrat (+1)</option>

        <option value="+356">Malta (+356)</option>

        <option value="+230">Mauritius (+230)</option>

        <option value="+960">Maldives (+960)</option>

        <option value="+265">Malawi (+265)</option>

        <option value="+52">Mexico (+52)</option>

        <option value="+60">Malaysia (+60)</option>

        <option value="+258">Mozambique (+258)</option>

        <option value="+264">Namibia (+264)</option>

        <option value="+687">New Caledonia (+687)</option>

        <option value="+227">Niger (+227)</option>

        <option value="+672">Norfolk Island (+672)</option>

        <option value="+234">Nigeria (+234)</option>

        <option value="+505">Nicaragua (+505)</option>

        <option value="+31">Netherlands (+31)</option>

        <option value="+47">Norway (+47)</option>

        <option value="+977">Nepal (+977)</option>

        <option value="+674">Nauru (+674)</option>

        <option value="+683">Niue (+683)</option>

        <option value="+64">New Zealand (+64)</option>

        <option value="+968">Oman (+968)</option>

        <option value="+507">Panama (+507)</option>

        <option value="+51">Peru (+51)</option>

        <option value="+689">French Polynesia (+689)</option>

        <option value="+675">Papua New Guinea (+675)</option>

        <option value="+63">Philippines (+63)</option>

        <option value="+92">Pakistan (+92)</option>

        <option value="+48">Poland (+48)</option>

        <option value="+508">Saint Pierre and Miquelon (+508)</option>

        <option value="+0">Pitcairn (+0)</option>

        <option value="+1">Puerto Rico (+1)</option>

        <option value="+970">Palestinian Territory (+970)</option>

        <option value="+351">Portugal (+351)</option>

        <option value="+680">Palau (+680)</option>

        <option value="+595">Paraguay (+595)</option>

        <option value="+974">Qatar (+974)</option>

        <option value="+262">Reunion (+262)</option>

        <option value="+40">Romania (+40)</option>

        <option value="+381">Serbia (+381)</option>

        <option value="+7">Russian Federation (+7)</option>

        <option value="+250">Rwanda (+250)</option>

        <option value="+966">Saudi Arabia (+966)</option>

        <option value="+677">Solomon Islands (+677)</option>

        <option value="+248">Seychelles (+248)</option>

        <option value="+249">Sudan (+249)</option>

        <option value="+46">Sweden (+46)</option>

        <option value="+65">Singapore (+65)</option>

        <option value="+290">Saint Helena (+290)</option>

        <option value="+386">Slovenia (+386)</option>

        <option value="+47">Svalbard and Jan Mayen (+47)</option>

        <option value="+421">Slovakia (+421)</option>

        <option value="+232">Sierra Leone (+232)</option>

        <option value="+378">San Marino (+378)</option>

        <option value="+221">Senegal (+221)</option>

        <option value="+252">Somalia (+252)</option>

        <option value="+597">Suriname (+597)</option>

        <option value="+211">South Sudan (+211)</option>

        <option value="+239">Sao Tome and Principe (+239)</option>

        <option value="+503">El Salvador (+503)</option>

        <option value="+1">Sint Maarten (+1)</option>

        <option value="+963">Syrian Arab Republic (+963)</option>

        <option value="+268">Swaziland (+268)</option>

        <option value="+1">Turks and Caicos Islands (+1)</option>

        <option value="+235">Chad (+235)</option>

        <option value="+0">French Southern Territories (+0)</option>

        <option value="+228">Togo (+228)</option>

        <option value="+66">Thailand (+66)</option>

        <option value="+992">Tajikistan (+992)</option>

        <option value="+690">Tokelau (+690)</option>

        <option value="+670">Timor-Leste (+670)</option>

        <option value="+993">Turkmenistan (+993)</option>

        <option value="+216">Tunisia (+216)</option>

        <option value="+676">Tonga (+676)</option>

        <option value="+90">Turkey (+90)</option>

        <option value="+1">Trinidad and Tobago (+1)</option>

        <option value="+688">Tuvalu (+688)</option>

        <option value="+886">Taiwan (+886)</option>

        <option value="+255">Tanzania, United Republic of (+255)</option>

        <option value="+380">Ukraine (+380)</option>

        <option value="+256">Uganda (+256)</option>

        <option value="+0">United States Minor Outlying Islands (+0)</option>

        <option value="+1">United States (+1)</option>

        <option value="+598">Uruguay (+598)</option>

        <option value="+998">Uzbekistan (+998)</option>

        <option value="+39">Holy See (Vatican City State) (+39)</option>

        <option value="+1">Saint Vincent and the Grenadines (+1)</option>

        <option value="+58">Venezuela (+58)</option>

        <option value="+1">Virgin Islands, British (+1)</option>

        <option value="+1">Virgin Islands, U.S. (+1)</option>

        <option value="+84">Vietnam (+84)</option>

        <option value="+678">Vanuatu (+678)</option>

        <option value="+681">Wallis and Futuna (+681)</option>

        <option value="+685">Samoa (+685)</option>

        <option value="+967">Yemen (+967)</option>

        <option value="+262">Mayotte (+262)</option>

        <option value="+27">South Africa (+27)</option>

        <option value="+260">Zambia (+260)</option>

        <option value="+263">Zimbabwe (+263)</option>
      </select>
    </div>
  );
};
const BecomePartner = (props: any) => {
  const navigate: any = useNavigate();
  const [isdCode, setIsdCode] = useState<any>("+91");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    // console.log(data)
    emailjs
      .send("service_e66i1jl", "template_6u5vhz9", data, "N7Gll-SNSDmw3kFkG")
      .then(
        (result) => {
          // console.log(result.text);
          alert("Our Team will reach out to you, Thanks!");
          navigate("/");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("change", event.target.value);
  };

  const userSettings = useSelector(selectUserSettings);

  return (
    <div className="h-screen bg-gray-800 grid grid-cols-2">
      <Helmet>
        <title>Become Partner</title>
      </Helmet>
      <div className="lg:flex hidden flex-col justify-center items-center p-10 sm:p-18 text-center bg-gray-800 ">
        <p className="text-4xl font-bolder text-white">
          Welcome to {userSettings?.site_name}
        </p>
        <img
          src={userSettings?.site_logo}
          className="h-20 cursor-pointer"
          alt={userSettings?.site_name}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="col-span-2 lg:col-span-1 flex flex-col justify-center items-center text-center p-10 bg-gray-800">
        <div className="flex-col flex justify-center items-center text-center lg:hidden">
          {LogoContent(navigate)}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white px-6 py-8 rounded-xl mt-6 w-96">
            <p className="text-3xl font-bold">Enroll With Us</p>
            <div className="py-2 flex mt-8">
              <BiBuilding className="w-10 h-10" />
              <input
                required
                type="text"
                placeholder="Enter your Company Name"
                className="py-2 pl-2 w-80 border-b-2 border-blue-400 focus:outline-0 focus:shadow-lg"
                {...register("companyName")}
              />
            </div>
            <div className="py-2 flex">
              <AiOutlineUser className="w-10 h-10" />
              <input
                required
                type="text"
                placeholder="Enter your Name"
                className="py-2 pl-2 w-80 border-b-2 border-blue-400 focus:outline-0 focus:shadow-lg"
                {...register("userName")}
              />
            </div>
            <div className="py-2 flex">
              <AiOutlineMail className="w-10 h-10" />
              <input
                required
                type="email"
                placeholder="Enter your Email"
                className="py-2 pl-2 w-80 border-b-2 border-blue-400 focus:outline-0 focus:shadow-lg"
                {...register("email")}
              />
            </div>
            <div className="py-2 flex row">
              <AiOutlineMobile className="w-10 h-10" />

              <CountryCode isdCode={isdCode} setIsdCode={setIsdCode} />
              <input
                required
                type="phone"
                placeholder="Enter your mobile number"
                className="py-2 pl-16 w-80 border-b-2 border-blue-400 focus:outline-0 focus:shadow-lg"
                {...register("mobile")}
              />
            </div>
            <div className="py-2 flex">
              <MdLocationOn className="w-10 h-10" />
              <input
                required
                type="city"
                placeholder="Enter your Operating City"
                className="py-2 pl-2 w-80 border-b-2 border-blue-400 focus:outline-0 focus:shadow-lg"
                {...register("city")}
              />
            </div>
            <div className="py-2 flex">
              <BiWorld className="w-10 h-10" />
              <input
                required
                type="webaddress"
                placeholder="Enter your Web Site"
                className="py-2 pl-2 w-80 border-b-2 border-blue-400 focus:outline-0 focus:shadow-lg"
                {...register("website")}
              />
            </div>
            <div className="py flex">
              <p className="font-bold">Select your business type</p>
            </div>
            <div className="flex">
              <div className="grid grid-cols-12">
                <div className="col-span-6 flex items-center">
                  <div>
                    <input
                      type="checkbox"
                      value="Fleets"
                      className="mt-2 mr-2 cursor-pointer"
                      {...register("businessType")}
                    />
                  </div>
                  <p className="text-left">Fleets</p>
                </div>
                <div className="col-span-6 flex items-center">
                  <div>
                    <input
                      {...register("businessType")}
                      type="checkbox"
                      value="Tour Operators"
                      className="mt-2 mr-2 cursor-pointer"
                    />
                  </div>
                  <p className="text-left">Tour Operator</p>
                </div>
                <div className="col-span-6 flex items-center">
                  <div>
                    <input
                      {...register("businessType")}
                      type="checkbox"
                      value="Travel Agency"
                      className="mt-2 mr-2 cursor-pointer"
                    />
                  </div>
                  <p className="text-left">Travel Agency</p>
                </div>
                <div className="col-span-6 flex items-center">
                  <div>
                    <input
                      {...register("businessType")}
                      type="checkbox"
                      value="Corporate"
                      className="mt-2 mr-2 cursor-pointer"
                    />
                  </div>
                  <p className="text-left">Corporate</p>
                </div>
                <div className="col-span-6 flex items-center">
                  <div>
                    <input
                      {...register("businessType")}
                      type="checkbox"
                      value="Events Company"
                      className="mt-2 mr-2 cursor-pointer"
                    />
                  </div>
                  <p className="text-left">Events Company</p>
                </div>
              </div>
            </div>
            <input
              type={"submit"}
              value="Enroll Now"
              className="w-full mt-6 rounded-lg bg-blue-600 px-10 py-4 text-white font-bold cursor-pointer"
            />
          </div>
        </form>
      </div>
      <div className="bg-gray-800 w-full col-span-2">
        <Footer />
      </div>
    </div>
  );
};

export default BecomePartner;
