import React, { useEffect, useState } from "react";
import Axios from "../../../Utils/Axios";
import Loading from "../../Loading";
import Modal from "react-modal";
import { Slide, toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
import { string } from "yup";
import { currencyList } from "../../NavBarFixed";
import { flightData } from "./flightsData";
import axios from "axios";
import Select from "react-select";
import AIRPORTS from "../../../Pages/FlightBooking/airports.json";
import { forEach } from "underscore";
import { TimePicker } from "antd";

type Flight = {
  [id: number]: any;
  [pickup: string]: any;

  destination: string;
  pickup_date: any;
  is_paid: string;
  no_of_adults: number;
  no_of_children: number;
  no_of_luggage: number;
  no_of_passenger: number;
  // Add more properties as needed
};

type StopageItem = {
  stop_name: string;
  minutes: string;
  airline_name:string;
};
type FlightData = {
  flight_number: string;
  airline_name: string;
  airline_data: any;
  fare: string;
  currency: string;
  cancellation_hour: number;
  no_of_stop: number;
  arrival_time: string;
  departure_time: string;
  departure_date: any;
  stopage: StopageItem[];
};

function Flight() {
  const initialStopage: StopageItem = { stop_name: "", minutes: "", airline_name:"" };
  const [isLoading, setLoading] = useState(false);
  const [flights, setFlights] = useState<Flight[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [minDate, setMinDate] = useState<string>();
  const [check, setCheck] = useState(false);
  const [sameDate, setSameDate] = useState("");
  const [status, setStatus] = useState("generated");
  const [newData, setNewData] = useState<FlightData>({
    airline_name: "",
    flight_number: "",
    airline_data: {},
    fare: "",
    currency: "USD",
    cancellation_hour: 0,
    arrival_time: "",
    departure_time: "",
    departure_date: "",
    stopage: [
      // {"stop_name": "Bombay"},
      // {"stop_name": "Madras"}
    ],
    no_of_stop: 0,
  });

  const airportSelectList = AIRPORTS.map((airport) => ({
    label: `${airport.name} - ${airport.city}, ${airport.state}, ${airport.country}`,
    value: airport,
  }));

  const [errors, setErrors] = useState(false);

  const loadFlights = async (status:string) => {
    setLoading(true);

    try {
      const response = await Axios.get(
        `/api/v1/limoairport/flightrequest/agency/${status}`
      );
      const data = response.data; // Assuming response is a JSON object

      if (data?.data) {
        setFlights(data?.data);
      }
    } catch (error) {
      console.error("Error fetching flights:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleActionClick = (flightId: any) => {
    // Open the modal
    newData.flight_number = flightId?.flightrequestno;
    
    // Input date in ISO 8601 format with Zulu time zone
    let inputDate = new Date(flightId?.pickup_date);

    // Add one day to the date
    inputDate.setDate(inputDate.getDate());

    // Format the output date as "YYYY-MM-DD"
    let outputDate = inputDate.toISOString().split("T")[0];
    setSameDate(() => outputDate);

    // Add one day to the date
    inputDate.setDate(inputDate.getDate());

    // Format the output date as "YYYY-MM-DD"
    let outputDate2 = inputDate.toISOString().split("T")[0];

    setMinDate(() => outputDate2);

    setModalOpen(true);
  };
  const handleModalClose = () => {

    setCheck(()=>false)
    // clear state
    setNewData({
      airline_name: "",
      flight_number: "",
      airline_data: {},
      fare: "",
      currency: "USD",
      cancellation_hour: 0,
      no_of_stop: 0,
      arrival_time: "",
      departure_time: "",
      departure_date: "",
      stopage: [],
    });
    // Close the modal
    setModalOpen(false);
  };
  const handleSaveData = async () => {
    const myObj: any = { ...newData };
    let formErr = false;
    for (const key in myObj) {
      if (!myObj[key]) {
        if (key === "no_of_stop" || key === "cancellation_hour") {
          if (myObj[key] === "") {
            formErr = true;
          }
        } else {
          formErr = true;
        }
      }
    }
    setErrors(formErr);

    if (!formErr) {
      try {
        // Make API call to save data
        let response = await Axios.post(
          "/api/v1/limoairport/agencyrespond/",
          newData
        );

        // Check if the response is successful (status code 200)
        if (response?.status === 201) {
          // Show success toast
          toast.success("Data Saved Successfully.", {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          });
          // clear state
          setNewData({
            airline_name: "",
            flight_number: "",
            airline_data: {},
            arrival_time: "",
            departure_time: "",
            fare: "",
            currency: "",
            cancellation_hour: 0,
            no_of_stop: 0,
            departure_date: "",
            stopage: [],
          });
          // Reload flights after saving data
          loadFlights(status);

          // Close the modal
          setModalOpen(false);
        } else {
          // Handle unsuccessful response (non-200 status code)
          toast.error("Error saving data. Please try again.", {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          });
        }
      } catch (error) {
        // Handle API call error
        // You can add additional logic here to handle the error, e.g., show an error toast
        toast.error("Error saving data. Please try again", {
          position: "top-center",
          autoClose: 3000,
          closeOnClick: true,
          transition: Slide,
        });
      }
    }
  };

  const handlecheck = (e: any) => {
    setCheck((prev) => !prev);
  };

  const handleAddStopage = () => {
    setNewData((prevData) => ({
      ...prevData,
      stopage: [...prevData.stopage, { ...initialStopage }],
      no_of_stop: prevData.no_of_stop + 1,
    }));
  };

  const handleRemoveStopage = (index: number) => {
    setNewData((prevData) => {
      const stopageCopy = [...prevData.stopage];
      stopageCopy.splice(index, 1);
      return {
        ...prevData,
        stopage: stopageCopy,
        no_of_stop: prevData.no_of_stop - 1,
      };
    });
  };
  const handleStopageChange = (index: number, event: any) => {
    const { value, name } = event;
    
    // stop_name
    setNewData((prevData) => {
      const stopageCopy: any[] = [...prevData.stopage];
      stopageCopy[index] = { ...stopageCopy[index], [name]: value };
      return { ...prevData, stopage: stopageCopy };
    });
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;

    setNewData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (data: any) => {
    setNewData((prevData) => ({
      ...prevData,
      airline_name: data.value.name,
      airline_data: data.value,
    }));
  };

  const handleStopageSelectChange = (data:any,ind:number)=>{

     // stop_airline
     setNewData((prevData) => {
      const stopageCopy: any[] = [...prevData.stopage];
      stopageCopy[ind] = { ...stopageCopy[ind], airline_name: data.label  , airline_data:data.value};
      return { ...prevData, stopage: stopageCopy };
    });

  }

  const handleStopageSelectChange2 = (data:any,ind:number)=>{

    // stop_airline
    setNewData((prevData) => {
     const stopageCopy: any[] = [...prevData.stopage];
     stopageCopy[ind] = { ...stopageCopy[ind], stop_name: data.label  , stop_data:data.value};
     return { ...prevData, stopage: stopageCopy };
   });

 }

 const handleTimeChange = (e:any,name:string)=>{
let time = `${e?.$H }`+":"+`${e?.$m }`

setNewData((prev)=>({...prev,[name]:time}))
 }

  useEffect(() => {
    loadFlights(status);
  }, [status]); // Empty dependency array ensures that the effect runs only once on mount


  

  useEffect(() => {
    if (check) {
      
      setNewData((prev)=>({...prev, departure_date:minDate}))
    }
  }, [check]);
  return (
    <div className="agent-flight-page">
      <div className="respond-status">
        <div className="respond-status-inner">
        <div style={{color:status==="generated"?"rgb(202 138 4 )":"black"}} onClick={()=>setStatus("generated")}>Genrated</div>
        <div>|</div>
        <div style={{color:status==="respond"?"rgb(202 138 4 )":"black"}} onClick={()=>setStatus("respond")}>Responded</div>
        </div>
      </div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="agent-flight" style={{ overflowX: "auto" }}>
          <table
            style={{
              borderCollapse: "collapse",
              borderSpacing: 0,
              width: "100%",
              border: "1px solid #ddd",
            }}
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Pickup</th>
                <th>Paid</th>
                <th>Destination</th>
                <th>Departure Date</th>
                <th>Adults</th>
                <th>Children</th>
                <th>Luggage</th>
                <th>Passenger</th>
                <th>Action</th>
                {/* Add more table headings based on your data */}
              </tr>
            </thead>

            <tbody>
              {flights.map((flight, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{flight.pickup}</td>
                  <td>{(flight.is_paid && "Yes") || "No"}</td>

                  <td>{flight.destination}</td>
                  <td>
                    {new Date(flight.pickup_date).toLocaleDateString("en-US")}
                  </td>
                  <td>{flight.no_of_adults}</td>
                  <td>{flight.no_of_children}</td>
                  <td>{flight.no_of_luggage}</td>
                  <td>{flight.no_of_passenger}</td>

                  <td>
                    <button onClick={() => handleActionClick(flight)}>
                      <FaEye />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Example Modal"
      >
        <form className="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Flight Request Number:
            </label>
            <input
              type="text"
              name="flight_number"
              value={newData.flight_number}
              readOnly
              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>

          {/* <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Airline Name:
            </label>
            <input
              type="text"
              name="airline_name"
              value={newData.airline_name}
              onChange={(e) => handleInputChange(e)}
              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <label className="text-red-500">
              {" "}
              {errors && !newData.airline_name && "Field is required !!"}
            </label>
          </div> */}

          <div
            className="mb-4"
            style={{ zIndex: "99999999999", position: "relative" }}
          >
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Airline Name:
            </label>
            <Select
              options={flightData}
              value={flightData.find(
                (option: any) => option.value === newData.airline_name
              )}
              onChange={(selectedOption) => handleSelectChange(selectedOption)}
              className="mt-1"
              isSearchable
              placeholder="Select Airline"
              name="airline_name"
            />
            <label className="text-red-500">
              {errors && !newData.airline_name && "Field is required !!"}
            </label>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Fare:
            </label>
            <input
              type="text"
              name="fare"
              value={newData.fare}
              onChange={(e) => handleInputChange(e)}
              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <label className="text-red-500">
              {errors && !newData.fare && "Field is required !!"}
            </label>
          </div>

          <div className="mb-4 ">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Currency:
            </label>
            <select
              name="currency"
              id="currency"
              value={newData.currency}
              onChange={(e: any) => handleInputChange(e)}
              className="lg:w-36 xl:w-36 md:w-36 w-24 text-bold font-4xl lg:px-7 xl:px-7 md:px-7 px-2  hover:text-yellow-600 cursor-pointer  tracking-wide relative z-50 bg-transparent border-gray-300  form-input mt-1 block w-full rounded-md  focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              {currencyList.map((lng: any, index: number) => (
                <option
                  key={index}
                  className={`w-20  hover:text-yellow-600 text-black ${
                    index < currencyList.length - 1 && "border-b-2"
                  }`}
                  value={lng.value}
                >
                  {lng.value} {lng.label}
                </option>
              ))}
            </select>

            <label className="text-red-500">
              {errors && !newData.currency && "Field is required !!"}
            </label>
          </div>

          <div className="mb-4">
            <div style={{ display: "flex" }}>
              {" "}
              <div>
                {" "}
                <input
                  type="checkbox"
                  checked={check}
                  onChange={(e) => handlecheck(e)}
                />
              </div>
              <div style={{ paddingBottom: "3px" }}>
                {" "}
                <label className="px-2 text-gray-700 text-sm font-bold mb-2 pb-1">
                  Departure Date is same as Arival date
                </label>
              </div>
            </div>
            <label className="text-red-500">
              {errors && !newData.departure_date && "Field is required !!"}
            </label>
          </div>
          {/* {!check ? (
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Departure Date
              </label>
              <input
                type="date"
                name="departure_date"
                
                value={newData.departure_date}
                min={minDate}
                onChange={(e) => handleInputChange(e)}
                className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <label className="text-red-500">
                {errors && !newData.departure_date && "Field is required !!"}
              </label>
            </div>
          ) : null} */}

          <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Departure Date
              </label>
              <input
                type="date"
                name="departure_date"
                value={newData.departure_date}
                min={minDate}
                onChange={(e) => handleInputChange(e)}
                className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <label className="text-red-500">
                {errors && !newData.departure_date && "Field is required !!"}
              </label>
            </div>

 <div className="mb-4 tBtn">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Departure Time
            </label>


            <TimePicker
            format={"HH:mm"}
            onChange={(e) => handleTimeChange(e,'departure_time')}
            className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
           
            <label className="text-red-500">
              {errors && !newData.departure_time && "Field is required !!"}
            </label>
          </div>

          <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Arrival Date
              </label>
              <input
                type="date"
                name="Arrival_date"
                value={minDate}
                
                className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                disabled
              />
              <label className="text-red-500">
                {errors && !newData.departure_date && "Field is required !!"}
              </label>
            </div>


          <div className="mb-4 tBtn">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Arival Time
            </label>
            <TimePicker
            format={"HH:mm"}
            onChange={(e) => handleTimeChange(e,'arrival_time')}
            className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />

          
            <label className="text-red-500">
              {errors && !newData.arrival_time && "Field is required !!"}
            </label>
          </div>

         

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Cancellation Hour:
            </label>
            <input
              type="text"
              name="cancellation_hour"
              value={newData.cancellation_hour}
              onChange={(e) => handleInputChange(e)}
              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <label className="text-red-500">
              {errors && !newData.cancellation_hour && "Field is required !!"}
            </label>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Stopage:
            </label>
            {newData.stopage.map((stopage, index) => (
              <div key={index} className="mb-2">
                <div className="text-gray-700 text-lg font-bold">
                  Stop {index + 1}
                </div>
                <div>
                  <div className="text-gray-700 text-sm font-bold">Name:</div>
                  <div>
                

                    <Select
                      placeholder={"Stop Name"}
                      options={airportSelectList}
                      className="cursor-pointer"
                      value={airportSelectList.find(
                        (option: any) => option.value === newData.stopage[index].airline_name
                      )}
                      onChange={(selectedOption) =>
                        handleStopageSelectChange2(selectedOption,index)
                      }
                    />
                  </div>
                  <div className="block text-gray-700 text-sm font-bold">
                      Airline Name:
                    </div>
                    <Select
                      options={flightData}
                      value={flightData.find(
                        (option: any) => option.value === newData.stopage[index].stop_name
                      )}
                      onChange={(selectedOption) =>
                        handleStopageSelectChange(selectedOption,index)
                      }
                      className="mt-1"
                      isSearchable
                      placeholder="Select Airline"
                      name="airline_name"
                    />
                </div>
                <div>
                  <div className="text-gray-700 text-sm font-bold">Time in minutes:</div>
                  <div className="flex items-center ">
                    <input
                      type="text"
                      name={`minutes`}
                      value={stopage.minutes}
                      onChange={(e) => handleStopageChange(index, e.target)}
                      className="form-input mt-1 w-3/4 rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />

                    <button
                      type="button"
                      onClick={() => handleRemoveStopage(index)}
                      className="ml-2 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:shadow-outline-red active:bg-red-800"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddStopage}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
            >
              Add Stopage
            </button>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleSaveData}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:shadow-outline-green active:bg-green-800"
            >
              Save
            </button>
            <button
              type="button"
              onClick={handleModalClose}
              className="ml-2 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none focus:shadow-outline-gray active:bg-gray-800"
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Flight;
