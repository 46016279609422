import { useLayoutEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollTop from "./Components/ScrollTop";
import Layout from "./Layout";
import AboutUs from "./Pages/AboutUs";
import Activation from "./Pages/Activation";
import AdminHome from "./Pages/Admin/AdminHome";
import BecomePartner from "./Pages/BecomePartner";
import Booking from "./Pages/Booking";
import BookingSuccess from "./Pages/BookingSuccess";
import Cities from "./Pages/Cities";
import Cookies from "./Pages/Cookies";
import CountryCityList from "./Pages/CountryCityList";
import Disclaimer from "./Pages/Disclaimer";
import DriverCancelationPolicy from "./Pages/DriverCancelationPolicy";
import FlightBooking from "./Pages/FlightBooking/FlightBooking";
import ForgotPassword from "./Pages/ForgotPassword";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Privacy from "./Pages/Privacy";
import Profile from "./Pages/Profile";
import MyJob from "./Pages/MyJob";
// import PingPage from "./Pages/MyJob/PingPage";
import ResetPassword from "./Pages/ResetPassword";
import Signup from "./Pages/Signup";
import TermsAndConditions from "./Pages/TermsAndConditions";
import Voucher from "./Pages/Voucher";
import Axios from "./Utils/Axios";
import { selectAuthToken } from "./store/authSlice";
import { selectCurrency } from "./store/searchSlice";
import FlightPassenger from "./Pages/FlightBooking/FlightPassenger";

function App({ t }: any) {
  const currency = useSelector(selectCurrency);
  Axios.defaults.headers.common["Authorization"] = `Bearer ${useSelector(
    selectAuthToken
  )}`;

  function checkSessionValidity() {
    let prevSessionRaw = localStorage.getItem("last_session");

    if (!prevSessionRaw) return;

    const prevSession = new Date(prevSessionRaw);

    const currentDate = new Date();

    const diff = currentDate.getTime() - prevSession.getTime();

    const msInDay = 24 * 60 * 60 * 1000;

    if (diff > msInDay) {
      localStorage.clear();
      window.location.reload();
    }
  }

  useLayoutEffect(() => {
    checkSessionValidity();

    const interval = setInterval(() => {
      checkSessionValidity();
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="bg-gray-800  overflow-x-hidden">
      <ToastContainer autoClose={8000} />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home currency={currency} t={t} />} />
          <Route
            path="popular_locations/:city"
            element={<Cities currency={currency} t={t} />}
          />

          <Route
            path="cities"
            element={<CountryCityList currency={currency} t={t} />}
          />

          <Route
            path="booking"
            element={<Booking currency={currency} t={t} />}
          />
          <Route
            path="account"
            element={<Profile currency={currency} t={t} />}
          />
          <Route
            path="jobs/:code"
            element={<MyJob currency={currency} t={t} />}
          />
          {/* <Route path="pin" element={<PingPage />} /> */}
          <Route
            path="bookingSuccess"
            element={<BookingSuccess currency={currency} t={t} />}
          />
          <Route
            path="voucher"
            element={<Voucher currency={currency} t={t} />}
          />
          <Route path="login" element={<Login t={t} />} />
          <Route path="forgot-password" element={<ForgotPassword t={t} />} />
          <Route path="reset-password" element={<ResetPassword t={t} />} />
          <Route path="signup" element={<Signup t={t} />} />
          <Route path="becomepartner" element={<BecomePartner t={t} />} />
          <Route path="aboutUs" element={<AboutUs t={t} />} />

          <Route
            path="disclaimer"
            element={<Disclaimer t={t} currency={currency} />}
          />
          <Route
            path="privacy"
            element={<Privacy t={t} currency={currency} />}
          />
          <Route
            path="cookies"
            element={<Cookies t={t} currency={currency} />}
          />
          <Route
            path="adminpanel/:page"
            element={<AdminHome t={t} currency={currency} />}
          />
          <Route
            path="email/activate/:id"
            element={<Activation t={t} currency={currency} />}
          />
          {/* Catch-all route */}
          <Route
            path="terms-and-conditions"
            element={<TermsAndConditions t={t} currency={currency} />}
          />

          <Route
            path="driver-cancellation-policy"
            element={<DriverCancelationPolicy t={t} currency={currency} />}
          />

          <Route
            path="flight-booking"
            element={<FlightBooking t={t} currency={currency} />}
          />

          <Route
            path="flight-passengers"
            element={<FlightPassenger t={t} currency={currency} />}
          />

          <Route path=":path/*" element={<Navigate to="/" />} />
        </Route>
      </Routes>

      <CookieConsent
        location="bottom"
        buttonText="Got it!"
        cookieName="yourCookieName"
        style={{ background: "#222", color: "#fff" }}
        buttonStyle={{ background: "#4e503b", color: "#fff", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to ensure you get the best experience on our
        website.
      </CookieConsent>
    </div>
  );
}

export default withTranslation()(App);
