import React, { useMemo, useState } from "react";
import { BsFilterRight } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUserSettings } from "../../store/authSlice";
const dummyBookings = [
  {
    id: "#84H83HT88443",
    modal: "Standard Sedan",
    tripDate: moment().add(1, "M").add(2, "days").add(2, "hours"),
    bookedOn: moment().add(1, "M").add(6, "days").add(5, "hours"),
    name: "Test 1",
    passengers: 3,
    luggages: 1,
    phone: 98765432,
    email: "test@test1.com",
    status: "Confirmed",
    type: "upcoming",
    from: "Fort Station, Fort St George, Chennai, Tamil Nadu 600003, India",
    to: "98a, Palakkarai Main Rd, Near KVB Atm, Palakarai, Sangillyandapuram, Tiruchirappalli, Tamil Nadu 620001, India",
    city: "Tamilnadu",
    paymentDetails: {
      method: "PREPAID",
      isPaid: true,
    },
    amount: 400,
  },
  {
    id: "#78FHHWE773H34T",
    modal: "Standard Mpv",
    tripDate: moment().add(2, "M").add(1, "days").add(1, "hours"),
    bookedOn: moment().add(2, "M").add(10, "days").add(2, "hours"),
    name: "Test 2",
    passengers: 1,
    luggages: 1,
    phone: 98765432,
    email: "test@test2.com",
    status: "Confirmed",
    type: "upcoming",
    from: "Fort Station, Fort St George, Chennai, Tamil Nadu 600003, India",
    to: "98a, Palakkarai Main Rd, Near KVB Atm, Palakarai, Sangillyandapuram, Tiruchirappalli, Tamil Nadu 620001, India",
    city: "Tamilnadu",
    paymentDetails: {
      method: "PREPAID",
      isPaid: true,
    },
    amount: 500,
  },
  {
    id: "#784375HG87H834T",
    modal: "First Class",
    tripDate: moment().subtract(2, "M").add(1, "days").add(2, "hours"),
    bookedOn: moment().subtract(2, "M").add(2, "days").add(5, "hours"),
    name: "Test 3",
    passengers: 3,
    luggages: 1,
    phone: 98765432,
    email: "test@test3.com",
    status: "Confirmed",
    type: "past",
    from: "Fort Station, Fort St George, Chennai, Tamil Nadu 600003, India",
    to: "98a, Palakkarai Main Rd, Near KVB Atm, Palakarai, Sangillyandapuram, Tiruchirappalli, Tamil Nadu 620001, India",
    city: "Tamilnadu",
    paymentDetails: {
      method: "PREPAID",
      isPaid: true,
    },
    amount: 200,
  },
  {
    id: "#83HT8H38TH823G",
    modal: "Standard Sedan",
    tripDate: moment().subtract(3, "M").add(3, "days").add(2, "hours"),
    bookedOn: moment().subtract(3, "M").add(6, "days").add(5, "hours"),
    name: "Test 4",
    passengers: 2,
    luggages: 2,
    phone: 98765432,
    email: "test@test4.com",
    status: "Confirmed",
    type: "past",
    from: "Fort Station, Fort St George, Chennai, Tamil Nadu 600003, India",
    to: "98a, Palakkarai Main Rd, Near KVB Atm, Palakarai, Sangillyandapuram, Tiruchirappalli, Tamil Nadu 620001, India",
    city: "Tamilnadu",
    paymentDetails: {
      method: "PREPAID",
      isPaid: true,
    },
    amount: 500,
  },
  {
    id: "#83HG83HT834G",
    modal: "Business Sedan",
    tripDate: moment().add(3, "M").add(1, "days").add(7, "hours"),
    bookedOn: moment().add(3, "M").add(2, "days").add(15, "hours"),
    name: "Test 5",
    passengers: 1,
    luggages: 3,
    phone: 98765432,
    email: "test@test5.com",
    status: "Confirmed",
    type: "upcoming",
    from: "Fort Station, Fort St George, Chennai, Tamil Nadu 600003, India",
    to: "98a, Palakkarai Main Rd, Near KVB Atm, Palakarai, Sangillyandapuram, Tiruchirappalli, Tamil Nadu 620001, India",
    city: "Tamilnadu",
    paymentDetails: {
      method: "PREPAID",
      isPaid: true,
    },
    amount: 600,
  },
  {
    id: "#3R9GJ93JG9",
    modal: "First Class",
    tripDate: moment().add(2, "M").add(4, "days").add(2, "hours"),
    bookedOn: moment().add(2, "M").add(9, "days").add(7, "hours"),
    name: "Test 6",
    passengers: 2,
    luggages: 2,
    phone: 98765432,
    email: "test@test6.com",
    status: "UnConfirmed",
    type: "upcoming",
    from: "Fort Station, Fort St George, Chennai, Tamil Nadu 600003, India",
    to: "98a, Palakkarai Main Rd, Near KVB Atm, Palakarai, Sangillyandapuram, Tiruchirappalli, Tamil Nadu 620001, India",
    city: "Tamilnadu",
    paymentDetails: {
      method: "PREPAID",
      isPaid: false,
    },
    amount: 200,
  },
  {
    id: "#8NFUN824F23RD",
    modal: "First Class",
    tripDate: moment().add(2, "M").add(6, "days").add(3, "hours"),
    bookedOn: moment().add(2, "M").add(10, "days").add(17, "hours"),
    name: "Test 7",
    passengers: 1,
    luggages: 3,
    phone: 87654324,
    email: "test@test7.com",
    status: "UnConfirmed",
    type: "upcoming",
    from: "Fort Station, Fort St George, Chennai, Tamil Nadu 600003, India",
    to: "98a, Palakkarai Main Rd, Near KVB Atm, Palakarai, Sangillyandapuram, Tiruchirappalli, Tamil Nadu 620001, India",
    city: "Tamilnadu",
    paymentDetails: {
      method: "PREPAID",
      isPaid: true,
    },
    amount: 600,
  },
];
const HomeTable = (props: any) => {
  const { t } = props;
  const [selectedMenu, setSelectedMenu] = useState("limowide");
  const [selectedFilter, setSelectedFilter] = useState("BOOKING DATE");
  const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
  const [bookings, setBookings] = useState<any[]>(dummyBookings);
  const statusValues = useMemo(() => {
    return [
      "GENERATED",
      "ACCEPTED",
      "PICKED",
      "DROPED",
      "CANCELED",
      "DRIVER NO SHOW",
      "CUSTOMER NO SHOW",
      "DRIVER NOT ASSIGNED",
      "MARKET BOOKING",
      "BOOKING DATE",
    ];
  }, []);

  const userSettings = useSelector(selectUserSettings);

  return (
    <div className="grid grid-cols-12 gap-4 ">
      <div className="col-span-12 md:col-span-3 lg:col-span-3 p-2">
        <div className="flex-row bg-white  rounded-md px-8 py-4 space-y-2">
          <div className="flex justify-between ">
            <p>Filter</p>
            <BsFilterRight className={`w-12`} size={20} />
          </div>
          <div>
            <select
              name="language"
              id="language"
              value={selectedFilter}
              onChange={(e: any) => {
                setSelectedFilter(e.target.value);
              }}
              className="w-full p-2 text-bold  hover:text-yelfullw-600 cursor-pointer border border-gray-500 rounded-md"
            >
              {statusValues.map((status: any, index: number) => (
                <option
                  key={index}
                  className="w-20 hover:text-yellow-600 text-black"
                  value={status}
                >
                  {status}
                </option>
              ))}
            </select>
          </div>

          <div className="flex-row  space-y-2">
            <DatePicker
              className="w-full border border-gray-500 rounded-md p-2"
              value={
                selectedStartDate
                  ? moment(selectedStartDate).format("DD MMM, yyyy")
                  : ""
              }
              onChange={(D: Date) =>
                setSelectedStartDate(moment(D).format("YYYY-MM-DD"))
              }
              placeholderText={"Start Date"}
              minDate={new Date()}
              dateFormat="eee, dd MMM, yyyy"
            />
            <DatePicker
              className="w-full border border-gray-500 rounded-md p-2"
              value={
                selectedEndDate
                  ? moment(selectedEndDate).format("DD MMM, yyyy")
                  : ""
              }
              onChange={(D: Date) =>
                setSelectedEndDate(moment(D).format("YYYY-MM-DD"))
              }
              placeholderText={"End Date"}
              minDate={new Date()}
              dateFormat="eee, dd MMM, yyyy"
            />
          </div>
          <div>
            <input
              className="w-full p-2 text-bold cursor-pointer border border-gray-500 rounded-md"
              placeholder="Search by booking id, agncy name, pickup, drop, city, country"
            />
          </div>
          <div>
            <div className="flex justify-around my-4">
              <button className="rounded-md text-sm font-bold text-white bg-blue-600 py-2 px-4">
                Reset
              </button>
              <button className="rounded-md text-sm font-bold text-white bg-yellow-600 py-2 px-4">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className=" col-span-12 md:col-span-9 lg:col-span-9  p-2">
        <div className="flex-row space-y-2">
          <div className="flex space-x-4">
            <p
              className={`px-4 py-2 rounded-md font-bold  cursor-pointer ${
                selectedMenu === "limowide" ? "bg-yellow-600 text-white" : ""
              }`}
              onClick={() => setSelectedMenu("limowide")}
            >
              {userSettings?.site_name} Booking
            </p>
            <p
              className={`px-4 py-2 rounded-md font-bold  cursor-pointer ${
                selectedMenu === "manual" ? "bg-yellow-600 text-white" : ""
              }`}
              onClick={() => setSelectedMenu("manual")}
            >
              Manuals Booking
            </p>
          </div>
          <div className="flex-row w-full h-[600px] overflow-scroll">
            <div className="flex bg-gray-400 w-full ">
              <div className="w-52 p-2 border-r  border-b">
                <p className="font-bold text-sm  ">Booking Id</p>
              </div>
              <div className="w-40 p-2 border-r  border-b ">
                <p className="font-bold text-sm w-32 p-2">Booking Status</p>
              </div>
              <p className="font-bold text-sm w-32 p-2 border-r  border-b">
                Booking City
              </p>
              <p className="font-bold text-sm w-24 p-2 border-r  border-b">
                Account Details
              </p>
              <p className="font-bold text-sm w-32 p-2 border-r  border-b">
                Payment Status
              </p>
              <p className="font-bold text-sm w-52 p-2 border-r  border-b">
                Trip Details
              </p>
              <p className="font-bold text-sm w-52 p-2 border-r  border-b">
                Amount and Details
              </p>
              <p className="font-bold text-sm w-25 p-2  border-b">
                More Options
              </p>
            </div>
            <div className="flex-row  w-full ">
              {bookings?.map((booking, index) => {
                const {
                  id,
                  status,
                  city,
                  name,
                  paymentDetails,
                  amount,
                  from,
                  to,
                } = booking;
                return (
                  <div key={`booking_${index}`} className="flex w-full ">
                    <div className="w-52 p-2 border-r  border-l border-gray-500">
                      <p className="font-bold text-sm ">{id}</p>
                    </div>
                    <div className="w-40 p-2 border-r  border-gray-500 ">
                      <p className="bg-green-900 p-2 text-white font-bold text-sm rounded-md">
                        {status}
                      </p>
                    </div>
                    <p className="font-bold text-sm w-32 p-2 border-r  border-gray-500">
                      {city}
                    </p>
                    <p className="font-bold text-sm w-24 p-2 border-r  border-gray-500">
                      {name}
                    </p>
                    <div className="flex-row space-y-2 w-32 p-2 border-r  border-gray-500">
                      <p className="p-1 text-center text-sm bg-blue-600 rounded-md text-white  font-bold">
                        {status}
                      </p>
                      <p
                        className={`p-1 text-center text-sm ${
                          paymentDetails?.isPaid ? "bg-green-900" : "bg-red-600"
                        } rounded-md text-white  font-bold`}
                      >
                        {paymentDetails?.isPaid ? "Paid" : "Un-paid"}
                      </p>
                      <p className="p-1 text-center text-sm bg-green-600 rounded-md text-white  font-bold">
                        {paymentDetails?.method}
                      </p>
                    </div>
                    <p className="font-bold text-sm w-52 p-2 border-r  border-gray-500">
                      {from}
                    </p>
                    <p className="font-bold text-sm w-52 p-2 border-r  border-gray-500">
                      {amount}
                    </p>
                    <div className="w-25 p-2">
                      <p className="font-bold text-sm p-2 bg-yellow-600 rounded-md">
                        More Options
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTable;
