import React from "react";

function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        fill="#000"
        d="M15 11.441l-2.286-.26a1.792 1.792 0 00-1.476.512L9.582 13.35A13.54 13.54 0 013.65 7.42l1.665-1.666c.387-.387.576-.927.513-1.476L5.568 2.01A1.801 1.801 0 003.777.416H2.22C1.203.416.357 1.262.42 2.28c.477 7.686 6.624 13.824 14.3 14.301 1.018.063 1.864-.783 1.864-1.8v-1.557A1.784 1.784 0 0015 11.441z"
      ></path>
    </svg>
  );
}

export default PhoneIcon;
