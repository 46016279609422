import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUserSettings } from "../store/authSlice";
import countries from "../Components/Profile/Account/countries.json";

export function useUserCountry() {
  const userSettings = useSelector(selectUserSettings);

  const userCountry = useMemo(() => {
    if (!userSettings.country) return undefined;

    const country = countries.find(
      (country) =>
        country.name.toLowerCase() === userSettings.country.toLowerCase()
    );

    return country;
  }, [userSettings?.country]);

  return userCountry ?? ({ dial_code: "+" } as unknown as typeof userCountry);
}
