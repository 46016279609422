import { useEffect, useState } from "react";
import { Slide, toast } from "react-toastify";
import Axios from "../../Utils/Axios";
import Trip from "./Trip";
import Loading from "../Loading";

const Trips = () => {
  const [menu, setMenu] = useState<string>("upcoming");
  const [trips, setTrips] = useState<any[]>([]);
  const [isLoading, setLoadingTrips] = useState<boolean>(false);
  const [userType, setUserType] = useState(localStorage.getItem("userType"));
  const loadTrips = async () => {
    setLoadingTrips(true);
    // past, upcoming, unconfirmed
    const config: any = {
      method: "GET",
      url: `/api/v1/booking/user/trips/${menu.toLowerCase()}/`,
    };
    const tripsResponse: any = await Axios(config).catch((e: any) => {});
    if (tripsResponse?.data?.data) {
      setTrips(tripsResponse?.data?.data);
    }
    setLoadingTrips(false);
  };

  useEffect(() => {
    return () => {
      setMenu("upcoming");
    };
  }, []);

  useEffect(() => {
    loadTrips();
  }, [menu]);

  const cancelBooking = async (booking_id: string) => {
    const config: any = {
      method: "GET",
      url: `/api/v1/booking/cancel/${booking_id}/`,
    };
    await Axios(config)
      .catch((e: any) => {
        console.log(e);
      })
      .then((res) => {
        if (res?.data?.data?.is_cancel) {
          toast.success(
            res?.data?.message || "Booking  is canceled successful ",
            {
              position: "top-center",
              autoClose: 3000,
              closeOnClick: true,
              transition: Slide,
            }
          );
          setTimeout(
            function () {
              loadTrips();
            }.bind(this),
            1000
          );
        } else {
          toast.error(res?.data?.message, {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          });
        }
      });
  };
  const bookAgain = () => {};
  const modifyBooking = () => {};

  return (
    <div className="flex-row h-full px-6 py-4">
      <div className="flex justify-end space-x-2">
        <p
          className={`${
            menu == "upcoming" ? "text-yellow-600 font-bold" : ""
          } border-r border-black pr-2 cursor-pointer`}
          onClick={() => setMenu("upcoming")}
        >
          UPCOMING
        </p>
        <p
          className={`${
            menu == "past" ? "text-yellow-600 font-bold" : ""
          } border-black pr-2 cursor-pointer`}
          onClick={() => setMenu("past")}
        >
          PAST
        </p>
        {userType === "driver" ? null : (
          <p
            className={`${
              menu === "cancel" ? "text-yellow-600 font-bold" : ""
            } border-black pr-2 cursor-pointer border-l pl-2`}
            onClick={() => setMenu("cancel")}
          >
            CANCELLED
          </p>
        )}
      </div>

      {isLoading ? (
        <div className="h-full justify-center items-center flex">
          {/* <p className="font-bold">Loading...</p> */}
          <Loading />
        </div>
      ) : (
        <div className="flex-row space-y-4 mt-2">
          {Array.isArray(trips) &&
            trips?.map((trip: any, index: number) => (
              <Trip
                trip={trip}
                key={`trip_${index + 1}`}
                type={menu}
                cancelBooking={cancelBooking}
                bookAgain={bookAgain}
                modifyBooking={modifyBooking}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default Trips;
