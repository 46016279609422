import axios, { Axios, AxiosError } from "axios";
// const token = JSON.parse(localStorage.user);
//  const authToken=token.token.access_token;
// console.log(authToken);
// const baseUrl = `https://service.limowide.com`;
// const baseUrl = `https://sandbox-api.limowide.com`;
const baseUrl =
  process.env.REACT_APP_ENV === "development"
    ? "https://sandbox-api.limowide.com"
    : `https://service.limowide.com`;
// const testBaseUrl = `https://sandbox.limowide.com`;
const csrfToken =
  "iL8BElW3jw32OW2we0GXFLfPkhaf0yCZnpI2d3wKgnCRBKbc2F3cQzQcdT9jQKQL";
const accessToken =
  "4ebc3b6fb41c8c9b31c42dec535bfce98e1e2ef1c95ea34d1f1e81bb109a2585";
// const accessToken = " Bearer 7aOrDv8LbJxGRsjseFWVLfHLzo2J6m";
// const accessToken = " Bearer 7aOrDv8LbJxGRsjseFWVLfHLzo2J6m";

const config = {
  baseURL: baseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    "Access-Token": accessToken,
    // "Authorization":`Bearer ${authToken}`
  },
  withCredentials: false,
};

export const unAuthenticatedAxiosInstance = axios.create(config);

const instance = axios.create(config);

instance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.localStorage.clear();
      window.location.assign("/login");
    }

    return Promise.reject(error);
  }
);

export default instance;
