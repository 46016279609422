import { useEffect, useRef, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import momentTimeZone from "moment-timezone";
import { Controller, useForm } from "react-hook-form";
import { BsFillCheckCircleFill, BsFillPersonFill } from "react-icons/bs";
import { FaRegMoneyBillAlt, FaRoad, FaSpinner } from "react-icons/fa";
import { GoCalendar } from "react-icons/go";
import { RiMapPinRangeFill, RiTimeFill } from "react-icons/ri";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import * as yup from "yup";
import Axios from "../../Utils/Axios";
import { isLoggedIn, selectUser } from "../../store/authSlice";
import { passengerUpdate, selectedPassengers } from "../../store/bookingSlice";
import Loading from "../Loading";
import LoginModal from "../LoginModal";
import EditPassengerModal from "./EditPassengerModal";
import { useUserCountry } from "../../Utils/user-user-country";

type PassengerForm = {
  firstName: string;
  lastName: string;
  email: string;
  flightNumber: string;
  specialInstructions: string;
  mobile: string;
};
const passengerschema = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is required")
    .max(20, "First Name must be at most 5 characters"),
  lastName: yup
    .string()
    .required("Last Name is required")
    .max(20, "Last Name must be at most 5 characters"),
  email: yup
    .string()
    .email("Enter Valid Mail Address")
    .required("Email is required")
    .max(100),
  flightNumber: yup.string().optional().default(""),
  specialInstructions: yup.string().optional().default(""),
  mobile: yup
    .string()
    .test("is-valid-phone-number", "Invalid phone number", (value) => {
      if (!value) {
        return true;
      }
      return isValidPhoneNumber(value);
    })
    .required(),
});

const Passenger = (props: any) => {
  const {
    reqObj: { currency, locationDetails, car },
    goToPayment,
  } = props;
  const selectedPassenger = useSelector(selectedPassengers);
  const [tempPass, setTempPass] = useState<any>({});
  const [passengers, setPassengers] = useState<any>([]);
  // const [costCenter, setCostCenter] = useState<any>("");
  const [terms, setacceptTerms] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<"invoice" | "creditCard">(
    "creditCard"
  );
  const [isShowEditPassengerModal, setEditPassengerModal] =
    useState<boolean>(false);
  const [editingPassenger, setEditingPassenger] = useState<any>({});
  const [editingPassengerIndex, setEditingPassengerIndex] = useState<any>(-1);
  const isLoggedin = useSelector(isLoggedIn);
  const [isShowLoginModal, setLoginModal] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<any>(false);
  const [isUserLoading, setIsUserLoading] = useState(false);
  // const [totalPassengers, setPassengersCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [isNextPageAvailable, setNextPage] = useState<boolean>(false);
  const userCountry = useUserCountry();

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState({
    is_invoice: false,
    is_credit_card: false,
  });

  const bookingDiv: any = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount },
    setValue,
  } = useForm<PassengerForm>({
    resolver: yupResolver(passengerschema),
    defaultValues: { mobile: userCountry?.dial_code ?? "+" },
  });
  const [flightNumberError, setFlightNumberError] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    const UserValues = data;
    if (!isValidPhoneNumber(data.mobile)) {
      return;
    } else if (!terms) {
      return;
    } else if (
      UserValues.flightNumber &&
      (UserValues.flightNumber.length < 3 || UserValues.flightNumber.length > 8)
    ) {
      setFlightNumberError(true);
      return;
    } else if (UserValues?.email && terms) {
      onSelectPassenger({
        firstName: UserValues.firstName,
        lastName: UserValues.lastName,
        email: UserValues.email,
        mobile: UserValues.mobile,
        flightNumber: UserValues.flightNumber,
        specialInstructions: UserValues.specialInstructions,
      });
    }
  };

  useEffect(() => {
    let isMounted: boolean = true;
    //getPassengers(isMounted, 1);
  }, [page]);

  // useEffect(() => {
  //   setValue("mobile", userCountry?.dial_code ?? "");
  // }, [userCountry?.dial_code]);

  useEffect(() => {
    if (user?.type === "travelagent") {
      setIsUserLoading(true);

      const paymentMethods = {
        is_invoice: false,
        is_credit_card: false,
      };

      Axios.get("/api/v1/user/detail/")
        .then((res) => {
          const data = res.data?.data;

          setValue("email", data?.email);
          setValue("firstName", data?.first_name);
          setValue("lastName", data?.last_name);
          setValue("mobile", data?.phone);

          if (data.is_invoice) paymentMethods.is_invoice = true;
          if (data.is_credit_card) paymentMethods.is_credit_card = true;

          setAvailablePaymentMethods(paymentMethods);
        })
        .catch((err) => {
          setAvailablePaymentMethods({
            is_invoice: !!user?.is_invoice,
            is_credit_card: !!user?.is_credit_card,
          });
        })
        .finally(() => {
          setIsUserLoading(false);
        });
    } else {
      setAvailablePaymentMethods({
        is_invoice: !!user?.is_invoice,
        is_credit_card: !!user?.is_credit_card,
      });
    }
  }, [user]);

  const onSelectPassenger: any = (passenger: any) => {
    if (passenger) {
      dispatch(passengerUpdate(passenger));
      localStorage.setItem("passenger", JSON.stringify(passenger));
      if (!isLoggedin) {
        setLoginModal(true);
      } else {
        payNow();
      }
    }
  };

  const savePassenger: any = (e: any) => {
    e.preventDefault();
    const updatedPass = {
      firstName: e.target.elements.firstName.value,
      lastName: e.target.elements.lastName.value,
      email: e.target.elements.email.value,
      mobile: e.target.elements.mobile.value,
    };
    const passArr = passengers;
    passArr[editingPassengerIndex] = updatedPass;
    setTempPass(updatedPass);
    setPassengers(passArr);
    setEditPassengerModal(false);
    setEditingPassenger({});
  };

  const editPassenger: any = (passenager: any, index: number) => {
    setEditingPassenger(passenager);
    setEditingPassengerIndex(index);
    setEditPassengerModal(true);
  };
  const addTemp: any = (e: any) => {
    setTempPass((passenger: any) => {
      return { ...passenger, [e.target.name]: e.target.value };
    });
  };

  const isDisabledAdd = () =>
    passengers.length == 3 ||
    !tempPass.email ||
    !tempPass.mobile ||
    !tempPass.firstName ||
    !tempPass.lastName;

  const payNow = () => {
    localStorage.setItem("selected_car", car.id);
    localStorage.setItem("car_waiting_time", car.waiting_time);

    if (paymentMethod === "invoice") {
      initBooking({ car, sysId: car.id });
    }

    if (paymentMethod === "creditCard") {
      goToPayment(car, {
        selectedPassenger,
        paymentMethod,
      });
    }
  };

  async function initBooking({ car, sysId }: any) {
    let selectedPassenger: any = localStorage.getItem("passenger");
    const pickupLocation: any = localStorage.getItem("pickup_location");
    const dropoffLocation: any = localStorage.getItem("dropoff_location");

    setLoading(true);

    try {
      selectedPassenger = JSON.parse(selectedPassenger);
      if (selectedPassenger && sysId) {
        localStorage.setItem("selected_car", car.id);
        const timeZone = momentTimeZone.tz.guess();

        let requestbody = {
          source: "API",
          passenger_email: selectedPassenger.email,
          passenger_name: `${selectedPassenger.firstName} ${selectedPassenger.lastName}`,
          passenger_mobile: selectedPassenger.mobile,
          sys_id: sysId,
          timezone: timeZone,
          start_point: JSON.parse(pickupLocation).label,
          end_point: JSON.parse(dropoffLocation).label,
          special_instructions: selectedPassenger?.specialInstructions,
          flight_no: selectedPassenger?.flightNumber,
        };

        if (!requestbody.special_instructions)
          delete requestbody.special_instructions;

        if (!requestbody.flight_no) delete requestbody.flight_no;

        const config: any = {
          method: "POST",
          url: `/api/v1/booking/confirm/`,
          data: requestbody,
        };

        let bookingDetailsData = {
          firstName: selectedPassenger.firstName,
          lastName: selectedPassenger.lastName,
          email: selectedPassenger.email,
          mobile: selectedPassenger.mobile,
          flightNumber: selectedPassenger.flightNumber,
          specialInstructions: selectedPassenger.specialInstructions,
          fare: car?.fare,
          bookingId: car?.id,
          passenger: locationDetails?.passengers,
          luggage: locationDetails?.bags,
          waitingTime: car?.waiting_time,
          car: car?.car_class,
          pickupLocation: locationDetails.pickupLocation.label,
          dropLocation: locationDetails?.dropoffLocation.label,
          duration: locationDetails?.duration,
          distance: locationDetails?.distance,
          pickupDate: locationDetails?.pickupDate,
          pickupTime: locationDetails?.pickupTime,
          pin: "",
          car_raw: car,
        };

        const bookingResponse: any = await Axios(config).catch((e: any) => {
          if (!!e?.response?.data?.data?.timezone) {
            toast.error("Invoice payment is not available in your region.", {
              position: "top-center",
              autoClose: 3000,
              closeOnClick: true,
              transition: Slide,
            });
          }
        });

        if (
          bookingResponse?.data.status === 200 &&
          bookingResponse?.data?.data?.booking_id &&
          bookingResponse?.data?.data?.is_confirm
        ) {
          setLoading(false);

          bookingDetailsData.bookingId =
            bookingResponse?.data?.data?.booking_id;

          bookingDetailsData.pin = bookingResponse?.data?.data?.pin ?? "";

          bookingDetailsData.luggage =
            bookingResponse?.data?.data?.data?.traveler?.num_bags ??
            bookingDetailsData.luggage;

          // @ts-ignore
          bookingDetailsData.cancellationDetails =
            bookingResponse?.data?.data?.data.cancellationDetails;

          navigate("/bookingSuccess", { state: {...bookingDetailsData, destination:bookingDetailsData.dropLocation,pickup:bookingDetailsData.pickupLocation} });
        } else {
          setLoading(false);
        }
      } else {
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const getPassengers = async (isMounted: boolean, page: number = 1) => {
    try {
      const passengersResult: {
        data: {
          count: number;
          next: string;
          previous: string;
          results: any[];
        };
      } = await Axios.get(`/api/v1/passenger/?page=${page}`);

      if (passengersResult?.data?.count) {
        const { count, results, next } = passengersResult.data;
        //setPassengersCount(count);
        if (results?.[0]) {
          const formattedPass = results.map(
            ({ first_name, last_name, email, pickup_sign, mobile }: any) => ({
              firstName: first_name,
              lastName: last_name,
              pickupSign: pickup_sign,
              email,
              mobile,
            })
          );

          if (next) {
            setNextPage(true);
          } else {
            setNextPage(false);
          }
          setPassengers((passengers: any) => [...passengers, ...formattedPass]);
        }
      }
    } catch (error) {}
  };

  const [data, setData] = useState("");
  const addPassenger: any = async () => {
    setPassengers((passengers: any) => [...passengers, tempPass]);
    setTempPass({});
    const { firstName, lastName, pickupSign, email, mobile } = tempPass;
    const config: any = {
      method: "POST",
      url: `/api/v1/passenger/`,
      data: {
        first_name: firstName,
        last_name: lastName,
        pickup_sign: pickupSign,
        email,
        mobile,
      },
    };
    const newPassengerResult = await Axios(config);
    if (newPassengerResult) {
    }
  };

  return (
    <div className="rounded-xl p-4 hover:shadow-2xl mt-28">
      <div className="grid grid-cols-12 space-x-4">
        <div className="col-span-12 lg:col-span-5 shadow-2xl rounded-xl xl:col-span-5 flex flex-col justify-start text-center">
          <div className={`w-full`}>
            <div className="flex justify-between py-2 border-b border-gray-600 pb-2">
              <p className="w-full mb-2 font-bold text-center">
                Booking Details
              </p>
            </div>
            <div className="border-b border-gray-200 dark:border-gray-700">
              <div className="flex justify-center">
                <div className="col-span-5 sm:col-span-5 md:col-span-3 xl:col-span-2 2xl:col-span-2 flex justify-center items-center opacity">
                  <img
                    src={car?.fleet_image}
                    alt={car?.model}
                    className="w-72 mt-4"
                  />
                </div>
              </div>
              <div className="my-2">
                <p className="font-bold">{car?.model}</p>
              </div>
            </div>
            <div className="border-b border-gray-200 dark:border-gray-700">
              <div className="flex flex-col px-4 py-4">
                <div className="flex mb-4">
                  <span>
                    <RiMapPinRangeFill
                      className="text-yellow-600 cursor-pointer mr-1"
                      size={25}
                    />
                  </span>
                  <span className="w-24 text-left ml-2">
                    <p className="w-24">Pick Up : </p>
                  </span>
                  <span>
                    <p className="font-bold break-words text-left">
                      {locationDetails?.pickupLocation?.label}
                    </p>
                  </span>
                </div>
                <div className="flex mb-4">
                  <span>
                    <GoCalendar
                      className="text-yellow-600 cursor-pointer mr-1"
                      size={25}
                    />
                  </span>
                  <span className="w-24 text-left ml-2">
                    <p>Date : </p>
                  </span>
                  <span>
                    <p className="font-bold">{locationDetails?.pickupDate}</p>
                  </span>
                  <span className="ml-2 w-24">
                    <p className="ml-1">Time :</p>
                  </span>
                  <span>
                    <p className="ml-1 font-bold">
                      {locationDetails?.pickupTime}
                    </p>
                  </span>
                </div>
                <div className="flex mb-4">
                  <span>
                    <RiMapPinRangeFill
                      className="text-yellow-600 cursor-pointer mr-1"
                      size={25}
                    />
                  </span>
                  <span className="w-24 text-left ml-2 ">
                    <p className="w-24">Drop off : </p>
                  </span>
                  <span>
                    <p className="text-left font-bold break-words">
                      {locationDetails?.dropoffLocation?.label}
                    </p>
                  </span>
                </div>
                <div className="flex mb-4">
                  <span>
                    <BsFillPersonFill
                      className="text-yellow-600 cursor-pointer mr-1"
                      size={25}
                    />
                  </span>
                  <span className="w-24 text-left ml-2">
                    <p>Passengers : </p>
                  </span>
                  <span className="col-span-3 text-left">
                    <p className="ml-1 font-bold">
                      {locationDetails?.passengers}
                    </p>
                  </span>
                  <span className=" ml-2">
                    <p className="ml-1">Bags :</p>
                  </span>
                  <span className="col-span-3 text-left">
                    <p className="ml-1 font-bold">{locationDetails?.bags}</p>
                  </span>
                </div>
                <div className="flex mb-4">
                  <span className="col-span-1">
                    <RiTimeFill
                      className="text-yellow-600 cursor-pointer mr-1"
                      size={25}
                    />
                  </span>
                  <span className="w-24 text-left ml-2">
                    <p>Duration : </p>
                  </span>
                  <span className="text-left">
                    <p className="ml-1 font-bold">
                      {locationDetails?.duration}
                    </p>
                  </span>
                </div>
                <div className="flex mb-4">
                  <span>
                    <FaRoad
                      className="text-yellow-600 cursor-pointer mr-1"
                      size={25}
                    />
                  </span>
                  <span className="w-24 text-left ml-2">
                    <p>Distance : </p>
                  </span>
                  <span className=" text-left">
                    <p className="ml-1 font-bold">
                      {locationDetails?.distance}
                    </p>
                  </span>
                </div>
                <div className="flex mb-4">
                  <span>
                    <FaRegMoneyBillAlt
                      className="text-yellow-600 cursor-pointer mr-1"
                      size={25}
                    />
                  </span>
                  <span className="w-24 text-left ml-2">
                    <p>Trip Total : </p>
                  </span>
                  <span className="flex text-left">
                    {/* <p className="ml-2 font-bold">{currency}</p> */}
                    <p className="ml-2 font-bold">{car.fare}</p>
                  </span>
                </div>
              </div>
            </div>
            <div className="border-b border-gray-200 dark:border-gray-700">
              <div className="grid grid-cols-12 p-2  text-left justify-start">
                <div className="md:col-span-3 lg:col-span-3 flex justify-center col-span-12 items-center space-x-2">
                  <BsFillCheckCircleFill
                    className="text-yellow-600 cursor-pointer"
                    size={20}
                  />
                  <p className="">Flat rate</p>
                </div>
                <div className="md:col-span-4 lg:col-span-4 flex justify-center col-span-12 items-center space-x-2">
                  <BsFillCheckCircleFill
                    className="text-yellow-600 cursor-pointer"
                    size={20}
                  />
                  <p className="">Free Cancellation *</p>
                </div>
                <div className="md:col-span-5 lg:col-span-5 flex justify-center col-span-12 items-center space-x-2">
                  <BsFillCheckCircleFill
                    className="text-yellow-600 cursor-pointer"
                    size={20}
                  />
                  <p className="ml">Certified & Reliable drivers</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isUserLoading ? (
          <div className="flex justify-center items-center w-full">
            <Loading />
          </div>
        ) : (
          <div className="col-span-12 lg:col-span-6 xl:col-span-6 flex flex-col">
            <div className="border-b border-gray-600 pb-2">
              <p className="font-bold mt-4">Passenger Details</p>
            </div>
            <div className="block p-6 mt-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    First name *
                  </label>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "First Name is Required" }}
                    render={({ field }) => (
                      <input
                        type="text"
                        placeholder="First Name *"
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700  bg-white bg-clip-padding border border-solid border-gray-300 rounded  transition  ease-in-out m-0 
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="exampleInput7"
                        {...field}
                      />
                    )}
                  />
                  <div>
                    <small className="text-lg text-red-500">
                      {errors.firstName?.message}
                    </small>
                  </div>
                </div>
                <div className="form-group mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Last Name *
                  </label>{" "}
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Last Name is Required" }}
                    render={({ field }) => (
                      <input
                        type="text"
                        autoComplete="family-name"
                        {...field}
                        className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="lastname"
                        placeholder="Surname"
                      />
                    )}
                  />
                  <div>
                    <small className="text-lg text-red-500">
                      {errors.lastName?.message}
                    </small>
                  </div>
                </div>
                <div className="form-group mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Email address *
                  </label>{" "}
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Email is Required" }}
                    render={({ field }) => (
                      <input
                        type="email"
                        autoComplete="email"
                        className="form-control block w-full px-3 py-1.5 text-base 
                    font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition 
                    ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="email"
                        placeholder="nicename@limowide.com"
                        {...field}
                      />
                    )}
                  />
                  <div>
                    <small className="text-lg text-red-500">
                      {errors.email?.message}
                    </small>
                  </div>
                </div>
                <div className="form-group mb-6" id="passengerphone">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Contact Number *
                  </label>
                  <Controller
                    name="mobile"
                    control={control}
                    rules={{ validate: (value) => isValidPhoneNumber(value) }}
                    render={({ field }) => (
                      <PhoneInput
                        placeholder="Mobile Number"
                        value={field.value}
                        onChange={(value) =>
                          value ? field.onChange(value) : field.onChange("")
                        }
                        error={errors.mobile?.message}
                      />
                    )}
                  />
                  {errors.mobile && (
                    <div>
                      <small className="capitalize text-lg text-red-500">
                        {errors.mobile.message}
                      </small>
                    </div>
                  )}
                </div>
                <div className="form-group mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Flight Number
                  </label>
                  <Controller
                    name="flightNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        type="text"
                        autoComplete="flightNumber"
                        className="form-control block w-full px-3  py-1.5 
                text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded 
                transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="flightNumber"
                        placeholder="UK 25"
                        {...field}
                      />
                    )}
                  />
                  {flightNumberError && (
                    <div>
                      <small className="capitalize text-lg text-red-500">
                        Flight Number should be between 3 to 8 characters
                      </small>
                    </div>
                  )}
                </div>

                <div className="form-group mb-6">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Special Instructions
                  </label>
                  <Controller
                    name="specialInstructions"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        type="text"
                        autoComplete="specialInstructions"
                        className="form-control block w-full px-3 py-1.5 
                    text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded 
                    transition ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        id="specialInstructions"
                        {...field}
                        placeholder="English speaking driver"
                      />
                    )}
                  />
                </div>

                {!!availablePaymentMethods.is_invoice && (
                  <div className="form-group mb-6">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Payment Method
                    </label>

                    <div className="flex flex-col gap-2">
                      {!!availablePaymentMethods.is_credit_card && (
                        <label
                          htmlFor="creditCard"
                          className="flex items-center gap-4"
                        >
                          <input
                            type="radio"
                            required
                            value={"creditCard"}
                            id="creditCard"
                            name="paymentMethod"
                            onChange={() => {
                              setPaymentMethod("creditCard");
                            }}
                            checked={paymentMethod === "creditCard"}
                          />

                          <span>Credit Card</span>
                        </label>
                      )}

                      <label
                        htmlFor="invoice"
                        className="flex items-center gap-4"
                      >
                        <input
                          type="radio"
                          required
                          value={"invoice"}
                          id="invoice"
                          name="paymentMethod"
                          onChange={() => {
                            setPaymentMethod("invoice");
                          }}
                          checked={paymentMethod === "invoice"}
                        />

                        <span>Invoice</span>
                      </label>
                    </div>
                  </div>
                )}

                <div className="form-control my-2 py-3">
                  <div className="p-2 text-left flex space-x-2 items-center">
                    <div>
                      <input
                        type="checkbox"
                        defaultChecked={false}
                        onChange={() => setacceptTerms(!terms)}
                      />
                    </div>
                    <div
                      className={
                        submitCount > 0 && !terms
                          ? "text-red-500"
                          : "text-black-600"
                      }
                    >
                      I accept{" "}
                      <Link
                        to="/conditions"
                        className={
                          submitCount > 0 && !terms
                            ? "text-red-500"
                            : "text-blue-600"
                        }
                      >
                        conditions of transport
                      </Link>
                      *
                    </div>
                  </div>

                  <button
                    className="w-52 h-12 flex justify-center items-center gap-2 bg-gradient-to-r text-white mt-3 p-1 rounded-lg text-lg font-bold bg-gray-800 hover:bg-yellow-600"
                    type="submit"
                    disabled={isLoading}
                    style={isLoading ? { filter: "grayscale(1)" } : {}}
                  >
                    {isLoading && (
                      <FaSpinner className="w-4 h-4 animate-spin" />
                    )}{" "}
                    {isLoggedin ? "Confirm Booking" : "Login to continue"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <div
        ref={bookingDiv}
        className="my-4 flex justify-center items-center"
      ></div>
      <LoginModal
        modalIsOpen={isShowLoginModal}
        closeModal={() => {
          setLoginModal(false);
          payNow();
        }}
      />
      <EditPassengerModal
        modalIsOpen={isShowEditPassengerModal}
        closeModal={() => {
          setEditPassengerModal(false);
          setEditingPassenger({});
        }}
        save={savePassenger}
        details={editingPassenger}
      />
    </div>
  );
};

export default Passenger;
