import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./Assets/locales/en/translation.json";
import translationAR from "./Assets/locales/ar/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  supportedLngs: ["en", "ar"],
  lng: "en",
  fallbackLng: "en",
  detection: {
    order: ["cookie"],
    caches: ["cookie"],
  },
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: { useSuspense: false },
});

export default i18n;
