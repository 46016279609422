import "./tooltip.css"

const ReactToolTip = ({ title, children,style }:any) => {
  return (
    <div className={`tooltip `}>
      {children}
      <div className={`tooltiptext`}>
        {title}
        <span className="arrow" style={style}></span>
      </div>
    </div>
  );
};

export default ReactToolTip;