import {
  FaFacebookF,
  FaLinkedinIn,
  FaPinterestP,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUserSettings } from "../store/authSlice";

const Footer = () => {
  const userSettings = useSelector(selectUserSettings);

  return (
    <div className=" lg:px-24 lg:pb-20 lg:pt-16 xl:px-24 xl:pb-20 xl:pt-16 md:px-24 md:pb-20 md:pt-16 px-6 pb-12 pt-3 bg-gray-700">
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
        <div className="lg:col-span-1 col-span-3">
          <div className="flex flex-col">
            <div className="basis-1">
              <p className="text-yellow-600 text-lg font-normal">
                England office
              </p>
              <p className="text-white text-lg font-normal mt-2">
                1 Peach Place,
              </p>
              <p className="text-white text-lg font-normal">
                Wokingham, RG40 1LY, UK
              </p>
            </div>
            <div className="basis-1 lg:mt-4"></div>
            <div className="basis-1 lg:mt-4">
              <p className="text-yellow-600 text-lg font-normal">
                Support Global
              </p>
              <p className="text-white text-lg font-normal mt-2">
                Phone : <a href="tel:+447458 148595">+44 7458 148595</a>
              </p>
              <p className="text-white text-lg font-normal">
                Email :{" "}
                <a className="underline" href="mailto:booking@limowide.com">
                  support@limowide.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="lg:col-span-1 col-span-3">
          <div className="flex flex-col">
            <p className="text-yellow-600 text-lg font-normal">About</p>
            <Link
              to="#"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              How it works
            </Link>
            <Link
              to="#"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              Become a Partner
            </Link>
            <Link
              to="#"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              Contact
            </Link>
            <Link
              to="#"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              Fleet
            </Link>
            <Link
              to="driver-cancellation-policy"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              Driver Cancellation Policy
            </Link>
            <Link
              to="terms-and-conditions"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              Terms And Conditions
            </Link>
          </div>
        </div>
        <div className="lg:col-span-1 col-span-3">
          <div className="flex flex-col">
            <p className="text-yellow-600 text-lg font-normal">Services</p>
            <Link
              to="/popular_locations/airport-transfer"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              Airport Transfer
            </Link>
            <Link
              to="/popular_locations/limousine-service"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              Limousine Service
            </Link>
            <Link
              to="/popular_locations/private-taxi"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              Private Taxi
            </Link>
            <Link
              to="/popular_locations/chauffeur-service"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              Chauffeur Service
            </Link>
            <Link
              to="/popular_locations/best-city-tour-operator"
              className="mt-2 text-white text-lg font-normal hover:underline"
            >
              City Tour
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="flex flex-row mt-8 justify-between pr-8">
          <div className="flex flex-row pr-8 mt-8">
            <a
              href="https://www.linkedin.com/company/limowide/"
              className="bg-yellow-600 p-3 rounded-full hover:bg-yellow-600 h-12"
            >
              <FaLinkedinIn
                className="rounded text-center cursor-pointer"
                size={25}
              />
            </a>
            <a
              href="https://twitter.com/limowide?s=08"
              className="lg:ml-4 ml-4 bg-yellow-600 p-3 rounded-full hover:bg-yellow-600 h-12"
            >
              <FaTwitter className="text-center cursor-pointer" size={25} />
            </a>
            <a
              href="https://www.facebook.com/limowide"
              className="lg:ml-4 ml-4 bg-yellow-600 p-3 rounded-full hover:bg-yellow-600 h-12"
            >
              <FaFacebookF className="text-center cursor-pointer" size={25} />
            </a>
            <a
              href="https://www.pinterest.de/limowide/"
              className="lg:ml-4 ml-4 bg-yellow-600 p-3 rounded-full hover:bg-yellow-600 h-12"
            >
              <FaPinterestP className="text-center cursor-pointer" size={25} />
            </a>
          </div>
          <div className="flex flex-row-reverse pr-8 mt-8">
            <button
              className="rounded-lg invisible md:visible"
              onClick={() =>
                window.open("https://wa.me/message/GPAZX544OBPNI1")
              }
            >
              <div className="flex bg-green-700 text-white px-4 py-2 rounded-lg h-12">
                <FaWhatsapp className="text-center cursor-pointer" size={25} />
                <p>WhatsApp chat</p>
              </div>
            </button>
            <button
              className="ml-6 bg-green-700 p-3 text-white rounded-full hover:bg-green-700 h-12 visible md:invisible"
              onClick={() =>
                window.open("https://wa.me/message/GPAZX544OBPNI1")
              }
            >
              <FaWhatsapp className="text-center cursor-pointer" size={25} />
            </button>
          </div>
        </div>
      </div>
      <div className="mt-4 border-t border-white w-full">
        <div className="flex flex-row mt-3">
          <p className="text-white pr-4">
            © {new Date().getFullYear()} {userSettings?.site_name}
          </p>
          <Link to="/disclaimer" className="text-white lg:ml-8">
            Disclaimer
          </Link>
          <Link to="/privacy" className="text-white ml-4">
            Privacy Policy
          </Link>
          <Link to="/cookies" className="text-white ml-4">
            Cookies
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
