import React, { useEffect, useState } from "react";
import Modal from "react-modal";

const EditPassengerModal = (props: any) => {
  const { modalIsOpen, closeModal, save, details } = props;
  const [tempPass, setTempPass] = useState<any>({});

  useEffect(() => {
    setTempPass(details);
  }, [details]);
  const editTemp: any = (e: any) => {
    setTempPass((passenger: any) => {
      return { ...passenger, [e.target.name]: e.target.value };
    });
  };
  return (
    <Modal
      isOpen={modalIsOpen}
      contentLabel="Edit Passenger"
      className="bg-white p-5 h-screen w-screen"
    >
      <div className="flex justify-center items-center p-2 mt-[10%] ">
        <form onSubmit={save} className="bg-gray-400 rounded-lg p-4">
          <div className="flex p-4">
            <p className="mr-2 w-48">First name :</p>
            <input
              className="px-2"
              name="firstName"
              type="text"
              value={tempPass?.firstName || ""}
              onChange={editTemp}
            />
          </div>
          <div className="flex p-4">
            <p className="mr-2 w-48">Last name :</p>
            <input
              className="px-2"
              name="lastName"
              type="text"
              value={tempPass?.lastName || ""}
              onChange={editTemp}
            />
          </div>

          <div className="flex p-4">
            <p className="mr-2 w-48">Email :</p>
            <input
              className="px-2"
              name="email"
              type="email"
              value={tempPass?.email || ""}
              onChange={editTemp}
            />
          </div>
          <div className="flex p-4 mb-4">
            <p className="mr-2 w-48">Mobile :</p>
            <input
              className="px-2"
              name="mobile"
              type="phone"
              value={tempPass?.mobile || ""}
              onChange={editTemp}
            />
          </div>
          <div className="flex p-4 mb-4">
            <p className="mr-2 w-48">Pickup Sign :</p>
            <input
              className="px-2"
              name="pickupSign"
              type="text"
              value={tempPass?.pickupSign || ""}
              onChange={editTemp}
            />
          </div>
          <div className="mt-2 flex justify-around">
            <button
              onClick={closeModal}
              className="bg-gray-800 px-4 py-2 rounded-lg text-yellow-600 font-bold hover:bg-yellow-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-gray-800 px-4 py-2 rounded-lg text-yellow-600 font-bold hover:bg-yellow-600 hover:text-gray-800"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditPassengerModal;
