import React, { useEffect } from "react";
import Footer from "../Components/Footer";
import NavBarFixed from "../Components/NavBarFixed";
import ScrollTop from "../Components/ScrollTop";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { selectUserSettings } from "../store/authSlice";

const Disclaimer = (props: any) => {
  const { currency, t } = props;

  const userSettings = useSelector(selectUserSettings);

  return (
    <div>
      <ScrollTop />
      <Helmet>
        <title>Disclaimer</title>
      </Helmet>
      <NavBarFixed currency={currency} t={t} />
      <div className="p-24 mt-24 bg-white">
        <div className="flex justify-center">
          <p className="text-3xl">Disclaimer</p>
        </div>
        <div className="mt-10">
          <p>
            The information provided by [{userSettings?.site_name}] (“we,” “us”
            or “our”) on [{userSettings?.site_name}] (the “Site”) [and our
            mobile application] is for general informational purposes only. All
            information on the Site [and our mobile application] is provided in
            good faith, however, we make no representation or warranty of any
            kind, express or implied, regarding the accuracy, adequacy,
            validity, reliability, availability or completeness of any
            information on the Site [or our mobile application].
          </p>
          <p className="mt-10">
            Under no circumstance shall we have any liability to you for any
            loss or damage of any kind incurred as a result of the use of the
            site [or our mobile application] or reliance on any information
            provided on the site [and our mobile application]. Your use of the
            site [and our mobile application] and your reliance on any
            information on the site [and our mobile application] is solely at
            your own risk.
          </p>
        </div>
      </div>
      <div className="bg-gray-800 w-full col-span-2">
        <Footer />
      </div>
    </div>
  );
};

export default Disclaimer;
