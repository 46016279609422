import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    location: {},
    car: {},
    passenger: {},
  },
  reducers: {
    bookingStarted: (state, param) => {
      state.location = param.payload;
    },
    carSelected: (state, param) => {
      state.car = param.payload;
    },
    passengerAdded: (state, param) => {
      state.passenger = param.payload;
    },
    passengerUpdate: (state, param) => {
      state.passenger = param.payload;
    },
  },
});

export const { bookingStarted, carSelected, passengerAdded, passengerUpdate } =
  bookingSlice.actions;
export default bookingSlice.reducer;

const selectBooking = (state) => state.booking;

export const selectedLocationDetails = createSelector(
  selectBooking,
  (booking) => booking.location
);

export const selectedPassengers = createSelector(
  selectBooking,
  (booking) => booking.passenger
);
export const selectedCar = createSelector(
  selectBooking,
  (booking) => booking.car
);
