import { useState, useEffect, useRef } from "react";
import { Slide, toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useNavigate } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import ScrollTop from "../../Components/ScrollTop";
import ReactModal from "react-modal";
import "./style.css";
import PingPage from "./PingPage";
import Map from "./Map";
import AnimatedButton from "./AnimatedButton";
import {
  PhoneIcon,
  UsersIcon,
  BagIcon,
  ClockIcon,
  LocationIcons,
  PicLocIcon,
  DropLocIcon,
  ArrowRightIcon,
  LocationIcon,
} from "../../icons";
import Axios from "../../Utils/Axios";
import { array } from "yup";
const lib = ["places"];
const key = "AIzaSyCQ5KzDHt2llFGTexTHNMAdwfJ24XsVzK4";

const MyJob = (props: any) => {
  const { currency, t } = props;
  const params = useParams();
  const navigate = useNavigate();
  const { code } = params;

  const [modalOpen, setModelOpen] = useState<boolean>(false);
  const [pinModalOpen, setPinModalOpen] = useState<boolean>(false);
  const [location, setLocation] = useState<any>({});
  const [payloadData, setPayloadData] = useState<any>({});
  const [clicked, setClicked] = useState<boolean>(false);
  const [shouldReturn, setShouldReturn] = useState<boolean>(false);
  const [directions, setDirections] = useState<any>({});
  const [driveStart, setDriveStart] = useState(
    localStorage.getItem("driveStart")
      ? localStorage.getItem("driveStart")
      : false
  );
  const [journeyStartData, setJourneyStartData] = useState<any>({});
  const [journeyId, setJourneyId] = useState<any>(
    localStorage.getItem("journeyId") ? localStorage.getItem("journeyId") : ""
  );
  const [destination, setDestination] = useState<any>(
    localStorage.getItem("destination")
      ? localStorage.getItem("destination")
      : ""
  );
  const [driverExecuteData, setDriverExecuteData] = useState<any>({});
  const [driverArriving, setDriverArriving] = useState<any>(
    localStorage.getItem("driverArriving")
      ? localStorage.getItem("driverArriving")
      : false
  );

  const getDriverExecute = async () => {
    const response = await Axios.get(
      `/api/v1/driverservices/execute?code=${code}`
    );
    if (response?.status === 200) {
      setDriverExecuteData(response?.data?.data);
      setPayloadData({
        ...payloadData,
        booking_id: response?.data?.data.bookingId,
      });
      localStorage.setItem("driveInfo", JSON.stringify(response?.data?.data));
    }
  };

  useEffect(() => {
    getDriverExecute();
  }, [code]);

  // API for move on Step 2
  const configJourneyStart: any = {
    method: "POST",
    url: "/api/v1/driverservices/journey-start/",
    data: {
      ...payloadData,
      amAtLocationPoint: `${location.lat}, ${location.long}`,
    },
  };
  const onSubmitJourneyStart = async () => {
    const journeyStartResp: any = await Axios(configJourneyStart).catch(
      (e: any) => {}
    );
    if (journeyStartResp == undefined) {
      toast.error("Something wrong", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        transition: Slide,
      });
    } else {
      if (journeyStartResp?.status === 200) {
        setJourneyStartData(journeyStartResp?.data?.data);
        setDriveStart(true);
        localStorage.setItem("driveStart", "true");
        setShouldReturn(false);
        setClicked(false);
        toast.success(
          journeyStartResp?.data?.message || "Journey Start Successfully",
          {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          }
        );
        setJourneyId(journeyStartResp?.data?.data?.journeyId);
        localStorage.setItem(
          "journeyId",
          journeyStartResp?.data?.data?.journeyId
        );
      } else {
        toast.error("Something wrong", {
          position: "top-center",
          autoClose: 3000,
          closeOnClick: true,
          transition: Slide,
        });
      }
    }
  };
  //API for Move step 3
  const configArrived: any = {
    method: "POST",
    url: `/api/v1/driverservices/arrive-at-location/${journeyId}/`,
    data: { arrivalPoint: `${location.lat}, ${location.long}` },
    // data: { arrivalPoint: `13.082679, 80.2708842` },
  };
  const submitArrivedAtLoc = async () => {
    try {
      const arrivedAtLocResp: any = await Axios(configArrived).catch(
        (e: any) => {}
      );
      if (arrivedAtLocResp == undefined) {
        setShouldReturn(false);
        setClicked(false);
        toast.error("You are too far from the booking location", {
          position: "top-center",
          autoClose: 3000,
          closeOnClick: true,
          transition: Slide,
        });
      } else {
        if (arrivedAtLocResp?.status === 200) {
          setShouldReturn(false);
          setClicked(false);
          toast.success(
            arrivedAtLocResp?.data?.message ||
              "Driver Arrived at pickup location!",
            {
              position: "top-center",
              autoClose: 3000,
              closeOnClick: true,
              transition: Slide,
            }
          );
          setDriverArriving(true);
          localStorage.setItem("driverArriving", "true");
          setDriveStart(false);
          localStorage.setItem("driveStart", "false");
        }
      }
    } catch (error) {
      setClicked(false);
      setShouldReturn(false);
      toast.error("You are too far from the booking location", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        transition: Slide,
      });
    }
  };

  //API for Move step 4 customer no show
  const configCustomerNoShow: any = {
    method: "POST",
    url: `/api/v1/driverservices/customer-no-show/${journeyId}/`,
    data: { arrivalPoint: `${location.lat}, ${location.long}` },
  };
  const submitCustomerNoShow = async () => {
    const customerNoShowResp: any = await Axios(configCustomerNoShow).catch(
      (e: any) => {}
    );

    if (customerNoShowResp?.status === 200) {
      navigate("/account");
      setShouldReturn(false);
      setClicked(false);
      setDriverArriving(false);
      localStorage.setItem("driverArriving", "false");
      toast.success(customerNoShowResp?.data?.message || "Customer No Show!", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        transition: Slide,
      });
    }
  };

  //API for Move step 5 customer no show
  const configJourneyEnd: any = {
    method: "POST",
    url: `/api/v1/driverservices/journey-end/${journeyId}/`,
    data: { destinationLocation: `${location.lat}, ${location.long}` },
  };
  const submitJourneyEnd = async () => {
    try {
      const journeyEndResp: any = await Axios(configJourneyEnd);
      // console.log("I am at destination api resp...", journeyEndResp);
      if (journeyEndResp?.status === 200) {
        // Handle success
        localStorage.removeItem("driveInfo");
        localStorage.removeItem("journeyId");
        localStorage.removeItem("driveStart");
        localStorage.removeItem("driverArriving");
        localStorage.removeItem("destination");
        navigate("/account");
        toast.success(journeyEndResp?.data?.message || "Journey End!", {
          position: "top-center",
          autoClose: 3000,
          closeOnClick: true,
          transition: Slide,
        });
      } else {
        // Handle error
        toast.error("Some thing wrong with Journey End!", {
          position: "top-center",
          autoClose: 3000,
          closeOnClick: true,
          transition: Slide,
        });
      }
    } catch (error) {
      toast.error("You are too far from the destination location", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        transition: Slide,
      });
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, long: longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <div className="my-job-page">
      <ScrollTop />
      <Helmet>
        <title>My Jobs</title>
      </Helmet>

      <div className="flex flex-col bg-white  min-h-screen">
        {/* Page Header */}
        <div className="flex flex-col flex-1">
          <div className="bg-[#EBAD18] h-[400px] rounded-b-[200px] px-5 py-14">
            <h1 className="text-black text-2xl font-medium mt-10">My Job</h1>
          </div>
          <div className="box-block bg-white sm:p-5 p-3 flex flex-col">
            {/* User Info section */}
            <div className="flex items-center gap-4 justify-between py-5">
              <div className="flex gap-4">
                {/* <div className="bg-[#543E3E] h-16 w-16 rounded-full"></div> */}
                <img
                  src="/company-logo-placeholder.jpg"
                  alt="img"
                  className="h-10 w-10 rounded-full bg-gray-50"
                />
                <div className="flex flex-col">
                  <h1 className="text-black font-semibold text-lg">
                    {driverExecuteData?.passengerName || ""}
                  </h1>
                  <h4 className="text-black font-light text-sm">
                    {driverExecuteData?.vehicleDetails || ""}
                  </h4>
                </div>
              </div>
              <div className="flex flex-col items-end gap-2">
                <button
                  className="flex items-center justify-center h-8 w-8 rounded-full bg-[#EBAD18] icon-shadow p-2"
                  onClick={() => {
                    if (driverExecuteData) {
                      window.open(`tel:${driverExecuteData.passengerMobile}`);
                    }
                  }}
                >
                  <PhoneIcon />
                </button>
                <h1 className="text-black font-medium text-sm">
                  #{driverExecuteData?.bookingId || ""}
                </h1>
              </div>
            </div>
            {/* Drive Info */}
            <div className="flex flex-col gap-3 py-5 border-t border-b border-[#EEEEEE]">
              <div className="flex items-center justify-between gap-2">
                <div className="flex items-center gap-1">
                  <div className="flex items-center justify-center h-7 w-6">
                    <UsersIcon />
                  </div>
                  <h4 className="text-[##444444] text-base font-normal">
                    {driverExecuteData?.passengers || ""}
                  </h4>
                </div>
                <div className="flex items-center gap-1">
                  <div className="flex items-center justify-center h-4 w-4">
                    <BagIcon />
                  </div>
                  <h4 className="text-[##444444] text-base font-normal">
                    {driverExecuteData?.luggage || ""}
                  </h4>
                </div>
                <div className="flex items-center gap-1">
                  <div className="flex items-center justify-center h-4 w-4">
                    <ClockIcon />
                  </div>
                  <h4 className="text-[##444444] text-base font-normal">
                    {driverExecuteData?.duration || ""}
                  </h4>
                </div>
                <div className="flex items-center gap-1">
                  <div className="flex items-center justify-center h-4 w-4">
                    <LocationIcons />
                  </div>
                  <h4 className="text-[##444444] text-base font-normal">
                    {driverExecuteData?.distance || ""} Kms
                  </h4>
                </div>
              </div>
              <div className="flex items-center justify-between gap-2">
                <h1 className="text-black text-base font-light">Date & Time</h1>
                <h1 className="text-black text-base font-light">
                  {driverExecuteData.booking_date || ""} |
                  {driverExecuteData?.arrival_time}
                </h1>
              </div>
            </div>

            {/* Location Distance */}
            <div className="flex gap-6 py-5">
              <div className="flex flex-col relative h-44 justify-between">
                <div className="flex items-center justify-center h-14 w-14 bg-[#FFEFC9] rounded-full p-2 z-10">
                  <div className="flex items-center justify-center rounded-full p-2 bg-[#EBAD18] h-8 w-8">
                    <PicLocIcon />
                  </div>
                </div>
                <div className=" absolute border border-dashed h-[80%] border-[#9E9E9E] left-[26px] mt-[22px] z-0"></div>
                <div className="flex items-center justify-center h-14 w-14 bg-[#FFEFC9] rounded-full p-2 z-10">
                  <div className="flex items-center justify-center rounded-full p-2 bg-[#EBAD18] h-8 w-8">
                    <DropLocIcon />
                  </div>
                </div>
              </div>
              <div className="flex flex-col h-44 justify-between py-2">
                <div className="flex flex-col">
                  <h1 className="sm:text-lg text-sm font-medium text-black">
                    Pick Up Location
                  </h1>
                  <h1 className="text-[#616161] text-sm font-normal">
                    {driverExecuteData?.pickup || ""}
                  </h1>
                </div>
                <div className="flex flex-col">
                  <h1 className="sm:text-lg text-sm font-medium text-black">
                    Drop off Location
                  </h1>
                  <h1 className="text-[#616161] text-sm font-normal">
                    {driverExecuteData?.destination || ""}
                  </h1>
                </div>
              </div>
            </div>
            {/* Map Area */}
            <div className="py-5 h-80 w-full mb-5 overflow-hidden">
              {driverExecuteData?.pickupLatLong ? (
                <LoadScript googleMapsApiKey={key}>
                  <Map
                    defaultLocation={{
                      lat: Number(driverExecuteData?.pickupLatLong?.lat),
                      lng: Number(driverExecuteData?.pickupLatLong?.long),
                    }}
                    destination={{
                      lat: Number(driverExecuteData?.destinationLatLong?.lat),
                      lng: Number(driverExecuteData?.destinationLatLong?.long),
                    }}
                    origin={{
                      lat: Number(driverExecuteData?.pickupLatLong?.lat),
                      lng: Number(driverExecuteData?.pickupLatLong?.long),
                    }}
                  />
                </LoadScript>
              ) : null}
            </div>

            {driveStart ? (
              <AnimatedButton
                onClick={submitArrivedAtLoc}
                shouldReturn={shouldReturn}
                setShouldReturn={setShouldReturn}
                clicked={clicked}
                setClicked={setClicked}
                text="Arrived at Pickup Location"
              />
            ) : driverArriving ? (
              <div className="flex flex-col gap-5">
                <button
                  className="flex items-center p-1 bg-[#EBAD18] w-full h-12 rounded-full gap-2"
                  onClick={(e) => setPinModalOpen(true)}
                >
                  <div className="flex items-center justify-center h-10 w-10 bg-white rounded-full p-2">
                    <ArrowRightIcon />
                  </div>
                  <h1 className="text-black font-semibold text-center justify-center flex flex-1">
                    Customer Onboard
                  </h1>
                </button>
                <button
                  className="flex items-center p-1 bg-black w-full h-12 rounded-full gap-2"
                  onClick={() => setModelOpen(true)}
                >
                  <h1 className="text-white font-semibold text-center justify-center flex flex-1">
                    Customer No Show
                  </h1>
                </button>
              </div>
            ) : destination ? (
              <button
                className="flex items-center p-1 bg-[#EBAD18] w-full h-12 rounded-full gap-2"
                onClick={(e) => submitJourneyEnd()}
              >
                <div className="flex items-center justify-center h-10 w-10 bg-white rounded-full p-2">
                  <ArrowRightIcon />
                </div>
                <h1 className="text-black font-semibold text-center justify-center flex flex-1">
                  I Am at Destination
                </h1>
              </button>
            ) : (
              <AnimatedButton
                onClick={onSubmitJourneyStart}
                shouldReturn={shouldReturn}
                setShouldReturn={setShouldReturn}
                clicked={clicked}
                setClicked={setClicked}
                text="On The Way"
              />
            )}
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={pinModalOpen}
        className="p-5 h-screen w-screen z-[9999] flex justify-center items-center"
      >
        <PingPage
          pinModalOpen={pinModalOpen}
          setPinModalOpen={setPinModalOpen}
          setDestination={setDestination}
          journeyId={journeyId}
          setDriverArriving={setDriverArriving}
        />
      </ReactModal>
      <ReactModal
        isOpen={modalOpen}
        className="p-5 h-screen w-screen z-[9999] flex justify-center items-center"
      >
        <div className="flex flex-col bg-white min-h-64 w-[90%] rounded-2xl overflow-hidden p-4">
          <div className="flex items-center justify-end">
            <button
              className="flex items-center justify-center font-semibold text-lg bg-red-500 text-white rounded-full p-2 h-7 w-7"
              onClick={() => setModelOpen(false)}
            >
              x
            </button>
          </div>
          <div className="bgImage flex items-center justify-center">
            <div className="flex items-center justify-center bg-[#1F2937] h-[135px] w-[135px] rounded-full p-6">
              <LocationIcon />
            </div>
          </div>
          <div className="flex items-center justify-center">
            <h1 className="text-black font-bold sm:text-2xl text-lg text-center leading-5">
              Please make sure you have all evidence
            </h1>
          </div>
          <Link
            to="/account"
            className="bg-[#EBAD18] py-3 px-5 text-black font-bold sm:text-lg text-base rounded-full mt-14 text-center"
            onClick={(e) => submitCustomerNoShow()}
          >
            Done
          </Link>
        </div>
      </ReactModal>
    </div>
  );
};

export default MyJob;
