import React from "react";

function DropLocIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 14 18"
    >
      <path
        fill="#000"
        d="M7 9c-.917 0-1.667-.75-1.667-1.667 0-.916.75-1.666 1.667-1.666s1.667.75 1.667 1.666C8.667 8.25 7.917 9 7 9zm5-1.5c0-3.025-2.208-5.167-5-5.167S2 4.475 2 7.5c0 1.95 1.625 4.533 5 7.617 3.375-3.084 5-5.667 5-7.617zM7 .667c3.5 0 6.667 2.683 6.667 6.833 0 2.767-2.225 6.042-6.667 9.833C2.558 13.542.333 10.267.333 7.5.333 3.35 3.5.667 7 .667z"
      ></path>
    </svg>
  );
}

export default DropLocIcon;
