import { isValidPhoneNumber } from "react-phone-number-input";
import { z } from "zod";

export function schemaWithPasswordValidation<T extends Record<string, any>>(
  schema: Zod.ZodObject<T>
) {
  return schema.superRefine((data, ctx) => {
    if (!!data.newpassword?.length && !data.password?.length) {
      ctx.addIssue({
        message: "Old Password is required",
        path: ["password"],
        code: "custom",
      });
    }

    if (!!data.confpassword?.length && !data.newpassword?.length) {
      ctx.addIssue({
        message: "Enter password here first",
        path: ["newpassword"],
        code: "custom",
      });
    } else {
      if (data.confpassword !== data.newpassword) {
        ctx.addIssue({
          message: "Passwords are not same",
          path: ["confpassword"],
          code: "custom",
        });
      }
    }
  });
}

export const editAccountSchema = z.object({
  firstName: z
    .string({ required_error: "First name is required" })
    .min(1, "First name is required"),

  lastName: z
    .string({ required_error: "First name is required" })
    .min(1, "First name is required"),

  email: z.string({ required_error: "First name is required" }).email(),

  enabledNewsletter: z.boolean().optional(),

  userCurrency: z.string().optional(),

  password: z.string().optional(),

  newpassword: z
    .string()
    .min(8, "Password must be at least 8 characters")
    .max(20, "Password must be at most 20 characters")
    .or(z.literal("")),

  confpassword: z.string().optional(),

  phone: z
    .string()
    .or(z.literal(""))
    .refine(
      (value) => {
        if (!value.length) return true;
        if (isValidPhoneNumber(value)) return true;

        return false;
      },
      { message: "Invalid phone number" }
    )
    .optional(),
});

export const editAccountByTravelAgentSchema = editAccountSchema.extend({
  city: z.string().min(3, "City is required"),
  street_address_1: z.string().min(1, "Address is required"),
  street_address_2: z.string().min(1, "Address is required"),
  pincode: z
    .string({
      required_error: "Pincode is required",
      invalid_type_error: "Invalid Pincode",
    })
    .min(4, "Invalid Pincode"),
  vat_id: z.string().min(1, "Required"),
  company_name: z.string().min(1, "Required"),
  country: z.string().min(1, "Required"),
  // logo: z.string().min(1, "Required"),
  logo: z.string().optional(),
});
