import { createSlice } from '@reduxjs/toolkit'
import { createSelector } from 'reselect';

const searchSlice = createSlice({
    name: 'search',
    initialState: {
        bookingType: "p_to_p",
        pickupDate: "",
        pickupTime: "",
        dropoffLocation: { "label": "", "value": "" },
        pickupLocation: { "label": "", "value": "" },
        dropoffGeometry: null,
        pickupGeometry: null,
        noofPass: 1,
        noofBags: 1,
        duration: "",
        currency: "EUR"
    },
    reducers: {
        pickupSelected: (state, param) => {
            state.pickupLocation = param.payload;
        },
        dropOffSelected: (state, param) => {
            state.dropoffLocation = param.payload;
        },
        noofPassSelected: (state, param) => {
            state.noofPass = param.payload;
        },
        noofBagsSelected: (state, param) => {
            state.noofBags = param.payload;
        },
        durationSelected: (state, param) => {
            state.duration = param.payload;
        },
        pickupDateSelected: (state, param) => {
            state.pickupDate = param.payload;
        },
        pickupTimeSelected: (state, param) => {
            state.pickupTime = param.payload;
        },
        bookingTypeChanged: (state, param) => {
            state.bookingType = param.payload;
        },
        pickupGeometrySelected: (state, param) => {
            state.pickupGeometry = param.payload;
        },
        dropoffGeometrySelected: (state, param) => {
            state.dropoffGeometry = param.payload;
        },
        exchangeLocation: (state) => {
            const { pickupLocation, dropoffLocation, pickupGeometry, dropoffGeometry } = state;
            state.pickupLocation = dropoffLocation;
            state.dropoffLocation = pickupLocation;
            state.dropoffGeometry = pickupGeometry;
            state.pickupGeometry = dropoffGeometry;
            
        },
        currencyChanged: (state, param) => {
            state.currency = param.payload;
        },
        resetSearch: (state) => {
            state.bookingType = "p_to_p";
            state.pickupDate = "";
            state.pickupTime = "";
            state.dropoffLocation = { "label": "", "value": "" };
            state.pickupLocation = { "label": "", "value": "" };
            state.dropoffGeometry = null;
            state.pickupGeometry = null;
            state.noofPass = 1;
            state.noofBags = 1;
            state.duration = "";
            state.currency = "EUR"
        }
    },
});

export const { bookingTypeChanged, pickupSelected, pickupTimeSelected, dropOffSelected, noofPassSelected, pickupDateSelected, noofBagsSelected, durationSelected, pickupGeometrySelected, dropoffGeometrySelected, exchangeLocation, currencyChanged, resetSearch } = searchSlice.actions;
export default searchSlice.reducer;

const selectSearch = state => state.search;

export const selectBookingType = createSelector(
    selectSearch,
    search => search.bookingType
);

export const selectPickupDate = createSelector(
    selectSearch,
    search => search.pickupDate
);
//pickupTime
export const selectPickupTime = createSelector(
    selectSearch,
    search => search.pickupTime
);

export const selectDropoffLocation = createSelector(
    selectSearch,
    search => search.dropoffLocation
);

export const selectPickupLocation = createSelector(
    selectSearch,
    search => search.pickupLocation
);
export const selectNoofBags = createSelector(selectSearch,
    search => search.noofBags
);
export const selectDuration = createSelector(selectSearch,
    search => search.duration
);
export const selectNoofpass = createSelector(selectSearch,
    search => search.noofPass
);
export const selectCurrency = createSelector(selectSearch,
    search => search.currency
);

export const selectPickupInput = createSelector(
    selectSearch,
    search => ({ label: search.pickupLocation?.label, value: { place_id: search.pickupLocation?.value } })
);
export const selectDropoffInput = createSelector(
    selectSearch,
    search => ({ label: search.dropoffLocation?.label, value: { place_id: search.dropoffLocation?.value } })
);
export const selectPickupGeo = createSelector(selectSearch,
    search => search.pickupGeometry
);
export const selectDropoffGeo = createSelector(selectSearch,
    search => search.dropoffGeometry
);

export const AllGeodata = createSelector(selectSearch,
    search => search
);
