import React from "react";
import { MdManageAccounts } from "react-icons/md";
import { FaDollarSign, FaNetworkWired, FaUsers } from "react-icons/fa";
import { RiCoupon4Line } from "react-icons/ri";
import { AiFillDashboard } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const AdminNavbar = (props: any) => {
  const navigate: any = useNavigate();
  const { page } = props;
  return (
    <div className="flex space-x-4 justify-center items-center py-4 px w-full bg-yellow-600">
      <div
        className="flex cursor-pointer"
        onClick={() => navigate("/adminpanel/index")}
      >
        <p
          className={`flex font-bold items-center hover:text-gray-600 ${
            page === "index" ? "text-black" : "text-white"
          }`}
        >
          <span>
            <MdManageAccounts className={`w-12`} size={20} />
          </span>
          <span> Admin Panel</span>
        </p>
      </div>
      <div
        className="flex cursor-pointer"
        onClick={() => navigate("/adminpanel/reports")}
      >
        <p
          className={`flex font-bold items-center hover:text-gray-600 ${
            page === "reports" ? "text-black" : "text-white"
          }`}
        >
          <span>
            <FaDollarSign className={`w-12`} size={20} />
          </span>
          <span>Reports</span>
        </p>
      </div>
      <div
        className="flex cursor-pointer"
        onClick={() => navigate("/adminpanel/agencies")}
      >
        <p
          className={`flex font-bold items-center hover:text-gray-600 ${
            page === "agencies" ? "text-black" : "text-white"
          }`}
        >
          <span>
            <FaNetworkWired className={`w-12`} size={20} />
          </span>
          <span>Agencies</span>
        </p>
      </div>
      <div
        className="flex cursor-pointer"
        onClick={() => navigate("/adminpanel/coupons")}
      >
        <p
          className={`flex font-bold items-center hover:text-gray-600 ${
            page === "coupons" ? "text-black" : "text-white"
          }`}
        >
          <span>
            <RiCoupon4Line className={`w-12`} size={20} />
          </span>
          <span>Coupons</span>
        </p>
      </div>
      <div
        className="flex cursor-pointer"
        onClick={() => navigate("/adminpanel/customers")}
      >
        <p
          className={`flex font-bold items-center hover:text-gray-600 ${
            page === "customers" ? "text-black" : "text-white"
          }`}
        >
          <span>
            <FaUsers className={`w-12`} size={20} />
          </span>
          <span> Customers</span>
        </p>
      </div>
      <div
        className="flex cursor-pointer"
        onClick={() => navigate("/adminpanel/dashboard")}
      >
        <p
          className={`flex font-bold items-center hover:text-gray-600 ${
            page === "dashboard" ? "text-black" : "text-white"
          }`}
        >
          <span>
            <AiFillDashboard className={`w-12`} size={20} />
          </span>
          <span> Dashboard</span>
        </p>
      </div>
    </div>
  );
};

export default AdminNavbar;
