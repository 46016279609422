import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import countriesList from "../Assets/countries.json";
const TopCitiesV2 = (props: any) => {
  // debugger
  const { city, index } = props;
  const [cityDetails, setCityDetails] = useState<any>({});
  useEffect(() => {
    // debugger
    let countries: any = countriesList;
    // console.log(countries);
    // city.countryCode = 'NO';
    // console.log(city)
    let flag: any = countries[city?.country_code]?.image;
    if (flag) {
      setCityDetails({ ...city, flag });
    }
  }, [city]);

  return (
    <Link
      to={`/popular_locations/${cityDetails.slug?.toLocaleLowerCase()}`} className="lg:col-span-1 col-span-3 rounded-md shadow-lg cursor-pointer hover:shadow-xl hover:bg-gray-200"
      key={`banner2_${index}`}
    >
      {cityDetails?.city_name ? (
        <div className="py-3 flex ">
          <div className="my-auto mx-4">
            <img src={cityDetails.flag} alt="b2" className="h-12 w-full" />
          </div>
          <div>
            <p className="mt text-md font-semibold">{cityDetails.city_name}</p>
            {/* <p className="mt text-gray-500 font-light font-xs">
              {cityDetails?.routes ?? 8} routes to/from this city
            </p> */}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </Link>
  );
};

export default TopCitiesV2;
