export const flightData =  [
    {
      
        "label": "Autostradale",
        "value": {
          "id": "AUTOSTRAD",
          "lcc": "None",
          "name": "Autostradale",
          "logo": "https://images.kiwi.com/airlines/64/AUTOSTRAD.png"
        }
     
    },
    {
      "label": "East Midlands Trains",
      "value": {
        "id": "EASTMIDLAN",
        "lcc": "None",
        "name": "East Midlands Trains",
        "logo": "https://images.kiwi.com/airlines/64/EASTMIDLAN.png"
      }
    },
    {
      "label": "Flibco",
      "value": {
        "id": "FLIBCO",
        "lcc": "0",
        "name": "Flibco",
        "logo": "https://images.kiwi.com/airlines/64/FLIBCO.png"
      }
    },
    {
      "label": "Sundair",
      "value": {
        "id": "SR",
        "lcc": "0",
        "name": "Sundair",
        "logo": "https://images.kiwi.com/airlines/64/SR.png"
      }
    },
    {
      "label": "Long iata code test",
      "value": {
        "id": "LONGTEST",
        "lcc": "0",
        "name": "Long iata code test",
        "logo": "https://images.kiwi.com/airlines/64/LONGTEST.png"
      }
    },
    {
      "label": "Sagales",
      "value": {
        "id": "SAGALES",
        "lcc": "None",
        "name": "Sagales",
        "logo": "https://images.kiwi.com/airlines/64/SAGALES.png"
      }
    },
    {
      "label": "Eurolines",
      "value": {
        "id": "EUROLINES",
        "lcc": "0",
        "name": "Eurolines",
        "logo": "https://images.kiwi.com/airlines/64/EUROLINES.png"
      }
    },
    {
      "label": "Isilines",
      "value": {
        "id": "ISILINES",
        "lcc": "0",
        "name": "Isilines",
        "logo": "https://images.kiwi.com/airlines/64/ISILINES.png"
      }
    },
    {
      "label": "Nomago",
      "value": {
        "id": "NOMAGO",
        "lcc": "None",
        "name": "Nomago",
        "logo": "https://images.kiwi.com/airlines/64/NOMAGO.png"
      }
    },
    {
      "label": "WestJet Encore",
      "value": {
        "id": "WR",
        "lcc": "1",
        "name": "WestJet Encore",
        "logo": "https://images.kiwi.com/airlines/64/WR.png"
      }
    },
    {
      "label": "Cyprus Airways",
      "value": {
        "id": "CY",
        "lcc": "0",
        "name": "Cyprus Airways",
        "logo": "https://images.kiwi.com/airlines/64/CY.png"
      }
    },
    {
      "label": "Flixtrain",
      "value": {
        "id": "FLIXTRAIN",
        "lcc": "0",
        "name": "Flixtrain",
        "logo": "https://images.kiwi.com/airlines/64/FLIXTRAIN.png"
      }
    },
    {
      "label": "Air Kenya",
      "value": {
        "id": "P2",
        "lcc": "1",
        "name": "Air Kenya",
        "logo": "https://images.kiwi.com/airlines/64/P2.png"
      }
    },
    {
      "label": "interCaribbean Airways",
      "value": {
        "id": "JY",
        "lcc": "0",
        "name": "interCaribbean Airways",
        "logo": "https://images.kiwi.com/airlines/64/JY.png"
      }
    },
    {
      "label": "Air Austral",
      "value": {
        "id": "UU",
        "lcc": "0",
        "name": "Air Austral",
        "logo": "https://images.kiwi.com/airlines/64/UU.png"
      }
    },
    {
      "label": "Scandinavian Airlines Ireland",
      "value": {
        "id": "SZS",
        "lcc": "None",
        "name": "Scandinavian Airlines Ireland",
        "logo": "https://images.kiwi.com/airlines/64/SZS.png"
      }
    },
    {
      "label": "Greyhound",
      "value": {
        "id": "GREYHOUND",
        "lcc": "0",
        "name": "Greyhound",
        "logo": "https://images.kiwi.com/airlines/64/GREYHOUND.png"
      }
    },
    {
      "label": "Lao Airlines",
      "value": {
        "id": "QV",
        "lcc": "0",
        "name": "Lao Airlines",
        "logo": "https://images.kiwi.com/airlines/64/QV.png"
      }
    },
    {
      "label": "NextJet",
      "value": {
        "id": "2N",
        "lcc": "1",
        "name": "NextJet",
        "logo": "https://images.kiwi.com/airlines/64/2N.png"
      }
    },
    {
      "label": "Air Algerie",
      "value": {
        "id": "AH",
        "lcc": "0",
        "name": "Air Algerie",
        "logo": "https://images.kiwi.com/airlines/64/AH.png"
      }
    },
    {
      "label": "Tandem Aero",
      "value": {
        "id": "TQ",
        "lcc": "0",
        "name": "Tandem Aero",
        "logo": "https://images.kiwi.com/airlines/64/TQ.png"
      }
    },
    {
      "label": "Armenia Aircompany",
      "value": {
        "id": "RM",
        "lcc": "0",
        "name": "Armenia Aircompany",
        "logo": "https://images.kiwi.com/airlines/64/RM.png"
      }
    },
    {
      "label": "National Express",
      "value": {
        "id": "NATEXPRESS",
        "lcc": "0",
        "name": "National Express",
        "logo": "https://images.kiwi.com/airlines/64/NATEXPRESS.png"
      }
    },
    {
      "label": "Avianca Argentina",
      "value": {
        "id": "A0",
        "lcc": "1",
        "name": "Avianca Argentina",
        "logo": "https://images.kiwi.com/airlines/64/A0.png"
      }
    },
    {
      "label": "JetGo",
      "value": {
        "id": "JG",
        "lcc": "1",
        "name": "JetGo",
        "logo": "https://images.kiwi.com/airlines/64/JG.png"
      }
    },
    {
      "label": "SkyJet Airlines",
      "value": {
        "id": "M8",
        "lcc": "1",
        "name": "SkyJet Airlines",
        "logo": "https://images.kiwi.com/airlines/64/M8.png"
      }
    },
    {
      "label": "Aero Mongolia",
      "value": {
        "id": "M0",
        "lcc": "1",
        "name": "Aero Mongolia",
        "logo": "https://images.kiwi.com/airlines/64/M0.png"
      }
    },
    {
      "label": "Canadian North",
      "value": {
        "id": "5T",
        "lcc": "0",
        "name": "Canadian North",
        "logo": "https://images.kiwi.com/airlines/64/5T.png"
      }
    },
    {
      "label": "Pelita",
      "value": {
        "id": "6D",
        "lcc": "0",
        "name": "Pelita",
        "logo": "https://images.kiwi.com/airlines/64/6D.png"
      }
    },
    {
      "label": "Avianca Guatemala",
      "value": {
        "id": "GU",
        "lcc": "1",
        "name": "Avianca Guatemala",
        "logo": "https://images.kiwi.com/airlines/64/GU.png"
      }
    },
    {
      "label": "NokScoot",
      "value": {
        "id": "XW",
        "lcc": "1",
        "name": "NokScoot",
        "logo": "https://images.kiwi.com/airlines/64/XW.png"
      }
    },
    {
      "label": "United Airlines",
      "value": {
        "id": "UA",
        "lcc": "0",
        "name": "United Airlines",
        "logo": "https://images.kiwi.com/airlines/64/UA.png"
      }
    },
    {
      "label": "Alaska Seaplanes X4",
      "value": {
        "id": "X4",
        "lcc": "0",
        "name": "Alaska Seaplanes X4",
        "logo": "https://images.kiwi.com/airlines/64/X4.png"
      }
    },
    {
      "label": "FMI Air",
      "value": {
        "id": "ND",
        "lcc": "0",
        "name": "FMI Air",
        "logo": "https://images.kiwi.com/airlines/64/ND.png"
      }
    },
    {
      "label": "Conviasa",
      "value": {
        "id": "V0",
        "lcc": "1",
        "name": "Conviasa",
        "logo": "https://images.kiwi.com/airlines/64/V0.png"
      }
    },
    {
      "label": "Greenfly",
      "value": {
        "id": "XX",
        "lcc": "0",
        "name": "Greenfly",
        "logo": "https://images.kiwi.com/airlines/64/XX.png"
      }
    },
    {
      "label": "AirAsia X",
      "value": {
        "id": "D7",
        "lcc": "0",
        "name": "AirAsia X",
        "logo": "https://images.kiwi.com/airlines/64/D7.png"
      }
    },
    {
      "label": "Thai AirAsia X",
      "value": {
        "id": "XJ",
        "lcc": "0",
        "name": "Thai AirAsia X",
        "logo": "https://images.kiwi.com/airlines/64/XJ.png"
      }
    },
    {
      "label": "PAL Express",
      "value": {
        "id": "2P",
        "lcc": "1",
        "name": "PAL Express",
        "logo": "https://images.kiwi.com/airlines/64/2P.png"
      }
    },
    {
      "label": "Buta Airways",
      "value": {
        "id": "BUTAAIR",
        "lcc": "1",
        "name": "Buta Airways",
        "logo": "https://images.kiwi.com/airlines/64/BUTAAIR.png"
      }
    },
    {
      "label": "Cubana de Aviación",
      "value": {
        "id": "CU",
        "lcc": "0",
        "name": "Cubana de Aviación",
        "logo": "https://images.kiwi.com/airlines/64/CU.png"
      }
    },
    {
      "label": "Viva Air",
      "value": {
        "id": "VV",
        "lcc": "0",
        "name": "Viva Air",
        "logo": "https://images.kiwi.com/airlines/64/VV.png"
      }
    },
    {
      "label": "SkyUp Airlines",
      "value": {
        "id": "PQ",
        "lcc": "0",
        "name": "SkyUp Airlines",
        "logo": "https://images.kiwi.com/airlines/64/PQ.png"
      }
    },
    {
      "label": "Arik Air",
      "value": {
        "id": "W3",
        "lcc": "0",
        "name": "Arik Air",
        "logo": "https://images.kiwi.com/airlines/64/W3.png"
      }
    },
    {
      "label": "People's Viennaline PE",
      "value": {
        "id": "PE",
        "lcc": "0",
        "name": "People's Viennaline PE",
        "logo": "https://images.kiwi.com/airlines/64/PE.png"
      }
    },
    {
      "label": "Gomelavia",
      "value": {
        "id": "YD",
        "lcc": "0",
        "name": "Gomelavia",
        "logo": "https://images.kiwi.com/airlines/64/YD.png"
      }
    },
    {
      "label": "Loong Air",
      "value": {
        "id": "GJ",
        "lcc": "0",
        "name": "Loong Air",
        "logo": "https://images.kiwi.com/airlines/64/GJ.png"
      }
    },
    {
      "label": "Flixbus",
      "value": {
        "id": "FLIXBUS",
        "lcc": "0",
        "name": "Flixbus",
        "logo": "https://images.kiwi.com/airlines/64/FLIXBUS.png"
      }
    },
    {
      "label": "Transportes Chihuahuenses",
      "value": {
        "id": "CHIHUAHUEN",
        "lcc": "None",
        "name": "Transportes Chihuahuenses",
        "logo": "https://images.kiwi.com/airlines/64/CHIHUAHUEN.png"
      }
    },
    {
      "label": "SNCB",
      "value": {
        "id": "SNCB",
        "lcc": "0",
        "name": "SNCB",
        "logo": "https://images.kiwi.com/airlines/64/SNCB.png"
      }
    },
    {
      "label": "Ciao Air",
      "value": {
        "id": "99",
        "lcc": "0",
        "name": "Ciao Air",
        "logo": "https://images.kiwi.com/airlines/64/99.png"
      }
    },
    {
      "label": "Starbow Airlines",
      "value": {
        "id": "S9",
        "lcc": "0",
        "name": "Starbow Airlines",
        "logo": "https://images.kiwi.com/airlines/64/S9.png"
      }
    },
    {
      "label": "Aerodart",
      "value": {
        "id": "D4",
        "lcc": "0",
        "name": "Aerodart",
        "logo": "https://images.kiwi.com/airlines/64/D4.png"
      }
    },
    {
      "label": "Mid Africa Aviation",
      "value": {
        "id": "8G",
        "lcc": "0",
        "name": "Mid Africa Aviation",
        "logo": "https://images.kiwi.com/airlines/64/8G.png"
      }
    },
    {
      "label": "TransNusa",
      "value": {
        "id": "8B",
        "lcc": "0",
        "name": "TransNusa",
        "logo": "https://images.kiwi.com/airlines/64/8B.png"
      }
    },
    {
      "label": "Azul",
      "value": {
        "id": "AD",
        "lcc": "1",
        "name": "Azul",
        "logo": "https://images.kiwi.com/airlines/64/AD.png"
      }
    },
    {
      "label": "Air Tahiti",
      "value": {
        "id": "VT",
        "lcc": "0",
        "name": "Air Tahiti",
        "logo": "https://images.kiwi.com/airlines/64/VT.png"
      }
    },
    {
      "label": "Peninsula Airways",
      "value": {
        "id": "KS",
        "lcc": "0",
        "name": "Peninsula Airways",
        "logo": "https://images.kiwi.com/airlines/64/KS.png"
      }
    },
    {
      "label": "Druk Air",
      "value": {
        "id": "KB",
        "lcc": "0",
        "name": "Druk Air",
        "logo": "https://images.kiwi.com/airlines/64/KB.png"
      }
    },
    {
      "label": "SkyWork Airlines",
      "value": {
        "id": "SX",
        "lcc": "0",
        "name": "SkyWork Airlines",
        "logo": "https://images.kiwi.com/airlines/64/SX.png"
      }
    },
    {
      "label": "SalamAir",
      "value": {
        "id": "OV",
        "lcc": "1",
        "name": "SalamAir",
        "logo": "https://images.kiwi.com/airlines/64/OV.png"
      }
    },
    {
      "label": "Meraj Air",
      "value": {
        "id": "JI",
        "lcc": "0",
        "name": "Meraj Air",
        "logo": "https://images.kiwi.com/airlines/64/JI.png"
      }
    },
    {
      "label": "Air Comet Chile",
      "value": {
        "id": "3I",
        "lcc": "0",
        "name": "Air Comet Chile",
        "logo": "https://images.kiwi.com/airlines/64/3I.png"
      }
    },
    {
      "label": "Click (Mexicana)",
      "value": {
        "id": "QA",
        "lcc": "0",
        "name": "Click (Mexicana)",
        "logo": "https://images.kiwi.com/airlines/64/QA.png"
      }
    },
    {
      "label": "Dana Airlines Limited",
      "value": {
        "id": "9J",
        "lcc": "0",
        "name": "Dana Airlines Limited",
        "logo": "https://images.kiwi.com/airlines/64/9J.png"
      }
    },
    {
      "label": "Astra Airlines",
      "value": {
        "id": "A2",
        "lcc": "0",
        "name": "Astra Airlines",
        "logo": "https://images.kiwi.com/airlines/64/A2.png"
      }
    },
    {
      "label": "Tassili Airlines",
      "value": {
        "id": "SF",
        "lcc": "0",
        "name": "Tassili Airlines",
        "logo": "https://images.kiwi.com/airlines/64/SF.png"
      }
    },
    {
      "label": "IC Bus",
      "value": {
        "id": "ICBUS",
        "lcc": "0",
        "name": "IC Bus",
        "logo": "https://images.kiwi.com/airlines/64/ICBUS.png"
      }
    },
    {
      "label": "DeinBus",
      "value": {
        "id": "DEINBUS",
        "lcc": "0",
        "name": "DeinBus",
        "logo": "https://images.kiwi.com/airlines/64/DEINBUS.png"
      }
    },
    {
      "label": "Oresundstag",
      "value": {
        "id": "ORESUNDST",
        "lcc": "0",
        "name": "Oresundstag",
        "logo": "https://images.kiwi.com/airlines/64/ORESUNDST.png"
      }
    },
    {
      "label": "Le Bus Direct",
      "value": {
        "id": "LEBUSDIR",
        "lcc": "0",
        "name": "Le Bus Direct",
        "logo": "https://images.kiwi.com/airlines/64/LEBUSDIR.png"
      }
    },
    {
      "label": "African Express",
      "value": {
        "id": "XU",
        "lcc": "1",
        "name": "African Express",
        "logo": "https://images.kiwi.com/airlines/64/XU.png"
      }
    },
    {
      "label": "Corendon Dutch Airlines B.V.",
      "value": {
        "id": "CD",
        "lcc": "0",
        "name": "Corendon Dutch Airlines B.V.",
        "logo": "https://images.kiwi.com/airlines/64/CD.png"
      }
    },
    {
      "label": "Alaska Seaplane Service",
      "value": {
        "id": "J5",
        "lcc": "0",
        "name": "Alaska Seaplane Service",
        "logo": "https://images.kiwi.com/airlines/64/J5.png"
      }
    },
    {
      "label": "Lanmei Airlines",
      "value": {
        "id": "LQ",
        "lcc": "1",
        "name": "Lanmei Airlines",
        "logo": "https://images.kiwi.com/airlines/64/LQ.png"
      }
    },
    {
      "label": "Saudi Arabian Airlines",
      "value": {
        "id": "SV",
        "lcc": "0",
        "name": "Saudi Arabian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/SV.png"
      }
    },
    {
      "label": "Lufthansa",
      "value": {
        "id": "LH",
        "lcc": "0",
        "name": "Lufthansa",
        "logo": "https://images.kiwi.com/airlines/64/LH.png"
      }
    },
    {
      "label": "LATAM Airlines",
      "value": {
        "id": "LA",
        "lcc": "0",
        "name": "LATAM Airlines",
        "logo": "https://images.kiwi.com/airlines/64/LA.png"
      }
    },
    {
      "label": "Qantas",
      "value": {
        "id": "QF",
        "lcc": "0",
        "name": "Qantas",
        "logo": "https://images.kiwi.com/airlines/64/QF.png"
      }
    },
    {
      "label": "Blue Air",
      "value": {
        "id": "0B",
        "lcc": "1",
        "name": "Blue Air",
        "logo": "https://images.kiwi.com/airlines/64/0B.png"
      }
    },
    {
      "label": "Air Mediterranean",
      "value": {
        "id": "MV",
        "lcc": "0",
        "name": "Air Mediterranean",
        "logo": "https://images.kiwi.com/airlines/64/MV.png"
      }
    },
    {
      "label": "Norwegian Air UK",
      "value": {
        "id": "DI",
        "lcc": "0",
        "name": "Norwegian Air UK",
        "logo": "https://images.kiwi.com/airlines/64/DI.png"
      }
    },
    {
      "label": "JSC UVT Aero",
      "value": {
        "id": "RT",
        "lcc": "0",
        "name": "JSC UVT Aero",
        "logo": "https://images.kiwi.com/airlines/64/RT.png"
      }
    },
    {
      "label": "Flyadeal",
      "value": {
        "id": "F3",
        "lcc": "0",
        "name": "Flyadeal",
        "logo": "https://images.kiwi.com/airlines/64/F3.png"
      }
    },
    {
      "label": "Transfero",
      "value": {
        "id": "TRANSFERO",
        "lcc": "0",
        "name": "Transfero",
        "logo": "https://images.kiwi.com/airlines/64/TRANSFERO.png"
      }
    },
    {
      "label": "AirCentury",
      "value": {
        "id": "Y2",
        "lcc": "0",
        "name": "AirCentury",
        "logo": "https://images.kiwi.com/airlines/64/Y2.png"
      }
    },
    {
      "label": "Air Arabia Jordan",
      "value": {
        "id": "9P",
        "lcc": "1",
        "name": "Air Arabia Jordan",
        "logo": "https://images.kiwi.com/airlines/64/9P.png"
      }
    },
    {
      "label": "LATAM Colombia",
      "value": {
        "id": "4C",
        "lcc": "0",
        "name": "LATAM Colombia",
        "logo": "https://images.kiwi.com/airlines/64/4C.png"
      }
    },
    {
      "label": "Air Travel",
      "value": {
        "id": "A6",
        "lcc": "0",
        "name": "Air Travel",
        "logo": "https://images.kiwi.com/airlines/64/A6.png"
      }
    },
    {
      "label": "ZanAir",
      "value": {
        "id": "B4",
        "lcc": "0",
        "name": "ZanAir",
        "logo": "https://images.kiwi.com/airlines/64/B4.png"
      }
    },
    {
      "label": "Avianca Peru",
      "value": {
        "id": "T0",
        "lcc": "0",
        "name": "Avianca Peru",
        "logo": "https://images.kiwi.com/airlines/64/T0.png"
      }
    },
    {
      "label": "Lugansk Airlines",
      "value": {
        "id": "L7",
        "lcc": "0",
        "name": "Lugansk Airlines",
        "logo": "https://images.kiwi.com/airlines/64/L7.png"
      }
    },
    {
      "label": "Air Nostrum",
      "value": {
        "id": "YW",
        "lcc": "0",
        "name": "Air Nostrum",
        "logo": "https://images.kiwi.com/airlines/64/YW.png"
      }
    },
    {
      "label": "NordStar Airlines",
      "value": {
        "id": "Y7",
        "lcc": "1",
        "name": "NordStar Airlines",
        "logo": "https://images.kiwi.com/airlines/64/Y7.png"
      }
    },
    {
      "label": "Binter Canarias",
      "value": {
        "id": "NT",
        "lcc": "1",
        "name": "Binter Canarias",
        "logo": "https://images.kiwi.com/airlines/64/NT.png"
      }
    },
    {
      "label": "Sichuan Airlines",
      "value": {
        "id": "3U",
        "lcc": "0",
        "name": "Sichuan Airlines",
        "logo": "https://images.kiwi.com/airlines/64/3U.png"
      }
    },
    {
      "label": "TUIfly (X3)",
      "value": {
        "id": "11",
        "lcc": "0",
        "name": "TUIfly (X3)",
        "logo": "https://images.kiwi.com/airlines/64/11.png"
      }
    },
    {
      "label": "Anadolujet",
      "value": {
        "id": "00",
        "lcc": "1",
        "name": "Anadolujet",
        "logo": "https://images.kiwi.com/airlines/64/00.png"
      }
    },
    {
      "label": "Regiojet Train",
      "value": {
        "id": "REGIOJETT",
        "lcc": "0",
        "name": "Regiojet Train",
        "logo": "https://images.kiwi.com/airlines/64/REGIOJETT.png"
      }
    },
    {
      "label": "LongJiang Airlines",
      "value": {
        "id": "LT",
        "lcc": "0",
        "name": "LongJiang Airlines",
        "logo": "https://images.kiwi.com/airlines/64/LT.png"
      }
    },
    {
      "label": "Alitalia Express",
      "value": {
        "id": "XM",
        "lcc": "0",
        "name": "Alitalia Express",
        "logo": "https://images.kiwi.com/airlines/64/XM.png"
      }
    },
    {
      "label": "Air Sinai",
      "value": {
        "id": "4D",
        "lcc": "0",
        "name": "Air Sinai",
        "logo": "https://images.kiwi.com/airlines/64/4D.png"
      }
    },
    {
      "label": "Africa West",
      "value": {
        "id": "FK",
        "lcc": "0",
        "name": "Africa West",
        "logo": "https://images.kiwi.com/airlines/64/FK.png"
      }
    },
    {
      "label": "Air Guinee Express",
      "value": {
        "id": "2U",
        "lcc": "0",
        "name": "Air Guinee Express",
        "logo": "https://images.kiwi.com/airlines/64/2U.png"
      }
    },
    {
      "label": "Calafia Airlines",
      "value": {
        "id": "A7",
        "lcc": "0",
        "name": "Calafia Airlines",
        "logo": "https://images.kiwi.com/airlines/64/A7.png"
      }
    },
    {
      "label": "Air Guyane",
      "value": {
        "id": "GG",
        "lcc": "0",
        "name": "Air Guyane",
        "logo": "https://images.kiwi.com/airlines/64/GG.png"
      }
    },
    {
      "label": "AirTran Airways",
      "value": {
        "id": "FL",
        "lcc": "0",
        "name": "AirTran Airways",
        "logo": "https://images.kiwi.com/airlines/64/FL.png"
      }
    },
    {
      "label": "FlyLal",
      "value": {
        "id": "TE",
        "lcc": "0",
        "name": "FlyLal",
        "logo": "https://images.kiwi.com/airlines/64/TE.png"
      }
    },
    {
      "label": "XL Airways France",
      "value": {
        "id": "SE",
        "lcc": "1",
        "name": "XL Airways France",
        "logo": "https://images.kiwi.com/airlines/64/SE.png"
      }
    },
    {
      "label": "Widerøe",
      "value": {
        "id": "WF",
        "lcc": "0",
        "name": "Widerøe",
        "logo": "https://images.kiwi.com/airlines/64/WF.png"
      }
    },
    {
      "label": "Serbian Airlines",
      "value": {
        "id": "S1",
        "lcc": "0",
        "name": "Serbian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/S1.png"
      }
    },
    {
      "label": "LSM International ",
      "value": {
        "id": "II",
        "lcc": "0",
        "name": "LSM International ",
        "logo": "https://images.kiwi.com/airlines/64/II.png"
      }
    },
    {
      "label": "Hankook Airline",
      "value": {
        "id": "HN",
        "lcc": "0",
        "name": "Hankook Airline",
        "logo": "https://images.kiwi.com/airlines/64/HN.png"
      }
    },
    {
      "label": "Nile Air",
      "value": {
        "id": "NP",
        "lcc": "0",
        "name": "Nile Air",
        "logo": "https://images.kiwi.com/airlines/64/NP.png"
      }
    },
    {
      "label": "Maryland Air",
      "value": {
        "id": "M1",
        "lcc": "0",
        "name": "Maryland Air",
        "logo": "https://images.kiwi.com/airlines/64/M1.png"
      }
    },
    {
      "label": "MHS Aviation GmbH",
      "value": {
        "id": "M2",
        "lcc": "0",
        "name": "MHS Aviation GmbH",
        "logo": "https://images.kiwi.com/airlines/64/M2.png"
      }
    },
    {
      "label": "Irish Citylink",
      "value": {
        "id": "IRCITYLINK",
        "lcc": "None",
        "name": "Irish Citylink",
        "logo": "https://images.kiwi.com/airlines/64/IRCITYLINK.png"
      }
    },
    {
      "label": "Voyage Air",
      "value": {
        "id": "VRG",
        "lcc": "1",
        "name": "Voyage Air",
        "logo": "https://images.kiwi.com/airlines/64/VRG.png"
      }
    },
    {
      "label": "White Airways",
      "value": {
        "id": "WI",
        "lcc": "0",
        "name": "White Airways",
        "logo": "https://images.kiwi.com/airlines/64/WI.png"
      }
    },
    {
      "label": "Air Iceland Connect",
      "value": {
        "id": "NY",
        "lcc": "1",
        "name": "Air Iceland Connect",
        "logo": "https://images.kiwi.com/airlines/64/NY.png"
      }
    },
    {
      "label": "Airlink (SAA)",
      "value": {
        "id": "4Z",
        "lcc": "0",
        "name": "Airlink (SAA)",
        "logo": "https://images.kiwi.com/airlines/64/4Z.png"
      }
    },
    {
      "label": "Red Jet Andes",
      "value": {
        "id": "PT",
        "lcc": "0",
        "name": "Red Jet Andes",
        "logo": "https://images.kiwi.com/airlines/64/PT.png"
      }
    },
    {
      "label": "Eastar Jet",
      "value": {
        "id": "ZE",
        "lcc": "1",
        "name": "Eastar Jet",
        "logo": "https://images.kiwi.com/airlines/64/ZE.png"
      }
    },
    {
      "label": "SilkAir",
      "value": {
        "id": "MI",
        "lcc": "0",
        "name": "SilkAir",
        "logo": "https://images.kiwi.com/airlines/64/MI.png"
      }
    },
    {
      "label": "Qatar Airways",
      "value": {
        "id": "QR",
        "lcc": "0",
        "name": "Qatar Airways",
        "logo": "https://images.kiwi.com/airlines/64/QR.png"
      }
    },
    {
      "label": "First Air",
      "value": {
        "id": "7F",
        "lcc": "0",
        "name": "First Air",
        "logo": "https://images.kiwi.com/airlines/64/7F.png"
      }
    },
    {
      "label": "Niki",
      "value": {
        "id": "HG",
        "lcc": "0",
        "name": "Niki",
        "logo": "https://images.kiwi.com/airlines/64/HG.png"
      }
    },
    {
      "label": "Royal Jordanian",
      "value": {
        "id": "RJ",
        "lcc": "0",
        "name": "Royal Jordanian",
        "logo": "https://images.kiwi.com/airlines/64/RJ.png"
      }
    },
    {
      "label": "VIM Airlines",
      "value": {
        "id": "NN",
        "lcc": "1",
        "name": "VIM Airlines",
        "logo": "https://images.kiwi.com/airlines/64/NN.png"
      }
    },
    {
      "label": "SunExpress",
      "value": {
        "id": "XQ",
        "lcc": "1",
        "name": "SunExpress",
        "logo": "https://images.kiwi.com/airlines/64/XQ.png"
      }
    },
    {
      "label": "Air Moldova",
      "value": {
        "id": "9U",
        "lcc": "0",
        "name": "Air Moldova",
        "logo": "https://images.kiwi.com/airlines/64/9U.png"
      }
    },
    {
      "label": "Canary Fly",
      "value": {
        "id": "PM",
        "lcc": "0",
        "name": "Canary Fly",
        "logo": "https://images.kiwi.com/airlines/64/PM.png"
      }
    },
    {
      "label": "Jetstar Japan",
      "value": {
        "id": "GK",
        "lcc": "0",
        "name": "Jetstar Japan",
        "logo": "https://images.kiwi.com/airlines/64/GK.png"
      }
    },
    {
      "label": "Nam Air",
      "value": {
        "id": "IN",
        "lcc": "0",
        "name": "Nam Air",
        "logo": "https://images.kiwi.com/airlines/64/IN.png"
      }
    },
    {
      "label": "SkyWest",
      "value": {
        "id": "OO",
        "lcc": "0",
        "name": "SkyWest",
        "logo": "https://images.kiwi.com/airlines/64/OO.png"
      }
    },
    {
      "label": "AirAsia Japan",
      "value": {
        "id": "DJ",
        "lcc": "1",
        "name": "AirAsia Japan",
        "logo": "https://images.kiwi.com/airlines/64/DJ.png"
      }
    },
    {
      "label": "Olympic Air",
      "value": {
        "id": "OA",
        "lcc": "0",
        "name": "Olympic Air",
        "logo": "https://images.kiwi.com/airlines/64/OA.png"
      }
    },
    {
      "label": "Air Manas",
      "value": {
        "id": "ZM",
        "lcc": "0",
        "name": "Air Manas",
        "logo": "https://images.kiwi.com/airlines/64/ZM.png"
      }
    },
    {
      "label": "Hahn Air",
      "value": {
        "id": "H1",
        "lcc": "0",
        "name": "Hahn Air",
        "logo": "https://images.kiwi.com/airlines/64/H1.png"
      }
    },
    {
      "label": "Jet2",
      "value": {
        "id": "LS",
        "lcc": "1",
        "name": "Jet2",
        "logo": "https://images.kiwi.com/airlines/64/LS.png"
      }
    },
    {
      "label": "Air Indus",
      "value": {
        "id": "PP",
        "lcc": "0",
        "name": "Air Indus",
        "logo": "https://images.kiwi.com/airlines/64/PP.png"
      }
    },
    {
      "label": "Izhavia",
      "value": {
        "id": "I8",
        "lcc": "0",
        "name": "Izhavia",
        "logo": "https://images.kiwi.com/airlines/64/I8.png"
      }
    },
    {
      "label": "Cargojet Airways",
      "value": {
        "id": "W8",
        "lcc": "0",
        "name": "Cargojet Airways",
        "logo": "https://images.kiwi.com/airlines/64/W8.png"
      }
    },
    {
      "label": "Titan Airways",
      "value": {
        "id": "ZT",
        "lcc": "0",
        "name": "Titan Airways",
        "logo": "https://images.kiwi.com/airlines/64/ZT.png"
      }
    },
    {
      "label": "Belair",
      "value": {
        "id": "4T",
        "lcc": "0",
        "name": "Belair",
        "logo": "https://images.kiwi.com/airlines/64/4T.png"
      }
    },
    {
      "label": "Bamboo Airways",
      "value": {
        "id": "QH",
        "lcc": "0",
        "name": "Bamboo Airways",
        "logo": "https://images.kiwi.com/airlines/64/QH.png"
      }
    },
    {
      "label": "LEVEL operated by Iberia",
      "value": {
        "id": "I0",
        "lcc": "None",
        "name": "LEVEL operated by Iberia",
        "logo": "https://images.kiwi.com/airlines/64/I0.png"
      }
    },
    {
      "label": "Komiaviatrans",
      "value": {
        "id": "KO",
        "lcc": "0",
        "name": "Komiaviatrans",
        "logo": "https://images.kiwi.com/airlines/64/KO.png"
      }
    },
    {
      "label": "LEOEXPRESS Train",
      "value": {
        "id": "LEOEXT",
        "lcc": "None",
        "name": "LEOEXPRESS Train",
        "logo": "https://images.kiwi.com/airlines/64/LEOEXT.png"
      }
    },
    {
      "label": "Air Saint Pierre",
      "value": {
        "id": "PJ",
        "lcc": "0",
        "name": "Air Saint Pierre",
        "logo": "https://images.kiwi.com/airlines/64/PJ.png"
      }
    },
    {
      "label": "Star Peru",
      "value": {
        "id": "2I",
        "lcc": "1",
        "name": "Star Peru",
        "logo": "https://images.kiwi.com/airlines/64/2I.png"
      }
    },
    {
      "label": "Qazaq Air",
      "value": {
        "id": "IQ",
        "lcc": "0",
        "name": "Qazaq Air",
        "logo": "https://images.kiwi.com/airlines/64/IQ.png"
      }
    },
    {
      "label": "Med-View Airline",
      "value": {
        "id": "VL",
        "lcc": "0",
        "name": "Med-View Airline",
        "logo": "https://images.kiwi.com/airlines/64/VL.png"
      }
    },
    {
      "label": "LEOEXPRESS Bus",
      "value": {
        "id": "LEOEXB",
        "lcc": "None",
        "name": "LEOEXPRESS Bus",
        "logo": "https://images.kiwi.com/airlines/64/LEOEXB.png"
      }
    },
    {
      "label": "British Mediterranean Airways",
      "value": {
        "id": "KJ",
        "lcc": "0",
        "name": "British Mediterranean Airways",
        "logo": "https://images.kiwi.com/airlines/64/KJ.png"
      }
    },
    {
      "label": "Horizon Airlines",
      "value": {
        "id": "BN",
        "lcc": "0",
        "name": "Horizon Airlines",
        "logo": "https://images.kiwi.com/airlines/64/BN.png"
      }
    },
    {
      "label": "Luxair",
      "value": {
        "id": "LG",
        "lcc": "0",
        "name": "Luxair",
        "logo": "https://images.kiwi.com/airlines/64/LG.png"
      }
    },
    {
      "label": "Orenburzhye Airline",
      "value": {
        "id": "O7",
        "lcc": "0",
        "name": "Orenburzhye Airline",
        "logo": "https://images.kiwi.com/airlines/64/O7.png"
      }
    },
    {
      "label": "TAMPA",
      "value": {
        "id": "QT",
        "lcc": "0",
        "name": "TAMPA",
        "logo": "https://images.kiwi.com/airlines/64/QT.png"
      }
    },
    {
      "label": "Nordic Regional Airlines",
      "value": {
        "id": "N7",
        "lcc": "0",
        "name": "Nordic Regional Airlines",
        "logo": "https://images.kiwi.com/airlines/64/N7.png"
      }
    },
    {
      "label": "Fiumicino express",
      "value": {
        "id": "FIUMICINO",
        "lcc": "None",
        "name": "Fiumicino express",
        "logo": "https://images.kiwi.com/airlines/64/FIUMICINO.png"
      }
    },
    {
      "label": "Jubba Airways",
      "value": {
        "id": "3J",
        "lcc": "0",
        "name": "Jubba Airways",
        "logo": "https://images.kiwi.com/airlines/64/3J.png"
      }
    },
    {
      "label": "Marino Bus",
      "value": {
        "id": "MARINO",
        "lcc": "0",
        "name": "Marino Bus",
        "logo": "https://images.kiwi.com/airlines/64/MARINO.png"
      }
    },
    {
      "label": "Aviabus",
      "value": {
        "id": "U1",
        "lcc": "0",
        "name": "Aviabus",
        "logo": "https://images.kiwi.com/airlines/64/U1.png"
      }
    },
    {
      "label": "Copenhagen Express",
      "value": {
        "id": "0X",
        "lcc": "0",
        "name": "Copenhagen Express",
        "logo": "https://images.kiwi.com/airlines/64/0X.png"
      }
    },
    {
      "label": "Air Choice One",
      "value": {
        "id": "3E",
        "lcc": "0",
        "name": "Air Choice One",
        "logo": "https://images.kiwi.com/airlines/64/3E.png"
      }
    },
    {
      "label": "Air Mauritius",
      "value": {
        "id": "MK",
        "lcc": "0",
        "name": "Air Mauritius",
        "logo": "https://images.kiwi.com/airlines/64/MK.png"
      }
    },
    {
      "label": "AlbaStar",
      "value": {
        "id": "AP",
        "lcc": "0",
        "name": "AlbaStar",
        "logo": "https://images.kiwi.com/airlines/64/AP.png"
      }
    },
    {
      "label": "Terravision",
      "value": {
        "id": "TERAVSN",
        "lcc": "None",
        "name": "Terravision",
        "logo": "https://images.kiwi.com/airlines/64/TERAVSN.png"
      }
    },
    {
      "label": "China Eastern Airlines",
      "value": {
        "id": "MU",
        "lcc": "0",
        "name": "China Eastern Airlines",
        "logo": "https://images.kiwi.com/airlines/64/MU.png"
      }
    },
    {
      "label": "Ekspres transfer",
      "value": {
        "id": "EXPRTRANS",
        "lcc": "None",
        "name": "Ekspres transfer",
        "logo": "https://images.kiwi.com/airlines/64/EXPRTRANS.png"
      }
    },
    {
      "label": "Sylt Air",
      "value": {
        "id": "7E",
        "lcc": "0",
        "name": "Sylt Air",
        "logo": "https://images.kiwi.com/airlines/64/7E.png"
      }
    },
    {
      "label": "Alitalia Cityliner",
      "value": {
        "id": "CT",
        "lcc": "0",
        "name": "Alitalia Cityliner",
        "logo": "https://images.kiwi.com/airlines/64/CT.png"
      }
    },
    {
      "label": "Overland Airways",
      "value": {
        "id": "OF",
        "lcc": "0",
        "name": "Overland Airways",
        "logo": "https://images.kiwi.com/airlines/64/OF.png"
      }
    },
    {
      "label": "Red Wings",
      "value": {
        "id": "WZ",
        "lcc": "1",
        "name": "Red Wings",
        "logo": "https://images.kiwi.com/airlines/64/WZ.png"
      }
    },
    {
      "label": "Leeward Islands Air Transport",
      "value": {
        "id": "LI",
        "lcc": "0",
        "name": "Leeward Islands Air Transport",
        "logo": "https://images.kiwi.com/airlines/64/LI.png"
      }
    },
    {
      "label": "Shenzhen Airlines",
      "value": {
        "id": "ZH",
        "lcc": "0",
        "name": "Shenzhen Airlines",
        "logo": "https://images.kiwi.com/airlines/64/ZH.png"
      }
    },
    {
      "label": "Onur Air",
      "value": {
        "id": "8Q",
        "lcc": "1",
        "name": "Onur Air",
        "logo": "https://images.kiwi.com/airlines/64/8Q.png"
      }
    },
    {
      "label": "Nesma Air",
      "value": {
        "id": "NE",
        "lcc": "0",
        "name": "Nesma Air",
        "logo": "https://images.kiwi.com/airlines/64/NE.png"
      }
    },
    {
      "label": "Auric Air",
      "value": {
        "id": "UI",
        "lcc": "0",
        "name": "Auric Air",
        "logo": "https://images.kiwi.com/airlines/64/UI.png"
      }
    },
    {
      "label": "Pakistan International Airlines",
      "value": {
        "id": "PK",
        "lcc": "0",
        "name": "Pakistan International Airlines",
        "logo": "https://images.kiwi.com/airlines/64/PK.png"
      }
    },
    {
      "label": "Aer Lingus",
      "value": {
        "id": "EI",
        "lcc": "1",
        "name": "Aer Lingus",
        "logo": "https://images.kiwi.com/airlines/64/EI.png"
      }
    },
    {
      "label": "LATAM Peru",
      "value": {
        "id": "LP",
        "lcc": "0",
        "name": "LATAM Peru",
        "logo": "https://images.kiwi.com/airlines/64/LP.png"
      }
    },
    {
      "label": "TUS Airways",
      "value": {
        "id": "U8",
        "lcc": "0",
        "name": "TUS Airways",
        "logo": "https://images.kiwi.com/airlines/64/U8.png"
      }
    },
    {
      "label": "Air Tanzania",
      "value": {
        "id": "TC",
        "lcc": "0",
        "name": "Air Tanzania",
        "logo": "https://images.kiwi.com/airlines/64/TC.png"
      }
    },
    {
      "label": "Air Arabia Egypt",
      "value": {
        "id": "E5",
        "lcc": "0",
        "name": "Air Arabia Egypt",
        "logo": "https://images.kiwi.com/airlines/64/E5.png"
      }
    },
    {
      "label": "Swoop",
      "value": {
        "id": "WO",
        "lcc": "0",
        "name": "Swoop",
        "logo": "https://images.kiwi.com/airlines/64/WO.png"
      }
    },
    {
      "label": "Atlantic Airways",
      "value": {
        "id": "RC",
        "lcc": "0",
        "name": "Atlantic Airways",
        "logo": "https://images.kiwi.com/airlines/64/RC.png"
      }
    },
    {
      "label": "HOP!",
      "value": {
        "id": "A5",
        "lcc": "1",
        "name": "HOP!",
        "logo": "https://images.kiwi.com/airlines/64/A5.png"
      }
    },
    {
      "label": "Air Bangladesh",
      "value": {
        "id": "B9",
        "lcc": "0",
        "name": "Air Bangladesh",
        "logo": "https://images.kiwi.com/airlines/64/B9.png"
      }
    },
    {
      "label": "Atlas Air",
      "value": {
        "id": "5Y",
        "lcc": "0",
        "name": "Atlas Air",
        "logo": "https://images.kiwi.com/airlines/64/5Y.png"
      }
    },
    {
      "label": "Air Wales",
      "value": {
        "id": "6G",
        "lcc": "0",
        "name": "Air Wales",
        "logo": "https://images.kiwi.com/airlines/64/6G.png"
      }
    },
    {
      "label": "Asian Spirit",
      "value": {
        "id": "6K",
        "lcc": "0",
        "name": "Asian Spirit",
        "logo": "https://images.kiwi.com/airlines/64/6K.png"
      }
    },
    {
      "label": "Aserca Airlines",
      "value": {
        "id": "R7",
        "lcc": "0",
        "name": "Aserca Airlines",
        "logo": "https://images.kiwi.com/airlines/64/R7.png"
      }
    },
    {
      "label": "Air Ivoire",
      "value": {
        "id": "VU",
        "lcc": "0",
        "name": "Air Ivoire",
        "logo": "https://images.kiwi.com/airlines/64/VU.png"
      }
    },
    {
      "label": "Air Zimbabwe",
      "value": {
        "id": "UM",
        "lcc": "0",
        "name": "Air Zimbabwe",
        "logo": "https://images.kiwi.com/airlines/64/UM.png"
      }
    },
    {
      "label": "Air Madrid",
      "value": {
        "id": "NM",
        "lcc": "0",
        "name": "Air Madrid",
        "logo": "https://images.kiwi.com/airlines/64/NM.png"
      }
    },
    {
      "label": "Fiji Airways",
      "value": {
        "id": "FJ",
        "lcc": "0",
        "name": "Fiji Airways",
        "logo": "https://images.kiwi.com/airlines/64/FJ.png"
      }
    },
    {
      "label": "Air Koryo",
      "value": {
        "id": "JS",
        "lcc": "0",
        "name": "Air Koryo",
        "logo": "https://images.kiwi.com/airlines/64/JS.png"
      }
    },
    {
      "label": "Air Madagascar",
      "value": {
        "id": "MD",
        "lcc": "0",
        "name": "Air Madagascar",
        "logo": "https://images.kiwi.com/airlines/64/MD.png"
      }
    },
    {
      "label": "Astair",
      "value": {
        "id": "8D",
        "lcc": "0",
        "name": "Astair",
        "logo": "https://images.kiwi.com/airlines/64/8D.png"
      }
    },
    {
      "label": "Aeropelican Air Services",
      "value": {
        "id": "OT",
        "lcc": "0",
        "name": "Aeropelican Air Services",
        "logo": "https://images.kiwi.com/airlines/64/OT.png"
      }
    },
    {
      "label": "Stobart Air",
      "value": {
        "id": "RE",
        "lcc": "0",
        "name": "Stobart Air",
        "logo": "https://images.kiwi.com/airlines/64/RE.png"
      }
    },
    {
      "label": "Aerosur",
      "value": {
        "id": "5L",
        "lcc": "0",
        "name": "Aerosur",
        "logo": "https://images.kiwi.com/airlines/64/5L.png"
      }
    },
    {
      "label": "Avient Aviation",
      "value": {
        "id": "Z3",
        "lcc": "0",
        "name": "Avient Aviation",
        "logo": "https://images.kiwi.com/airlines/64/Z3.png"
      }
    },
    {
      "label": "Avialeasing Aviation Company",
      "value": {
        "id": "EC",
        "lcc": "0",
        "name": "Avialeasing Aviation Company",
        "logo": "https://images.kiwi.com/airlines/64/EC.png"
      }
    },
    {
      "label": "Regional Express",
      "value": {
        "id": "ZL",
        "lcc": "1",
        "name": "Regional Express",
        "logo": "https://images.kiwi.com/airlines/64/ZL.png"
      }
    },
    {
      "label": "Berjaya Air",
      "value": {
        "id": "J8",
        "lcc": "0",
        "name": "Berjaya Air",
        "logo": "https://images.kiwi.com/airlines/64/J8.png"
      }
    },
    {
      "label": "IrAero",
      "value": {
        "id": "IO",
        "lcc": "1",
        "name": "IrAero",
        "logo": "https://images.kiwi.com/airlines/64/IO.png"
      }
    },
    {
      "label": "Alaska Airlines",
      "value": {
        "id": "AS",
        "lcc": "0",
        "name": "Alaska Airlines",
        "logo": "https://images.kiwi.com/airlines/64/AS.png"
      }
    },
    {
      "label": "Star Flyer",
      "value": {
        "id": "7G",
        "lcc": "1",
        "name": "Star Flyer",
        "logo": "https://images.kiwi.com/airlines/64/7G.png"
      }
    },
    {
      "label": "Helvetic Airways",
      "value": {
        "id": "2L",
        "lcc": "0",
        "name": "Helvetic Airways",
        "logo": "https://images.kiwi.com/airlines/64/2L.png"
      }
    },
    {
      "label": "Citylink",
      "value": {
        "id": "CITYLINK",
        "lcc": "None",
        "name": "Citylink",
        "logo": "https://images.kiwi.com/airlines/64/CITYLINK.png"
      }
    },
    {
      "label": "Bulgaria Air",
      "value": {
        "id": "FB",
        "lcc": "0",
        "name": "Bulgaria Air",
        "logo": "https://images.kiwi.com/airlines/64/FB.png"
      }
    },
    {
      "label": "Orchid Airlines",
      "value": {
        "id": "OI",
        "lcc": "0",
        "name": "Orchid Airlines",
        "logo": "https://images.kiwi.com/airlines/64/OI.png"
      }
    },
    {
      "label": "Appenino shuttle ",
      "value": {
        "id": "APPENINO",
        "lcc": "None",
        "name": "Appenino shuttle ",
        "logo": "https://images.kiwi.com/airlines/64/APPENINO.png"
      }
    },
    {
      "label": "Pegas Fly",
      "value": {
        "id": "EO",
        "lcc": "0",
        "name": "Pegas Fly",
        "logo": "https://images.kiwi.com/airlines/64/EO.png"
      }
    },
    {
      "label": "Level",
      "value": {
        "id": "LV",
        "lcc": "0",
        "name": "Level",
        "logo": "https://images.kiwi.com/airlines/64/LV.png"
      }
    },
    {
      "label": "Cimber Air",
      "value": {
        "id": "QI",
        "lcc": "0",
        "name": "Cimber Air",
        "logo": "https://images.kiwi.com/airlines/64/QI.png"
      }
    },
    {
      "label": "Air Canada",
      "value": {
        "id": "AC",
        "lcc": "0",
        "name": "Air Canada",
        "logo": "https://images.kiwi.com/airlines/64/AC.png"
      }
    },
    {
      "label": "LAN Express",
      "value": {
        "id": "LU",
        "lcc": "0",
        "name": "LAN Express",
        "logo": "https://images.kiwi.com/airlines/64/LU.png"
      }
    },
    {
      "label": "Sun Country Airlines",
      "value": {
        "id": "SY",
        "lcc": "1",
        "name": "Sun Country Airlines",
        "logo": "https://images.kiwi.com/airlines/64/SY.png"
      }
    },
    {
      "label": "CityJet",
      "value": {
        "id": "WX",
        "lcc": "0",
        "name": "CityJet",
        "logo": "https://images.kiwi.com/airlines/64/WX.png"
      }
    },
    {
      "label": "Cambodia Bayon Airlines",
      "value": {
        "id": "BD",
        "lcc": "0",
        "name": "Cambodia Bayon Airlines",
        "logo": "https://images.kiwi.com/airlines/64/BD.png"
      }
    },
    {
      "label": "Egyptair",
      "value": {
        "id": "MS",
        "lcc": "0",
        "name": "Egyptair",
        "logo": "https://images.kiwi.com/airlines/64/MS.png"
      }
    },
    {
      "label": "AirAsia India",
      "value": {
        "id": "I5",
        "lcc": "0",
        "name": "AirAsia India",
        "logo": "https://images.kiwi.com/airlines/64/I5.png"
      }
    },
    {
      "label": "Porter Airlines",
      "value": {
        "id": "PD",
        "lcc": "1",
        "name": "Porter Airlines",
        "logo": "https://images.kiwi.com/airlines/64/PD.png"
      }
    },
    {
      "label": "Regional Air Services",
      "value": {
        "id": "8N",
        "lcc": "0",
        "name": "Regional Air Services",
        "logo": "https://images.kiwi.com/airlines/64/8N.png"
      }
    },
    {
      "label": "Ukraine International Airlines",
      "value": {
        "id": "PS",
        "lcc": "0",
        "name": "Ukraine International Airlines",
        "logo": "https://images.kiwi.com/airlines/64/PS.png"
      }
    },
    {
      "label": "Rossiya-Russian Airlines",
      "value": {
        "id": "FV",
        "lcc": "0",
        "name": "Rossiya-Russian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/FV.png"
      }
    },
    {
      "label": "Cambodia Airways",
      "value": {
        "id": "KR",
        "lcc": "0",
        "name": "Cambodia Airways",
        "logo": "https://images.kiwi.com/airlines/64/KR.png"
      }
    },
    {
      "label": "Wizz Air UK",
      "value": {
        "id": "W9",
        "lcc": "0",
        "name": "Wizz Air UK",
        "logo": "https://images.kiwi.com/airlines/64/W9.png"
      }
    },
    {
      "label": "Avianca Ecuador",
      "value": {
        "id": "2K",
        "lcc": "0",
        "name": "Avianca Ecuador",
        "logo": "https://images.kiwi.com/airlines/64/2K.png"
      }
    },
    {
      "label": "Air Arabia Maroc",
      "value": {
        "id": "3O",
        "lcc": "0",
        "name": "Air Arabia Maroc",
        "logo": "https://images.kiwi.com/airlines/64/3O.png"
      }
    },
    {
      "label": "Avianca Costa Rica",
      "value": {
        "id": "LR",
        "lcc": "0",
        "name": "Avianca Costa Rica",
        "logo": "https://images.kiwi.com/airlines/64/LR.png"
      }
    },
    {
      "label": "LATAM Brasil",
      "value": {
        "id": "JJ",
        "lcc": "0",
        "name": "LATAM Brasil",
        "logo": "https://images.kiwi.com/airlines/64/JJ.png"
      }
    },
    {
      "label": "Openskies",
      "value": {
        "id": "OPENSKIES",
        "lcc": "1",
        "name": "Openskies",
        "logo": "https://images.kiwi.com/airlines/64/OPENSKIES.png"
      }
    },
    {
      "label": "Air Vanuatu",
      "value": {
        "id": "NF",
        "lcc": "0",
        "name": "Air Vanuatu",
        "logo": "https://images.kiwi.com/airlines/64/NF.png"
      }
    },
    {
      "label": "Tianjin Airlines",
      "value": {
        "id": "GS",
        "lcc": "0",
        "name": "Tianjin Airlines",
        "logo": "https://images.kiwi.com/airlines/64/GS.png"
      }
    },
    {
      "label": "Air Dolomiti",
      "value": {
        "id": "EN",
        "lcc": "0",
        "name": "Air Dolomiti",
        "logo": "https://images.kiwi.com/airlines/64/EN.png"
      }
    },
    {
      "label": "Aero Contractors",
      "value": {
        "id": "AJ",
        "lcc": "0",
        "name": "Aero Contractors",
        "logo": "https://images.kiwi.com/airlines/64/AJ.png"
      }
    },
    {
      "label": "British International Helicopters",
      "value": {
        "id": "BS",
        "lcc": "0",
        "name": "British International Helicopters",
        "logo": "https://images.kiwi.com/airlines/64/BS.png"
      }
    },
    {
      "label": "Bemidji Airlines",
      "value": {
        "id": "CH",
        "lcc": "0",
        "name": "Bemidji Airlines",
        "logo": "https://images.kiwi.com/airlines/64/CH.png"
      }
    },
    {
      "label": "Bering Air",
      "value": {
        "id": "8E",
        "lcc": "0",
        "name": "Bering Air",
        "logo": "https://images.kiwi.com/airlines/64/8E.png"
      }
    },
    {
      "label": "Renfe",
      "value": {
        "id": "4R",
        "lcc": "0",
        "name": "Renfe",
        "logo": "https://images.kiwi.com/airlines/64/4R.png"
      }
    },
    {
      "label": "Far Eastern Air Transport",
      "value": {
        "id": "FE",
        "lcc": "None",
        "name": "Far Eastern Air Transport",
        "logo": "https://images.kiwi.com/airlines/64/FE.png"
      }
    },
    {
      "label": "Coastal Air",
      "value": {
        "id": "DQ",
        "lcc": "0",
        "name": "Coastal Air",
        "logo": "https://images.kiwi.com/airlines/64/DQ.png"
      }
    },
    {
      "label": "Consorcio Aviaxsa",
      "value": {
        "id": "6A",
        "lcc": "0",
        "name": "Consorcio Aviaxsa",
        "logo": "https://images.kiwi.com/airlines/64/6A.png"
      }
    },
    {
      "label": "Corsair International",
      "value": {
        "id": "SS",
        "lcc": "0",
        "name": "Corsair International",
        "logo": "https://images.kiwi.com/airlines/64/SS.png"
      }
    },
    {
      "label": "Avia Traffic Airline",
      "value": {
        "id": "YK",
        "lcc": "0",
        "name": "Avia Traffic Airline",
        "logo": "https://images.kiwi.com/airlines/64/YK.png"
      }
    },
    {
      "label": "Dominicana de Aviaci",
      "value": {
        "id": "DO",
        "lcc": "0",
        "name": "Dominicana de Aviaci",
        "logo": "https://images.kiwi.com/airlines/64/DO.png"
      }
    },
    {
      "label": "Domodedovo Airlines",
      "value": {
        "id": "E3",
        "lcc": "0",
        "name": "Domodedovo Airlines",
        "logo": "https://images.kiwi.com/airlines/64/E3.png"
      }
    },
    {
      "label": "Eagle Air",
      "value": {
        "id": "H7",
        "lcc": "0",
        "name": "Eagle Air",
        "logo": "https://images.kiwi.com/airlines/64/H7.png"
      }
    },
    {
      "label": "Eastern Airways",
      "value": {
        "id": "T3",
        "lcc": "0",
        "name": "Eastern Airways",
        "logo": "https://images.kiwi.com/airlines/64/T3.png"
      }
    },
    {
      "label": "El-Buraq Air Transport",
      "value": {
        "id": "UZ",
        "lcc": "0",
        "name": "El-Buraq Air Transport",
        "logo": "https://images.kiwi.com/airlines/64/UZ.png"
      }
    },
    {
      "label": "Eritrean Airlines",
      "value": {
        "id": "B8",
        "lcc": "0",
        "name": "Eritrean Airlines",
        "logo": "https://images.kiwi.com/airlines/64/B8.png"
      }
    },
    {
      "label": "European Air Express",
      "value": {
        "id": "EA",
        "lcc": "0",
        "name": "European Air Express",
        "logo": "https://images.kiwi.com/airlines/64/EA.png"
      }
    },
    {
      "label": "Gulf Air Bahrain",
      "value": {
        "id": "GF",
        "lcc": "0",
        "name": "Gulf Air Bahrain",
        "logo": "https://images.kiwi.com/airlines/64/GF.png"
      }
    },
    {
      "label": "Air Caledonie",
      "value": {
        "id": "TY",
        "lcc": "0",
        "name": "Air Caledonie",
        "logo": "https://images.kiwi.com/airlines/64/TY.png"
      }
    },
    {
      "label": "Line Blue",
      "value": {
        "id": "L8",
        "lcc": "0",
        "name": "Line Blue",
        "logo": "https://images.kiwi.com/airlines/64/L8.png"
      }
    },
    {
      "label": "LEOEXPRESS Minibus",
      "value": {
        "id": "LEOEXM",
        "lcc": "0",
        "name": "LEOEXPRESS Minibus",
        "logo": "https://images.kiwi.com/airlines/64/LEOEXM.png"
      }
    },
    {
      "label": "Transportes Aéreos Guatemaltecos",
      "value": {
        "id": "5U",
        "lcc": "0",
        "name": "Transportes Aéreos Guatemaltecos",
        "logo": "https://images.kiwi.com/airlines/64/5U.png"
      }
    },
    {
      "label": "Small Planet Airline",
      "value": {
        "id": "P7",
        "lcc": "0",
        "name": "Small Planet Airline",
        "logo": "https://images.kiwi.com/airlines/64/P7.png"
      }
    },
    {
      "label": "MyAir",
      "value": {
        "id": "8I",
        "lcc": "0",
        "name": "MyAir",
        "logo": "https://images.kiwi.com/airlines/64/8I.png"
      }
    },
    {
      "label": "Air Corsica",
      "value": {
        "id": "XK",
        "lcc": "0",
        "name": "Air Corsica",
        "logo": "https://images.kiwi.com/airlines/64/XK.png"
      }
    },
    {
      "label": "Ibex Airlines",
      "value": {
        "id": "FW",
        "lcc": "0",
        "name": "Ibex Airlines",
        "logo": "https://images.kiwi.com/airlines/64/FW.png"
      }
    },
    {
      "label": "Int'Air Iles",
      "value": {
        "id": "I7",
        "lcc": "0",
        "name": "Int'Air Iles",
        "logo": "https://images.kiwi.com/airlines/64/I7.png"
      }
    },
    {
      "label": "LOT Polish Airlines",
      "value": {
        "id": "LO",
        "lcc": "0",
        "name": "LOT Polish Airlines",
        "logo": "https://images.kiwi.com/airlines/64/LO.png"
      }
    },
    {
      "label": "Belavia Belarusian Airlines",
      "value": {
        "id": "B2",
        "lcc": "0",
        "name": "Belavia Belarusian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/B2.png"
      }
    },
    {
      "label": "TruJet",
      "value": {
        "id": "2T",
        "lcc": "0",
        "name": "TruJet",
        "logo": "https://images.kiwi.com/airlines/64/2T.png"
      }
    },
    {
      "label": "Air Transat",
      "value": {
        "id": "TS",
        "lcc": "0",
        "name": "Air Transat",
        "logo": "https://images.kiwi.com/airlines/64/TS.png"
      }
    },
    {
      "label": "Croatia Airlines",
      "value": {
        "id": "OU",
        "lcc": "0",
        "name": "Croatia Airlines",
        "logo": "https://images.kiwi.com/airlines/64/OU.png"
      }
    },
    {
      "label": "Virgin America",
      "value": {
        "id": "VX",
        "lcc": "1",
        "name": "Virgin America",
        "logo": "https://images.kiwi.com/airlines/64/VX.png"
      }
    },
    {
      "label": "Severstal Air Company",
      "value": {
        "id": "D2",
        "lcc": "1",
        "name": "Severstal Air Company",
        "logo": "https://images.kiwi.com/airlines/64/D2.png"
      }
    },
    {
      "label": "Air Belgium",
      "value": {
        "id": "KF",
        "lcc": "0",
        "name": "Air Belgium",
        "logo": "https://images.kiwi.com/airlines/64/KF.png"
      }
    },
    {
      "label": "JOON",
      "value": {
        "id": "JN",
        "lcc": "0",
        "name": "JOON",
        "logo": "https://images.kiwi.com/airlines/64/JN.png"
      }
    },
    {
      "label": "ANA Wings",
      "value": {
        "id": "EH",
        "lcc": "0",
        "name": "ANA Wings",
        "logo": "https://images.kiwi.com/airlines/64/EH.png"
      }
    },
    {
      "label": "Indonesia AirAsia X",
      "value": {
        "id": "XT",
        "lcc": "0",
        "name": "Indonesia AirAsia X",
        "logo": "https://images.kiwi.com/airlines/64/XT.png"
      }
    },
    {
      "label": "Avianca El Salvador",
      "value": {
        "id": "TA",
        "lcc": "0",
        "name": "Avianca El Salvador",
        "logo": "https://images.kiwi.com/airlines/64/TA.png"
      }
    },
    {
      "label": "Hex'Air",
      "value": {
        "id": "UD",
        "lcc": "0",
        "name": "Hex'Air",
        "logo": "https://images.kiwi.com/airlines/64/UD.png"
      }
    },
    {
      "label": "Sansa Air",
      "value": {
        "id": "RZ",
        "lcc": "0",
        "name": "Sansa Air",
        "logo": "https://images.kiwi.com/airlines/64/RZ.png"
      }
    },
    {
      "label": "Gazpromavia",
      "value": {
        "id": "4G",
        "lcc": "0",
        "name": "Gazpromavia",
        "logo": "https://images.kiwi.com/airlines/64/4G.png"
      }
    },
    {
      "label": "Ghana International Airlines",
      "value": {
        "id": "G0",
        "lcc": "0",
        "name": "Ghana International Airlines",
        "logo": "https://images.kiwi.com/airlines/64/G0.png"
      }
    },
    {
      "label": "Indian Airlines",
      "value": {
        "id": "IC",
        "lcc": "0",
        "name": "Indian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/IC.png"
      }
    },
    {
      "label": "Interair South Africa",
      "value": {
        "id": "D6",
        "lcc": "0",
        "name": "Interair South Africa",
        "logo": "https://images.kiwi.com/airlines/64/D6.png"
      }
    },
    {
      "label": "Kavminvodyavia",
      "value": {
        "id": "KV",
        "lcc": "0",
        "name": "Kavminvodyavia",
        "logo": "https://images.kiwi.com/airlines/64/KV.png"
      }
    },
    {
      "label": "Kenmore Air",
      "value": {
        "id": "M5",
        "lcc": "0",
        "name": "Kenmore Air",
        "logo": "https://images.kiwi.com/airlines/64/M5.png"
      }
    },
    {
      "label": "Kish Air",
      "value": {
        "id": "Y9",
        "lcc": "0",
        "name": "Kish Air",
        "logo": "https://images.kiwi.com/airlines/64/Y9.png"
      }
    },
    {
      "label": "Kogalymavia Air Company",
      "value": {
        "id": "7K",
        "lcc": "0",
        "name": "Kogalymavia Air Company",
        "logo": "https://images.kiwi.com/airlines/64/7K.png"
      }
    },
    {
      "label": "Kuban Airlines",
      "value": {
        "id": "GW",
        "lcc": "0",
        "name": "Kuban Airlines",
        "logo": "https://images.kiwi.com/airlines/64/GW.png"
      }
    },
    {
      "label": "Lauda Air",
      "value": {
        "id": "NG",
        "lcc": "0",
        "name": "Lauda Air",
        "logo": "https://images.kiwi.com/airlines/64/NG.png"
      }
    },
    {
      "label": "Regional sky",
      "value": {
        "id": "4P",
        "lcc": "0",
        "name": "Regional sky",
        "logo": "https://images.kiwi.com/airlines/64/4P.png"
      }
    },
    {
      "label": "Linear Air",
      "value": {
        "id": "O2",
        "lcc": "0",
        "name": "Linear Air",
        "logo": "https://images.kiwi.com/airlines/64/O2.png"
      }
    },
    {
      "label": "Libyan Arab Airlines",
      "value": {
        "id": "LN",
        "lcc": "0",
        "name": "Libyan Arab Airlines",
        "logo": "https://images.kiwi.com/airlines/64/LN.png"
      }
    },
    {
      "label": "Martinair",
      "value": {
        "id": "MP",
        "lcc": "0",
        "name": "Martinair",
        "logo": "https://images.kiwi.com/airlines/64/MP.png"
      }
    },
    {
      "label": "Merpati Nusantara Airlines",
      "value": {
        "id": "MZ",
        "lcc": "0",
        "name": "Merpati Nusantara Airlines",
        "logo": "https://images.kiwi.com/airlines/64/MZ.png"
      }
    },
    {
      "label": "Mesa Airlines",
      "value": {
        "id": "YV",
        "lcc": "0",
        "name": "Mesa Airlines",
        "logo": "https://images.kiwi.com/airlines/64/YV.png"
      }
    },
    {
      "label": "Mexicana de Aviaci",
      "value": {
        "id": "MX",
        "lcc": "0",
        "name": "Mexicana de Aviaci",
        "logo": "https://images.kiwi.com/airlines/64/MX.png"
      }
    },
    {
      "label": "Midwest Airlines (Egypt)",
      "value": {
        "id": "MY",
        "lcc": "0",
        "name": "Midwest Airlines (Egypt)",
        "logo": "https://images.kiwi.com/airlines/64/MY.png"
      }
    },
    {
      "label": "Maya Island Air",
      "value": {
        "id": "2M",
        "lcc": "0",
        "name": "Maya Island Air",
        "logo": "https://images.kiwi.com/airlines/64/2M.png"
      }
    },
    {
      "label": "Moskovia Airlines",
      "value": {
        "id": "3R",
        "lcc": "0",
        "name": "Moskovia Airlines",
        "logo": "https://images.kiwi.com/airlines/64/3R.png"
      }
    },
    {
      "label": "Motor Sich",
      "value": {
        "id": "M9",
        "lcc": "0",
        "name": "Motor Sich",
        "logo": "https://images.kiwi.com/airlines/64/M9.png"
      }
    },
    {
      "label": "National Jet Systems",
      "value": {
        "id": "NC",
        "lcc": "0",
        "name": "National Jet Systems",
        "logo": "https://images.kiwi.com/airlines/64/NC.png"
      }
    },
    {
      "label": "Royal Falcon",
      "value": {
        "id": "RL",
        "lcc": "0",
        "name": "Royal Falcon",
        "logo": "https://images.kiwi.com/airlines/64/RL.png"
      }
    },
    {
      "label": "Baikotovitchestrian Airlines ",
      "value": {
        "id": "BU",
        "lcc": "0",
        "name": "Baikotovitchestrian Airlines ",
        "logo": "https://images.kiwi.com/airlines/64/BU.png"
      }
    },
    {
      "label": "Eco Jet",
      "value": {
        "id": "8J",
        "lcc": "0",
        "name": "Eco Jet",
        "logo": "https://images.kiwi.com/airlines/64/8J.png"
      }
    },
    {
      "label": "Lufthansa express bus",
      "value": {
        "id": "LUFTHBUS",
        "lcc": "None",
        "name": "Lufthansa express bus",
        "logo": "https://images.kiwi.com/airlines/64/LUFTHBUS.png"
      }
    },
    {
      "label": "Polar Airlines",
      "value": {
        "id": "PI",
        "lcc": "0",
        "name": "Polar Airlines",
        "logo": "https://images.kiwi.com/airlines/64/PI.png"
      }
    },
    {
      "label": "AccesRail",
      "value": {
        "id": "9B",
        "lcc": "0",
        "name": "AccesRail",
        "logo": "https://images.kiwi.com/airlines/64/9B.png"
      }
    },
    {
      "label": "Air Italy",
      "value": {
        "id": "I9",
        "lcc": "0",
        "name": "Air Italy",
        "logo": "https://images.kiwi.com/airlines/64/I9.png"
      }
    },
    {
      "label": "EST Lorek",
      "value": {
        "id": "ESTLOREK",
        "lcc": "None",
        "name": "EST Lorek",
        "logo": "https://images.kiwi.com/airlines/64/ESTLOREK.png"
      }
    },
    {
      "label": "Envoy Air as American Eagle",
      "value": {
        "id": "MQ",
        "lcc": "0",
        "name": "Envoy Air as American Eagle",
        "logo": "https://images.kiwi.com/airlines/64/MQ.png"
      }
    },
    {
      "label": "Slovak Lines ",
      "value": {
        "id": "SLOVAKLNS",
        "lcc": "None",
        "name": "Slovak Lines ",
        "logo": "https://images.kiwi.com/airlines/64/SLOVAKLNS.png"
      }
    },
    {
      "label": "Polynesian Airlines",
      "value": {
        "id": "PH",
        "lcc": "0",
        "name": "Polynesian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/PH.png"
      }
    },
    {
      "label": "Etihad Airways",
      "value": {
        "id": "EY",
        "lcc": "0",
        "name": "Etihad Airways",
        "logo": "https://images.kiwi.com/airlines/64/EY.png"
      }
    },
    {
      "label": "Oman Air",
      "value": {
        "id": "WY",
        "lcc": "0",
        "name": "Oman Air",
        "logo": "https://images.kiwi.com/airlines/64/WY.png"
      }
    },
    {
      "label": "Somon Air",
      "value": {
        "id": "SZ",
        "lcc": "0",
        "name": "Somon Air",
        "logo": "https://images.kiwi.com/airlines/64/SZ.png"
      }
    },
    {
      "label": "Czech Airlines",
      "value": {
        "id": "OK",
        "lcc": "0",
        "name": "Czech Airlines",
        "logo": "https://images.kiwi.com/airlines/64/OK.png"
      }
    },
    {
      "label": "Georgian Airways",
      "value": {
        "id": "A9",
        "lcc": "0",
        "name": "Georgian Airways",
        "logo": "https://images.kiwi.com/airlines/64/A9.png"
      }
    },
    {
      "label": "Pacific Coastal Airline",
      "value": {
        "id": "8P",
        "lcc": "0",
        "name": "Pacific Coastal Airline",
        "logo": "https://images.kiwi.com/airlines/64/8P.png"
      }
    },
    {
      "label": "Finnair",
      "value": {
        "id": "AY",
        "lcc": "0",
        "name": "Finnair",
        "logo": "https://images.kiwi.com/airlines/64/AY.png"
      }
    },
    {
      "label": "Buddha Air",
      "value": {
        "id": "U4",
        "lcc": "0",
        "name": "Buddha Air",
        "logo": "https://images.kiwi.com/airlines/64/U4.png"
      }
    },
    {
      "label": "Kam Air",
      "value": {
        "id": "RQ",
        "lcc": "0",
        "name": "Kam Air",
        "logo": "https://images.kiwi.com/airlines/64/RQ.png"
      }
    },
    {
      "label": "Air Rarotonga",
      "value": {
        "id": "GZ",
        "lcc": "0",
        "name": "Air Rarotonga",
        "logo": "https://images.kiwi.com/airlines/64/GZ.png"
      }
    },
    {
      "label": "Republic Airline",
      "value": {
        "id": "YX",
        "lcc": "0",
        "name": "Republic Airline",
        "logo": "https://images.kiwi.com/airlines/64/YX.png"
      }
    },
    {
      "label": "GoJet Airlines",
      "value": {
        "id": "G7",
        "lcc": "0",
        "name": "GoJet Airlines",
        "logo": "https://images.kiwi.com/airlines/64/G7.png"
      }
    },
    {
      "label": "Nauru Air Corporation",
      "value": {
        "id": "ON",
        "lcc": "0",
        "name": "Nauru Air Corporation",
        "logo": "https://images.kiwi.com/airlines/64/ON.png"
      }
    },
    {
      "label": "Qeshm Air",
      "value": {
        "id": "QB",
        "lcc": "0",
        "name": "Qeshm Air",
        "logo": "https://images.kiwi.com/airlines/64/QB.png"
      }
    },
    {
      "label": "Volotea",
      "value": {
        "id": "V7",
        "lcc": "1",
        "name": "Volotea",
        "logo": "https://images.kiwi.com/airlines/64/V7.png"
      }
    },
    {
      "label": "Republic Express Airlines",
      "value": {
        "id": "RH",
        "lcc": "0",
        "name": "Republic Express Airlines",
        "logo": "https://images.kiwi.com/airlines/64/RH.png"
      }
    },
    {
      "label": "Tiara Air",
      "value": {
        "id": "3P",
        "lcc": "0",
        "name": "Tiara Air",
        "logo": "https://images.kiwi.com/airlines/64/3P.png"
      }
    },
    {
      "label": "Nepal Airlines",
      "value": {
        "id": "RA",
        "lcc": "0",
        "name": "Nepal Airlines",
        "logo": "https://images.kiwi.com/airlines/64/RA.png"
      }
    },
    {
      "label": "Korean Air",
      "value": {
        "id": "KE",
        "lcc": "0",
        "name": "Korean Air",
        "logo": "https://images.kiwi.com/airlines/64/KE.png"
      }
    },
    {
      "label": "PNG Air",
      "value": {
        "id": "CG",
        "lcc": "0",
        "name": "PNG Air",
        "logo": "https://images.kiwi.com/airlines/64/CG.png"
      }
    },
    {
      "label": "New England Airlines",
      "value": {
        "id": "EJ",
        "lcc": "0",
        "name": "New England Airlines",
        "logo": "https://images.kiwi.com/airlines/64/EJ.png"
      }
    },
    {
      "label": "Northern Dene Airways",
      "value": {
        "id": "U7",
        "lcc": "0",
        "name": "Northern Dene Airways",
        "logo": "https://images.kiwi.com/airlines/64/U7.png"
      }
    },
    {
      "label": "Northwestern Air",
      "value": {
        "id": "J3",
        "lcc": "0",
        "name": "Northwestern Air",
        "logo": "https://images.kiwi.com/airlines/64/J3.png"
      }
    },
    {
      "label": "Avianca Brazil",
      "value": {
        "id": "O6",
        "lcc": "0",
        "name": "Avianca Brazil",
        "logo": "https://images.kiwi.com/airlines/64/O6.png"
      }
    },
    {
      "label": "PAN Air",
      "value": {
        "id": "PV",
        "lcc": "0",
        "name": "PAN Air",
        "logo": "https://images.kiwi.com/airlines/64/PV.png"
      }
    },
    {
      "label": "Plus Ultra Lineas Aereas",
      "value": {
        "id": "PU",
        "lcc": "0",
        "name": "Plus Ultra Lineas Aereas",
        "logo": "https://images.kiwi.com/airlines/64/PU.png"
      }
    },
    {
      "label": "belleair",
      "value": {
        "id": "LZ",
        "lcc": "0",
        "name": "belleair",
        "logo": "https://images.kiwi.com/airlines/64/LZ.png"
      }
    },
    {
      "label": "Fuji Dream Airlines",
      "value": {
        "id": "JH",
        "lcc": "0",
        "name": "Fuji Dream Airlines",
        "logo": "https://images.kiwi.com/airlines/64/JH.png"
      }
    },
    {
      "label": "STP Airways",
      "value": {
        "id": "8F",
        "lcc": "0",
        "name": "STP Airways",
        "logo": "https://images.kiwi.com/airlines/64/8F.png"
      }
    },
    {
      "label": "Alliance Airlines",
      "value": {
        "id": "QQ",
        "lcc": "0",
        "name": "Alliance Airlines",
        "logo": "https://images.kiwi.com/airlines/64/QQ.png"
      }
    },
    {
      "label": "Kulula",
      "value": {
        "id": "MN",
        "lcc": "1",
        "name": "Kulula",
        "logo": "https://images.kiwi.com/airlines/64/MN.png"
      }
    },
    {
      "label": "Japan Transocean Air",
      "value": {
        "id": "NU",
        "lcc": "0",
        "name": "Japan Transocean Air",
        "logo": "https://images.kiwi.com/airlines/64/NU.png"
      }
    },
    {
      "label": "Emirates",
      "value": {
        "id": "EK",
        "lcc": "0",
        "name": "Emirates",
        "logo": "https://images.kiwi.com/airlines/64/EK.png"
      }
    },
    {
      "label": "Pegas Fly",
      "value": {
        "id": "IK",
        "lcc": "0",
        "name": "Pegas Fly",
        "logo": "https://images.kiwi.com/airlines/64/IK.png"
      }
    },
    {
      "label": "Endeavor Air",
      "value": {
        "id": "9E",
        "lcc": "0",
        "name": "Endeavor Air",
        "logo": "https://images.kiwi.com/airlines/64/9E.png"
      }
    },
    {
      "label": "Andes Líneas Aéreas",
      "value": {
        "id": "OY",
        "lcc": "0",
        "name": "Andes Líneas Aéreas",
        "logo": "https://images.kiwi.com/airlines/64/OY.png"
      }
    },
    {
      "label": "Philippines AirAsia",
      "value": {
        "id": "Z2",
        "lcc": "0",
        "name": "Philippines AirAsia",
        "logo": "https://images.kiwi.com/airlines/64/Z2.png"
      }
    },
    {
      "label": "Seaborne Airlines",
      "value": {
        "id": "BB",
        "lcc": "0",
        "name": "Seaborne Airlines",
        "logo": "https://images.kiwi.com/airlines/64/BB.png"
      }
    },
    {
      "label": "Cathay Pacific",
      "value": {
        "id": "CX",
        "lcc": "0",
        "name": "Cathay Pacific",
        "logo": "https://images.kiwi.com/airlines/64/CX.png"
      }
    },
    {
      "label": "Czech Rail bus",
      "value": {
        "id": "CZECHRAILB",
        "lcc": "None",
        "name": "Czech Rail bus",
        "logo": "https://images.kiwi.com/airlines/64/CZECHRAILB.png"
      }
    },
    {
      "label": "Chair Airlines",
      "value": {
        "id": "GM",
        "lcc": "0",
        "name": "Chair Airlines",
        "logo": "https://images.kiwi.com/airlines/64/GM.png"
      }
    },
    {
      "label": "Badr Airlines",
      "value": {
        "id": "J4",
        "lcc": "0",
        "name": "Badr Airlines",
        "logo": "https://images.kiwi.com/airlines/64/J4.png"
      }
    },
    {
      "label": "LATAM Paraguay",
      "value": {
        "id": "PZ",
        "lcc": "0",
        "name": "LATAM Paraguay",
        "logo": "https://images.kiwi.com/airlines/64/PZ.png"
      }
    },
    {
      "label": "Okay Airways",
      "value": {
        "id": "BK",
        "lcc": "0",
        "name": "Okay Airways",
        "logo": "https://images.kiwi.com/airlines/64/BK.png"
      }
    },
    {
      "label": "Sibaviatrans",
      "value": {
        "id": "5M",
        "lcc": "0",
        "name": "Sibaviatrans",
        "logo": "https://images.kiwi.com/airlines/64/5M.png"
      }
    },
    {
      "label": "Sama Airlines",
      "value": {
        "id": "ZS",
        "lcc": "0",
        "name": "Sama Airlines",
        "logo": "https://images.kiwi.com/airlines/64/ZS.png"
      }
    },
    {
      "label": "FlyEgypt FT",
      "value": {
        "id": "FT",
        "lcc": "0",
        "name": "FlyEgypt FT",
        "logo": "https://images.kiwi.com/airlines/64/FT.png"
      }
    },
    {
      "label": "Servicios de Transportes A",
      "value": {
        "id": "FS",
        "lcc": "0",
        "name": "Servicios de Transportes A",
        "logo": "https://images.kiwi.com/airlines/64/FS.png"
      }
    },
    {
      "label": "Sudan Airways",
      "value": {
        "id": "SD",
        "lcc": "0",
        "name": "Sudan Airways",
        "logo": "https://images.kiwi.com/airlines/64/SD.png"
      }
    },
    {
      "label": "Syrian Arab Airlines",
      "value": {
        "id": "RB",
        "lcc": "0",
        "name": "Syrian Arab Airlines",
        "logo": "https://images.kiwi.com/airlines/64/RB.png"
      }
    },
    {
      "label": "Shuttle America",
      "value": {
        "id": "S5",
        "lcc": "0",
        "name": "Shuttle America",
        "logo": "https://images.kiwi.com/airlines/64/S5.png"
      }
    },
    {
      "label": "Thomas Cook Airlines",
      "value": {
        "id": "FQ",
        "lcc": "0",
        "name": "Thomas Cook Airlines",
        "logo": "https://images.kiwi.com/airlines/64/FQ.png"
      }
    },
    {
      "label": "TransAsia Airways",
      "value": {
        "id": "GE",
        "lcc": "0",
        "name": "TransAsia Airways",
        "logo": "https://images.kiwi.com/airlines/64/GE.png"
      }
    },
    {
      "label": "TACV",
      "value": {
        "id": "VR",
        "lcc": "0",
        "name": "TACV",
        "logo": "https://images.kiwi.com/airlines/64/VR.png"
      }
    },
    {
      "label": "Yangon Airways",
      "value": {
        "id": "HK",
        "lcc": "0",
        "name": "Yangon Airways",
        "logo": "https://images.kiwi.com/airlines/64/HK.png"
      }
    },
    {
      "label": "ExpressBus",
      "value": {
        "id": "EXPRESSBUS",
        "lcc": "None",
        "name": "ExpressBus",
        "logo": "https://images.kiwi.com/airlines/64/EXPRESSBUS.png"
      }
    },
    {
      "label": "Congo Express",
      "value": {
        "id": "XZ",
        "lcc": "0",
        "name": "Congo Express",
        "logo": "https://images.kiwi.com/airlines/64/XZ.png"
      }
    },
    {
      "label": "Allegiant Air",
      "value": {
        "id": "G4",
        "lcc": "1",
        "name": "Allegiant Air",
        "logo": "https://images.kiwi.com/airlines/64/G4.png"
      }
    },
    {
      "label": "TUI Airways",
      "value": {
        "id": "BY",
        "lcc": "1",
        "name": "TUI Airways",
        "logo": "https://images.kiwi.com/airlines/64/BY.png"
      }
    },
    {
      "label": "Yemenia",
      "value": {
        "id": "IY",
        "lcc": "0",
        "name": "Yemenia",
        "logo": "https://images.kiwi.com/airlines/64/IY.png"
      }
    },
    {
      "label": "Air Volga",
      "value": {
        "id": "G6",
        "lcc": "0",
        "name": "Air Volga",
        "logo": "https://images.kiwi.com/airlines/64/G6.png"
      }
    },
    {
      "label": "Maldivian",
      "value": {
        "id": "Q2",
        "lcc": "0",
        "name": "Maldivian",
        "logo": "https://images.kiwi.com/airlines/64/Q2.png"
      }
    },
    {
      "label": "Carnival Air Lines",
      "value": {
        "id": "KW",
        "lcc": "0",
        "name": "Carnival Air Lines",
        "logo": "https://images.kiwi.com/airlines/64/KW.png"
      }
    },
    {
      "label": "Sunrise Airways",
      "value": {
        "id": "S6",
        "lcc": "0",
        "name": "Sunrise Airways",
        "logo": "https://images.kiwi.com/airlines/64/S6.png"
      }
    },
    {
      "label": "Trans States Airlines",
      "value": {
        "id": "AX",
        "lcc": "0",
        "name": "Trans States Airlines",
        "logo": "https://images.kiwi.com/airlines/64/AX.png"
      }
    },
    {
      "label": "Turan Air",
      "value": {
        "id": "3T",
        "lcc": "0",
        "name": "Turan Air",
        "logo": "https://images.kiwi.com/airlines/64/3T.png"
      }
    },
    {
      "label": "USA3000 Airlines",
      "value": {
        "id": "U5",
        "lcc": "0",
        "name": "USA3000 Airlines",
        "logo": "https://images.kiwi.com/airlines/64/U5.png"
      }
    },
    {
      "label": "UM Airlines",
      "value": {
        "id": "UF",
        "lcc": "0",
        "name": "UM Airlines",
        "logo": "https://images.kiwi.com/airlines/64/UF.png"
      }
    },
    {
      "label": "US Airways",
      "value": {
        "id": "US",
        "lcc": "0",
        "name": "US Airways",
        "logo": "https://images.kiwi.com/airlines/64/US.png"
      }
    },
    {
      "label": "Tibet Airlines",
      "value": {
        "id": "TV",
        "lcc": "0",
        "name": "Tibet Airlines",
        "logo": "https://images.kiwi.com/airlines/64/TV.png"
      }
    },
    {
      "label": "Welcome Air",
      "value": {
        "id": "2W",
        "lcc": "0",
        "name": "Welcome Air",
        "logo": "https://images.kiwi.com/airlines/64/2W.png"
      }
    },
    {
      "label": "West Coast Air",
      "value": {
        "id": "8O",
        "lcc": "0",
        "name": "West Coast Air",
        "logo": "https://images.kiwi.com/airlines/64/8O.png"
      }
    },
    {
      "label": "Wind Jet",
      "value": {
        "id": "IV",
        "lcc": "0",
        "name": "Wind Jet",
        "logo": "https://images.kiwi.com/airlines/64/IV.png"
      }
    },
    {
      "label": "Xiamen Airlines",
      "value": {
        "id": "MF",
        "lcc": "0",
        "name": "Xiamen Airlines",
        "logo": "https://images.kiwi.com/airlines/64/MF.png"
      }
    },
    {
      "label": "Air Kazakhstan",
      "value": {
        "id": "9Y",
        "lcc": "0",
        "name": "Air Kazakhstan",
        "logo": "https://images.kiwi.com/airlines/64/9Y.png"
      }
    },
    {
      "label": "Uni Air",
      "value": {
        "id": "B7",
        "lcc": "0",
        "name": "Uni Air",
        "logo": "https://images.kiwi.com/airlines/64/B7.png"
      }
    },
    {
      "label": "Latin American Wings",
      "value": {
        "id": "H8",
        "lcc": "0",
        "name": "Latin American Wings",
        "logo": "https://images.kiwi.com/airlines/64/H8.png"
      }
    },
    {
      "label": "88",
      "value": {
        "id": "47",
        "lcc": "0",
        "name": "88",
        "logo": "https://images.kiwi.com/airlines/64/47.png"
      }
    },
    {
      "label": "Royal European Airlines",
      "value": {
        "id": "69",
        "lcc": "0",
        "name": "Royal European Airlines",
        "logo": "https://images.kiwi.com/airlines/64/69.png"
      }
    },
    {
      "label": "Mann Yadanarpon Airlines",
      "value": {
        "id": "7Y",
        "lcc": "0",
        "name": "Mann Yadanarpon Airlines",
        "logo": "https://images.kiwi.com/airlines/64/7Y.png"
      }
    },
    {
      "label": "Euroline",
      "value": {
        "id": "4L",
        "lcc": "0",
        "name": "Euroline",
        "logo": "https://images.kiwi.com/airlines/64/4L.png"
      }
    },
    {
      "label": "Azur Air",
      "value": {
        "id": "ZF",
        "lcc": "0",
        "name": "Azur Air",
        "logo": "https://images.kiwi.com/airlines/64/ZF.png"
      }
    },
    {
      "label": "Gryphon Airlines",
      "value": {
        "id": "6P",
        "lcc": "0",
        "name": "Gryphon Airlines",
        "logo": "https://images.kiwi.com/airlines/64/6P.png"
      }
    },
    {
      "label": "Joy Air",
      "value": {
        "id": "JR",
        "lcc": "0",
        "name": "Joy Air",
        "logo": "https://images.kiwi.com/airlines/64/JR.png"
      }
    },
    {
      "label": "Azur Air Germany",
      "value": {
        "id": "UR",
        "lcc": "0",
        "name": "Azur Air Germany",
        "logo": "https://images.kiwi.com/airlines/64/UR.png"
      }
    },
    {
      "label": "Tailwind Airlines",
      "value": {
        "id": "TI",
        "lcc": "0",
        "name": "Tailwind Airlines",
        "logo": "https://images.kiwi.com/airlines/64/TI.png"
      }
    },
    {
      "label": "Fly One",
      "value": {
        "id": "5F",
        "lcc": "1",
        "name": "Fly One",
        "logo": "https://images.kiwi.com/airlines/64/5F.png"
      }
    },
    {
      "label": "VickJet",
      "value": {
        "id": "KT",
        "lcc": "0",
        "name": "VickJet",
        "logo": "https://images.kiwi.com/airlines/64/KT.png"
      }
    },
    {
      "label": "I-Fly",
      "value": {
        "id": "H5",
        "lcc": "0",
        "name": "I-Fly",
        "logo": "https://images.kiwi.com/airlines/64/H5.png"
      }
    },
    {
      "label": "China Express Airlines",
      "value": {
        "id": "G5",
        "lcc": "0",
        "name": "China Express Airlines",
        "logo": "https://images.kiwi.com/airlines/64/G5.png"
      }
    },
    {
      "label": "Domenican Airlines",
      "value": {
        "id": "D1",
        "lcc": "0",
        "name": "Domenican Airlines",
        "logo": "https://images.kiwi.com/airlines/64/D1.png"
      }
    },
    {
      "label": "LionXpress",
      "value": {
        "id": "C4",
        "lcc": "0",
        "name": "LionXpress",
        "logo": "https://images.kiwi.com/airlines/64/C4.png"
      }
    },
    {
      "label": "Air Mekong",
      "value": {
        "id": "P8",
        "lcc": "0",
        "name": "Air Mekong",
        "logo": "https://images.kiwi.com/airlines/64/P8.png"
      }
    },
    {
      "label": "VLM Airlines",
      "value": {
        "id": "VG",
        "lcc": "0",
        "name": "VLM Airlines",
        "logo": "https://images.kiwi.com/airlines/64/VG.png"
      }
    },
    {
      "label": "Svenska Buss",
      "value": {
        "id": "SVENSKABUS",
        "lcc": "None",
        "name": "Svenska Buss",
        "logo": "https://images.kiwi.com/airlines/64/SVENSKABUS.png"
      }
    },
    {
      "label": "VIP Ecuador",
      "value": {
        "id": "V6",
        "lcc": "0",
        "name": "VIP Ecuador",
        "logo": "https://images.kiwi.com/airlines/64/V6.png"
      }
    },
    {
      "label": "Halcyonair",
      "value": {
        "id": "7Z",
        "lcc": "0",
        "name": "Halcyonair",
        "logo": "https://images.kiwi.com/airlines/64/7Z.png"
      }
    },
    {
      "label": "Sterling Airlines",
      "value": {
        "id": "NB",
        "lcc": "0",
        "name": "Sterling Airlines",
        "logo": "https://images.kiwi.com/airlines/64/NB.png"
      }
    },
    {
      "label": "Hitit Bilgisayar Hizmetleri",
      "value": {
        "id": "1T",
        "lcc": "0",
        "name": "Hitit Bilgisayar Hizmetleri",
        "logo": "https://images.kiwi.com/airlines/64/1T.png"
      }
    },
    {
      "label": "SmartLynx Airlines",
      "value": {
        "id": "6Y",
        "lcc": "0",
        "name": "SmartLynx Airlines",
        "logo": "https://images.kiwi.com/airlines/64/6Y.png"
      }
    },
    {
      "label": "Air Cargo Carriers",
      "value": {
        "id": "2Q",
        "lcc": "0",
        "name": "Air Cargo Carriers",
        "logo": "https://images.kiwi.com/airlines/64/2Q.png"
      }
    },
    {
      "label": "Oriental Air Bridge",
      "value": {
        "id": "OC",
        "lcc": "0",
        "name": "Oriental Air Bridge",
        "logo": "https://images.kiwi.com/airlines/64/OC.png"
      }
    },
    {
      "label": "Atlantis European Airways",
      "value": {
        "id": "TD",
        "lcc": "0",
        "name": "Atlantis European Airways",
        "logo": "https://images.kiwi.com/airlines/64/TD.png"
      }
    },
    {
      "label": "Neos Air",
      "value": {
        "id": "NO",
        "lcc": "1",
        "name": "Neos Air",
        "logo": "https://images.kiwi.com/airlines/64/NO.png"
      }
    },
    {
      "label": "Safi Airlines",
      "value": {
        "id": "4Q",
        "lcc": "0",
        "name": "Safi Airlines",
        "logo": "https://images.kiwi.com/airlines/64/4Q.png"
      }
    },
    {
      "label": "Montenegro Airlines",
      "value": {
        "id": "YM",
        "lcc": "0",
        "name": "Montenegro Airlines",
        "logo": "https://images.kiwi.com/airlines/64/YM.png"
      }
    },
    {
      "label": "AirPanama",
      "value": {
        "id": "7P",
        "lcc": "1",
        "name": "AirPanama",
        "logo": "https://images.kiwi.com/airlines/64/7P.png"
      }
    },
    {
      "label": "TAAG Angola Airlines",
      "value": {
        "id": "DT",
        "lcc": "0",
        "name": "TAAG Angola Airlines",
        "logo": "https://images.kiwi.com/airlines/64/DT.png"
      }
    },
    {
      "label": "Azimuth",
      "value": {
        "id": "A4",
        "lcc": "0",
        "name": "Azimuth",
        "logo": "https://images.kiwi.com/airlines/64/A4.png"
      }
    },
    {
      "label": "LEVEL operated by ANISEC",
      "value": {
        "id": "VK",
        "lcc": "0",
        "name": "LEVEL operated by ANISEC",
        "logo": "https://images.kiwi.com/airlines/64/VK.png"
      }
    },
    {
      "label": "Elite Airways",
      "value": {
        "id": "7Q",
        "lcc": "0",
        "name": "Elite Airways",
        "logo": "https://images.kiwi.com/airlines/64/7Q.png"
      }
    },
    {
      "label": "Donghai Airlines",
      "value": {
        "id": "DZ",
        "lcc": "0",
        "name": "Donghai Airlines",
        "logo": "https://images.kiwi.com/airlines/64/DZ.png"
      }
    },
    {
      "label": "TAR Aerolineas",
      "value": {
        "id": "YQ",
        "lcc": "0",
        "name": "TAR Aerolineas",
        "logo": "https://images.kiwi.com/airlines/64/YQ.png"
      }
    },
    {
      "label": "Solaseed Air",
      "value": {
        "id": "6J",
        "lcc": "0",
        "name": "Solaseed Air",
        "logo": "https://images.kiwi.com/airlines/64/6J.png"
      }
    },
    {
      "label": "Elysian Airlines",
      "value": {
        "id": "E4",
        "lcc": "0",
        "name": "Elysian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/E4.png"
      }
    },
    {
      "label": "Daallo Airlines",
      "value": {
        "id": "D3",
        "lcc": "0",
        "name": "Daallo Airlines",
        "logo": "https://images.kiwi.com/airlines/64/D3.png"
      }
    },
    {
      "label": "Iraqi Airways",
      "value": {
        "id": "IA",
        "lcc": "0",
        "name": "Iraqi Airways",
        "logo": "https://images.kiwi.com/airlines/64/IA.png"
      }
    },
    {
      "label": "LTE International Airways",
      "value": {
        "id": "XO",
        "lcc": "0",
        "name": "LTE International Airways",
        "logo": "https://images.kiwi.com/airlines/64/XO.png"
      }
    },
    {
      "label": "Chalair",
      "value": {
        "id": "CE",
        "lcc": "0",
        "name": "Chalair",
        "logo": "https://images.kiwi.com/airlines/64/CE.png"
      }
    },
    {
      "label": "Sun D'Or",
      "value": {
        "id": "7L",
        "lcc": "0",
        "name": "Sun D'Or",
        "logo": "https://images.kiwi.com/airlines/64/7L.png"
      }
    },
    {
      "label": "Spanair",
      "value": {
        "id": "JK",
        "lcc": "0",
        "name": "Spanair",
        "logo": "https://images.kiwi.com/airlines/64/JK.png"
      }
    },
    {
      "label": "SBA Airlines",
      "value": {
        "id": "S3",
        "lcc": "0",
        "name": "SBA Airlines",
        "logo": "https://images.kiwi.com/airlines/64/S3.png"
      }
    },
    {
      "label": "AtlasGlobal Ukraine",
      "value": {
        "id": "UH",
        "lcc": "0",
        "name": "AtlasGlobal Ukraine",
        "logo": "https://images.kiwi.com/airlines/64/UH.png"
      }
    },
    {
      "label": "Volga-Dnepr Airlines",
      "value": {
        "id": "VI",
        "lcc": "0",
        "name": "Volga-Dnepr Airlines",
        "logo": "https://images.kiwi.com/airlines/64/VI.png"
      }
    },
    {
      "label": "Colorful Guizhou Airlines",
      "value": {
        "id": "GY",
        "lcc": "0",
        "name": "Colorful Guizhou Airlines",
        "logo": "https://images.kiwi.com/airlines/64/GY.png"
      }
    },
    {
      "label": "Small Planet Airlines",
      "value": {
        "id": "5P",
        "lcc": "0",
        "name": "Small Planet Airlines",
        "logo": "https://images.kiwi.com/airlines/64/5P.png"
      }
    },
    {
      "label": "Mistral Air",
      "value": {
        "id": "M4",
        "lcc": "0",
        "name": "Mistral Air",
        "logo": "https://images.kiwi.com/airlines/64/M4.png"
      }
    },
    {
      "label": "AirRussia",
      "value": {
        "id": "R8",
        "lcc": "0",
        "name": "AirRussia",
        "logo": "https://images.kiwi.com/airlines/64/R8.png"
      }
    },
    {
      "label": "12 North",
      "value": {
        "id": "12",
        "lcc": "0",
        "name": "12 North",
        "logo": "https://images.kiwi.com/airlines/64/12.png"
      }
    },
    {
      "label": "JC International Airlines",
      "value": {
        "id": "QD",
        "lcc": "0",
        "name": "JC International Airlines",
        "logo": "https://images.kiwi.com/airlines/64/QD.png"
      }
    },
    {
      "label": "Air Malawi",
      "value": {
        "id": "QM",
        "lcc": "0",
        "name": "Air Malawi",
        "logo": "https://images.kiwi.com/airlines/64/QM.png"
      }
    },
    {
      "label": "BVI Airways",
      "value": {
        "id": "XV",
        "lcc": "0",
        "name": "BVI Airways",
        "logo": "https://images.kiwi.com/airlines/64/XV.png"
      }
    },
    {
      "label": "Mauritania Airlines International",
      "value": {
        "id": "L6",
        "lcc": "0",
        "name": "Mauritania Airlines International",
        "logo": "https://images.kiwi.com/airlines/64/L6.png"
      }
    },
    {
      "label": "DAT Danish Air Transport",
      "value": {
        "id": "DX",
        "lcc": "0",
        "name": "DAT Danish Air Transport",
        "logo": "https://images.kiwi.com/airlines/64/DX.png"
      }
    },
    {
      "label": "Air Senegal",
      "value": {
        "id": "HC",
        "lcc": "0",
        "name": "Air Senegal",
        "logo": "https://images.kiwi.com/airlines/64/HC.png"
      }
    },
    {
      "label": "Star1 Airlines",
      "value": {
        "id": "V9",
        "lcc": "0",
        "name": "Star1 Airlines",
        "logo": "https://images.kiwi.com/airlines/64/V9.png"
      }
    },
    {
      "label": "Stansted Express",
      "value": {
        "id": "STANSTDEXP",
        "lcc": "None",
        "name": "Stansted Express",
        "logo": "https://images.kiwi.com/airlines/64/STANSTDEXP.png"
      }
    },
    {
      "label": "Abacus International",
      "value": {
        "id": "1B",
        "lcc": "0",
        "name": "Abacus International",
        "logo": "https://images.kiwi.com/airlines/64/1B.png"
      }
    },
    {
      "label": "Hellenic Imperial Airways",
      "value": {
        "id": "HT",
        "lcc": "0",
        "name": "Hellenic Imperial Airways",
        "logo": "https://images.kiwi.com/airlines/64/HT.png"
      }
    },
    {
      "label": "NetJets",
      "value": {
        "id": "1I",
        "lcc": "0",
        "name": "NetJets",
        "logo": "https://images.kiwi.com/airlines/64/1I.png"
      }
    },
    {
      "label": "PB Air",
      "value": {
        "id": "9Q",
        "lcc": "0",
        "name": "PB Air",
        "logo": "https://images.kiwi.com/airlines/64/9Q.png"
      }
    },
    {
      "label": "Aircalin",
      "value": {
        "id": "SB",
        "lcc": "0",
        "name": "Aircalin",
        "logo": "https://images.kiwi.com/airlines/64/SB.png"
      }
    },
    {
      "label": "TransHolding System",
      "value": {
        "id": "YO",
        "lcc": "0",
        "name": "TransHolding System",
        "logo": "https://images.kiwi.com/airlines/64/YO.png"
      }
    },
    {
      "label": "MNG Airlines",
      "value": {
        "id": "MB",
        "lcc": "0",
        "name": "MNG Airlines",
        "logo": "https://images.kiwi.com/airlines/64/MB.png"
      }
    },
    {
      "label": "Fly Colombia ( Interliging Flights )",
      "value": {
        "id": "3F",
        "lcc": "0",
        "name": "Fly Colombia ( Interliging Flights )",
        "logo": "https://images.kiwi.com/airlines/64/3F.png"
      }
    },
    {
      "label": "Zenith International Airline",
      "value": {
        "id": "ZN",
        "lcc": "0",
        "name": "Zenith International Airline",
        "logo": "https://images.kiwi.com/airlines/64/ZN.png"
      }
    },
    {
      "label": "Jordan Aviation",
      "value": {
        "id": "R5",
        "lcc": "0",
        "name": "Jordan Aviation",
        "logo": "https://images.kiwi.com/airlines/64/R5.png"
      }
    },
    {
      "label": "Air Macau",
      "value": {
        "id": "NX",
        "lcc": "0",
        "name": "Air Macau",
        "logo": "https://images.kiwi.com/airlines/64/NX.png"
      }
    },
    {
      "label": "Air North",
      "value": {
        "id": "4N",
        "lcc": "0",
        "name": "Air North",
        "logo": "https://images.kiwi.com/airlines/64/4N.png"
      }
    },
    {
      "label": "Jet Airways",
      "value": {
        "id": "QJ",
        "lcc": "0",
        "name": "Jet Airways",
        "logo": "https://images.kiwi.com/airlines/64/QJ.png"
      }
    },
    {
      "label": "ExpressJet",
      "value": {
        "id": "EV",
        "lcc": "0",
        "name": "ExpressJet",
        "logo": "https://images.kiwi.com/airlines/64/EV.png"
      }
    },
    {
      "label": "Atlant-Soyuz Airlines",
      "value": {
        "id": "3G",
        "lcc": "0",
        "name": "Atlant-Soyuz Airlines",
        "logo": "https://images.kiwi.com/airlines/64/3G.png"
      }
    },
    {
      "label": "Frontier Flying Service",
      "value": {
        "id": "2F",
        "lcc": "0",
        "name": "Frontier Flying Service",
        "logo": "https://images.kiwi.com/airlines/64/2F.png"
      }
    },
    {
      "label": "FlyVLM",
      "value": {
        "id": "VO",
        "lcc": "0",
        "name": "FlyVLM",
        "logo": "https://images.kiwi.com/airlines/64/VO.png"
      }
    },
    {
      "label": "AlbaWings",
      "value": {
        "id": "2B",
        "lcc": "0",
        "name": "AlbaWings",
        "logo": "https://images.kiwi.com/airlines/64/2B.png"
      }
    },
    {
      "label": "Binter Cabo Verde",
      "value": {
        "id": "3B",
        "lcc": "0",
        "name": "Binter Cabo Verde",
        "logo": "https://images.kiwi.com/airlines/64/3B.png"
      }
    },
    {
      "label": "Shanghai Airlines",
      "value": {
        "id": "FM",
        "lcc": "0",
        "name": "Shanghai Airlines",
        "logo": "https://images.kiwi.com/airlines/64/FM.png"
      }
    },
    {
      "label": "JetSMART Argentina",
      "value": {
        "id": "WJ",
        "lcc": "0",
        "name": "JetSMART Argentina",
        "logo": "https://images.kiwi.com/airlines/64/WJ.png"
      }
    },
    {
      "label": "Austral Lineas Aereas",
      "value": {
        "id": "AU",
        "lcc": "0",
        "name": "Austral Lineas Aereas",
        "logo": "https://images.kiwi.com/airlines/64/AU.png"
      }
    },
    {
      "label": "Bulgarian Air Charter",
      "value": {
        "id": "H6",
        "lcc": "0",
        "name": "Bulgarian Air Charter",
        "logo": "https://images.kiwi.com/airlines/64/H6.png"
      }
    },
    {
      "label": "Air Cote d'Ivoire",
      "value": {
        "id": "HF",
        "lcc": "0",
        "name": "Air Cote d'Ivoire",
        "logo": "https://images.kiwi.com/airlines/64/HF.png"
      }
    },
    {
      "label": "Sky Angkor Airlines",
      "value": {
        "id": "ZA",
        "lcc": "0",
        "name": "Sky Angkor Airlines",
        "logo": "https://images.kiwi.com/airlines/64/ZA.png"
      }
    },
    {
      "label": "Jetairfly",
      "value": {
        "id": "JF",
        "lcc": "0",
        "name": "Jetairfly",
        "logo": "https://images.kiwi.com/airlines/64/JF.png"
      }
    },
    {
      "label": "KLM Cityhopper",
      "value": {
        "id": "WA",
        "lcc": "0",
        "name": "KLM Cityhopper",
        "logo": "https://images.kiwi.com/airlines/64/WA.png"
      }
    },
    {
      "label": "Kuzu Airlines Cargo",
      "value": {
        "id": "GO",
        "lcc": "0",
        "name": "Kuzu Airlines Cargo",
        "logo": "https://images.kiwi.com/airlines/64/GO.png"
      }
    },
    {
      "label": "LTU Austria",
      "value": {
        "id": "L3",
        "lcc": "0",
        "name": "LTU Austria",
        "logo": "https://images.kiwi.com/airlines/64/L3.png"
      }
    },
    {
      "label": "Luftfahrtgesellschaft Walter",
      "value": {
        "id": "HE",
        "lcc": "0",
        "name": "Luftfahrtgesellschaft Walter",
        "logo": "https://images.kiwi.com/airlines/64/HE.png"
      }
    },
    {
      "label": "Maersk",
      "value": {
        "id": "DM",
        "lcc": "0",
        "name": "Maersk",
        "logo": "https://images.kiwi.com/airlines/64/DM.png"
      }
    },
    {
      "label": "Northwest Airlines",
      "value": {
        "id": "NW",
        "lcc": "0",
        "name": "Northwest Airlines",
        "logo": "https://images.kiwi.com/airlines/64/NW.png"
      }
    },
    {
      "label": "Siam Air",
      "value": {
        "id": "O8",
        "lcc": "0",
        "name": "Siam Air",
        "logo": "https://images.kiwi.com/airlines/64/O8.png"
      }
    },
    {
      "label": "Origin Pacific Airways",
      "value": {
        "id": "QO",
        "lcc": "0",
        "name": "Origin Pacific Airways",
        "logo": "https://images.kiwi.com/airlines/64/QO.png"
      }
    },
    {
      "label": "Portugalia",
      "value": {
        "id": "NI",
        "lcc": "0",
        "name": "Portugalia",
        "logo": "https://images.kiwi.com/airlines/64/NI.png"
      }
    },
    {
      "label": "Ryan International Airlines",
      "value": {
        "id": "RD",
        "lcc": "0",
        "name": "Ryan International Airlines",
        "logo": "https://images.kiwi.com/airlines/64/RD.png"
      }
    },
    {
      "label": "Régional",
      "value": {
        "id": "YS",
        "lcc": "0",
        "name": "Régional",
        "logo": "https://images.kiwi.com/airlines/64/YS.png"
      }
    },
    {
      "label": "Skywalk Airlines",
      "value": {
        "id": "AL",
        "lcc": "0",
        "name": "Skywalk Airlines",
        "logo": "https://images.kiwi.com/airlines/64/AL.png"
      }
    },
    {
      "label": "Spring Airlines",
      "value": {
        "id": "9S",
        "lcc": "0",
        "name": "Spring Airlines",
        "logo": "https://images.kiwi.com/airlines/64/9S.png"
      }
    },
    {
      "label": "Transwest Air",
      "value": {
        "id": "9T",
        "lcc": "0",
        "name": "Transwest Air",
        "logo": "https://images.kiwi.com/airlines/64/9T.png"
      }
    },
    {
      "label": "TUIfly Nordic",
      "value": {
        "id": "6B",
        "lcc": "0",
        "name": "TUIfly Nordic",
        "logo": "https://images.kiwi.com/airlines/64/6B.png"
      }
    },
    {
      "label": "Grozny Avia",
      "value": {
        "id": "ZG",
        "lcc": "0",
        "name": "Grozny Avia",
        "logo": "https://images.kiwi.com/airlines/64/ZG.png"
      }
    },
    {
      "label": "Wizz Air Hungary",
      "value": {
        "id": "8Z",
        "lcc": "0",
        "name": "Wizz Air Hungary",
        "logo": "https://images.kiwi.com/airlines/64/8Z.png"
      }
    },
    {
      "label": "Air Chathams",
      "value": {
        "id": "CV",
        "lcc": "0",
        "name": "Air Chathams",
        "logo": "https://images.kiwi.com/airlines/64/CV.png"
      }
    },
    {
      "label": "SATA Air Acores",
      "value": {
        "id": "SP",
        "lcc": "0",
        "name": "SATA Air Acores",
        "logo": "https://images.kiwi.com/airlines/64/SP.png"
      }
    },
    {
      "label": "Contour Airlines",
      "value": {
        "id": "LF",
        "lcc": "0",
        "name": "Contour Airlines",
        "logo": "https://images.kiwi.com/airlines/64/LF.png"
      }
    },
    {
      "label": "Swiftair",
      "value": {
        "id": "WQ",
        "lcc": "0",
        "name": "Swiftair",
        "logo": "https://images.kiwi.com/airlines/64/WQ.png"
      }
    },
    {
      "label": "Southjet cargo",
      "value": {
        "id": "78",
        "lcc": "0",
        "name": "Southjet cargo",
        "logo": "https://images.kiwi.com/airlines/64/78.png"
      }
    },
    {
      "label": "Yamal Airlines",
      "value": {
        "id": "YL",
        "lcc": "0",
        "name": "Yamal Airlines",
        "logo": "https://images.kiwi.com/airlines/64/YL.png"
      }
    },
    {
      "label": "Kostromskie avialinii",
      "value": {
        "id": "K1",
        "lcc": "0",
        "name": "Kostromskie avialinii",
        "logo": "https://images.kiwi.com/airlines/64/K1.png"
      }
    },
    {
      "label": "Polar Airlines",
      "value": {
        "id": "PO",
        "lcc": "0",
        "name": "Polar Airlines",
        "logo": "https://images.kiwi.com/airlines/64/PO.png"
      }
    },
    {
      "label": "Helijet",
      "value": {
        "id": "JB",
        "lcc": "0",
        "name": "Helijet",
        "logo": "https://images.kiwi.com/airlines/64/JB.png"
      }
    },
    {
      "label": "LASA Argentina",
      "value": {
        "id": "L4",
        "lcc": "0",
        "name": "LASA Argentina",
        "logo": "https://images.kiwi.com/airlines/64/L4.png"
      }
    },
    {
      "label": "Japan Regio",
      "value": {
        "id": "ZX",
        "lcc": "0",
        "name": "Japan Regio",
        "logo": "https://images.kiwi.com/airlines/64/ZX.png"
      }
    },
    {
      "label": "Coastal Aviation",
      "value": {
        "id": "CQ",
        "lcc": "0",
        "name": "Coastal Aviation",
        "logo": "https://images.kiwi.com/airlines/64/CQ.png"
      }
    },
    {
      "label": "Buquebus Líneas Aéreas",
      "value": {
        "id": "BQ",
        "lcc": "0",
        "name": "Buquebus Líneas Aéreas",
        "logo": "https://images.kiwi.com/airlines/64/BQ.png"
      }
    },
    {
      "label": "VIA Rail Canada",
      "value": {
        "id": "VIARAILCAD",
        "lcc": "None",
        "name": "VIA Rail Canada",
        "logo": "https://images.kiwi.com/airlines/64/VIARAILCAD.png"
      }
    },
    {
      "label": "Papillon Grand Canyon Helicopters",
      "value": {
        "id": "HI",
        "lcc": "0",
        "name": "Papillon Grand Canyon Helicopters",
        "logo": "https://images.kiwi.com/airlines/64/HI.png"
      }
    },
    {
      "label": "Swedish Railways",
      "value": {
        "id": "SJRAIL",
        "lcc": "None",
        "name": "Swedish Railways",
        "logo": "https://images.kiwi.com/airlines/64/SJRAIL.png"
      }
    },
    {
      "label": "SENIC AIRLINES",
      "value": {
        "id": "YR",
        "lcc": "0",
        "name": "SENIC AIRLINES",
        "logo": "https://images.kiwi.com/airlines/64/YR.png"
      }
    },
    {
      "label": "MasAir",
      "value": {
        "id": "M7",
        "lcc": "0",
        "name": "MasAir",
        "logo": "https://images.kiwi.com/airlines/64/M7.png"
      }
    },
    {
      "label": "Ansett Australia",
      "value": {
        "id": "AN",
        "lcc": "0",
        "name": "Ansett Australia",
        "logo": "https://images.kiwi.com/airlines/64/AN.png"
      }
    },
    {
      "label": "Hunnu Air",
      "value": {
        "id": "MR",
        "lcc": "0",
        "name": "Hunnu Air",
        "logo": "https://images.kiwi.com/airlines/64/MR.png"
      }
    },
    {
      "label": "Virginwings",
      "value": {
        "id": "YY",
        "lcc": "0",
        "name": "Virginwings",
        "logo": "https://images.kiwi.com/airlines/64/YY.png"
      }
    },
    {
      "label": "Askari Aviation",
      "value": {
        "id": "4K",
        "lcc": "0",
        "name": "Askari Aviation",
        "logo": "https://images.kiwi.com/airlines/64/4K.png"
      }
    },
    {
      "label": "Camair-co",
      "value": {
        "id": "QC",
        "lcc": "0",
        "name": "Camair-co",
        "logo": "https://images.kiwi.com/airlines/64/QC.png"
      }
    },
    {
      "label": "AirOnix",
      "value": {
        "id": "OG",
        "lcc": "0",
        "name": "AirOnix",
        "logo": "https://images.kiwi.com/airlines/64/OG.png"
      }
    },
    {
      "label": "Afriqiyah Airways",
      "value": {
        "id": "8U",
        "lcc": "0",
        "name": "Afriqiyah Airways",
        "logo": "https://images.kiwi.com/airlines/64/8U.png"
      }
    },
    {
      "label": "Ariana Afghan Airlines",
      "value": {
        "id": "FG",
        "lcc": "0",
        "name": "Ariana Afghan Airlines",
        "logo": "https://images.kiwi.com/airlines/64/FG.png"
      }
    },
    {
      "label": "Grant Aviation",
      "value": {
        "id": "GV",
        "lcc": "0",
        "name": "Grant Aviation",
        "logo": "https://images.kiwi.com/airlines/64/GV.png"
      }
    },
    {
      "label": "Hawkair",
      "value": {
        "id": "BH",
        "lcc": "0",
        "name": "Hawkair",
        "logo": "https://images.kiwi.com/airlines/64/BH.png"
      }
    },
    {
      "label": "Heli France",
      "value": {
        "id": "8H",
        "lcc": "0",
        "name": "Heli France",
        "logo": "https://images.kiwi.com/airlines/64/8H.png"
      }
    },
    {
      "label": "Hellas Jet",
      "value": {
        "id": "T4",
        "lcc": "0",
        "name": "Hellas Jet",
        "logo": "https://images.kiwi.com/airlines/64/T4.png"
      }
    },
    {
      "label": "Lufthansa CityLine",
      "value": {
        "id": "CL",
        "lcc": "0",
        "name": "Lufthansa CityLine",
        "logo": "https://images.kiwi.com/airlines/64/CL.png"
      }
    },
    {
      "label": "Globus",
      "value": {
        "id": "GH",
        "lcc": "0",
        "name": "Globus",
        "logo": "https://images.kiwi.com/airlines/64/GH.png"
      }
    },
    {
      "label": "LATAM Argentina",
      "value": {
        "id": "4M",
        "lcc": "0",
        "name": "LATAM Argentina",
        "logo": "https://images.kiwi.com/airlines/64/4M.png"
      }
    },
    {
      "label": "Jetways Airlines Limited",
      "value": {
        "id": "WU",
        "lcc": "0",
        "name": "Jetways Airlines Limited",
        "logo": "https://images.kiwi.com/airlines/64/WU.png"
      }
    },
    {
      "label": "Fuzhou Airlines",
      "value": {
        "id": "FU",
        "lcc": "0",
        "name": "Fuzhou Airlines",
        "logo": "https://images.kiwi.com/airlines/64/FU.png"
      }
    },
    {
      "label": "Hello",
      "value": {
        "id": "HW",
        "lcc": "0",
        "name": "Hello",
        "logo": "https://images.kiwi.com/airlines/64/HW.png"
      }
    },
    {
      "label": "Atlas Atlantique Airlines",
      "value": {
        "id": "L5",
        "lcc": "0",
        "name": "Atlas Atlantique Airlines",
        "logo": "https://images.kiwi.com/airlines/64/L5.png"
      }
    },
    {
      "label": "MIAT Mongolian Airlines",
      "value": {
        "id": "OM",
        "lcc": "0",
        "name": "MIAT Mongolian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/OM.png"
      }
    },
    {
      "label": "Mahan Air",
      "value": {
        "id": "W5",
        "lcc": "0",
        "name": "Mahan Air",
        "logo": "https://images.kiwi.com/airlines/64/W5.png"
      }
    },
    {
      "label": "Malév",
      "value": {
        "id": "MA",
        "lcc": "0",
        "name": "Malév",
        "logo": "https://images.kiwi.com/airlines/64/MA.png"
      }
    },
    {
      "label": "Skagway Air Service",
      "value": {
        "id": "N5",
        "lcc": "0",
        "name": "Skagway Air Service",
        "logo": "https://images.kiwi.com/airlines/64/N5.png"
      }
    },
    {
      "label": "VASP",
      "value": {
        "id": "VP",
        "lcc": "0",
        "name": "VASP",
        "logo": "https://images.kiwi.com/airlines/64/VP.png"
      }
    },
    {
      "label": "United Airways",
      "value": {
        "id": "4H",
        "lcc": "0",
        "name": "United Airways",
        "logo": "https://images.kiwi.com/airlines/64/4H.png"
      }
    },
    {
      "label": "Salsa d\\\\'Haiti",
      "value": {
        "id": "SO",
        "lcc": "0",
        "name": "Salsa d\\\\'Haiti",
        "logo": "https://images.kiwi.com/airlines/64/SO.png"
      }
    },
    {
      "label": "Korongo Airlines",
      "value": {
        "id": "ZC",
        "lcc": "0",
        "name": "Korongo Airlines",
        "logo": "https://images.kiwi.com/airlines/64/ZC.png"
      }
    },
    {
      "label": "China Northwest Airlines (WH)",
      "value": {
        "id": "WH",
        "lcc": "0",
        "name": "China Northwest Airlines (WH)",
        "logo": "https://images.kiwi.com/airlines/64/WH.png"
      }
    },
    {
      "label": "BQB Lineas Aereas",
      "value": {
        "id": "5Q",
        "lcc": "0",
        "name": "BQB Lineas Aereas",
        "logo": "https://images.kiwi.com/airlines/64/5Q.png"
      }
    },
    {
      "label": "Royal Airways",
      "value": {
        "id": "KG",
        "lcc": "0",
        "name": "Royal Airways",
        "logo": "https://images.kiwi.com/airlines/64/KG.png"
      }
    },
    {
      "label": "Yangon Airways Ltd.",
      "value": {
        "id": "YH",
        "lcc": "0",
        "name": "Yangon Airways Ltd.",
        "logo": "https://images.kiwi.com/airlines/64/YH.png"
      }
    },
    {
      "label": "Anguilla Air Services",
      "value": {
        "id": "Q3",
        "lcc": "0",
        "name": "Anguilla Air Services",
        "logo": "https://images.kiwi.com/airlines/64/Q3.png"
      }
    },
    {
      "label": "CCML Airlines",
      "value": {
        "id": "CB",
        "lcc": "0",
        "name": "CCML Airlines",
        "logo": "https://images.kiwi.com/airlines/64/CB.png"
      }
    },
    {
      "label": "BRAZIL AIR",
      "value": {
        "id": "GB",
        "lcc": "0",
        "name": "BRAZIL AIR",
        "logo": "https://images.kiwi.com/airlines/64/GB.png"
      }
    },
    {
      "label": "N1",
      "value": {
        "id": "N1",
        "lcc": "0",
        "name": "N1",
        "logo": "https://images.kiwi.com/airlines/64/N1.png"
      }
    },
    {
      "label": "Indya Airline Group",
      "value": {
        "id": "G1",
        "lcc": "0",
        "name": "Indya Airline Group",
        "logo": "https://images.kiwi.com/airlines/64/G1.png"
      }
    },
    {
      "label": "Air Norway",
      "value": {
        "id": "M3",
        "lcc": "0",
        "name": "Air Norway",
        "logo": "https://images.kiwi.com/airlines/64/M3.png"
      }
    },
    {
      "label": "PassionAir",
      "value": {
        "id": "OP",
        "lcc": "None",
        "name": "PassionAir",
        "logo": "https://images.kiwi.com/airlines/64/OP.png"
      }
    },
    {
      "label": "Azur Air Ukraine",
      "value": {
        "id": "QU",
        "lcc": "None",
        "name": "Azur Air Ukraine",
        "logo": "https://images.kiwi.com/airlines/64/QU.png"
      }
    },
    {
      "label": "Euro Jet",
      "value": {
        "id": "24",
        "lcc": "0",
        "name": "Euro Jet",
        "logo": "https://images.kiwi.com/airlines/64/24.png"
      }
    },
    {
      "label": "Evergreen International Airlines",
      "value": {
        "id": "EZ",
        "lcc": "0",
        "name": "Evergreen International Airlines",
        "logo": "https://images.kiwi.com/airlines/64/EZ.png"
      }
    },
    {
      "label": "Sun lines",
      "value": {
        "id": "SUNLINES",
        "lcc": "None",
        "name": "Sun lines",
        "logo": "https://images.kiwi.com/airlines/64/SUNLINES.png"
      }
    },
    {
      "label": "Ada Air",
      "value": {
        "id": "ZY",
        "lcc": "0",
        "name": "Ada Air",
        "logo": "https://images.kiwi.com/airlines/64/ZY.png"
      }
    },
    {
      "label": "CanXpress",
      "value": {
        "id": "C1",
        "lcc": "0",
        "name": "CanXpress",
        "logo": "https://images.kiwi.com/airlines/64/C1.png"
      }
    },
    {
      "label": "Empire Airlines",
      "value": {
        "id": "EM",
        "lcc": "0",
        "name": "Empire Airlines",
        "logo": "https://images.kiwi.com/airlines/64/EM.png"
      }
    },
    {
      "label": "CommutAir",
      "value": {
        "id": "C5",
        "lcc": "0",
        "name": "CommutAir",
        "logo": "https://images.kiwi.com/airlines/64/C5.png"
      }
    },
    {
      "label": "Contact Air",
      "value": {
        "id": "C3",
        "lcc": "0",
        "name": "Contact Air",
        "logo": "https://images.kiwi.com/airlines/64/C3.png"
      }
    },
    {
      "label": "Continental Micronesia",
      "value": {
        "id": "CS",
        "lcc": "0",
        "name": "Continental Micronesia",
        "logo": "https://images.kiwi.com/airlines/64/CS.png"
      }
    },
    {
      "label": "Darwin Airline",
      "value": {
        "id": "0D",
        "lcc": "0",
        "name": "Darwin Airline",
        "logo": "https://images.kiwi.com/airlines/64/0D.png"
      }
    },
    {
      "label": "Eastland Air",
      "value": {
        "id": "DK",
        "lcc": "0",
        "name": "Eastland Air",
        "logo": "https://images.kiwi.com/airlines/64/DK.png"
      }
    },
    {
      "label": "ExpressJet",
      "value": {
        "id": "XE",
        "lcc": "0",
        "name": "ExpressJet",
        "logo": "https://images.kiwi.com/airlines/64/XE.png"
      }
    },
    {
      "label": "Florida West International Airways",
      "value": {
        "id": "RF",
        "lcc": "0",
        "name": "Florida West International Airways",
        "logo": "https://images.kiwi.com/airlines/64/RF.png"
      }
    },
    {
      "label": "Tunisair",
      "value": {
        "id": "TU",
        "lcc": "0",
        "name": "Tunisair",
        "logo": "https://images.kiwi.com/airlines/64/TU.png"
      }
    },
    {
      "label": "Twin Jet",
      "value": {
        "id": "T7",
        "lcc": "0",
        "name": "Twin Jet",
        "logo": "https://images.kiwi.com/airlines/64/T7.png"
      }
    },
    {
      "label": "Virgin Australia Airlines",
      "value": {
        "id": "VA",
        "lcc": "1",
        "name": "Virgin Australia Airlines",
        "logo": "https://images.kiwi.com/airlines/64/VA.png"
      }
    },
    {
      "label": "World Experience Airline",
      "value": {
        "id": "W1",
        "lcc": "0",
        "name": "World Experience Airline",
        "logo": "https://images.kiwi.com/airlines/64/W1.png"
      }
    },
    {
      "label": "Locair",
      "value": {
        "id": "ZQ",
        "lcc": "0",
        "name": "Locair",
        "logo": "https://images.kiwi.com/airlines/64/ZQ.png"
      }
    },
    {
      "label": "Air indus",
      "value": {
        "id": "I6",
        "lcc": "0",
        "name": "Air indus",
        "logo": "https://images.kiwi.com/airlines/64/I6.png"
      }
    },
    {
      "label": "Chongqing Airlines",
      "value": {
        "id": "OQ",
        "lcc": "0",
        "name": "Chongqing Airlines",
        "logo": "https://images.kiwi.com/airlines/64/OQ.png"
      }
    },
    {
      "label": "OneChina",
      "value": {
        "id": "1C",
        "lcc": "0",
        "name": "OneChina",
        "logo": "https://images.kiwi.com/airlines/64/1C.png"
      }
    },
    {
      "label": "Airblue",
      "value": {
        "id": "PA",
        "lcc": "1",
        "name": "Airblue",
        "logo": "https://images.kiwi.com/airlines/64/PA.png"
      }
    },
    {
      "label": "Rotana Jet",
      "value": {
        "id": "RG",
        "lcc": "0",
        "name": "Rotana Jet",
        "logo": "https://images.kiwi.com/airlines/64/RG.png"
      }
    },
    {
      "label": "Tradewind Aviation",
      "value": {
        "id": "TJ",
        "lcc": "0",
        "name": "Tradewind Aviation",
        "logo": "https://images.kiwi.com/airlines/64/TJ.png"
      }
    },
    {
      "label": "Norwegian Air Argentina",
      "value": {
        "id": "DN",
        "lcc": "0",
        "name": "Norwegian Air Argentina",
        "logo": "https://images.kiwi.com/airlines/64/DN.png"
      }
    },
    {
      "label": "Hebei Airlines",
      "value": {
        "id": "NS",
        "lcc": "0",
        "name": "Hebei Airlines",
        "logo": "https://images.kiwi.com/airlines/64/NS.png"
      }
    },
    {
      "label": "Valuair",
      "value": {
        "id": "VF",
        "lcc": "0",
        "name": "Valuair",
        "logo": "https://images.kiwi.com/airlines/64/VF.png"
      }
    },
    {
      "label": "Air Seychelles",
      "value": {
        "id": "HM",
        "lcc": "0",
        "name": "Air Seychelles",
        "logo": "https://images.kiwi.com/airlines/64/HM.png"
      }
    },
    {
      "label": "China United",
      "value": {
        "id": "KN",
        "lcc": "0",
        "name": "China United",
        "logo": "https://images.kiwi.com/airlines/64/KN.png"
      }
    },
    {
      "label": "Flexflight",
      "value": {
        "id": "W2",
        "lcc": "0",
        "name": "Flexflight",
        "logo": "https://images.kiwi.com/airlines/64/W2.png"
      }
    },
    {
      "label": "Nesma Air",
      "value": {
        "id": "NA",
        "lcc": "0",
        "name": "Nesma Air",
        "logo": "https://images.kiwi.com/airlines/64/NA.png"
      }
    },
    {
      "label": "Alliance Air",
      "value": {
        "id": "9I",
        "lcc": "0",
        "name": "Alliance Air",
        "logo": "https://images.kiwi.com/airlines/64/9I.png"
      }
    },
    {
      "label": "TransBrasil Airlines",
      "value": {
        "id": "TH",
        "lcc": "0",
        "name": "TransBrasil Airlines",
        "logo": "https://images.kiwi.com/airlines/64/TH.png"
      }
    },
    {
      "label": "Yellowstone Club Private Shuttle",
      "value": {
        "id": "Y1",
        "lcc": "0",
        "name": "Yellowstone Club Private Shuttle",
        "logo": "https://images.kiwi.com/airlines/64/Y1.png"
      }
    },
    {
      "label": "Fly Brasil",
      "value": {
        "id": "F1",
        "lcc": "0",
        "name": "Fly Brasil",
        "logo": "https://images.kiwi.com/airlines/64/F1.png"
      }
    },
    {
      "label": "CB Airways UK ( Interliging Flights )",
      "value": {
        "id": "1F",
        "lcc": "0",
        "name": "CB Airways UK ( Interliging Flights )",
        "logo": "https://images.kiwi.com/airlines/64/1F.png"
      }
    },
    {
      "label": "Airswift Transport",
      "value": {
        "id": "T6",
        "lcc": "0",
        "name": "Airswift Transport",
        "logo": "https://images.kiwi.com/airlines/64/T6.png"
      }
    },
    {
      "label": "Norte Lineas Aereas",
      "value": {
        "id": "N0",
        "lcc": "0",
        "name": "Norte Lineas Aereas",
        "logo": "https://images.kiwi.com/airlines/64/N0.png"
      }
    },
    {
      "label": "Himalaya Airlines",
      "value": {
        "id": "H9",
        "lcc": "0",
        "name": "Himalaya Airlines",
        "logo": "https://images.kiwi.com/airlines/64/H9.png"
      }
    },
    {
      "label": "Air Cargo Germany",
      "value": {
        "id": "6U",
        "lcc": "0",
        "name": "Air Cargo Germany",
        "logo": "https://images.kiwi.com/airlines/64/6U.png"
      }
    },
    {
      "label": "NEXT Brasil",
      "value": {
        "id": "XB",
        "lcc": "0",
        "name": "NEXT Brasil",
        "logo": "https://images.kiwi.com/airlines/64/XB.png"
      }
    },
    {
      "label": "GNB Linhas Aereas",
      "value": {
        "id": "GN",
        "lcc": "0",
        "name": "GNB Linhas Aereas",
        "logo": "https://images.kiwi.com/airlines/64/GN.png"
      }
    },
    {
      "label": "Usa Sky Cargo",
      "value": {
        "id": "E1",
        "lcc": "0",
        "name": "Usa Sky Cargo",
        "logo": "https://images.kiwi.com/airlines/64/E1.png"
      }
    },
    {
      "label": "Red Jet Canada",
      "value": {
        "id": "QY",
        "lcc": "0",
        "name": "Red Jet Canada",
        "logo": "https://images.kiwi.com/airlines/64/QY.png"
      }
    },
    {
      "label": "Red Jet Mexico",
      "value": {
        "id": "4X",
        "lcc": "0",
        "name": "Red Jet Mexico",
        "logo": "https://images.kiwi.com/airlines/64/4X.png"
      }
    },
    {
      "label": "Marusya Airways",
      "value": {
        "id": "Y8",
        "lcc": "0",
        "name": "Marusya Airways",
        "logo": "https://images.kiwi.com/airlines/64/Y8.png"
      }
    },
    {
      "label": "Freebird Airlines",
      "value": {
        "id": "FH",
        "lcc": "0",
        "name": "Freebird Airlines",
        "logo": "https://images.kiwi.com/airlines/64/FH.png"
      }
    },
    {
      "label": "Aero VIP (2D)",
      "value": {
        "id": "2D",
        "lcc": "0",
        "name": "Aero VIP (2D)",
        "logo": "https://images.kiwi.com/airlines/64/2D.png"
      }
    },
    {
      "label": "FakeAirline",
      "value": {
        "id": "__",
        "lcc": "0",
        "name": "FakeAirline",
        "logo": "https://images.kiwi.com/airlines/64/__.png"
      }
    },
    {
      "label": "Thomas Cook Belgium",
      "value": {
        "id": "HQ",
        "lcc": "0",
        "name": "Thomas Cook Belgium",
        "logo": "https://images.kiwi.com/airlines/64/HQ.png"
      }
    },
    {
      "label": "BoutiqueAir",
      "value": {
        "id": "4B",
        "lcc": "0",
        "name": "BoutiqueAir",
        "logo": "https://images.kiwi.com/airlines/64/4B.png"
      }
    },
    {
      "label": "Branson Air Express",
      "value": {
        "id": "1X",
        "lcc": "0",
        "name": "Branson Air Express",
        "logo": "https://images.kiwi.com/airlines/64/1X.png"
      }
    },
    {
      "label": "Evelop Airlines",
      "value": {
        "id": "E9",
        "lcc": "0",
        "name": "Evelop Airlines",
        "logo": "https://images.kiwi.com/airlines/64/E9.png"
      }
    },
    {
      "label": "Aerolinea de Antioquia",
      "value": {
        "id": "DA",
        "lcc": "0",
        "name": "Aerolinea de Antioquia",
        "logo": "https://images.kiwi.com/airlines/64/DA.png"
      }
    },
    {
      "label": "MementoBUS",
      "value": {
        "id": "MEMENTO",
        "lcc": "None",
        "name": "MementoBUS",
        "logo": "https://images.kiwi.com/airlines/64/MEMENTO.png"
      }
    },
    {
      "label": "Island Air Kodiak",
      "value": {
        "id": "2O",
        "lcc": "0",
        "name": "Island Air Kodiak",
        "logo": "https://images.kiwi.com/airlines/64/2O.png"
      }
    },
    {
      "label": "Biman Bangladesh Airlines",
      "value": {
        "id": "BG",
        "lcc": "1",
        "name": "Biman Bangladesh Airlines",
        "logo": "https://images.kiwi.com/airlines/64/BG.png"
      }
    },
    {
      "label": "Aero VIP",
      "value": {
        "id": "WV",
        "lcc": "0",
        "name": "Aero VIP",
        "logo": "https://images.kiwi.com/airlines/64/WV.png"
      }
    },
    {
      "label": "Air Caraïbes",
      "value": {
        "id": "TX",
        "lcc": "0",
        "name": "Air Caraïbes",
        "logo": "https://images.kiwi.com/airlines/64/TX.png"
      }
    },
    {
      "label": "Andbus",
      "value": {
        "id": "ANDBUS",
        "lcc": "None",
        "name": "Andbus",
        "logo": "https://images.kiwi.com/airlines/64/ANDBUS.png"
      }
    },
    {
      "label": "Via Air",
      "value": {
        "id": "VC",
        "lcc": "0",
        "name": "Via Air",
        "logo": "https://images.kiwi.com/airlines/64/VC.png"
      }
    },
    {
      "label": "Air Salone",
      "value": {
        "id": "20",
        "lcc": "0",
        "name": "Air Salone",
        "logo": "https://images.kiwi.com/airlines/64/20.png"
      }
    },
    {
      "label": "EJR – East Japan Rail Company",
      "value": {
        "id": "EJR",
        "lcc": "None",
        "name": "EJR – East Japan Rail Company",
        "logo": "https://images.kiwi.com/airlines/64/EJR.png"
      }
    },
    {
      "label": "Iran Air",
      "value": {
        "id": "IR",
        "lcc": "0",
        "name": "Iran Air",
        "logo": "https://images.kiwi.com/airlines/64/IR.png"
      }
    },
    {
      "label": "Zz",
      "value": {
        "id": "ZZ",
        "lcc": "0",
        "name": "Zz",
        "logo": "https://images.kiwi.com/airlines/64/ZZ.png"
      }
    },
    {
      "label": "SGA Airlines",
      "value": {
        "id": "5E",
        "lcc": "0",
        "name": "SGA Airlines",
        "logo": "https://images.kiwi.com/airlines/64/5E.png"
      }
    },
    {
      "label": "Vienna Airport Lines",
      "value": {
        "id": "VIEAPLINES",
        "lcc": "None",
        "name": "Vienna Airport Lines",
        "logo": "https://images.kiwi.com/airlines/64/VIEAPLINES.png"
      }
    },
    {
      "label": "Jambojet",
      "value": {
        "id": "JM",
        "lcc": "0",
        "name": "Jambojet",
        "logo": "https://images.kiwi.com/airlines/64/JM.png"
      }
    },
    {
      "label": "RegioJet",
      "value": {
        "id": "REGIOJET",
        "lcc": "0",
        "name": "RegioJet",
        "logo": "https://images.kiwi.com/airlines/64/REGIOJET.png"
      }
    },
    {
      "label": "Arkia",
      "value": {
        "id": "IZ",
        "lcc": "1",
        "name": "Arkia",
        "logo": "https://images.kiwi.com/airlines/64/IZ.png"
      }
    },
    {
      "label": "Air Do",
      "value": {
        "id": "HD",
        "lcc": "1",
        "name": "Air Do",
        "logo": "https://images.kiwi.com/airlines/64/HD.png"
      }
    },
    {
      "label": "China Airlines",
      "value": {
        "id": "CI",
        "lcc": "0",
        "name": "China Airlines",
        "logo": "https://images.kiwi.com/airlines/64/CI.png"
      }
    },
    {
      "label": "Fly540",
      "value": {
        "id": "5H",
        "lcc": "1",
        "name": "Fly540",
        "logo": "https://images.kiwi.com/airlines/64/5H.png"
      }
    },
    {
      "label": "Citilink",
      "value": {
        "id": "QG",
        "lcc": "1",
        "name": "Citilink",
        "logo": "https://images.kiwi.com/airlines/64/QG.png"
      }
    },
    {
      "label": "Cambodia Angkor Air",
      "value": {
        "id": "K6",
        "lcc": "0",
        "name": "Cambodia Angkor Air",
        "logo": "https://images.kiwi.com/airlines/64/K6.png"
      }
    },
    {
      "label": "Air Costa",
      "value": {
        "id": "LB",
        "lcc": "1",
        "name": "Air Costa",
        "logo": "https://images.kiwi.com/airlines/64/LB.png"
      }
    },
    {
      "label": "Volaris Costa Rica",
      "value": {
        "id": "Q6",
        "lcc": "0",
        "name": "Volaris Costa Rica",
        "logo": "https://images.kiwi.com/airlines/64/Q6.png"
      }
    },
    {
      "label": "Flair Airlines",
      "value": {
        "id": "F8",
        "lcc": "0",
        "name": "Flair Airlines",
        "logo": "https://images.kiwi.com/airlines/64/F8.png"
      }
    },
    {
      "label": "Mandarin Airlines",
      "value": {
        "id": "AE",
        "lcc": "0",
        "name": "Mandarin Airlines",
        "logo": "https://images.kiwi.com/airlines/64/AE.png"
      }
    },
    {
      "label": "CebGo",
      "value": {
        "id": "DG",
        "lcc": "0",
        "name": "CebGo",
        "logo": "https://images.kiwi.com/airlines/64/DG.png"
      }
    },
    {
      "label": "LaudaMotion",
      "value": {
        "id": "OE",
        "lcc": "1",
        "name": "LaudaMotion",
        "logo": "https://images.kiwi.com/airlines/64/OE.png"
      }
    },
    {
      "label": "SunExpress",
      "value": {
        "id": "XG",
        "lcc": "0",
        "name": "SunExpress",
        "logo": "https://images.kiwi.com/airlines/64/XG.png"
      }
    },
    {
      "label": "Wings Air",
      "value": {
        "id": "IW",
        "lcc": "0",
        "name": "Wings Air",
        "logo": "https://images.kiwi.com/airlines/64/IW.png"
      }
    },
    {
      "label": "China Southern Airlines",
      "value": {
        "id": "CZ",
        "lcc": "0",
        "name": "China Southern Airlines",
        "logo": "https://images.kiwi.com/airlines/64/CZ.png"
      }
    },
    {
      "label": "GoOpti",
      "value": {
        "id": "GOOPTI",
        "lcc": "None",
        "name": "GoOpti",
        "logo": "https://images.kiwi.com/airlines/64/GOOPTI.png"
      }
    },
    {
      "label": "Air Liaison",
      "value": {
        "id": "LIZ",
        "lcc": "None",
        "name": "Air Liaison",
        "logo": "https://images.kiwi.com/airlines/64/LIZ.png"
      }
    },
    {
      "label": "Air Chathams Limited 3C",
      "value": {
        "id": "3C",
        "lcc": "None",
        "name": "Air Chathams Limited 3C",
        "logo": "https://images.kiwi.com/airlines/64/3C.png"
      }
    },
    {
      "label": "CAT- City Airport Train",
      "value": {
        "id": "CAT",
        "lcc": "None",
        "name": "CAT- City Airport Train",
        "logo": "https://images.kiwi.com/airlines/64/CAT.png"
      }
    },
    {
      "label": "Oxford Bus Company",
      "value": {
        "id": "OXFORDBUS",
        "lcc": "None",
        "name": "Oxford Bus Company",
        "logo": "https://images.kiwi.com/airlines/64/OXFORDBUS.png"
      }
    },
    {
      "label": "Air Leap",
      "value": {
        "id": "LPA",
        "lcc": "None",
        "name": "Air Leap",
        "logo": "https://images.kiwi.com/airlines/64/LPA.png"
      }
    },
    {
      "label": "Kan Air",
      "value": {
        "id": "K8",
        "lcc": "0",
        "name": "Kan Air",
        "logo": "https://images.kiwi.com/airlines/64/K8.png"
      }
    },
    {
      "label": "Orbit Airlines Azerbaijan",
      "value": {
        "id": "O1",
        "lcc": "0",
        "name": "Orbit Airlines Azerbaijan",
        "logo": "https://images.kiwi.com/airlines/64/O1.png"
      }
    },
    {
      "label": "RegionalJet",
      "value": {
        "id": "EE",
        "lcc": "0",
        "name": "RegionalJet",
        "logo": "https://images.kiwi.com/airlines/64/EE.png"
      }
    },
    {
      "label": "Yan Air",
      "value": {
        "id": "YE",
        "lcc": "0",
        "name": "Yan Air",
        "logo": "https://images.kiwi.com/airlines/64/YE.png"
      }
    },
    {
      "label": "American Airlines",
      "value": {
        "id": "AA",
        "lcc": "0",
        "name": "American Airlines",
        "logo": "https://images.kiwi.com/airlines/64/AA.png"
      }
    },
    {
      "label": "Cape Air",
      "value": {
        "id": "9K",
        "lcc": "1",
        "name": "Cape Air",
        "logo": "https://images.kiwi.com/airlines/64/9K.png"
      }
    },
    {
      "label": "Tarom",
      "value": {
        "id": "RO",
        "lcc": "0",
        "name": "Tarom",
        "logo": "https://images.kiwi.com/airlines/64/RO.png"
      }
    },
    {
      "label": "Atifly",
      "value": {
        "id": "A1",
        "lcc": "0",
        "name": "Atifly",
        "logo": "https://images.kiwi.com/airlines/64/A1.png"
      }
    },
    {
      "label": "Southern Airways Express",
      "value": {
        "id": "9X",
        "lcc": "1",
        "name": "Southern Airways Express",
        "logo": "https://images.kiwi.com/airlines/64/9X.png"
      }
    },
    {
      "label": "Bek Air",
      "value": {
        "id": "Z9",
        "lcc": "0",
        "name": "Bek Air",
        "logo": "https://images.kiwi.com/airlines/64/Z9.png"
      }
    },
    {
      "label": "Amaszonas",
      "value": {
        "id": "Z8",
        "lcc": "1",
        "name": "Amaszonas",
        "logo": "https://images.kiwi.com/airlines/64/Z8.png"
      }
    },
    {
      "label": "PKS Szczencin",
      "value": {
        "id": "PKSZCZECIN",
        "lcc": "None",
        "name": "PKS Szczencin",
        "logo": "https://images.kiwi.com/airlines/64/PKSZCZECIN.png"
      }
    },
    {
      "label": "Tropic Ocean Airways",
      "value": {
        "id": "TROPOCEAN",
        "lcc": "None",
        "name": "Tropic Ocean Airways",
        "logo": "https://images.kiwi.com/airlines/64/TROPOCEAN.png"
      }
    },
    {
      "label": "Jota Aviation",
      "value": {
        "id": "ENZ",
        "lcc": "None",
        "name": "Jota Aviation",
        "logo": "https://images.kiwi.com/airlines/64/ENZ.png"
      }
    },
    {
      "label": "Air Tahiti Nui",
      "value": {
        "id": "TN",
        "lcc": "0",
        "name": "Air Tahiti Nui",
        "logo": "https://images.kiwi.com/airlines/64/TN.png"
      }
    },
    {
      "label": "Regiojet Bus",
      "value": {
        "id": "REGIOJETB",
        "lcc": "0",
        "name": "Regiojet Bus",
        "logo": "https://images.kiwi.com/airlines/64/REGIOJETB.png"
      }
    },
    {
      "label": "Southjet",
      "value": {
        "id": "76",
        "lcc": "0",
        "name": "Southjet",
        "logo": "https://images.kiwi.com/airlines/64/76.png"
      }
    },
    {
      "label": "Southjet connect",
      "value": {
        "id": "77",
        "lcc": "0",
        "name": "Southjet connect",
        "logo": "https://images.kiwi.com/airlines/64/77.png"
      }
    },
    {
      "label": "Avianova (Russia)",
      "value": {
        "id": "AO",
        "lcc": "0",
        "name": "Avianova (Russia)",
        "logo": "https://images.kiwi.com/airlines/64/AO.png"
      }
    },
    {
      "label": "Pascan Aviation",
      "value": {
        "id": "P6",
        "lcc": "0",
        "name": "Pascan Aviation",
        "logo": "https://images.kiwi.com/airlines/64/P6.png"
      }
    },
    {
      "label": "CM Airlines",
      "value": {
        "id": "CC",
        "lcc": "0",
        "name": "CM Airlines",
        "logo": "https://images.kiwi.com/airlines/64/CC.png"
      }
    },
    {
      "label": "Air Malta",
      "value": {
        "id": "KM",
        "lcc": "0",
        "name": "Air Malta",
        "logo": "https://images.kiwi.com/airlines/64/KM.png"
      }
    },
    {
      "label": "Aurigny Air Services",
      "value": {
        "id": "GR",
        "lcc": "0",
        "name": "Aurigny Air Services",
        "logo": "https://images.kiwi.com/airlines/64/GR.png"
      }
    },
    {
      "label": "Air Afrique",
      "value": {
        "id": "RK",
        "lcc": "0",
        "name": "Air Afrique",
        "logo": "https://images.kiwi.com/airlines/64/RK.png"
      }
    },
    {
      "label": "Laser Air",
      "value": {
        "id": "QL",
        "lcc": "1",
        "name": "Laser Air",
        "logo": "https://images.kiwi.com/airlines/64/QL.png"
      }
    },
    {
      "label": "BA CityFlyer",
      "value": {
        "id": "CJ",
        "lcc": "0",
        "name": "BA CityFlyer",
        "logo": "https://images.kiwi.com/airlines/64/CJ.png"
      }
    },
    {
      "label": "Colgan Air",
      "value": {
        "id": "9L",
        "lcc": "0",
        "name": "Colgan Air",
        "logo": "https://images.kiwi.com/airlines/64/9L.png"
      }
    },
    {
      "label": "Comair",
      "value": {
        "id": "OH",
        "lcc": "0",
        "name": "Comair",
        "logo": "https://images.kiwi.com/airlines/64/OH.png"
      }
    },
    {
      "label": "Mango",
      "value": {
        "id": "JE",
        "lcc": "1",
        "name": "Mango",
        "logo": "https://images.kiwi.com/airlines/64/JE.png"
      }
    },
    {
      "label": "Thai Airways International",
      "value": {
        "id": "TG",
        "lcc": "0",
        "name": "Thai Airways International",
        "logo": "https://images.kiwi.com/airlines/64/TG.png"
      }
    },
    {
      "label": "Vietnam Airlines",
      "value": {
        "id": "VN",
        "lcc": "0",
        "name": "Vietnam Airlines",
        "logo": "https://images.kiwi.com/airlines/64/VN.png"
      }
    },
    {
      "label": "Boliviana de Aviación",
      "value": {
        "id": "OB",
        "lcc": "1",
        "name": "Boliviana de Aviación",
        "logo": "https://images.kiwi.com/airlines/64/OB.png"
      }
    },
    {
      "label": "Austrian Airlines",
      "value": {
        "id": "OS",
        "lcc": "0",
        "name": "Austrian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/OS.png"
      }
    },
    {
      "label": "Cinnamon Air",
      "value": {
        "id": "C7",
        "lcc": "1",
        "name": "Cinnamon Air",
        "logo": "https://images.kiwi.com/airlines/64/C7.png"
      }
    },
    {
      "label": "VivaAerobus",
      "value": {
        "id": "VB",
        "lcc": "1",
        "name": "VivaAerobus",
        "logo": "https://images.kiwi.com/airlines/64/VB.png"
      }
    },
    {
      "label": "Air Europa",
      "value": {
        "id": "UX",
        "lcc": "0",
        "name": "Air Europa",
        "logo": "https://images.kiwi.com/airlines/64/UX.png"
      }
    },
    {
      "label": "Ryanair",
      "value": {
        "id": "FR",
        "lcc": "1",
        "name": "Ryanair",
        "logo": "https://images.kiwi.com/airlines/64/FR.png"
      }
    },
    {
      "label": "Air Namibia",
      "value": {
        "id": "SW",
        "lcc": "0",
        "name": "Air Namibia",
        "logo": "https://images.kiwi.com/airlines/64/SW.png"
      }
    },
    {
      "label": "WOW air",
      "value": {
        "id": "WW",
        "lcc": "1",
        "name": "WOW air",
        "logo": "https://images.kiwi.com/airlines/64/WW.png"
      }
    },
    {
      "label": "Susi Air",
      "value": {
        "id": "SQS",
        "lcc": "None",
        "name": "Susi Air",
        "logo": "https://images.kiwi.com/airlines/64/SQS.png"
      }
    },
    {
      "label": "Horizon Air",
      "value": {
        "id": "QX",
        "lcc": "0",
        "name": "Horizon Air",
        "logo": "https://images.kiwi.com/airlines/64/QX.png"
      }
    },
    {
      "label": "Proflight Zambia",
      "value": {
        "id": "P0",
        "lcc": "0",
        "name": "Proflight Zambia",
        "logo": "https://images.kiwi.com/airlines/64/P0.png"
      }
    },
    {
      "label": "South African Airways",
      "value": {
        "id": "SA",
        "lcc": "0",
        "name": "South African Airways",
        "logo": "https://images.kiwi.com/airlines/64/SA.png"
      }
    },
    {
      "label": "EuroAtlantic Airways",
      "value": {
        "id": "YU",
        "lcc": "0",
        "name": "EuroAtlantic Airways",
        "logo": "https://images.kiwi.com/airlines/64/YU.png"
      }
    },
    {
      "label": "Nordic Global Airlines",
      "value": {
        "id": "NJ",
        "lcc": "0",
        "name": "Nordic Global Airlines",
        "logo": "https://images.kiwi.com/airlines/64/NJ.png"
      }
    },
    {
      "label": "Central Mountain Air",
      "value": {
        "id": "9M",
        "lcc": "0",
        "name": "Central Mountain Air",
        "logo": "https://images.kiwi.com/airlines/64/9M.png"
      }
    },
    {
      "label": "Nettbuss",
      "value": {
        "id": "NETTBUSS",
        "lcc": "None",
        "name": "Nettbuss",
        "logo": "https://images.kiwi.com/airlines/64/NETTBUSS.png"
      }
    },
    {
      "label": "Aerolitoral",
      "value": {
        "id": "5D",
        "lcc": "0",
        "name": "Aerolitoral",
        "logo": "https://images.kiwi.com/airlines/64/5D.png"
      }
    },
    {
      "label": "Ollex (express)",
      "value": {
        "id": "OLLEX",
        "lcc": "None",
        "name": "Ollex (express)",
        "logo": "https://images.kiwi.com/airlines/64/OLLEX.png"
      }
    },
    {
      "label": "EasyFly",
      "value": {
        "id": "EF",
        "lcc": "1",
        "name": "EasyFly",
        "logo": "https://images.kiwi.com/airlines/64/EF.png"
      }
    },
    {
      "label": "KrasAvia",
      "value": {
        "id": "KI",
        "lcc": "1",
        "name": "KrasAvia",
        "logo": "https://images.kiwi.com/airlines/64/KI.png"
      }
    },
    {
      "label": "LATAM Ecuador",
      "value": {
        "id": "XL",
        "lcc": "0",
        "name": "LATAM Ecuador",
        "logo": "https://images.kiwi.com/airlines/64/XL.png"
      }
    },
    {
      "label": "Carpatair",
      "value": {
        "id": "V3",
        "lcc": "0",
        "name": "Carpatair",
        "logo": "https://images.kiwi.com/airlines/64/V3.png"
      }
    },
    {
      "label": "Rossiya",
      "value": {
        "id": "R4",
        "lcc": "0",
        "name": "Rossiya",
        "logo": "https://images.kiwi.com/airlines/64/R4.png"
      }
    },
    {
      "label": "Air Mandalay",
      "value": {
        "id": "6T",
        "lcc": "1",
        "name": "Air Mandalay",
        "logo": "https://images.kiwi.com/airlines/64/6T.png"
      }
    },
    {
      "label": "Estelar Latinoamerica",
      "value": {
        "id": "ES",
        "lcc": "0",
        "name": "Estelar Latinoamerica",
        "logo": "https://images.kiwi.com/airlines/64/ES.png"
      }
    },
    {
      "label": "Pawa Dominicana",
      "value": {
        "id": "7N",
        "lcc": "0",
        "name": "Pawa Dominicana",
        "logo": "https://images.kiwi.com/airlines/64/7N.png"
      }
    },
    {
      "label": "Ural Airlines",
      "value": {
        "id": "U6",
        "lcc": "1",
        "name": "Ural Airlines",
        "logo": "https://images.kiwi.com/airlines/64/U6.png"
      }
    },
    {
      "label": "SNCF",
      "value": {
        "id": "2C",
        "lcc": "0",
        "name": "SNCF",
        "logo": "https://images.kiwi.com/airlines/64/2C.png"
      }
    },
    {
      "label": "Edelweiss Air",
      "value": {
        "id": "WK",
        "lcc": "0",
        "name": "Edelweiss Air",
        "logo": "https://images.kiwi.com/airlines/64/WK.png"
      }
    },
    {
      "label": "Avanti Air",
      "value": {
        "id": "ATV",
        "lcc": "None",
        "name": "Avanti Air",
        "logo": "https://images.kiwi.com/airlines/64/ATV.png"
      }
    },
    {
      "label": "Air Burkina",
      "value": {
        "id": "2J",
        "lcc": "0",
        "name": "Air Burkina",
        "logo": "https://images.kiwi.com/airlines/64/2J.png"
      }
    },
    {
      "label": "Air Europa express",
      "value": {
        "id": "X5",
        "lcc": "None",
        "name": "Air Europa express",
        "logo": "https://images.kiwi.com/airlines/64/X5.png"
      }
    },
    {
      "label": "Southwest Airlines",
      "value": {
        "id": "WN",
        "lcc": "1",
        "name": "Southwest Airlines",
        "logo": "https://images.kiwi.com/airlines/64/WN.png"
      }
    },
    {
      "label": "CityBusExpress",
      "value": {
        "id": "CITYBUSEXP",
        "lcc": "None",
        "name": "CityBusExpress",
        "logo": "https://images.kiwi.com/airlines/64/CITYBUSEXP.png"
      }
    },
    {
      "label": "Domo Swiss Express",
      "value": {
        "id": "DOMO",
        "lcc": "None",
        "name": "Domo Swiss Express",
        "logo": "https://images.kiwi.com/airlines/64/DOMO.png"
      }
    },
    {
      "label": "Wagner Transport",
      "value": {
        "id": "WAGNERTRNS",
        "lcc": "None",
        "name": "Wagner Transport",
        "logo": "https://images.kiwi.com/airlines/64/WAGNERTRNS.png"
      }
    },
    {
      "label": "Marozzi",
      "value": {
        "id": "MAROZZI",
        "lcc": "None",
        "name": "Marozzi",
        "logo": "https://images.kiwi.com/airlines/64/MAROZZI.png"
      }
    },
    {
      "label": "Spirit Airlines",
      "value": {
        "id": "NK",
        "lcc": "1",
        "name": "Spirit Airlines",
        "logo": "https://images.kiwi.com/airlines/64/NK.png"
      }
    },
    {
      "label": "Aegean",
      "value": {
        "id": "A3",
        "lcc": "1",
        "name": "Aegean",
        "logo": "https://images.kiwi.com/airlines/64/A3.png"
      }
    },
    {
      "label": "Air Cairo",
      "value": {
        "id": "SM",
        "lcc": "1",
        "name": "Air Cairo",
        "logo": "https://images.kiwi.com/airlines/64/SM.png"
      }
    },
    {
      "label": "SaudiGulf Airlines",
      "value": {
        "id": "6S",
        "lcc": "0",
        "name": "SaudiGulf Airlines",
        "logo": "https://images.kiwi.com/airlines/64/6S.png"
      }
    },
    {
      "label": "Kunming Airlines",
      "value": {
        "id": "KY",
        "lcc": "0",
        "name": "Kunming Airlines",
        "logo": "https://images.kiwi.com/airlines/64/KY.png"
      }
    },
    {
      "label": "Wingo airlines",
      "value": {
        "id": "P5",
        "lcc": "0",
        "name": "Wingo airlines",
        "logo": "https://images.kiwi.com/airlines/64/P5.png"
      }
    },
    {
      "label": "Mombasa Air Safari",
      "value": {
        "id": "RRV",
        "lcc": "None",
        "name": "Mombasa Air Safari",
        "logo": "https://images.kiwi.com/airlines/64/RRV.png"
      }
    },
    {
      "label": "Air Jiangxi",
      "value": {
        "id": "RY",
        "lcc": "1",
        "name": "Air Jiangxi",
        "logo": "https://images.kiwi.com/airlines/64/RY.png"
      }
    },
    {
      "label": "Amaszonas Uruguay",
      "value": {
        "id": "Z7",
        "lcc": "1",
        "name": "Amaszonas Uruguay",
        "logo": "https://images.kiwi.com/airlines/64/Z7.png"
      }
    },
    {
      "label": "Skyward Express Limited",
      "value": {
        "id": "OW",
        "lcc": "None",
        "name": "Skyward Express Limited",
        "logo": "https://images.kiwi.com/airlines/64/OW.png"
      }
    },
    {
      "label": "Globtour",
      "value": {
        "id": "GLOBTOUR",
        "lcc": "None",
        "name": "Globtour",
        "logo": "https://images.kiwi.com/airlines/64/GLOBTOUR.png"
      }
    },
    {
      "label": "Gobus",
      "value": {
        "id": "GOBUS",
        "lcc": "None",
        "name": "Gobus",
        "logo": "https://images.kiwi.com/airlines/64/GOBUS.png"
      }
    },
    {
      "label": "Galicja Express",
      "value": {
        "id": "GALICJAEX",
        "lcc": "None",
        "name": "Galicja Express",
        "logo": "https://images.kiwi.com/airlines/64/GALICJAEX.png"
      }
    },
    {
      "label": "Bus4You",
      "value": {
        "id": "BUS4YOU",
        "lcc": "None",
        "name": "Bus4You",
        "logo": "https://images.kiwi.com/airlines/64/BUS4YOU.png"
      }
    },
    {
      "label": "Arriva",
      "value": {
        "id": "ARRIVA",
        "lcc": "None",
        "name": "Arriva",
        "logo": "https://images.kiwi.com/airlines/64/ARRIVA.png"
      }
    },
    {
      "label": "Busplana",
      "value": {
        "id": "BUSPLANA",
        "lcc": "None",
        "name": "Busplana",
        "logo": "https://images.kiwi.com/airlines/64/BUSPLANA.png"
      }
    },
    {
      "label": "Skanetrafiken",
      "value": {
        "id": "SKANETRAF",
        "lcc": "None",
        "name": "Skanetrafiken",
        "logo": "https://images.kiwi.com/airlines/64/SKANETRAF.png"
      }
    },
    {
      "label": "NSB",
      "value": {
        "id": "NSB",
        "lcc": "None",
        "name": "NSB",
        "logo": "https://images.kiwi.com/airlines/64/NSB.png"
      }
    },
    {
      "label": "Vasttrafik",
      "value": {
        "id": "VASTTRAF",
        "lcc": "None",
        "name": "Vasttrafik",
        "logo": "https://images.kiwi.com/airlines/64/VASTTRAF.png"
      }
    },
    {
      "label": "Harbour Air (Priv)",
      "value": {
        "id": "H3",
        "lcc": "0",
        "name": "Harbour Air (Priv)",
        "logo": "https://images.kiwi.com/airlines/64/H3.png"
      }
    },
    {
      "label": "LT Kronoberg",
      "value": {
        "id": "LTKRONOBUS",
        "lcc": "None",
        "name": "LT Kronoberg",
        "logo": "https://images.kiwi.com/airlines/64/LTKRONOBUS.png"
      }
    },
    {
      "label": "Blue Sky Aviation",
      "value": {
        "id": "SBK",
        "lcc": "None",
        "name": "Blue Sky Aviation",
        "logo": "https://images.kiwi.com/airlines/64/SBK.png"
      }
    },
    {
      "label": "Skyway CR",
      "value": {
        "id": "GCS",
        "lcc": "None",
        "name": "Skyway CR",
        "logo": "https://images.kiwi.com/airlines/64/GCS.png"
      }
    },
    {
      "label": "Fly Ulendo",
      "value": {
        "id": "ULENDO",
        "lcc": "None",
        "name": "Fly Ulendo",
        "logo": "https://images.kiwi.com/airlines/64/ULENDO.png"
      }
    },
    {
      "label": "Governors Aviation",
      "value": {
        "id": "GOVERNORS",
        "lcc": "None",
        "name": "Governors Aviation",
        "logo": "https://images.kiwi.com/airlines/64/GOVERNORS.png"
      }
    },
    {
      "label": "Blue Bird Airways",
      "value": {
        "id": "BZ",
        "lcc": "0",
        "name": "Blue Bird Airways",
        "logo": "https://images.kiwi.com/airlines/64/BZ.png"
      }
    },
    {
      "label": "Air Flamenco",
      "value": {
        "id": "F4",
        "lcc": "None",
        "name": "Air Flamenco",
        "logo": "https://images.kiwi.com/airlines/64/F4.png"
      }
    },
    {
      "label": "Stewart Island Flights",
      "value": {
        "id": "STEWISLAND",
        "lcc": "None",
        "name": "Stewart Island Flights",
        "logo": "https://images.kiwi.com/airlines/64/STEWISLAND.png"
      }
    },
    {
      "label": "Air Loyaute",
      "value": {
        "id": "RLY",
        "lcc": "None",
        "name": "Air Loyaute",
        "logo": "https://images.kiwi.com/airlines/64/RLY.png"
      }
    },
    {
      "label": "Divi Divi Air",
      "value": {
        "id": "DVR",
        "lcc": "None",
        "name": "Divi Divi Air",
        "logo": "https://images.kiwi.com/airlines/64/DVR.png"
      }
    },
    {
      "label": "TGV Lyria",
      "value": {
        "id": "TGVLYRIA",
        "lcc": "None",
        "name": "TGV Lyria",
        "logo": "https://images.kiwi.com/airlines/64/TGVLYRIA.png"
      }
    },
    {
      "label": "Thello",
      "value": {
        "id": "THELLO",
        "lcc": "None",
        "name": "Thello",
        "logo": "https://images.kiwi.com/airlines/64/THELLO.png"
      }
    },
    {
      "label": "Grumeti Air",
      "value": {
        "id": "GRUMAIR",
        "lcc": "None",
        "name": "Grumeti Air",
        "logo": "https://images.kiwi.com/airlines/64/GRUMAIR.png"
      }
    },
    {
      "label": "Thalys",
      "value": {
        "id": "THALYS",
        "lcc": "None",
        "name": "Thalys",
        "logo": "https://images.kiwi.com/airlines/64/THALYS.png"
      }
    },
    {
      "label": "Orange2Fly",
      "value": {
        "id": "O4",
        "lcc": "None",
        "name": "Orange2Fly",
        "logo": "https://images.kiwi.com/airlines/64/O4.png"
      }
    },
    {
      "label": "Sky Pasada",
      "value": {
        "id": "SKYPASADA",
        "lcc": "None",
        "name": "Sky Pasada",
        "logo": "https://images.kiwi.com/airlines/64/SKYPASADA.png"
      }
    },
    {
      "label": "NS",
      "value": {
        "id": "NSI",
        "lcc": "None",
        "name": "NS",
        "logo": "https://images.kiwi.com/airlines/64/NSI.png"
      }
    },
    {
      "label": "Russian Railways ",
      "value": {
        "id": "RZD",
        "lcc": "None",
        "name": "Russian Railways ",
        "logo": "https://images.kiwi.com/airlines/64/RZD.png"
      }
    },
    {
      "label": "Amtrak train ",
      "value": {
        "id": "AMTRAKT",
        "lcc": "None",
        "name": "Amtrak train ",
        "logo": "https://images.kiwi.com/airlines/64/AMTRAKT.png"
      }
    },
    {
      "label": "Air Botswana",
      "value": {
        "id": "BP",
        "lcc": "0",
        "name": "Air Botswana",
        "logo": "https://images.kiwi.com/airlines/64/BP.png"
      }
    },
    {
      "label": "Royal Brunei Airlines",
      "value": {
        "id": "BI",
        "lcc": "0",
        "name": "Royal Brunei Airlines",
        "logo": "https://images.kiwi.com/airlines/64/BI.png"
      }
    },
    {
      "label": "Belarusian Railway",
      "value": {
        "id": "BELARURAIL",
        "lcc": "None",
        "name": "Belarusian Railway",
        "logo": "https://images.kiwi.com/airlines/64/BELARURAIL.png"
      }
    },
    {
      "label": "Grand Express",
      "value": {
        "id": "GRANDEXP",
        "lcc": "None",
        "name": "Grand Express",
        "logo": "https://images.kiwi.com/airlines/64/GRANDEXP.png"
      }
    },
    {
      "label": "Comboios de Portugal",
      "value": {
        "id": "COMBOIOS",
        "lcc": "None",
        "name": "Comboios de Portugal",
        "logo": "https://images.kiwi.com/airlines/64/COMBOIOS.png"
      }
    },
    {
      "label": "MAV",
      "value": {
        "id": "MAV",
        "lcc": "None",
        "name": "MAV",
        "logo": "https://images.kiwi.com/airlines/64/MAV.png"
      }
    },
    {
      "label": "SBB",
      "value": {
        "id": "SBB",
        "lcc": "None",
        "name": "SBB",
        "logo": "https://images.kiwi.com/airlines/64/SBB.png"
      }
    },
    {
      "label": "ATRAN Cargo Airlines",
      "value": {
        "id": "V8",
        "lcc": "0",
        "name": "ATRAN Cargo Airlines",
        "logo": "https://images.kiwi.com/airlines/64/V8.png"
      }
    },
    {
      "label": "Air Marshall Islands",
      "value": {
        "id": "CW",
        "lcc": "0",
        "name": "Air Marshall Islands",
        "logo": "https://images.kiwi.com/airlines/64/CW.png"
      }
    },
    {
      "label": " Aero4M",
      "value": {
        "id": "AEH",
        "lcc": "None",
        "name": " Aero4M",
        "logo": "https://images.kiwi.com/airlines/64/AEH.png"
      }
    },
    {
      "label": "Eilat Shuttle",
      "value": {
        "id": "EILAT",
        "lcc": "None",
        "name": "Eilat Shuttle",
        "logo": "https://images.kiwi.com/airlines/64/EILAT.png"
      }
    },
    {
      "label": "Zambezi Airlines (ZMA)",
      "value": {
        "id": "ZJ",
        "lcc": "0",
        "name": "Zambezi Airlines (ZMA)",
        "logo": "https://images.kiwi.com/airlines/64/ZJ.png"
      }
    },
    {
      "label": "Georgian Bus",
      "value": {
        "id": "GEORGIBUS",
        "lcc": "None",
        "name": "Georgian Bus",
        "logo": "https://images.kiwi.com/airlines/64/GEORGIBUS.png"
      }
    },
    {
      "label": "Navette de Vatry",
      "value": {
        "id": "NAVETTE",
        "lcc": "None",
        "name": "Navette de Vatry",
        "logo": "https://images.kiwi.com/airlines/64/NAVETTE.png"
      }
    },
    {
      "label": "Follow me! Interglobus",
      "value": {
        "id": "INTERGLOB",
        "lcc": "None",
        "name": "Follow me! Interglobus",
        "logo": "https://images.kiwi.com/airlines/64/INTERGLOB.png"
      }
    },
    {
      "label": "Miccolis",
      "value": {
        "id": "MICCOLIS",
        "lcc": "None",
        "name": "Miccolis",
        "logo": "https://images.kiwi.com/airlines/64/MICCOLIS.png"
      }
    },
    {
      "label": "Cilento",
      "value": {
        "id": "CILENTO",
        "lcc": "None",
        "name": "Cilento",
        "logo": "https://images.kiwi.com/airlines/64/CILENTO.png"
      }
    },
    {
      "label": "APG Airlines",
      "value": {
        "id": "GP",
        "lcc": "0",
        "name": "APG Airlines",
        "logo": "https://images.kiwi.com/airlines/64/GP.png"
      }
    },
    {
      "label": "LT Kronoberg",
      "value": {
        "id": "LTKRONOTRN",
        "lcc": "None",
        "name": "LT Kronoberg",
        "logo": "https://images.kiwi.com/airlines/64/LTKRONOTRN.png"
      }
    },
    {
      "label": "S7 Airlines",
      "value": {
        "id": "S7",
        "lcc": "0",
        "name": "S7 Airlines",
        "logo": "https://images.kiwi.com/airlines/64/S7.png"
      }
    },
    {
      "label": "Mokulele Flight Service",
      "value": {
        "id": "MW",
        "lcc": "0",
        "name": "Mokulele Flight Service",
        "logo": "https://images.kiwi.com/airlines/64/MW.png"
      }
    },
    {
      "label": "Air Italy",
      "value": {
        "id": "IG",
        "lcc": "1",
        "name": "Air Italy",
        "logo": "https://images.kiwi.com/airlines/64/IG.png"
      }
    },
    {
      "label": "Fly Tristar Services",
      "value": {
        "id": "FLYTRISTAR",
        "lcc": "None",
        "name": "Fly Tristar Services",
        "logo": "https://images.kiwi.com/airlines/64/FLYTRISTAR.png"
      }
    },
    {
      "label": "Primera Air Nordic",
      "value": {
        "id": "6F",
        "lcc": "1",
        "name": "Primera Air Nordic",
        "logo": "https://images.kiwi.com/airlines/64/6F.png"
      }
    },
    {
      "label": "Amaszonas del Paraguay S.A. Lineas Aereas",
      "value": {
        "id": "ZP",
        "lcc": "0",
        "name": "Amaszonas del Paraguay S.A. Lineas Aereas",
        "logo": "https://images.kiwi.com/airlines/64/ZP.png"
      }
    },
    {
      "label": "Emetebe Airlines",
      "value": {
        "id": "EMETEBE",
        "lcc": "None",
        "name": "Emetebe Airlines",
        "logo": "https://images.kiwi.com/airlines/64/EMETEBE.png"
      }
    },
    {
      "label": "As Salaam Air",
      "value": {
        "id": "ASSALAAM",
        "lcc": "None",
        "name": "As Salaam Air",
        "logo": "https://images.kiwi.com/airlines/64/ASSALAAM.png"
      }
    },
    {
      "label": "Silverstone Air",
      "value": {
        "id": "K5",
        "lcc": "0",
        "name": "Silverstone Air",
        "logo": "https://images.kiwi.com/airlines/64/K5.png"
      }
    },
    {
      "label": "AB Aviation",
      "value": {
        "id": "Y6",
        "lcc": "None",
        "name": "AB Aviation",
        "logo": "https://images.kiwi.com/airlines/64/Y6.png"
      }
    },
    {
      "label": "Unity Air",
      "value": {
        "id": "UNIAIR",
        "lcc": "None",
        "name": "Unity Air",
        "logo": "https://images.kiwi.com/airlines/64/UNIAIR.png"
      }
    },
    {
      "label": "Eurostar",
      "value": {
        "id": "EUROSTAR",
        "lcc": "None",
        "name": "Eurostar",
        "logo": "https://images.kiwi.com/airlines/64/EUROSTAR.png"
      }
    },
    {
      "label": "Fly Safari Airlink",
      "value": {
        "id": "FLYSAFARI",
        "lcc": "None",
        "name": "Fly Safari Airlink",
        "logo": "https://images.kiwi.com/airlines/64/FLYSAFARI.png"
      }
    },
    {
      "label": "Rhônexpress",
      "value": {
        "id": "RHONEXP",
        "lcc": "None",
        "name": "Rhônexpress",
        "logo": "https://images.kiwi.com/airlines/64/RHONEXP.png"
      }
    },
    {
      "label": "Caspian Airlines",
      "value": {
        "id": "RV",
        "lcc": "0",
        "name": "Caspian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/RV.png"
      }
    },
    {
      "label": "Centralwings",
      "value": {
        "id": "C0",
        "lcc": "0",
        "name": "Centralwings",
        "logo": "https://images.kiwi.com/airlines/64/C0.png"
      }
    },
    {
      "label": "Golden Myanmar Airlines",
      "value": {
        "id": "Y5",
        "lcc": "1",
        "name": "Golden Myanmar Airlines",
        "logo": "https://images.kiwi.com/airlines/64/Y5.png"
      }
    },
    {
      "label": "Yeti Airways",
      "value": {
        "id": "YT",
        "lcc": "0",
        "name": "Yeti Airways",
        "logo": "https://images.kiwi.com/airlines/64/YT.png"
      }
    },
    {
      "label": "SMS Flughafen",
      "value": {
        "id": "SMSFLUG",
        "lcc": "None",
        "name": "SMS Flughafen",
        "logo": "https://images.kiwi.com/airlines/64/SMSFLUG.png"
      }
    },
    {
      "label": "Minibud Ltd.",
      "value": {
        "id": "MINIBUD",
        "lcc": "None",
        "name": "Minibud Ltd.",
        "logo": "https://images.kiwi.com/airlines/64/MINIBUD.png"
      }
    },
    {
      "label": "Flightlink",
      "value": {
        "id": "FLZ",
        "lcc": "None",
        "name": "Flightlink",
        "logo": "https://images.kiwi.com/airlines/64/FLZ.png"
      }
    },
    {
      "label": "Madagasikara Airways",
      "value": {
        "id": "7D",
        "lcc": "None",
        "name": "Madagasikara Airways",
        "logo": "https://images.kiwi.com/airlines/64/7D.png"
      }
    },
    {
      "label": "Grenadine Airways",
      "value": {
        "id": "GRENADINE",
        "lcc": "None",
        "name": "Grenadine Airways",
        "logo": "https://images.kiwi.com/airlines/64/GRENADINE.png"
      }
    },
    {
      "label": "Aerobus Lisbon",
      "value": {
        "id": "AEROBUSLIS",
        "lcc": "None",
        "name": "Aerobus Lisbon",
        "logo": "https://images.kiwi.com/airlines/64/AEROBUSLIS.png"
      }
    },
    {
      "label": "CFL",
      "value": {
        "id": "CFLB",
        "lcc": "None",
        "name": "CFL",
        "logo": "https://images.kiwi.com/airlines/64/CFLB.png"
      }
    },
    {
      "label": "Megabus",
      "value": {
        "id": "MEGABUS",
        "lcc": "None",
        "name": "Megabus",
        "logo": "https://images.kiwi.com/airlines/64/MEGABUS.png"
      }
    },
    {
      "label": "Get Bus",
      "value": {
        "id": "GETBUS",
        "lcc": "None",
        "name": "Get Bus",
        "logo": "https://images.kiwi.com/airlines/64/GETBUS.png"
      }
    },
    {
      "label": "Sit Bus Shuttle",
      "value": {
        "id": "SITBUS",
        "lcc": "None",
        "name": "Sit Bus Shuttle",
        "logo": "https://images.kiwi.com/airlines/64/SITBUS.png"
      }
    },
    {
      "label": "Air Changan",
      "value": {
        "id": "9H",
        "lcc": "0",
        "name": "Air Changan",
        "logo": "https://images.kiwi.com/airlines/64/9H.png"
      }
    },
    {
      "label": "Wasaya Airways",
      "value": {
        "id": "WT",
        "lcc": "None",
        "name": "Wasaya Airways",
        "logo": "https://images.kiwi.com/airlines/64/WT.png"
      }
    },
    {
      "label": "Bhutan Airlines",
      "value": {
        "id": "B3",
        "lcc": "0",
        "name": "Bhutan Airlines",
        "logo": "https://images.kiwi.com/airlines/64/B3.png"
      }
    },
    {
      "label": "Amtrak bus",
      "value": {
        "id": "AMTRAKB",
        "lcc": "None",
        "name": "Amtrak bus",
        "logo": "https://images.kiwi.com/airlines/64/AMTRAKB.png"
      }
    },
    {
      "label": "100Rumos",
      "value": {
        "id": "100RUMOS",
        "lcc": "None",
        "name": "100Rumos",
        "logo": "https://images.kiwi.com/airlines/64/100RUMOS.png"
      }
    },
    {
      "label": "Hoosier ride",
      "value": {
        "id": "HOOSIER",
        "lcc": "None",
        "name": "Hoosier ride",
        "logo": "https://images.kiwi.com/airlines/64/HOOSIER.png"
      }
    },
    {
      "label": "DSB",
      "value": {
        "id": "DSB",
        "lcc": "None",
        "name": "DSB",
        "logo": "https://images.kiwi.com/airlines/64/DSB.png"
      }
    },
    {
      "label": "Great Dane Airlines",
      "value": {
        "id": "GDE",
        "lcc": "None",
        "name": "Great Dane Airlines",
        "logo": "https://images.kiwi.com/airlines/64/GDE.png"
      }
    },
    {
      "label": "QuickLlama",
      "value": {
        "id": "QUICKLLAMA",
        "lcc": "None",
        "name": "QuickLlama",
        "logo": "https://images.kiwi.com/airlines/64/QUICKLLAMA.png"
      }
    },
    {
      "label": "Skytrans",
      "value": {
        "id": "QN",
        "lcc": "None",
        "name": "Skytrans",
        "logo": "https://images.kiwi.com/airlines/64/QN.png"
      }
    },
    {
      "label": "Salt Lake Express",
      "value": {
        "id": "SALTLAKEEX",
        "lcc": "None",
        "name": "Salt Lake Express",
        "logo": "https://images.kiwi.com/airlines/64/SALTLAKEEX.png"
      }
    },
    {
      "label": "Adirondack Trailways",
      "value": {
        "id": "ADIRONDACK",
        "lcc": "None",
        "name": "Adirondack Trailways",
        "logo": "https://images.kiwi.com/airlines/64/ADIRONDACK.png"
      }
    },
    {
      "label": "Avies",
      "value": {
        "id": "U3",
        "lcc": "0",
        "name": "Avies",
        "logo": "https://images.kiwi.com/airlines/64/U3.png"
      }
    },
    {
      "label": "Fly Ais Airlines",
      "value": {
        "id": "IS",
        "lcc": "0",
        "name": "Fly Ais Airlines",
        "logo": "https://images.kiwi.com/airlines/64/IS.png"
      }
    },
    {
      "label": "Azerbaijan Airlines",
      "value": {
        "id": "J2",
        "lcc": "0",
        "name": "Azerbaijan Airlines",
        "logo": "https://images.kiwi.com/airlines/64/J2.png"
      }
    },
    {
      "label": "Icelandair",
      "value": {
        "id": "FI",
        "lcc": "0",
        "name": "Icelandair",
        "logo": "https://images.kiwi.com/airlines/64/FI.png"
      }
    },
    {
      "label": "Pelican Airlines",
      "value": {
        "id": "FP",
        "lcc": "0",
        "name": "Pelican Airlines",
        "logo": "https://images.kiwi.com/airlines/64/FP.png"
      }
    },
    {
      "label": "Aerolink Uganda",
      "value": {
        "id": "A8",
        "lcc": "None",
        "name": "Aerolink Uganda",
        "logo": "https://images.kiwi.com/airlines/64/A8.png"
      }
    },
    {
      "label": "CR Aviation",
      "value": {
        "id": "CRAVIATION",
        "lcc": "None",
        "name": "CR Aviation",
        "logo": "https://images.kiwi.com/airlines/64/CRAVIATION.png"
      }
    },
    {
      "label": "Air Excel Limited",
      "value": {
        "id": "XLL",
        "lcc": "None",
        "name": "Air Excel Limited",
        "logo": "https://images.kiwi.com/airlines/64/XLL.png"
      }
    },
    {
      "label": "Fly Baghdad Airlines",
      "value": {
        "id": "IF",
        "lcc": "0",
        "name": "Fly Baghdad Airlines",
        "logo": "https://images.kiwi.com/airlines/64/IF.png"
      }
    },
    {
      "label": "Airport Supersaver",
      "value": {
        "id": "ARPTSUPERS",
        "lcc": "None",
        "name": "Airport Supersaver",
        "logo": "https://images.kiwi.com/airlines/64/ARPTSUPERS.png"
      }
    },
    {
      "label": "Shandong Airlines",
      "value": {
        "id": "SC",
        "lcc": "0",
        "name": "Shandong Airlines",
        "logo": "https://images.kiwi.com/airlines/64/SC.png"
      }
    },
    {
      "label": "Yakutia Airlines",
      "value": {
        "id": "R3",
        "lcc": "1",
        "name": "Yakutia Airlines",
        "logo": "https://images.kiwi.com/airlines/64/R3.png"
      }
    },
    {
      "label": "Prestia e Comande",
      "value": {
        "id": "PRESTIA",
        "lcc": "None",
        "name": "Prestia e Comande",
        "logo": "https://images.kiwi.com/airlines/64/PRESTIA.png"
      }
    },
    {
      "label": "Beauvaisbus",
      "value": {
        "id": "BEAUVAIS",
        "lcc": "None",
        "name": "Beauvaisbus",
        "logo": "https://images.kiwi.com/airlines/64/BEAUVAIS.png"
      }
    },
    {
      "label": "Flybus Iceland",
      "value": {
        "id": "FLYBUS",
        "lcc": "None",
        "name": "Flybus Iceland",
        "logo": "https://images.kiwi.com/airlines/64/FLYBUS.png"
      }
    },
    {
      "label": "Airport Bus Express",
      "value": {
        "id": "AIRBEXP",
        "lcc": "None",
        "name": "Airport Bus Express",
        "logo": "https://images.kiwi.com/airlines/64/AIRBEXP.png"
      }
    },
    {
      "label": "CFL",
      "value": {
        "id": "CFLT",
        "lcc": "None",
        "name": "CFL",
        "logo": "https://images.kiwi.com/airlines/64/CFLT.png"
      }
    },
    {
      "label": "RACSA",
      "value": {
        "id": "R6",
        "lcc": "0",
        "name": "RACSA",
        "logo": "https://images.kiwi.com/airlines/64/R6.png"
      }
    },
    {
      "label": "Republic Airlines",
      "value": {
        "id": "RW",
        "lcc": "0",
        "name": "Republic Airlines",
        "logo": "https://images.kiwi.com/airlines/64/RW.png"
      }
    },
    {
      "label": "Skyways Express",
      "value": {
        "id": "JZ",
        "lcc": "0",
        "name": "Skyways Express",
        "logo": "https://images.kiwi.com/airlines/64/JZ.png"
      }
    },
    {
      "label": "Thai Air Cargo",
      "value": {
        "id": "T2",
        "lcc": "0",
        "name": "Thai Air Cargo",
        "logo": "https://images.kiwi.com/airlines/64/T2.png"
      }
    },
    {
      "label": "CanXplorer",
      "value": {
        "id": "C2",
        "lcc": "0",
        "name": "CanXplorer",
        "logo": "https://images.kiwi.com/airlines/64/C2.png"
      }
    },
    {
      "label": "Virginia Breeze",
      "value": {
        "id": "VIRGINIABR",
        "lcc": "None",
        "name": "Virginia Breeze",
        "logo": "https://images.kiwi.com/airlines/64/VIRGINIABR.png"
      }
    },
    {
      "label": "NYC Airporter",
      "value": {
        "id": "NYCAIRPORT",
        "lcc": "None",
        "name": "NYC Airporter",
        "logo": "https://images.kiwi.com/airlines/64/NYCAIRPORT.png"
      }
    },
    {
      "label": "Michael Airlines",
      "value": {
        "id": "DF",
        "lcc": "0",
        "name": "Michael Airlines",
        "logo": "https://images.kiwi.com/airlines/64/DF.png"
      }
    },
    {
      "label": "40-Mile Air",
      "value": {
        "id": "Q5",
        "lcc": "0",
        "name": "40-Mile Air",
        "logo": "https://images.kiwi.com/airlines/64/Q5.png"
      }
    },
    {
      "label": "Air Japan",
      "value": {
        "id": "NQ",
        "lcc": "0",
        "name": "Air Japan",
        "logo": "https://images.kiwi.com/airlines/64/NQ.png"
      }
    },
    {
      "label": "Iran Aseman Airlines",
      "value": {
        "id": "EP",
        "lcc": "0",
        "name": "Iran Aseman Airlines",
        "logo": "https://images.kiwi.com/airlines/64/EP.png"
      }
    },
    {
      "label": "Solomon Airlines",
      "value": {
        "id": "IE",
        "lcc": "0",
        "name": "Solomon Airlines",
        "logo": "https://images.kiwi.com/airlines/64/IE.png"
      }
    },
    {
      "label": "Sharp Airlines",
      "value": {
        "id": "SH",
        "lcc": "0",
        "name": "Sharp Airlines",
        "logo": "https://images.kiwi.com/airlines/64/SH.png"
      }
    },
    {
      "label": "Park's of Hamilton",
      "value": {
        "id": "PARKSOFHAM",
        "lcc": "None",
        "name": "Park's of Hamilton",
        "logo": "https://images.kiwi.com/airlines/64/PARKSOFHAM.png"
      }
    },
    {
      "label": "Aerovías DAP",
      "value": {
        "id": "V5",
        "lcc": "0",
        "name": "Aerovías DAP",
        "logo": "https://images.kiwi.com/airlines/64/V5.png"
      }
    },
    {
      "label": "Air Kiribati",
      "value": {
        "id": "4A",
        "lcc": "0",
        "name": "Air Kiribati",
        "logo": "https://images.kiwi.com/airlines/64/4A.png"
      }
    },
    {
      "label": "Turkmenistan Airlines",
      "value": {
        "id": "T5",
        "lcc": "0",
        "name": "Turkmenistan Airlines",
        "logo": "https://images.kiwi.com/airlines/64/T5.png"
      }
    },
    {
      "label": "Vladivostok Air",
      "value": {
        "id": "XF",
        "lcc": "0",
        "name": "Vladivostok Air",
        "logo": "https://images.kiwi.com/airlines/64/XF.png"
      }
    },
    {
      "label": "Varig Log",
      "value": {
        "id": "LC",
        "lcc": "0",
        "name": "Varig Log",
        "logo": "https://images.kiwi.com/airlines/64/LC.png"
      }
    },
    {
      "label": "Windrose Airlines",
      "value": {
        "id": "7W",
        "lcc": "0",
        "name": "Windrose Airlines",
        "logo": "https://images.kiwi.com/airlines/64/7W.png"
      }
    },
    {
      "label": "ASKY Airlines",
      "value": {
        "id": "KP",
        "lcc": "0",
        "name": "ASKY Airlines",
        "logo": "https://images.kiwi.com/airlines/64/KP.png"
      }
    },
    {
      "label": "PKP Intercity",
      "value": {
        "id": "PKP",
        "lcc": "None",
        "name": "PKP Intercity",
        "logo": "https://images.kiwi.com/airlines/64/PKP.png"
      }
    },
    {
      "label": "Ernest Airlines",
      "value": {
        "id": "EG",
        "lcc": "0",
        "name": "Ernest Airlines",
        "logo": "https://images.kiwi.com/airlines/64/EG.png"
      }
    },
    {
      "label": "Maltatransfer",
      "value": {
        "id": "MALTATRANS",
        "lcc": "None",
        "name": "Maltatransfer",
        "logo": "https://images.kiwi.com/airlines/64/MALTATRANS.png"
      }
    },
    {
      "label": "America West Airlines",
      "value": {
        "id": "HP",
        "lcc": "0",
        "name": "America West Airlines",
        "logo": "https://images.kiwi.com/airlines/64/HP.png"
      }
    },
    {
      "label": "Etihad Regional",
      "value": {
        "id": "F7",
        "lcc": "0",
        "name": "Etihad Regional",
        "logo": "https://images.kiwi.com/airlines/64/F7.png"
      }
    },
    {
      "label": "Israir",
      "value": {
        "id": "6H",
        "lcc": "0",
        "name": "Israir",
        "logo": "https://images.kiwi.com/airlines/64/6H.png"
      }
    },
    {
      "label": "Scoot - old",
      "value": {
        "id": "TZ",
        "lcc": "1",
        "name": "Scoot - old",
        "logo": "https://images.kiwi.com/airlines/64/TZ.png"
      }
    },
    {
      "label": "Avianca Honduras",
      "value": {
        "id": "WC",
        "lcc": "0",
        "name": "Avianca Honduras",
        "logo": "https://images.kiwi.com/airlines/64/WC.png"
      }
    },
    {
      "label": "Thomas Cook Airlines",
      "value": {
        "id": "MT",
        "lcc": "1",
        "name": "Thomas Cook Airlines",
        "logo": "https://images.kiwi.com/airlines/64/MT.png"
      }
    },
    {
      "label": "Alsie Express",
      "value": {
        "id": "6I",
        "lcc": "0",
        "name": "Alsie Express",
        "logo": "https://images.kiwi.com/airlines/64/6I.png"
      }
    },
    {
      "label": "Jet Konnect",
      "value": {
        "id": "S2",
        "lcc": "0",
        "name": "Jet Konnect",
        "logo": "https://images.kiwi.com/airlines/64/S2.png"
      }
    },
    {
      "label": "Batik Air",
      "value": {
        "id": "ID",
        "lcc": "1",
        "name": "Batik Air",
        "logo": "https://images.kiwi.com/airlines/64/ID.png"
      }
    },
    {
      "label": "Thai Vietjet",
      "value": {
        "id": "VZ",
        "lcc": "0",
        "name": "Thai Vietjet",
        "logo": "https://images.kiwi.com/airlines/64/VZ.png"
      }
    },
    {
      "label": "Iberia Express",
      "value": {
        "id": "I2",
        "lcc": "0",
        "name": "Iberia Express",
        "logo": "https://images.kiwi.com/airlines/64/I2.png"
      }
    },
    {
      "label": "Uzbekistan Airways",
      "value": {
        "id": "HY",
        "lcc": "0",
        "name": "Uzbekistan Airways",
        "logo": "https://images.kiwi.com/airlines/64/HY.png"
      }
    },
    {
      "label": "BMC Aerobus",
      "value": {
        "id": "BMCAEROBUS",
        "lcc": "None",
        "name": "BMC Aerobus",
        "logo": "https://images.kiwi.com/airlines/64/BMCAEROBUS.png"
      }
    },
    {
      "label": "Astral Aviation",
      "value": {
        "id": "8V",
        "lcc": "0",
        "name": "Astral Aviation",
        "logo": "https://images.kiwi.com/airlines/64/8V.png"
      }
    },
    {
      "label": "Air Tindi",
      "value": {
        "id": "8T",
        "lcc": "0",
        "name": "Air Tindi",
        "logo": "https://images.kiwi.com/airlines/64/8T.png"
      }
    },
    {
      "label": "Air Wisconsin",
      "value": {
        "id": "ZW",
        "lcc": "0",
        "name": "Air Wisconsin",
        "logo": "https://images.kiwi.com/airlines/64/ZW.png"
      }
    },
    {
      "label": "Itek Air",
      "value": {
        "id": "GI",
        "lcc": "0",
        "name": "Itek Air",
        "logo": "https://images.kiwi.com/airlines/64/GI.png"
      }
    },
    {
      "label": "Beijing Capital Airlines",
      "value": {
        "id": "JD",
        "lcc": "0",
        "name": "Beijing Capital Airlines",
        "logo": "https://images.kiwi.com/airlines/64/JD.png"
      }
    },
    {
      "label": "Vision Airlines",
      "value": {
        "id": "V2",
        "lcc": "0",
        "name": "Vision Airlines",
        "logo": "https://images.kiwi.com/airlines/64/V2.png"
      }
    },
    {
      "label": "V Air",
      "value": {
        "id": "ZV",
        "lcc": "1",
        "name": "V Air",
        "logo": "https://images.kiwi.com/airlines/64/ZV.png"
      }
    },
    {
      "label": "Tatarstan Airlines",
      "value": {
        "id": "U9",
        "lcc": "0",
        "name": "Tatarstan Airlines",
        "logo": "https://images.kiwi.com/airlines/64/U9.png"
      }
    },
    {
      "label": "JAL Express",
      "value": {
        "id": "JC",
        "lcc": "0",
        "name": "JAL Express",
        "logo": "https://images.kiwi.com/airlines/64/JC.png"
      }
    },
    {
      "label": "Myanmar National Airlines",
      "value": {
        "id": "UB",
        "lcc": "0",
        "name": "Myanmar National Airlines",
        "logo": "https://images.kiwi.com/airlines/64/UB.png"
      }
    },
    {
      "label": "EasyJet (DS)",
      "value": {
        "id": "DS",
        "lcc": "0",
        "name": "EasyJet (DS)",
        "logo": "https://images.kiwi.com/airlines/64/DS.png"
      }
    },
    {
      "label": "Orbest",
      "value": {
        "id": "6O",
        "lcc": "0",
        "name": "Orbest",
        "logo": "https://images.kiwi.com/airlines/64/6O.png"
      }
    },
    {
      "label": "Air Creebec",
      "value": {
        "id": "YN",
        "lcc": "0",
        "name": "Air Creebec",
        "logo": "https://images.kiwi.com/airlines/64/YN.png"
      }
    },
    {
      "label": "LAM Mozambique Airlines",
      "value": {
        "id": "TM",
        "lcc": "0",
        "name": "LAM Mozambique Airlines",
        "logo": "https://images.kiwi.com/airlines/64/TM.png"
      }
    },
    {
      "label": "Federal Airlines",
      "value": {
        "id": "7V",
        "lcc": "0",
        "name": "Federal Airlines",
        "logo": "https://images.kiwi.com/airlines/64/7V.png"
      }
    },
    {
      "label": "Air Libert",
      "value": {
        "id": "VD",
        "lcc": "0",
        "name": "Air Libert",
        "logo": "https://images.kiwi.com/airlines/64/VD.png"
      }
    },
    {
      "label": "JALways",
      "value": {
        "id": "JO",
        "lcc": "0",
        "name": "JALways",
        "logo": "https://images.kiwi.com/airlines/64/JO.png"
      }
    },
    {
      "label": "Eurowings Europe",
      "value": {
        "id": "E2",
        "lcc": "0",
        "name": "Eurowings Europe",
        "logo": "https://images.kiwi.com/airlines/64/E2.png"
      }
    },
    {
      "label": "Provincial Airlines",
      "value": {
        "id": "PB",
        "lcc": "0",
        "name": "Provincial Airlines",
        "logo": "https://images.kiwi.com/airlines/64/PB.png"
      }
    },
    {
      "label": "Vieques Air Link",
      "value": {
        "id": "V4",
        "lcc": "0",
        "name": "Vieques Air Link",
        "logo": "https://images.kiwi.com/airlines/64/V4.png"
      }
    },
    {
      "label": "Regional Sky",
      "value": {
        "id": "P1",
        "lcc": "0",
        "name": "Regional Sky",
        "logo": "https://images.kiwi.com/airlines/64/P1.png"
      }
    },
    {
      "label": "VASCO",
      "value": {
        "id": "0V",
        "lcc": "0",
        "name": "VASCO",
        "logo": "https://images.kiwi.com/airlines/64/0V.png"
      }
    },
    {
      "label": "Air Niugini",
      "value": {
        "id": "PX",
        "lcc": "0",
        "name": "Air Niugini",
        "logo": "https://images.kiwi.com/airlines/64/PX.png"
      }
    },
    {
      "label": "Nomad Aviation",
      "value": {
        "id": "N6",
        "lcc": "0",
        "name": "Nomad Aviation",
        "logo": "https://images.kiwi.com/airlines/64/N6.png"
      }
    },
    {
      "label": "EWA Air",
      "value": {
        "id": "ZD",
        "lcc": "0",
        "name": "EWA Air",
        "logo": "https://images.kiwi.com/airlines/64/ZD.png"
      }
    },
    {
      "label": "Allied Air",
      "value": {
        "id": "4W",
        "lcc": "0",
        "name": "Allied Air",
        "logo": "https://images.kiwi.com/airlines/64/4W.png"
      }
    },
    {
      "label": "Viva Air",
      "value": {
        "id": "VH",
        "lcc": "0",
        "name": "Viva Air",
        "logo": "https://images.kiwi.com/airlines/64/VH.png"
      }
    },
    {
      "label": "Ouigo",
      "value": {
        "id": "OUIGO",
        "lcc": "0",
        "name": "Ouigo",
        "logo": "https://images.kiwi.com/airlines/64/OUIGO.png"
      }
    },
    {
      "label": "Slovak rail",
      "value": {
        "id": "ZSSK",
        "lcc": "None",
        "name": "Slovak rail",
        "logo": "https://images.kiwi.com/airlines/64/ZSSK.png"
      }
    },
    {
      "label": "Air Mediterranee",
      "value": {
        "id": "ML",
        "lcc": "1",
        "name": "Air Mediterranee",
        "logo": "https://images.kiwi.com/airlines/64/ML.png"
      }
    },
    {
      "label": "Baltic Air lines",
      "value": {
        "id": "B1",
        "lcc": "0",
        "name": "Baltic Air lines",
        "logo": "https://images.kiwi.com/airlines/64/B1.png"
      }
    },
    {
      "label": "Transavia France",
      "value": {
        "id": "TO",
        "lcc": "0",
        "name": "Transavia France",
        "logo": "https://images.kiwi.com/airlines/64/TO.png"
      }
    },
    {
      "label": "Peruvian Airlines",
      "value": {
        "id": "P9",
        "lcc": "1",
        "name": "Peruvian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/P9.png"
      }
    },
    {
      "label": "JetSMART",
      "value": {
        "id": "JA",
        "lcc": "0",
        "name": "JetSMART",
        "logo": "https://images.kiwi.com/airlines/64/JA.png"
      }
    },
    {
      "label": "Tiger Airways Australia",
      "value": {
        "id": "TT",
        "lcc": "1",
        "name": "Tiger Airways Australia",
        "logo": "https://images.kiwi.com/airlines/64/TT.png"
      }
    },
    {
      "label": "Alrosa",
      "value": {
        "id": "6R",
        "lcc": "1",
        "name": "Alrosa",
        "logo": "https://images.kiwi.com/airlines/64/6R.png"
      }
    },
    {
      "label": "One Jet",
      "value": {
        "id": "J1",
        "lcc": "0",
        "name": "One Jet",
        "logo": "https://images.kiwi.com/airlines/64/J1.png"
      }
    },
    {
      "label": "Insel Air",
      "value": {
        "id": "7I",
        "lcc": "1",
        "name": "Insel Air",
        "logo": "https://images.kiwi.com/airlines/64/7I.png"
      }
    },
    {
      "label": "Tajik Air",
      "value": {
        "id": "7J",
        "lcc": "1",
        "name": "Tajik Air",
        "logo": "https://images.kiwi.com/airlines/64/7J.png"
      }
    },
    {
      "label": "Avianca",
      "value": {
        "id": "AV",
        "lcc": "0",
        "name": "Avianca",
        "logo": "https://images.kiwi.com/airlines/64/AV.png"
      }
    },
    {
      "label": "Germania",
      "value": {
        "id": "ST",
        "lcc": "1",
        "name": "Germania",
        "logo": "https://images.kiwi.com/airlines/64/ST.png"
      }
    },
    {
      "label": "Brit Air",
      "value": {
        "id": "DB",
        "lcc": "0",
        "name": "Brit Air",
        "logo": "https://images.kiwi.com/airlines/64/DB.png"
      }
    },
    {
      "label": "Air Serbia",
      "value": {
        "id": "JU",
        "lcc": "0",
        "name": "Air Serbia",
        "logo": "https://images.kiwi.com/airlines/64/JU.png"
      }
    },
    {
      "label": "Safarilink Aviation",
      "value": {
        "id": "F2",
        "lcc": "0",
        "name": "Safarilink Aviation",
        "logo": "https://images.kiwi.com/airlines/64/F2.png"
      }
    },
    {
      "label": "SriLankan Airlines",
      "value": {
        "id": "UL",
        "lcc": "0",
        "name": "SriLankan Airlines",
        "logo": "https://images.kiwi.com/airlines/64/UL.png"
      }
    },
    {
      "label": "Tropic Air Limited",
      "value": {
        "id": "9N",
        "lcc": "0",
        "name": "Tropic Air Limited",
        "logo": "https://images.kiwi.com/airlines/64/9N.png"
      }
    },
    {
      "label": "West Air China",
      "value": {
        "id": "PN",
        "lcc": "1",
        "name": "West Air China",
        "logo": "https://images.kiwi.com/airlines/64/PN.png"
      }
    },
    {
      "label": "Hong Kong Airlines",
      "value": {
        "id": "HX",
        "lcc": "1",
        "name": "Hong Kong Airlines",
        "logo": "https://images.kiwi.com/airlines/64/HX.png"
      }
    },
    {
      "label": "Afrijet Business Service",
      "value": {
        "id": "J7",
        "lcc": "1",
        "name": "Afrijet Business Service",
        "logo": "https://images.kiwi.com/airlines/64/J7.png"
      }
    },
    {
      "label": "Africa World Airlines",
      "value": {
        "id": "AW",
        "lcc": "1",
        "name": "Africa World Airlines",
        "logo": "https://images.kiwi.com/airlines/64/AW.png"
      }
    },
    {
      "label": "Ellinair",
      "value": {
        "id": "EL",
        "lcc": "1",
        "name": "Ellinair",
        "logo": "https://images.kiwi.com/airlines/64/EL.png"
      }
    },
    {
      "label": "Ruili Airlines",
      "value": {
        "id": "DR",
        "lcc": "1",
        "name": "Ruili Airlines",
        "logo": "https://images.kiwi.com/airlines/64/DR.png"
      }
    },
    {
      "label": "KrasAvia (old iata)",
      "value": {
        "id": "K9",
        "lcc": "1",
        "name": "KrasAvia (old iata)",
        "logo": "https://images.kiwi.com/airlines/64/K9.png"
      }
    },
    {
      "label": "TUI Airlines Netherlands",
      "value": {
        "id": "OR",
        "lcc": "1",
        "name": "TUI Airlines Netherlands",
        "logo": "https://images.kiwi.com/airlines/64/OR.png"
      }
    },
    {
      "label": "Nature Air",
      "value": {
        "id": "5C",
        "lcc": "1",
        "name": "Nature Air",
        "logo": "https://images.kiwi.com/airlines/64/5C.png"
      }
    },
    {
      "label": "Lucky air",
      "value": {
        "id": "8L",
        "lcc": "1",
        "name": "Lucky air",
        "logo": "https://images.kiwi.com/airlines/64/8L.png"
      }
    },
    {
      "label": "Wamos Air",
      "value": {
        "id": "EB",
        "lcc": "1",
        "name": "Wamos Air",
        "logo": "https://images.kiwi.com/airlines/64/EB.png"
      }
    },
    {
      "label": "WestJet",
      "value": {
        "id": "WS",
        "lcc": "1",
        "name": "WestJet",
        "logo": "https://images.kiwi.com/airlines/64/WS.png"
      }
    },
    {
      "label": "Yamal Air",
      "value": {
        "id": "YC",
        "lcc": "1",
        "name": "Yamal Air",
        "logo": "https://images.kiwi.com/airlines/64/YC.png"
      }
    },
    {
      "label": "Jambojet",
      "value": {
        "id": "JX",
        "lcc": "1",
        "name": "Jambojet",
        "logo": "https://images.kiwi.com/airlines/64/JX.png"
      }
    },
    {
      "label": "ASL Airlines France",
      "value": {
        "id": "5O",
        "lcc": "0",
        "name": "ASL Airlines France",
        "logo": "https://images.kiwi.com/airlines/64/5O.png"
      }
    },
    {
      "label": "SkyWise",
      "value": {
        "id": "S8",
        "lcc": "1",
        "name": "SkyWise",
        "logo": "https://images.kiwi.com/airlines/64/S8.png"
      }
    },
    {
      "label": "Jefferson Lines",
      "value": {
        "id": "JEFFERSON",
        "lcc": "None",
        "name": "Jefferson Lines",
        "logo": "https://images.kiwi.com/airlines/64/JEFFERSON.png"
      }
    },
    {
      "label": "Spicejet",
      "value": {
        "id": "SG",
        "lcc": "1",
        "name": "Spicejet",
        "logo": "https://images.kiwi.com/airlines/64/SG.png"
      }
    },
    {
      "label": "flynas",
      "value": {
        "id": "XY",
        "lcc": "1",
        "name": "flynas",
        "logo": "https://images.kiwi.com/airlines/64/XY.png"
      }
    },
    {
      "label": "Spring Airlines",
      "value": {
        "id": "9C",
        "lcc": "1",
        "name": "Spring Airlines",
        "logo": "https://images.kiwi.com/airlines/64/9C.png"
      }
    },
    {
      "label": "Scat Airlines",
      "value": {
        "id": "DV",
        "lcc": "1",
        "name": "Scat Airlines",
        "logo": "https://images.kiwi.com/airlines/64/DV.png"
      }
    },
    {
      "label": "Air Guilin",
      "value": {
        "id": "GT",
        "lcc": "1",
        "name": "Air Guilin",
        "logo": "https://images.kiwi.com/airlines/64/GT.png"
      }
    },
    {
      "label": "Air Berlin",
      "value": {
        "id": "AB",
        "lcc": "1",
        "name": "Air Berlin",
        "logo": "https://images.kiwi.com/airlines/64/AB.png"
      }
    },
    {
      "label": "Jet Airways",
      "value": {
        "id": "9W",
        "lcc": "0",
        "name": "Jet Airways",
        "logo": "https://images.kiwi.com/airlines/64/9W.png"
      }
    },
    {
      "label": "Myway Airlines",
      "value": {
        "id": "MJ",
        "lcc": "1",
        "name": "Myway Airlines",
        "logo": "https://images.kiwi.com/airlines/64/MJ.png"
      }
    },
    {
      "label": "VietJet Air",
      "value": {
        "id": "VJ",
        "lcc": "1",
        "name": "VietJet Air",
        "logo": "https://images.kiwi.com/airlines/64/VJ.png"
      }
    },
    {
      "label": "Alas Uruguay",
      "value": {
        "id": "YZ",
        "lcc": "0",
        "name": "Alas Uruguay",
        "logo": "https://images.kiwi.com/airlines/64/YZ.png"
      }
    },
    {
      "label": "Shaheen Air International",
      "value": {
        "id": "NL",
        "lcc": "1",
        "name": "Shaheen Air International",
        "logo": "https://images.kiwi.com/airlines/64/NL.png"
      }
    },
    {
      "label": "Fly Safair",
      "value": {
        "id": "FA",
        "lcc": "1",
        "name": "Fly Safair",
        "logo": "https://images.kiwi.com/airlines/64/FA.png"
      }
    },
    {
      "label": "flybmi",
      "value": {
        "id": "BM",
        "lcc": "0",
        "name": "flybmi",
        "logo": "https://images.kiwi.com/airlines/64/BM.png"
      }
    },
    {
      "label": "Primera Air",
      "value": {
        "id": "PF",
        "lcc": "1",
        "name": "Primera Air",
        "logo": "https://images.kiwi.com/airlines/64/PF.png"
      }
    },
    {
      "label": "Perimeter Aviation",
      "value": {
        "id": "JV",
        "lcc": "0",
        "name": "Perimeter Aviation",
        "logo": "https://images.kiwi.com/airlines/64/JV.png"
      }
    },
    {
      "label": "Yunnan Airlines",
      "value": {
        "id": "3Q",
        "lcc": "0",
        "name": "Yunnan Airlines",
        "logo": "https://images.kiwi.com/airlines/64/3Q.png"
      }
    },
    {
      "label": "Jin Air",
      "value": {
        "id": "LJ",
        "lcc": "1",
        "name": "Jin Air",
        "logo": "https://images.kiwi.com/airlines/64/LJ.png"
      }
    },
    {
      "label": "Malindo Air",
      "value": {
        "id": "OD",
        "lcc": "1",
        "name": "Malindo Air",
        "logo": "https://images.kiwi.com/airlines/64/OD.png"
      }
    },
    {
      "label": "AtlasGlobal",
      "value": {
        "id": "KK",
        "lcc": "1",
        "name": "AtlasGlobal",
        "logo": "https://images.kiwi.com/airlines/64/KK.png"
      }
    },
    {
      "label": "Skymark Airlines",
      "value": {
        "id": "BC",
        "lcc": "1",
        "name": "Skymark Airlines",
        "logo": "https://images.kiwi.com/airlines/64/BC.png"
      }
    },
    {
      "label": "Kenya Airways",
      "value": {
        "id": "KQ",
        "lcc": "0",
        "name": "Kenya Airways",
        "logo": "https://images.kiwi.com/airlines/64/KQ.png"
      }
    },
    {
      "label": "KLM Royal Dutch Airlines",
      "value": {
        "id": "KL",
        "lcc": "0",
        "name": "KLM Royal Dutch Airlines",
        "logo": "https://images.kiwi.com/airlines/64/KL.png"
      }
    },
    {
      "label": "Air India Express",
      "value": {
        "id": "IX",
        "lcc": "1",
        "name": "Air India Express",
        "logo": "https://images.kiwi.com/airlines/64/IX.png"
      }
    },
    {
      "label": "Air KBZ",
      "value": {
        "id": "K7",
        "lcc": "0",
        "name": "Air KBZ",
        "logo": "https://images.kiwi.com/airlines/64/K7.png"
      }
    },
    {
      "label": "Aigle Azur",
      "value": {
        "id": "ZI",
        "lcc": "0",
        "name": "Aigle Azur",
        "logo": "https://images.kiwi.com/airlines/64/ZI.png"
      }
    },
    {
      "label": "Atlas Blue",
      "value": {
        "id": "8A",
        "lcc": "0",
        "name": "Atlas Blue",
        "logo": "https://images.kiwi.com/airlines/64/8A.png"
      }
    },
    {
      "label": "Swiss International Air Lines",
      "value": {
        "id": "LX",
        "lcc": "0",
        "name": "Swiss International Air Lines",
        "logo": "https://images.kiwi.com/airlines/64/LX.png"
      }
    },
    {
      "label": "Avior Airlines",
      "value": {
        "id": "9V",
        "lcc": "0",
        "name": "Avior Airlines",
        "logo": "https://images.kiwi.com/airlines/64/9V.png"
      }
    },
    {
      "label": "Air Arabia",
      "value": {
        "id": "G9",
        "lcc": "1",
        "name": "Air Arabia",
        "logo": "https://images.kiwi.com/airlines/64/G9.png"
      }
    },
    {
      "label": "Cem Air",
      "value": {
        "id": "5Z",
        "lcc": "1",
        "name": "Cem Air",
        "logo": "https://images.kiwi.com/airlines/64/5Z.png"
      }
    },
    {
      "label": "Dniproavia",
      "value": {
        "id": "Z6",
        "lcc": "1",
        "name": "Dniproavia",
        "logo": "https://images.kiwi.com/airlines/64/Z6.png"
      }
    },
    {
      "label": "Xpressair",
      "value": {
        "id": "XN",
        "lcc": "0",
        "name": "Xpressair",
        "logo": "https://images.kiwi.com/airlines/64/XN.png"
      }
    },
    {
      "label": "Air China",
      "value": {
        "id": "CA",
        "lcc": "0",
        "name": "Air China",
        "logo": "https://images.kiwi.com/airlines/64/CA.png"
      }
    },
    {
      "label": "Nordwind Airlines",
      "value": {
        "id": "N4",
        "lcc": "0",
        "name": "Nordwind Airlines",
        "logo": "https://images.kiwi.com/airlines/64/N4.png"
      }
    },
    {
      "label": "Calm Air",
      "value": {
        "id": "MO",
        "lcc": "1",
        "name": "Calm Air",
        "logo": "https://images.kiwi.com/airlines/64/MO.png"
      }
    },
    {
      "label": "Fake Airline",
      "value": {
        "id": "X9",
        "lcc": "0",
        "name": "Fake Airline",
        "logo": "https://images.kiwi.com/airlines/64/X9.png"
      }
    },
    {
      "label": "Air New Zealand",
      "value": {
        "id": "NZ",
        "lcc": "1",
        "name": "Air New Zealand",
        "logo": "https://images.kiwi.com/airlines/64/NZ.png"
      }
    },
    {
      "label": "Novoair",
      "value": {
        "id": "VQ",
        "lcc": "1",
        "name": "Novoair",
        "logo": "https://images.kiwi.com/airlines/64/VQ.png"
      }
    },
    {
      "label": "Cayman Airways",
      "value": {
        "id": "KX",
        "lcc": "0",
        "name": "Cayman Airways",
        "logo": "https://images.kiwi.com/airlines/64/KX.png"
      }
    },
    {
      "label": "Cobalt Air",
      "value": {
        "id": "CO",
        "lcc": "0",
        "name": "Cobalt Air",
        "logo": "https://images.kiwi.com/airlines/64/CO.png"
      }
    },
    {
      "label": "Rwandair Express",
      "value": {
        "id": "WB",
        "lcc": "0",
        "name": "Rwandair Express",
        "logo": "https://images.kiwi.com/airlines/64/WB.png"
      }
    },
    {
      "label": "Blue Islands",
      "value": {
        "id": "SI",
        "lcc": "0",
        "name": "Blue Islands",
        "logo": "https://images.kiwi.com/airlines/64/SI.png"
      }
    },
    {
      "label": "Sol Líneas Aéreas",
      "value": {
        "id": "8R",
        "lcc": "1",
        "name": "Sol Líneas Aéreas",
        "logo": "https://images.kiwi.com/airlines/64/8R.png"
      }
    },
    {
      "label": "I-Fly",
      "value": {
        "id": "I4",
        "lcc": "0",
        "name": "I-Fly",
        "logo": "https://images.kiwi.com/airlines/64/I4.png"
      }
    },
    {
      "label": "Asian Wings Air",
      "value": {
        "id": "YJ",
        "lcc": "1",
        "name": "Asian Wings Air",
        "logo": "https://images.kiwi.com/airlines/64/YJ.png"
      }
    },
    {
      "label": "Regent Airways",
      "value": {
        "id": "RX",
        "lcc": "1",
        "name": "Regent Airways",
        "logo": "https://images.kiwi.com/airlines/64/RX.png"
      }
    },
    {
      "label": "Angara airlines",
      "value": {
        "id": "2G",
        "lcc": "1",
        "name": "Angara airlines",
        "logo": "https://images.kiwi.com/airlines/64/2G.png"
      }
    },
    {
      "label": "Rusline",
      "value": {
        "id": "7R",
        "lcc": "1",
        "name": "Rusline",
        "logo": "https://images.kiwi.com/airlines/64/7R.png"
      }
    },
    {
      "label": "Vanilla Air",
      "value": {
        "id": "JW",
        "lcc": "1",
        "name": "Vanilla Air",
        "logo": "https://images.kiwi.com/airlines/64/JW.png"
      }
    },
    {
      "label": "Smartavia",
      "value": {
        "id": "5N",
        "lcc": "1",
        "name": "Smartavia",
        "logo": "https://images.kiwi.com/airlines/64/5N.png"
      }
    },
    {
      "label": "Air Busan",
      "value": {
        "id": "BX",
        "lcc": "1",
        "name": "Air Busan",
        "logo": "https://images.kiwi.com/airlines/64/BX.png"
      }
    },
    {
      "label": "LC Perú",
      "value": {
        "id": "W4",
        "lcc": "1",
        "name": "LC Perú",
        "logo": "https://images.kiwi.com/airlines/64/W4.png"
      }
    },
    {
      "label": "Gol Transportes Aéreos",
      "value": {
        "id": "G3",
        "lcc": "1",
        "name": "Gol Transportes Aéreos",
        "logo": "https://images.kiwi.com/airlines/64/G3.png"
      }
    },
    {
      "label": "Pobeda",
      "value": {
        "id": "DP",
        "lcc": "1",
        "name": "Pobeda",
        "logo": "https://images.kiwi.com/airlines/64/DP.png"
      }
    },
    {
      "label": "Precision Air",
      "value": {
        "id": "PW",
        "lcc": "0",
        "name": "Precision Air",
        "logo": "https://images.kiwi.com/airlines/64/PW.png"
      }
    },
    {
      "label": "Jetstar Airways",
      "value": {
        "id": "JQ",
        "lcc": "1",
        "name": "Jetstar Airways",
        "logo": "https://images.kiwi.com/airlines/64/JQ.png"
      }
    },
    {
      "label": "Hi Fly",
      "value": {
        "id": "5K",
        "lcc": "0",
        "name": "Hi Fly",
        "logo": "https://images.kiwi.com/airlines/64/5K.png"
      }
    },
    {
      "label": "NouvelAir",
      "value": {
        "id": "BJ",
        "lcc": "0",
        "name": "NouvelAir",
        "logo": "https://images.kiwi.com/airlines/64/BJ.png"
      }
    },
    {
      "label": "Copa Airlines",
      "value": {
        "id": "CM",
        "lcc": "0",
        "name": "Copa Airlines",
        "logo": "https://images.kiwi.com/airlines/64/CM.png"
      }
    },
    {
      "label": "Myanmar Airways",
      "value": {
        "id": "8M",
        "lcc": "0",
        "name": "Myanmar Airways",
        "logo": "https://images.kiwi.com/airlines/64/8M.png"
      }
    },
    {
      "label": "JetBlue Airways",
      "value": {
        "id": "B6",
        "lcc": "1",
        "name": "JetBlue Airways",
        "logo": "https://images.kiwi.com/airlines/64/B6.png"
      }
    },
    {
      "label": "Middle East Airlines",
      "value": {
        "id": "ME",
        "lcc": "0",
        "name": "Middle East Airlines",
        "logo": "https://images.kiwi.com/airlines/64/ME.png"
      }
    },
    {
      "label": "Asiana Airlines",
      "value": {
        "id": "OZ",
        "lcc": "0",
        "name": "Asiana Airlines",
        "logo": "https://images.kiwi.com/airlines/64/OZ.png"
      }
    },
    {
      "label": "Kuwait Airways",
      "value": {
        "id": "KU",
        "lcc": "0",
        "name": "Kuwait Airways",
        "logo": "https://images.kiwi.com/airlines/64/KU.png"
      }
    },
    {
      "label": "Fly Jamaica Airways",
      "value": {
        "id": "OJ",
        "lcc": "0",
        "name": "Fly Jamaica Airways",
        "logo": "https://images.kiwi.com/airlines/64/OJ.png"
      }
    },
    {
      "label": "Air Antilles Express",
      "value": {
        "id": "3S",
        "lcc": "0",
        "name": "Air Antilles Express",
        "logo": "https://images.kiwi.com/airlines/64/3S.png"
      }
    },
    {
      "label": "Airnorth",
      "value": {
        "id": "TL",
        "lcc": "1",
        "name": "Airnorth",
        "logo": "https://images.kiwi.com/airlines/64/TL.png"
      }
    },
    {
      "label": "Air Canada Jazz",
      "value": {
        "id": "QK",
        "lcc": "0",
        "name": "Air Canada Jazz",
        "logo": "https://images.kiwi.com/airlines/64/QK.png"
      }
    },
    {
      "label": "Aeromar",
      "value": {
        "id": "VW",
        "lcc": "0",
        "name": "Aeromar",
        "logo": "https://images.kiwi.com/airlines/64/VW.png"
      }
    },
    {
      "label": "Qingdao Airlines",
      "value": {
        "id": "QW",
        "lcc": "0",
        "name": "Qingdao Airlines",
        "logo": "https://images.kiwi.com/airlines/64/QW.png"
      }
    },
    {
      "label": "Island Air",
      "value": {
        "id": "WP",
        "lcc": "0",
        "name": "Island Air",
        "logo": "https://images.kiwi.com/airlines/64/WP.png"
      }
    },
    {
      "label": "City Airline",
      "value": {
        "id": "CF",
        "lcc": "0",
        "name": "City Airline",
        "logo": "https://images.kiwi.com/airlines/64/CF.png"
      }
    },
    {
      "label": "Juneyao Airlines",
      "value": {
        "id": "HO",
        "lcc": "1",
        "name": "Juneyao Airlines",
        "logo": "https://images.kiwi.com/airlines/64/HO.png"
      }
    },
    {
      "label": "Kalstar Aviation",
      "value": {
        "id": "KD",
        "lcc": "0",
        "name": "Kalstar Aviation",
        "logo": "https://images.kiwi.com/airlines/64/KD.png"
      }
    },
    {
      "label": "SmartWings",
      "value": {
        "id": "QS",
        "lcc": "1",
        "name": "SmartWings",
        "logo": "https://images.kiwi.com/airlines/64/QS.png"
      }
    },
    {
      "label": "Scoot",
      "value": {
        "id": "TR",
        "lcc": "1",
        "name": "Scoot",
        "logo": "https://images.kiwi.com/airlines/64/TR.png"
      }
    },
    {
      "label": "Saratov Aviation Division",
      "value": {
        "id": "6W",
        "lcc": "1",
        "name": "Saratov Aviation Division",
        "logo": "https://images.kiwi.com/airlines/64/6W.png"
      }
    },
    {
      "label": "Aws express",
      "value": {
        "id": "B0",
        "lcc": "0",
        "name": "Aws express",
        "logo": "https://images.kiwi.com/airlines/64/B0.png"
      }
    },
    {
      "label": "Silver Airways",
      "value": {
        "id": "3M",
        "lcc": "1",
        "name": "Silver Airways",
        "logo": "https://images.kiwi.com/airlines/64/3M.png"
      }
    },
    {
      "label": "Island Spirit",
      "value": {
        "id": "IP",
        "lcc": "0",
        "name": "Island Spirit",
        "logo": "https://images.kiwi.com/airlines/64/IP.png"
      }
    },
    {
      "label": "Aerolinea de Antioquia",
      "value": {
        "id": "1D",
        "lcc": "1",
        "name": "Aerolinea de Antioquia",
        "logo": "https://images.kiwi.com/airlines/64/1D.png"
      }
    },
    {
      "label": "Air Vistara",
      "value": {
        "id": "UK",
        "lcc": "0",
        "name": "Air Vistara",
        "logo": "https://images.kiwi.com/airlines/64/UK.png"
      }
    },
    {
      "label": "9 Air",
      "value": {
        "id": "AQ",
        "lcc": "1",
        "name": "9 Air",
        "logo": "https://images.kiwi.com/airlines/64/AQ.png"
      }
    },
    {
      "label": "SAS",
      "value": {
        "id": "SK",
        "lcc": "0",
        "name": "SAS",
        "logo": "https://images.kiwi.com/airlines/64/SK.png"
      }
    },
    {
      "label": "Norwegian",
      "value": {
        "id": "DY",
        "lcc": "1",
        "name": "Norwegian",
        "logo": "https://images.kiwi.com/airlines/64/DY.png"
      }
    },
    {
      "label": "Hong Kong Express Airways",
      "value": {
        "id": "UO",
        "lcc": "1",
        "name": "Hong Kong Express Airways",
        "logo": "https://images.kiwi.com/airlines/64/UO.png"
      }
    },
    {
      "label": "Air France",
      "value": {
        "id": "AF",
        "lcc": "0",
        "name": "Air France",
        "logo": "https://images.kiwi.com/airlines/64/AF.png"
      }
    },
    {
      "label": "TAP Portugal",
      "value": {
        "id": "TP",
        "lcc": "0",
        "name": "TAP Portugal",
        "logo": "https://images.kiwi.com/airlines/64/TP.png"
      }
    },
    {
      "label": "Air India Limited",
      "value": {
        "id": "AI",
        "lcc": "0",
        "name": "Air India Limited",
        "logo": "https://images.kiwi.com/airlines/64/AI.png"
      }
    },
    {
      "label": "Vueling",
      "value": {
        "id": "VY",
        "lcc": "1",
        "name": "Vueling",
        "logo": "https://images.kiwi.com/airlines/64/VY.png"
      }
    },
    {
      "label": "Japan Airlines",
      "value": {
        "id": "JL",
        "lcc": "0",
        "name": "Japan Airlines",
        "logo": "https://images.kiwi.com/airlines/64/JL.png"
      }
    },
    {
      "label": "Air Astana",
      "value": {
        "id": "KC",
        "lcc": "0",
        "name": "Air Astana",
        "logo": "https://images.kiwi.com/airlines/64/KC.png"
      }
    },
    {
      "label": "Brussels Airlines",
      "value": {
        "id": "SN",
        "lcc": "0",
        "name": "Brussels Airlines",
        "logo": "https://images.kiwi.com/airlines/64/SN.png"
      }
    },
    {
      "label": "Fly Corporate",
      "value": {
        "id": "FC",
        "lcc": "1",
        "name": "Fly Corporate",
        "logo": "https://images.kiwi.com/airlines/64/FC.png"
      }
    },
    {
      "label": "Braathens Regional Aviation",
      "value": {
        "id": "TF",
        "lcc": "0",
        "name": "Braathens Regional Aviation",
        "logo": "https://images.kiwi.com/airlines/64/TF.png"
      }
    },
    {
      "label": "tuifly.be",
      "value": {
        "id": "TB",
        "lcc": "1",
        "name": "tuifly.be",
        "logo": "https://images.kiwi.com/airlines/64/TB.png"
      }
    },
    {
      "label": "Eurowings",
      "value": {
        "id": "EW",
        "lcc": "1",
        "name": "Eurowings",
        "logo": "https://images.kiwi.com/airlines/64/EW.png"
      }
    },
    {
      "label": "Iberia Airlines",
      "value": {
        "id": "IB",
        "lcc": "0",
        "name": "Iberia Airlines",
        "logo": "https://images.kiwi.com/airlines/64/IB.png"
      }
    },
    {
      "label": "Sky Bahamas",
      "value": {
        "id": "Q7",
        "lcc": "0",
        "name": "Sky Bahamas",
        "logo": "https://images.kiwi.com/airlines/64/Q7.png"
      }
    },
    {
      "label": "MAP Linhas Aéreas",
      "value": {
        "id": "7M",
        "lcc": "0",
        "name": "MAP Linhas Aéreas",
        "logo": "https://images.kiwi.com/airlines/64/7M.png"
      }
    },
    {
      "label": "Flybondi",
      "value": {
        "id": "FO",
        "lcc": "1",
        "name": "Flybondi",
        "logo": "https://images.kiwi.com/airlines/64/FO.png"
      }
    },
    {
      "label": "Compass Airlines",
      "value": {
        "id": "CP",
        "lcc": "0",
        "name": "Compass Airlines",
        "logo": "https://images.kiwi.com/airlines/64/CP.png"
      }
    },
    {
      "label": "SATENA",
      "value": {
        "id": "9R",
        "lcc": "1",
        "name": "SATENA",
        "logo": "https://images.kiwi.com/airlines/64/9R.png"
      }
    },
    {
      "label": "Thai AirAsia",
      "value": {
        "id": "FD",
        "lcc": "0",
        "name": "Thai AirAsia",
        "logo": "https://images.kiwi.com/airlines/64/FD.png"
      }
    },
    {
      "label": "Fly Dubai",
      "value": {
        "id": "FZ",
        "lcc": "1",
        "name": "Fly Dubai",
        "logo": "https://images.kiwi.com/airlines/64/FZ.png"
      }
    },
    {
      "label": "TUIfly",
      "value": {
        "id": "X3",
        "lcc": "1",
        "name": "TUIfly",
        "logo": "https://images.kiwi.com/airlines/64/X3.png"
      }
    },
    {
      "label": "Turkish Airlines",
      "value": {
        "id": "TK",
        "lcc": "0",
        "name": "Turkish Airlines",
        "logo": "https://images.kiwi.com/airlines/64/TK.png"
      }
    },
    {
      "label": "Ravn Alaska",
      "value": {
        "id": "7H",
        "lcc": "1",
        "name": "Ravn Alaska",
        "logo": "https://images.kiwi.com/airlines/64/7H.png"
      }
    },
    {
      "label": "Norwegian International",
      "value": {
        "id": "D8",
        "lcc": "0",
        "name": "Norwegian International",
        "logo": "https://images.kiwi.com/airlines/64/D8.png"
      }
    },
    {
      "label": "Adria Airways",
      "value": {
        "id": "JP",
        "lcc": "0",
        "name": "Adria Airways",
        "logo": "https://images.kiwi.com/airlines/64/JP.png"
      }
    },
    {
      "label": "Burlington Trailways",
      "value": {
        "id": "BURLINGTON",
        "lcc": "None",
        "name": "Burlington Trailways",
        "logo": "https://images.kiwi.com/airlines/64/BURLINGTON.png"
      }
    },
    {
      "label": "Passaredo",
      "value": {
        "id": "2Z",
        "lcc": "1",
        "name": "Passaredo",
        "logo": "https://images.kiwi.com/airlines/64/2Z.png"
      }
    },
    {
      "label": "Canadian National Airways",
      "value": {
        "id": "CN",
        "lcc": "0",
        "name": "Canadian National Airways",
        "logo": "https://images.kiwi.com/airlines/64/CN.png"
      }
    },
    {
      "label": "Windward Islands Airways",
      "value": {
        "id": "WM",
        "lcc": "0",
        "name": "Windward Islands Airways",
        "logo": "https://images.kiwi.com/airlines/64/WM.png"
      }
    },
    {
      "label": "Pegasus",
      "value": {
        "id": "PC",
        "lcc": "1",
        "name": "Pegasus",
        "logo": "https://images.kiwi.com/airlines/64/PC.png"
      }
    },
    {
      "label": "Lao Skyway",
      "value": {
        "id": "LK",
        "lcc": "0",
        "name": "Lao Skyway",
        "logo": "https://images.kiwi.com/airlines/64/LK.png"
      }
    },
    {
      "label": "Chautauqua Airlines",
      "value": {
        "id": "RP",
        "lcc": "0",
        "name": "Chautauqua Airlines",
        "logo": "https://images.kiwi.com/airlines/64/RP.png"
      }
    },
    {
      "label": "El Al Israel Airlines",
      "value": {
        "id": "LY",
        "lcc": "1",
        "name": "El Al Israel Airlines",
        "logo": "https://images.kiwi.com/airlines/64/LY.png"
      }
    },
    {
      "label": "Frontier Airlines",
      "value": {
        "id": "F9",
        "lcc": "1",
        "name": "Frontier Airlines",
        "logo": "https://images.kiwi.com/airlines/64/F9.png"
      }
    },
    {
      "label": "Intersky",
      "value": {
        "id": "3L",
        "lcc": "1",
        "name": "Intersky",
        "logo": "https://images.kiwi.com/airlines/64/3L.png"
      }
    },
    {
      "label": "Jazeera Airways",
      "value": {
        "id": "J9",
        "lcc": "1",
        "name": "Jazeera Airways",
        "logo": "https://images.kiwi.com/airlines/64/J9.png"
      }
    },
    {
      "label": "Nasair",
      "value": {
        "id": "UE",
        "lcc": "0",
        "name": "Nasair",
        "logo": "https://images.kiwi.com/airlines/64/UE.png"
      }
    },
    {
      "label": "Dennis Sky",
      "value": {
        "id": "DH",
        "lcc": "0",
        "name": "Dennis Sky",
        "logo": "https://images.kiwi.com/airlines/64/DH.png"
      }
    },
    {
      "label": "Tway Airlines",
      "value": {
        "id": "TW",
        "lcc": "1",
        "name": "Tway Airlines",
        "logo": "https://images.kiwi.com/airlines/64/TW.png"
      }
    },
    {
      "label": "AirInuit",
      "value": {
        "id": "3H",
        "lcc": "1",
        "name": "AirInuit",
        "logo": "https://images.kiwi.com/airlines/64/3H.png"
      }
    },
    {
      "label": "Wizzair",
      "value": {
        "id": "W6",
        "lcc": "1",
        "name": "Wizzair",
        "logo": "https://images.kiwi.com/airlines/64/W6.png"
      }
    },
    {
      "label": "Volaris",
      "value": {
        "id": "Y4",
        "lcc": "1",
        "name": "Volaris",
        "logo": "https://images.kiwi.com/airlines/64/Y4.png"
      }
    },
    {
      "label": "IndiGo Airlines",
      "value": {
        "id": "6E",
        "lcc": "1",
        "name": "IndiGo Airlines",
        "logo": "https://images.kiwi.com/airlines/64/6E.png"
      }
    },
    {
      "label": "Bangkok Airways",
      "value": {
        "id": "PG",
        "lcc": "1",
        "name": "Bangkok Airways",
        "logo": "https://images.kiwi.com/airlines/64/PG.png"
      }
    },
    {
      "label": "Vermont Translines",
      "value": {
        "id": "VTTRANSLIN",
        "lcc": "None",
        "name": "Vermont Translines",
        "logo": "https://images.kiwi.com/airlines/64/VTTRANSLIN.png"
      }
    },
    {
      "label": "Nok Air",
      "value": {
        "id": "DD",
        "lcc": "1",
        "name": "Nok Air",
        "logo": "https://images.kiwi.com/airlines/64/DD.png"
      }
    },
    {
      "label": "Firefly",
      "value": {
        "id": "FY",
        "lcc": "1",
        "name": "Firefly",
        "logo": "https://images.kiwi.com/airlines/64/FY.png"
      }
    },
    {
      "label": "Airblue",
      "value": {
        "id": "ED",
        "lcc": "1",
        "name": "Airblue",
        "logo": "https://images.kiwi.com/airlines/64/ED.png"
      }
    },
    {
      "label": "Fastjet",
      "value": {
        "id": "FN",
        "lcc": "1",
        "name": "Fastjet",
        "logo": "https://images.kiwi.com/airlines/64/FN.png"
      }
    },
    {
      "label": "Lion Air",
      "value": {
        "id": "JT",
        "lcc": "1",
        "name": "Lion Air",
        "logo": "https://images.kiwi.com/airlines/64/JT.png"
      }
    },
    {
      "label": "BoraJet",
      "value": {
        "id": "YB",
        "lcc": "1",
        "name": "BoraJet",
        "logo": "https://images.kiwi.com/airlines/64/YB.png"
      }
    },
    {
      "label": "Jeju Air",
      "value": {
        "id": "7C",
        "lcc": "1",
        "name": "Jeju Air",
        "logo": "https://images.kiwi.com/airlines/64/7C.png"
      }
    },
    {
      "label": "Thai Lion Air",
      "value": {
        "id": "SL",
        "lcc": "1",
        "name": "Thai Lion Air",
        "logo": "https://images.kiwi.com/airlines/64/SL.png"
      }
    },
    {
      "label": "Czech Rail",
      "value": {
        "id": "CZECHRAIL",
        "lcc": "None",
        "name": "Czech Rail",
        "logo": "https://images.kiwi.com/airlines/64/CZECHRAIL.png"
      }
    },
    {
      "label": "SkyWise",
      "value": {
        "id": "C9",
        "lcc": "1",
        "name": "SkyWise",
        "logo": "https://images.kiwi.com/airlines/64/C9.png"
      }
    },
    {
      "label": "Alitalia",
      "value": {
        "id": "AZ",
        "lcc": "0",
        "name": "Alitalia",
        "logo": "https://images.kiwi.com/airlines/64/AZ.png"
      }
    },
    {
      "label": "Aerolineas Argentinas",
      "value": {
        "id": "AR",
        "lcc": "0",
        "name": "Aerolineas Argentinas",
        "logo": "https://images.kiwi.com/airlines/64/AR.png"
      }
    },
    {
      "label": "Interjet",
      "value": {
        "id": "4O",
        "lcc": "1",
        "name": "Interjet",
        "logo": "https://images.kiwi.com/airlines/64/4O.png"
      }
    },
    {
      "label": "AeroMéxico",
      "value": {
        "id": "AM",
        "lcc": "0",
        "name": "AeroMéxico",
        "logo": "https://images.kiwi.com/airlines/64/AM.png"
      }
    },
    {
      "label": "Sky Airline",
      "value": {
        "id": "H2",
        "lcc": "1",
        "name": "Sky Airline",
        "logo": "https://images.kiwi.com/airlines/64/H2.png"
      }
    },
    {
      "label": "Cebu Pacific",
      "value": {
        "id": "5J",
        "lcc": "1",
        "name": "Cebu Pacific",
        "logo": "https://images.kiwi.com/airlines/64/5J.png"
      }
    },
    {
      "label": "Blue Panorama",
      "value": {
        "id": "BV",
        "lcc": "1",
        "name": "Blue Panorama",
        "logo": "https://images.kiwi.com/airlines/64/BV.png"
      }
    },
    {
      "label": "Condor",
      "value": {
        "id": "DE",
        "lcc": "1",
        "name": "Condor",
        "logo": "https://images.kiwi.com/airlines/64/DE.png"
      }
    },
    {
      "label": "Indonesia AirAsia",
      "value": {
        "id": "QZ",
        "lcc": "0",
        "name": "Indonesia AirAsia",
        "logo": "https://images.kiwi.com/airlines/64/QZ.png"
      }
    },
    {
      "label": "easyJet",
      "value": {
        "id": "U2",
        "lcc": "1",
        "name": "easyJet",
        "logo": "https://images.kiwi.com/airlines/64/U2.png"
      }
    },
    {
      "label": "Alsa",
      "value": {
        "id": "ALSA",
        "lcc": "0",
        "name": "Alsa",
        "logo": "https://images.kiwi.com/airlines/64/ALSA.png"
      }
    },
    {
      "label": "germanwings",
      "value": {
        "id": "4U",
        "lcc": "1",
        "name": "germanwings",
        "logo": "https://images.kiwi.com/airlines/64/4U.png"
      }
    },
    {
      "label": "Jetstar Asia Airways",
      "value": {
        "id": "3K",
        "lcc": "0",
        "name": "Jetstar Asia Airways",
        "logo": "https://images.kiwi.com/airlines/64/3K.png"
      }
    },
    {
      "label": "Delta Air Lines",
      "value": {
        "id": "DL",
        "lcc": "0",
        "name": "Delta Air Lines",
        "logo": "https://images.kiwi.com/airlines/64/DL.png"
      }
    },
    {
      "label": "UTair",
      "value": {
        "id": "UT",
        "lcc": "1",
        "name": "UTair",
        "logo": "https://images.kiwi.com/airlines/64/UT.png"
      }
    },
    {
      "label": "Hawaiian Airlines",
      "value": {
        "id": "HA",
        "lcc": "0",
        "name": "Hawaiian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/HA.png"
      }
    },
    {
      "label": "Sunwing",
      "value": {
        "id": "WG",
        "lcc": "1",
        "name": "Sunwing",
        "logo": "https://images.kiwi.com/airlines/64/WG.png"
      }
    },
    {
      "label": "Orenburg Airlines",
      "value": {
        "id": "R2",
        "lcc": "1",
        "name": "Orenburg Airlines",
        "logo": "https://images.kiwi.com/airlines/64/R2.png"
      }
    },
    {
      "label": "Transaero Airlines",
      "value": {
        "id": "UN",
        "lcc": "1",
        "name": "Transaero Airlines",
        "logo": "https://images.kiwi.com/airlines/64/UN.png"
      }
    },
    {
      "label": "AirAsia",
      "value": {
        "id": "AK",
        "lcc": "1",
        "name": "AirAsia",
        "logo": "https://images.kiwi.com/airlines/64/AK.png"
      }
    },
    {
      "label": "Sriwijaya Air",
      "value": {
        "id": "SJ",
        "lcc": "0",
        "name": "Sriwijaya Air",
        "logo": "https://images.kiwi.com/airlines/64/SJ.png"
      }
    },
    {
      "label": "Singapore Airlines",
      "value": {
        "id": "SQ",
        "lcc": "0",
        "name": "Singapore Airlines",
        "logo": "https://images.kiwi.com/airlines/64/SQ.png"
      }
    },
    {
      "label": "Bahamasair",
      "value": {
        "id": "UP",
        "lcc": "1",
        "name": "Bahamasair",
        "logo": "https://images.kiwi.com/airlines/64/UP.png"
      }
    },
    {
      "label": "British Airways",
      "value": {
        "id": "BA",
        "lcc": "0",
        "name": "British Airways",
        "logo": "https://images.kiwi.com/airlines/64/BA.png"
      }
    },
    {
      "label": "Aeroflot Russian Airlines",
      "value": {
        "id": "SU",
        "lcc": "0",
        "name": "Aeroflot Russian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/SU.png"
      }
    },
    {
      "label": "TAME",
      "value": {
        "id": "EQ",
        "lcc": "0",
        "name": "TAME",
        "logo": "https://images.kiwi.com/airlines/64/EQ.png"
      }
    },
    {
      "label": "Caribbean Airlines",
      "value": {
        "id": "BW",
        "lcc": "0",
        "name": "Caribbean Airlines",
        "logo": "https://images.kiwi.com/airlines/64/BW.png"
      }
    },
    {
      "label": "Garuda Indonesia",
      "value": {
        "id": "GA",
        "lcc": "0",
        "name": "Garuda Indonesia",
        "logo": "https://images.kiwi.com/airlines/64/GA.png"
      }
    },
    {
      "label": "Ethiopian Airlines",
      "value": {
        "id": "ET",
        "lcc": "0",
        "name": "Ethiopian Airlines",
        "logo": "https://images.kiwi.com/airlines/64/ET.png"
      }
    },
    {
      "label": "Transavia",
      "value": {
        "id": "HV",
        "lcc": "1",
        "name": "Transavia",
        "logo": "https://images.kiwi.com/airlines/64/HV.png"
      }
    },
    {
      "label": "Go Air",
      "value": {
        "id": "G8",
        "lcc": "1",
        "name": "Go Air",
        "logo": "https://images.kiwi.com/airlines/64/G8.png"
      }
    },
    {
      "label": "AlMasria Universal Airlines",
      "value": {
        "id": "UJ",
        "lcc": "0",
        "name": "AlMasria Universal Airlines",
        "logo": "https://images.kiwi.com/airlines/64/UJ.png"
      }
    },
    {
      "label": "French Bee",
      "value": {
        "id": "BF",
        "lcc": "0",
        "name": "French Bee",
        "logo": "https://images.kiwi.com/airlines/64/BF.png"
      }
    },
    {
      "label": "Wataniya Airways",
      "value": {
        "id": "Q9",
        "lcc": "0",
        "name": "Wataniya Airways",
        "logo": "https://images.kiwi.com/airlines/64/Q9.png"
      }
    },
    {
      "label": "Chengdu Airlines",
      "value": {
        "id": "EU",
        "lcc": "0",
        "name": "Chengdu Airlines",
        "logo": "https://images.kiwi.com/airlines/64/EU.png"
      }
    },
    {
      "label": "Berlinas Menorca",
      "value": {
        "id": "MENORCA",
        "lcc": "None",
        "name": "Berlinas Menorca",
        "logo": "https://images.kiwi.com/airlines/64/MENORCA.png"
      }
    },
    {
      "label": "Dublin Bus",
      "value": {
        "id": "DUBLINBUS",
        "lcc": "None",
        "name": "Dublin Bus",
        "logo": "https://images.kiwi.com/airlines/64/DUBLINBUS.png"
      }
    },
    {
      "label": "Lamezia Multiservizi",
      "value": {
        "id": "LAMEZIA",
        "lcc": "None",
        "name": "Lamezia Multiservizi",
        "logo": "https://images.kiwi.com/airlines/64/LAMEZIA.png"
      }
    },
    {
      "label": "Vy",
      "value": {
        "id": "VYRAIL",
        "lcc": "None",
        "name": "Vy",
        "logo": "https://images.kiwi.com/airlines/64/VYRAIL.png"
      }
    },
    {
      "label": "Emetebe Airlines",
      "value": {
        "id": "EMT",
        "lcc": "None",
        "name": "Emetebe Airlines",
        "logo": "https://images.kiwi.com/airlines/64/EMT.png"
      }
    },
    {
      "label": "Unity Air",
      "value": {
        "id": "FKK",
        "lcc": "None",
        "name": "Unity Air",
        "logo": "https://images.kiwi.com/airlines/64/FKK.png"
      }
    },
    {
      "label": "Cathay Dragon",
      "value": {
        "id": "KA",
        "lcc": "0",
        "name": "Cathay Dragon",
        "logo": "https://images.kiwi.com/airlines/64/KA.png"
      }
    },
    {
      "label": "Corendon Airlines Europe",
      "value": {
        "id": "XR",
        "lcc": "0",
        "name": "Corendon Airlines Europe",
        "logo": "https://images.kiwi.com/airlines/64/XR.png"
      }
    },
    {
      "label": "Braathens Regional Airways",
      "value": {
        "id": "DC",
        "lcc": "1",
        "name": "Braathens Regional Airways",
        "logo": "https://images.kiwi.com/airlines/64/DC.png"
      }
    },
    {
      "label": "ATA Airlines (Iran)",
      "value": {
        "id": "I3",
        "lcc": "None",
        "name": "ATA Airlines (Iran)",
        "logo": "https://images.kiwi.com/airlines/64/I3.png"
      }
    },
    {
      "label": "Zagros Airlines",
      "value": {
        "id": "ZO",
        "lcc": "None",
        "name": "Zagros Airlines",
        "logo": "https://images.kiwi.com/airlines/64/ZO.png"
      }
    },
    {
      "label": "East African",
      "value": {
        "id": "B5",
        "lcc": "0",
        "name": "East African",
        "logo": "https://images.kiwi.com/airlines/64/B5.png"
      }
    },
    {
      "label": "Eireagle",
      "value": {
        "id": "EIREAGLE",
        "lcc": "None",
        "name": "Eireagle",
        "logo": "https://images.kiwi.com/airlines/64/EIREAGLE.png"
      }
    },
    {
      "label": "Giosy tours SA",
      "value": {
        "id": "GTOURSSA",
        "lcc": "None",
        "name": "Giosy tours SA",
        "logo": "https://images.kiwi.com/airlines/64/GTOURSSA.png"
      }
    },
    {
      "label": "Westfalen Bahn",
      "value": {
        "id": "WESTFALEN",
        "lcc": "None",
        "name": "Westfalen Bahn",
        "logo": "https://images.kiwi.com/airlines/64/WESTFALEN.png"
      }
    },
    {
      "label": "NordWestBahn",
      "value": {
        "id": "NORDWEST",
        "lcc": "None",
        "name": "NordWestBahn",
        "logo": "https://images.kiwi.com/airlines/64/NORDWEST.png"
      }
    },
    {
      "label": "Korail",
      "value": {
        "id": "KORAIL",
        "lcc": "None",
        "name": "Korail",
        "logo": "https://images.kiwi.com/airlines/64/KORAIL.png"
      }
    },
    {
      "label": "Yuma County Area Transit",
      "value": {
        "id": "YCAT",
        "lcc": "None",
        "name": "Yuma County Area Transit",
        "logo": "https://images.kiwi.com/airlines/64/YCAT.png"
      }
    },
    {
      "label": "Dalatrafik",
      "value": {
        "id": "DALATRAFIK",
        "lcc": "None",
        "name": "Dalatrafik",
        "logo": "https://images.kiwi.com/airlines/64/DALATRAFIK.png"
      }
    },
    {
      "label": "Flygbussarna",
      "value": {
        "id": "FLYGBUSSAR",
        "lcc": "None",
        "name": "Flygbussarna",
        "logo": "https://images.kiwi.com/airlines/64/FLYGBUSSAR.png"
      }
    },
    {
      "label": "airBaltic",
      "value": {
        "id": "BT",
        "lcc": "1",
        "name": "airBaltic",
        "logo": "https://images.kiwi.com/airlines/64/BT.png"
      }
    },
    {
      "label": "Aurora Airlines",
      "value": {
        "id": "HZ",
        "lcc": "0",
        "name": "Aurora Airlines",
        "logo": "https://images.kiwi.com/airlines/64/HZ.png"
      }
    },
    {
      "label": "Peach Aviation",
      "value": {
        "id": "MM",
        "lcc": "1",
        "name": "Peach Aviation",
        "logo": "https://images.kiwi.com/airlines/64/MM.png"
      }
    },
    {
      "label": "Taban Airlines",
      "value": {
        "id": "HH",
        "lcc": "0",
        "name": "Taban Airlines",
        "logo": "https://images.kiwi.com/airlines/64/HH.png"
      }
    },
    {
      "label": "AD EuroTrans",
      "value": {
        "id": "EUROTRANS",
        "lcc": "None",
        "name": "AD EuroTrans",
        "logo": "https://images.kiwi.com/airlines/64/EUROTRANS.png"
      }
    },
    {
      "label": "Arriva United Kingdom",
      "value": {
        "id": "ARRIVAUK",
        "lcc": "None",
        "name": "Arriva United Kingdom",
        "logo": "https://images.kiwi.com/airlines/64/ARRIVAUK.png"
      }
    },
    {
      "label": "MTR Nordic",
      "value": {
        "id": "MTRNORDIC",
        "lcc": "None",
        "name": "MTR Nordic",
        "logo": "https://images.kiwi.com/airlines/64/MTRNORDIC.png"
      }
    },
    {
      "label": "Samoa Airways",
      "value": {
        "id": "OL",
        "lcc": "0",
        "name": "Samoa Airways",
        "logo": "https://images.kiwi.com/airlines/64/OL.png"
      }
    },
    {
      "label": "IZY",
      "value": {
        "id": "IZY",
        "lcc": "None",
        "name": "IZY",
        "logo": "https://images.kiwi.com/airlines/64/IZY.png"
      }
    },
    {
      "label": "WESTBahn",
      "value": {
        "id": "5W",
        "lcc": "0",
        "name": "WESTBahn",
        "logo": "https://images.kiwi.com/airlines/64/5W.png"
      }
    },
    {
      "label": "Tagkompaniet",
      "value": {
        "id": "TAGKOMPANI",
        "lcc": "None",
        "name": "Tagkompaniet",
        "logo": "https://images.kiwi.com/airlines/64/TAGKOMPANI.png"
      }
    },
    {
      "label": "Air Greenland",
      "value": {
        "id": "GL",
        "lcc": "0",
        "name": "Air Greenland",
        "logo": "https://images.kiwi.com/airlines/64/GL.png"
      }
    },
    {
      "label": "Barons Bus",
      "value": {
        "id": "BARONSBUS",
        "lcc": "None",
        "name": "Barons Bus",
        "logo": "https://images.kiwi.com/airlines/64/BARONSBUS.png"
      }
    },
    {
      "label": "New York Trailways",
      "value": {
        "id": "NYTRAILWAY",
        "lcc": "None",
        "name": "New York Trailways",
        "logo": "https://images.kiwi.com/airlines/64/NYTRAILWAY.png"
      }
    },
    {
      "label": "Ouibus",
      "value": {
        "id": "OUIBUS",
        "lcc": "0",
        "name": "Ouibus",
        "logo": "https://images.kiwi.com/airlines/64/OUIBUS.png"
      }
    },
    {
      "label": "LoganAir LM",
      "value": {
        "id": "LM",
        "lcc": "0",
        "name": "LoganAir LM",
        "logo": "https://images.kiwi.com/airlines/64/LM.png"
      }
    },
    {
      "label": "Shree Airlines",
      "value": {
        "id": "SHA",
        "lcc": "None",
        "name": "Shree Airlines",
        "logo": "https://images.kiwi.com/airlines/64/SHA.png"
      }
    },
    {
      "label": "Fullington Trailways",
      "value": {
        "id": "FULLINGTON",
        "lcc": "None",
        "name": "Fullington Trailways",
        "logo": "https://images.kiwi.com/airlines/64/FULLINGTON.png"
      }
    },
    {
      "label": "Capital - Colonial Trailways",
      "value": {
        "id": "CAPITALCOL",
        "lcc": "None",
        "name": "Capital - Colonial Trailways",
        "logo": "https://images.kiwi.com/airlines/64/CAPITALCOL.png"
      }
    },
    {
      "label": "Jetstar Pacific",
      "value": {
        "id": "BL",
        "lcc": "0",
        "name": "Jetstar Pacific",
        "logo": "https://images.kiwi.com/airlines/64/BL.png"
      }
    },
    {
      "label": "Yorkshire Tiger",
      "value": {
        "id": "YORKSTIGER",
        "lcc": "None",
        "name": "Yorkshire Tiger",
        "logo": "https://images.kiwi.com/airlines/64/YORKSTIGER.png"
      }
    },
    {
      "label": "First Bus",
      "value": {
        "id": "FIRSTBUS",
        "lcc": "None",
        "name": "First Bus",
        "logo": "https://images.kiwi.com/airlines/64/FIRSTBUS.png"
      }
    },
    {
      "label": "High Peak",
      "value": {
        "id": "HIGHPEAK",
        "lcc": "None",
        "name": "High Peak",
        "logo": "https://images.kiwi.com/airlines/64/HIGHPEAK.png"
      }
    },
    {
      "label": "Yellow Buses",
      "value": {
        "id": "YELLOWBUS",
        "lcc": "None",
        "name": "Yellow Buses",
        "logo": "https://images.kiwi.com/airlines/64/YELLOWBUS.png"
      }
    },
    {
      "label": "Bath Bus Company",
      "value": {
        "id": "BATHBUS",
        "lcc": "None",
        "name": "Bath Bus Company",
        "logo": "https://images.kiwi.com/airlines/64/BATHBUS.png"
      }
    },
    {
      "label": "Blekingetrafiken bus",
      "value": {
        "id": "BLEKINGEB",
        "lcc": "None",
        "name": "Blekingetrafiken bus",
        "logo": "https://images.kiwi.com/airlines/64/BLEKINGEB.png"
      }
    },
    {
      "label": "Spring Airlines Japan",
      "value": {
        "id": "IJ",
        "lcc": "None",
        "name": "Spring Airlines Japan",
        "logo": "https://images.kiwi.com/airlines/64/IJ.png"
      }
    },
    {
      "label": "VR",
      "value": {
        "id": "VRRAIL",
        "lcc": "None",
        "name": "VR",
        "logo": "https://images.kiwi.com/airlines/64/VRRAIL.png"
      }
    },
    {
      "label": "flybe",
      "value": {
        "id": "BE",
        "lcc": "1",
        "name": "flybe",
        "logo": "https://images.kiwi.com/airlines/64/BE.png"
      }
    },
    {
      "label": "OBB",
      "value": {
        "id": "OBB",
        "lcc": "None",
        "name": "OBB",
        "logo": "https://images.kiwi.com/airlines/64/OBB.png"
      }
    },
    {
      "label": "Slovenian Railways",
      "value": {
        "id": "SLORAIL",
        "lcc": "None",
        "name": "Slovenian Railways",
        "logo": "https://images.kiwi.com/airlines/64/SLORAIL.png"
      }
    },
    {
      "label": "Public Traffic Uppland train",
      "value": {
        "id": "ULT",
        "lcc": "None",
        "name": "Public Traffic Uppland train",
        "logo": "https://images.kiwi.com/airlines/64/ULT.png"
      }
    },
    {
      "label": "Megabus train",
      "value": {
        "id": "MEGABUST",
        "lcc": "None",
        "name": "Megabus train",
        "logo": "https://images.kiwi.com/airlines/64/MEGABUST.png"
      }
    },
    {
      "label": "Megabus bus",
      "value": {
        "id": "MEGABUSB",
        "lcc": "None",
        "name": "Megabus bus",
        "logo": "https://images.kiwi.com/airlines/64/MEGABUSB.png"
      }
    },
    {
      "label": "Cityzap",
      "value": {
        "id": "CITYZAP",
        "lcc": "None",
        "name": "Cityzap",
        "logo": "https://images.kiwi.com/airlines/64/CITYZAP.png"
      }
    },
    {
      "label": "Coastliner",
      "value": {
        "id": "COASTLINER",
        "lcc": "None",
        "name": "Coastliner",
        "logo": "https://images.kiwi.com/airlines/64/COASTLINER.png"
      }
    },
    {
      "label": "Green Line",
      "value": {
        "id": "GREENLINE",
        "lcc": "None",
        "name": "Green Line",
        "logo": "https://images.kiwi.com/airlines/64/GREENLINE.png"
      }
    },
    {
      "label": "Stagecoach bus",
      "value": {
        "id": "STAGECOACB",
        "lcc": "None",
        "name": "Stagecoach bus",
        "logo": "https://images.kiwi.com/airlines/64/STAGECOACB.png"
      }
    },
    {
      "label": "Oxford Tube",
      "value": {
        "id": "OXFORDTUBE",
        "lcc": "None",
        "name": "Oxford Tube",
        "logo": "https://images.kiwi.com/airlines/64/OXFORDTUBE.png"
      }
    },
    {
      "label": "Enno",
      "value": {
        "id": "ENNO",
        "lcc": "None",
        "name": "Enno",
        "logo": "https://images.kiwi.com/airlines/64/ENNO.png"
      }
    },
    {
      "label": "Metronom",
      "value": {
        "id": "METRONOM",
        "lcc": "None",
        "name": "Metronom",
        "logo": "https://images.kiwi.com/airlines/64/METRONOM.png"
      }
    },
    {
      "label": "Sud-Thuringen-Bahn",
      "value": {
        "id": "SUDTHURING",
        "lcc": "None",
        "name": "Sud-Thuringen-Bahn",
        "logo": "https://images.kiwi.com/airlines/64/SUDTHURING.png"
      }
    },
    {
      "label": "Vias",
      "value": {
        "id": "VIAS",
        "lcc": "None",
        "name": "Vias",
        "logo": "https://images.kiwi.com/airlines/64/VIAS.png"
      }
    },
    {
      "label": "Meridian, BOB, BRB",
      "value": {
        "id": "MERIBOBBRB",
        "lcc": "None",
        "name": "Meridian, BOB, BRB",
        "logo": "https://images.kiwi.com/airlines/64/MERIBOBBRB.png"
      }
    },
    {
      "label": "Eurobahn",
      "value": {
        "id": "EUROBAHN",
        "lcc": "None",
        "name": "Eurobahn",
        "logo": "https://images.kiwi.com/airlines/64/EUROBAHN.png"
      }
    },
    {
      "label": "Landerbahn",
      "value": {
        "id": "LANDERBAHN",
        "lcc": "None",
        "name": "Landerbahn",
        "logo": "https://images.kiwi.com/airlines/64/LANDERBAHN.png"
      }
    },
    {
      "label": "Abellio",
      "value": {
        "id": "ABELLIO",
        "lcc": "None",
        "name": "Abellio",
        "logo": "https://images.kiwi.com/airlines/64/ABELLIO.png"
      }
    },
    {
      "label": "Czech Rail train",
      "value": {
        "id": "CZECHRAILT",
        "lcc": "None",
        "name": "Czech Rail train",
        "logo": "https://images.kiwi.com/airlines/64/CZECHRAILT.png"
      }
    },
    {
      "label": "Stockholm Public Transport train",
      "value": {
        "id": "SLT",
        "lcc": "None",
        "name": "Stockholm Public Transport train",
        "logo": "https://images.kiwi.com/airlines/64/SLT.png"
      }
    },
    {
      "label": "Aerolineas Sosa",
      "value": {
        "id": "S0",
        "lcc": "1",
        "name": "Aerolineas Sosa",
        "logo": "https://images.kiwi.com/airlines/64/S0.png"
      }
    },
    {
      "label": "Aruba Airlines",
      "value": {
        "id": "AG",
        "lcc": "0",
        "name": "Aruba Airlines",
        "logo": "https://images.kiwi.com/airlines/64/AG.png"
      }
    },
    {
      "label": "GX airlines",
      "value": {
        "id": "GX",
        "lcc": "1",
        "name": "GX airlines",
        "logo": "https://images.kiwi.com/airlines/64/GX.png"
      }
    },
    {
      "label": "Philippine Airlines",
      "value": {
        "id": "PR",
        "lcc": "0",
        "name": "Philippine Airlines",
        "logo": "https://images.kiwi.com/airlines/64/PR.png"
      }
    },
    {
      "label": "Urumqi Airlines",
      "value": {
        "id": "UQ",
        "lcc": "0",
        "name": "Urumqi Airlines",
        "logo": "https://images.kiwi.com/airlines/64/UQ.png"
      }
    },
    {
      "label": "TunisAir Express",
      "value": {
        "id": "UG",
        "lcc": "0",
        "name": "TunisAir Express",
        "logo": "https://images.kiwi.com/airlines/64/UG.png"
      }
    },
    {
      "label": "EuroLot",
      "value": {
        "id": "K2",
        "lcc": "0",
        "name": "EuroLot",
        "logo": "https://images.kiwi.com/airlines/64/K2.png"
      }
    },
    {
      "label": "VE",
      "value": {
        "id": "VE",
        "lcc": "0",
        "name": "VE",
        "logo": "https://images.kiwi.com/airlines/64/VE.png"
      }
    },
    {
      "label": "Aeroflot-Don",
      "value": {
        "id": "D9",
        "lcc": "0",
        "name": "Aeroflot-Don",
        "logo": "https://images.kiwi.com/airlines/64/D9.png"
      }
    },
    {
      "label": "Tigerair Taiwan",
      "value": {
        "id": "IT",
        "lcc": "0",
        "name": "Tigerair Taiwan",
        "logo": "https://images.kiwi.com/airlines/64/IT.png"
      }
    },
    {
      "label": "Great Lakes Airlines",
      "value": {
        "id": "ZK",
        "lcc": "0",
        "name": "Great Lakes Airlines",
        "logo": "https://images.kiwi.com/airlines/64/ZK.png"
      }
    },
    {
      "label": "Hahn Airlines",
      "value": {
        "id": "HR",
        "lcc": "0",
        "name": "Hahn Airlines",
        "logo": "https://images.kiwi.com/airlines/64/HR.png"
      }
    },
    {
      "label": "Hainan Airlines",
      "value": {
        "id": "HU",
        "lcc": "0",
        "name": "Hainan Airlines",
        "logo": "https://images.kiwi.com/airlines/64/HU.png"
      }
    },
    {
      "label": "Surinam Airways",
      "value": {
        "id": "PY",
        "lcc": "0",
        "name": "Surinam Airways",
        "logo": "https://images.kiwi.com/airlines/64/PY.png"
      }
    },
    {
      "label": "Fly Blue Crane",
      "value": {
        "id": "7B",
        "lcc": "0",
        "name": "Fly Blue Crane",
        "logo": "https://images.kiwi.com/airlines/64/7B.png"
      }
    },
    {
      "label": "Latin American Wings",
      "value": {
        "id": "LW",
        "lcc": "0",
        "name": "Latin American Wings",
        "logo": "https://images.kiwi.com/airlines/64/LW.png"
      }
    },
    {
      "label": "Mandala Airlines",
      "value": {
        "id": "RI",
        "lcc": "0",
        "name": "Mandala Airlines",
        "logo": "https://images.kiwi.com/airlines/64/RI.png"
      }
    },
    {
      "label": "Trans Air Congo",
      "value": {
        "id": "Q8",
        "lcc": "0",
        "name": "Trans Air Congo",
        "logo": "https://images.kiwi.com/airlines/64/Q8.png"
      }
    },
    {
      "label": "Corendon",
      "value": {
        "id": "XC",
        "lcc": "1",
        "name": "Corendon",
        "logo": "https://images.kiwi.com/airlines/64/XC.png"
      }
    },
    {
      "label": "Thai Smile",
      "value": {
        "id": "WE",
        "lcc": "1",
        "name": "Thai Smile",
        "logo": "https://images.kiwi.com/airlines/64/WE.png"
      }
    },
    {
      "label": "Southern Air Charter",
      "value": {
        "id": "PL",
        "lcc": "0",
        "name": "Southern Air Charter",
        "logo": "https://images.kiwi.com/airlines/64/PL.png"
      }
    },
    {
      "label": "Royal Air Maroc",
      "value": {
        "id": "AT",
        "lcc": "0",
        "name": "Royal Air Maroc",
        "logo": "https://images.kiwi.com/airlines/64/AT.png"
      }
    },
    {
      "label": "EVA Air",
      "value": {
        "id": "BR",
        "lcc": "1",
        "name": "EVA Air",
        "logo": "https://images.kiwi.com/airlines/64/BR.png"
      }
    },
    {
      "label": "Malaysia Airlines",
      "value": {
        "id": "MH",
        "lcc": "1",
        "name": "Malaysia Airlines",
        "logo": "https://images.kiwi.com/airlines/64/MH.png"
      }
    },
    {
      "label": "Orient Thai Airlines",
      "value": {
        "id": "OX",
        "lcc": "1",
        "name": "Orient Thai Airlines",
        "logo": "https://images.kiwi.com/airlines/64/OX.png"
      }
    },
    {
      "label": "SATA Azores Airlines",
      "value": {
        "id": "S4",
        "lcc": "0",
        "name": "SATA Azores Airlines",
        "logo": "https://images.kiwi.com/airlines/64/S4.png"
      }
    },
    {
      "label": "Trenitalia",
      "value": {
        "id": "7T",
        "lcc": "0",
        "name": "Trenitalia",
        "logo": "https://images.kiwi.com/airlines/64/7T.png"
      }
    },
    {
      "label": "Arda Tur",
      "value": {
        "id": "ARDATUR",
        "lcc": "None",
        "name": "Arda Tur",
        "logo": "https://images.kiwi.com/airlines/64/ARDATUR.png"
      }
    },
    {
      "label": "Magical Shuttle",
      "value": {
        "id": "MAGICSHUT",
        "lcc": "None",
        "name": "Magical Shuttle",
        "logo": "https://images.kiwi.com/airlines/64/MAGICSHUT.png"
      }
    },
    {
      "label": "Crnja tours",
      "value": {
        "id": "CRNJAT",
        "lcc": "None",
        "name": "Crnja tours",
        "logo": "https://images.kiwi.com/airlines/64/CRNJAT.png"
      }
    },
    {
      "label": "Virgin Atlantic Airways",
      "value": {
        "id": "VS",
        "lcc": "0",
        "name": "Virgin Atlantic Airways",
        "logo": "https://images.kiwi.com/airlines/64/VS.png"
      }
    },
    {
      "label": "GDN Express",
      "value": {
        "id": "GDNEX",
        "lcc": "None",
        "name": "GDN Express",
        "logo": "https://images.kiwi.com/airlines/64/GDNEX.png"
      }
    },
    {
      "label": "Global biomet ",
      "value": {
        "id": "GLOBALBIO",
        "lcc": "None",
        "name": "Global biomet ",
        "logo": "https://images.kiwi.com/airlines/64/GLOBALBIO.png"
      }
    },
    {
      "label": "Christian Transfers",
      "value": {
        "id": "CHRISTRAN",
        "lcc": "None",
        "name": "Christian Transfers",
        "logo": "https://images.kiwi.com/airlines/64/CHRISTRAN.png"
      }
    },
    {
      "label": "Yellow Transfers",
      "value": {
        "id": "YELLOWTRAN",
        "lcc": "None",
        "name": "Yellow Transfers",
        "logo": "https://images.kiwi.com/airlines/64/YELLOWTRAN.png"
      }
    },
    {
      "label": "Sky Express",
      "value": {
        "id": "GQ",
        "lcc": "0",
        "name": "Sky Express",
        "logo": "https://images.kiwi.com/airlines/64/GQ.png"
      }
    },
    {
      "label": "Deutsche Bahn",
      "value": {
        "id": "2A",
        "lcc": "0",
        "name": "Deutsche Bahn",
        "logo": "https://images.kiwi.com/airlines/64/2A.png"
      }
    },
    {
      "label": "Autna SL - Spain",
      "value": {
        "id": "AUTNA",
        "lcc": "None",
        "name": "Autna SL - Spain",
        "logo": "https://images.kiwi.com/airlines/64/AUTNA.png"
      }
    },
    {
      "label": "China Railway",
      "value": {
        "id": "CHINARAIL",
        "lcc": "None",
        "name": "China Railway",
        "logo": "https://images.kiwi.com/airlines/64/CHINARAIL.png"
      }
    },
    {
      "label": "OK bus",
      "value": {
        "id": "OKBUS",
        "lcc": "None",
        "name": "OK bus",
        "logo": "https://images.kiwi.com/airlines/64/OKBUS.png"
      }
    },
    {
      "label": "Mountain Line Transit Authority",
      "value": {
        "id": "MOUNTAINLI",
        "lcc": "None",
        "name": "Mountain Line Transit Authority",
        "logo": "https://images.kiwi.com/airlines/64/MOUNTAINLI.png"
      }
    },
    {
      "label": "BoltBus",
      "value": {
        "id": "BOLTBUS",
        "lcc": "None",
        "name": "BoltBus",
        "logo": "https://images.kiwi.com/airlines/64/BOLTBUS.png"
      }
    },
    {
      "label": "SwissTours",
      "value": {
        "id": "SWISSTOURS",
        "lcc": "None",
        "name": "SwissTours",
        "logo": "https://images.kiwi.com/airlines/64/SWISSTOURS.png"
      }
    },
    {
      "label": "Autolinee federico",
      "value": {
        "id": "AUTOLIFEDE",
        "lcc": "None",
        "name": "Autolinee federico",
        "logo": "https://images.kiwi.com/airlines/64/AUTOLIFEDE.png"
      }
    },
    {
      "label": "All Nippon Airways",
      "value": {
        "id": "NH",
        "lcc": "0",
        "name": "All Nippon Airways",
        "logo": "https://images.kiwi.com/airlines/64/NH.png"
      }
    },
    {
      "label": "Aerobus Barcelona",
      "value": {
        "id": "AEROBUSBCN",
        "lcc": "None",
        "name": "Aerobus Barcelona",
        "logo": "https://images.kiwi.com/airlines/64/AEROBUSBCN.png"
      }
    },
    {
      "label": "Roma Express",
      "value": {
        "id": "ROMAEX",
        "lcc": "None",
        "name": "Roma Express",
        "logo": "https://images.kiwi.com/airlines/64/ROMAEX.png"
      }
    },
    {
      "label": "Balearia",
      "value": {
        "id": "BALEARIA",
        "lcc": "None",
        "name": "Balearia",
        "logo": "https://images.kiwi.com/airlines/64/BALEARIA.png"
      }
    },
    {
      "label": "MAYAir",
      "value": {
        "id": "5G",
        "lcc": "0",
        "name": "MAYAir",
        "logo": "https://images.kiwi.com/airlines/64/5G.png"
      }
    },
    {
      "label": "Amsterdam Airlines",
      "value": {
        "id": "WD",
        "lcc": "0",
        "name": "Amsterdam Airlines",
        "logo": "https://images.kiwi.com/airlines/64/WD.png"
      }
    },
    {
      "label": "Ibom Air",
      "value": {
        "id": "Z4",
        "lcc": "0",
        "name": "Ibom Air",
        "logo": "https://images.kiwi.com/airlines/64/Z4.png"
      }
    },
    {
      "label": "Air Albania",
      "value": {
        "id": "ZB",
        "lcc": "1",
        "name": "Air Albania",
        "logo": "https://images.kiwi.com/airlines/64/ZB.png"
      }
    },
    {
      "label": "Air Peace Limited",
      "value": {
        "id": "P4",
        "lcc": "0",
        "name": "Air Peace Limited",
        "logo": "https://images.kiwi.com/airlines/64/P4.png"
      }
    },
    {
      "label": "Italo NTV",
      "value": {
        "id": "ITALONTV",
        "lcc": "None",
        "name": "Italo NTV",
        "logo": "https://images.kiwi.com/airlines/64/ITALONTV.png"
      }
    },
    {
      "label": "Italobus",
      "value": {
        "id": "ITALOBUS",
        "lcc": "None",
        "name": "Italobus",
        "logo": "https://images.kiwi.com/airlines/64/ITALOBUS.png"
      }
    },
    {
      "label": "Wings of Lebanon",
      "value": {
        "id": "W7",
        "lcc": "0",
        "name": "Wings of Lebanon",
        "logo": "https://images.kiwi.com/airlines/64/W7.png"
      }
    },
    {
      "label": "Air Seoul",
      "value": {
        "id": "RS",
        "lcc": "0",
        "name": "Air Seoul",
        "logo": "https://images.kiwi.com/airlines/64/RS.png"
      }
    }
  ]