import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FaFacebook, FaLinkedin, FaPinterest } from "react-icons/fa";
import { MdDownload } from "react-icons/md";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { selectUser, selectUserSettings } from "../store/authSlice";
import { Link } from "react-router-dom";

const Voucher = ({ currency, t }: any) => {
  const location = useLocation();
  const user = useSelector(selectUser);
  const userSettings = useSelector(selectUserSettings);
  const [bookingDetailsData] = useState(location.state ?? {});

  const htmlRef = useRef<HTMLDivElement>(null);

  const handlePrintClick = useReactToPrint({
    content: () => htmlRef.current,
  });

  function getLogo() {
    let logo: string | null = null;

    logo = userSettings?.site_logo;

    return logo?.length ? logo : "logo.png";
  }

  console.log(bookingDetailsData);

  if (!bookingDetailsData || user?.type !== "travelagent") {
    return <Navigate to={"/"} />;
  }

  console.log(bookingDetailsData);

  return (
    <div style={{ background: "#fff" }}>
      <Helmet>
        <title>Booking Voucher</title>
      </Helmet>

      <div className="p-4 flex justify-center ">
        <button
          onClick={() => {
            handlePrintClick();
          }}
          className="px-4 py-2 mt-2 items-center flex gap-2 rounded-md bg-gray-800 text-white font-bold  hover:bg-yellow-600"
        >
          <MdDownload /> Download PDF
        </button>
      </div>

      <div style={{width:'820px',margin: 'auto'}} ref={htmlRef}>
        <div
          className="top-headr"
          style={{
            display: "flex",
            backgroundColor: "#0b4068",
            padding: "10px 20px",
          }}
        >
          <div style={{ width: "50%", textAlign: "left" }}>
            <h1
              style={{
                fontFamily: '"Barlow Semi Condensed", sans-serif',
                fontSize: 42,
                color: "#ffffff",
                fontWeight: 700,
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              BOOKING<br/>CONFIRMATION
            </h1>
            <a
              href="https://limowide.com"
              style={{
                fontSize: 20,
                color: "#c4a15f",
                fontWeight: 600,
                textDecoration: "none",
                fontFamily: '"Poppins", sans-serif',
              }}
            >
              WWW.LIMOWIDE.COM
            </a>
          </div>
          
          <div style={{ width: "50%", textAlign: "center",marginTop:'-20px' }}>
            <img src={getLogo()} width="150px" alt="Limowide" style={{ margin: "auto" }} />
            
            <div className="flex gap-3 justify-center items-center">
              <a
                href="https://www.linkedin.com/company/limowide/"
                className="w-8 h-8 bg-yellow-600 rounded-full flex justify-center items-center "
              >
                <FaLinkedin className="h-5 w-5 fill-gray-800 " />
              </a>

              <a
                href="https://www.facebook.com/limowide"
                className="w-8 h-8 bg-yellow-600 rounded-full flex justify-center items-center "
              >
                <FaFacebook className="h-5 w-5 fill-gray-800 " />
              </a>

              <a
                href="https://twitter.com/limowide?s=08"
                className="w-8 h-8 bg-yellow-600 rounded-full flex justify-center items-center "
              >
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="h-5 w-5 fill-gray-800"
                >
                  <g>
                    <path d="M14.258 10.152L23.176 0h-2.113l-7.747 8.813L7.133 0H0l9.352 13.328L0 23.973h2.113l8.176-9.309 6.531 9.309h7.133zm-2.895 3.293l-.949-1.328L2.875 1.56h3.246l6.086 8.523.945 1.328 7.91 11.078h-3.246zm0 0"></path>
                  </g>
                </svg>
              </a>

              <a
                href="https://www.pinterest.de/limowide/"
                className="w-8 h-8 bg-yellow-600 rounded-full flex justify-center items-center "
              >
                <FaPinterest className="h-5 w-5 fill-gray-800 " />
              </a>
            </div>
            
            <p
              style={{
                fontSize: 20,
                paddingTop: 5,
                color: "#c4a15f",
                fontWeight: 600,
                fontFamily: '"Poppins", sans-serif',
                marginBottom: 0,
              }}
            >
              {new Date(bookingDetailsData.pickupDate).toDateString()}
            </p>
          </div>

        </div>

        <div className="name-text" style={{ textAlign: "center", paddingTop: "5px" }}>
          <h3
            style={{
              fontSize: 46,
              color: "#0b4068",
              fontWeight: 700,
              fontFamily: '"Poppins", sans-serif',
            }}
          >
            Dear {bookingDetailsData.firstName} {bookingDetailsData.lastName}
          </h3>
          <p
            style={{
              maxWidth: 1218,
              fontSize: 18,
              color: "#000000",
              fontWeight: 400,
              fontFamily: '"Poppins", sans-serif',
              textAlign: "center",
              margin: "auto 20px",
            }}
          >
            Your reservation with Limowide has been confirmed. The service is
            described in detail below. Please double-check the pickup
            instructions.
          </p>
        </div>

        <div
          className="middle"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className="left"
            style={{
              width: "50%",
              backgroundColor: "#0b4068",
              padding: 15,
              textAlign: "center",
              margin: 20,
            }}
          >
            <h5
              style={{
                fontSize: 14,
                color: "#ffffff",
                fontWeight: "bold",
                marginBottom: 10,
                marginTop: 0,
              }}>Pickup - Instructions</h5>
            <p style={{ fontSize: 14, color: "#ffffff", marginBottom: 10 }}>
              Information you may use during your trip: Please remember to turn
              on and have your phone fully charged before the pickup.
            </p>
            <p style={{ fontSize: 14, color: "#ffffff", marginBottom: 10 }}>
              If you cannot find your driver at the stated pickup place, please
              call the driver directly at the phone number provided in the SMS
              and email containing the driver and car details. If you cannot
              reach the driver, please contact Limowide at +44 7458 148595.
            </p>
            <p style={{ fontSize: 14, color: "#ffffff", marginBottom: 10 }}>
              Two hours before your pickup, you will receive an SMS with driver
              and car information.
            </p>
            <p style={{ fontSize: 14, color: "#ffffff", marginBottom: 10 }}>
              Note: Be informed that we track flights and the driver will arrive
              at the airport when your flight arrives; If your flight landing
              time changes, our driver will arrive at the airport appropriately;
              you do not need to do anything.
            </p>
            <p style={{ fontSize: 14, color: "#ffffff", marginBottom: 10 }}>
              Integrated Waiting Period: {bookingDetailsData.waitingTime}{" "}
              minutes Beginning at the time of pickup as stated above, the
              driver will remain for the entire allotted waiting period. Please
              let your driver know in advance if you need more time to meet him
              or her (or call our emergency number). Be warned that additional
              fee may apply if you want driver to wait longer.
            </p>
            <p style={{ fontSize: 14, color: "#ffffff", marginBottom: 10 }}>
              Note: By accepting this offer, you confirm that you are familiar
              with the{" "}
              <Link
                to="/driver-cancellation-policy"
                style={{ textDecoration: "underline", color: "#ffffff" }}
              >
                Driver Cancellation Policy.
              </Link>{" "}
            </p>
          </div>

          <div
            className="right"
            style={{
              width: "50%",
              backgroundColor: "#0b4068",
              padding: 15,
              margin: 20,
            }}
          >
            <h3
                style={{
                  fontSize: 36,
                  color: "#ffffff",
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  marginTop: 0,
                }}
              >
                Reservation number : {bookingDetailsData.bookingId ?? ""}
            </h3>
            <p
              style={{
                fontSize: 14,
                color: "#ffffff",
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              Pickup Location:  {bookingDetailsData.pickup || ""}
            </p>
            <p
              style={{
                fontSize: 14,
                color: "#ffffff",
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                marginBottom: 30,
              }}
            >
              Drop Location:  { bookingDetailsData.destination || " "}
            </p>
            
            <div className="other">
              <p
                style={{
                  fontSize: 14,
                  color: "#ffffff",
                  fontWeight: 500,
                  fontFamily: '"Poppins", sans-serif',
                  margin: 0,
                }}
              >
                {" "}
                Vehicle category: {bookingDetailsData.car}
              </p>
              <p
                style={{
                  fontSize: 14,
                  color: "#ffffff",
                  fontWeight: 500,
                  fontFamily: '"Poppins", sans-serif',
                  margin: 0,
                }}
              >
                Name: {bookingDetailsData.firstName}{" "}
                {bookingDetailsData.lastName}
              </p>

              {!!bookingDetailsData.mobile?.length && (
                <p
                  style={{
                    fontSize: 14,
                    color: "#ffffff",
                    fontWeight: 500,
                    fontFamily: '"Poppins", sans-serif',
                    margin: 0,
                  }}
                >
                  Mob: {bookingDetailsData.mobile}
                </p>
              )}

              <p
                style={{
                  fontSize: 14,
                  color: "#ffffff",
                  fontWeight: 500,
                  fontFamily: '"Poppins", sans-serif',
                  margin: 0,
                }}
              >
                Travel date: {bookingDetailsData.pickupDate} at{" "}
                {bookingDetailsData.pickupTime}
              </p>

              <p
                style={{
                  fontSize: 14,
                  color: "#ffffff",
                  fontWeight: 500,
                  fontFamily: '"Poppins", sans-serif',
                  margin: 0,
                }}
              >
                No of Passengers: {bookingDetailsData.passenger}
              </p>

              <p
                style={{
                  fontSize: 14,
                  color: "#ffffff",
                  fontWeight: 500,
                  fontFamily: '"Poppins", sans-serif',
                  margin: 0,
                }}
              >
                No of Luggage: {bookingDetailsData.luggage}
              </p>

              <p
                style={{
                  fontSize: 14,
                  color: "#ffffff",
                  fontWeight: 500,
                  fontFamily: '"Poppins", sans-serif',
                  margin: 0,
                }}
              >
                Distance:{" "}
                {bookingDetailsData.distance
                  ? `${bookingDetailsData.distance} `
                  : ""}
              </p>

              <p
                style={{
                  fontSize: 14,
                  color: "#ffffff",
                  fontWeight: 500,
                  fontFamily: '"Poppins", sans-serif',
                  margin: 0,
                }}
              >
                Flight No : {bookingDetailsData.flightNumber || ""}
              </p>

              <p
                style={{
                  fontSize: 14,
                  color: "#ffffff",
                  fontWeight: 500,
                  fontFamily: '"Poppins", sans-serif',
                  margin: 0,
                }}
              >
                Special Instructions :{" "}
                {bookingDetailsData.specialInstructions?.length
                  ? bookingDetailsData.specialInstructions
                  : "N/A"}
              </p>

              {bookingDetailsData?.cancellationDetails?.cancellable && (
                <p
                  style={{
                    fontSize: 14,
                    color: "#ffffff",
                    fontWeight: 500,
                    fontFamily: '"Poppins", sans-serif',
                    margin: 0,
                  }}
                >
                  Free Cancellation Until :{" "}
                  {
                    bookingDetailsData.cancellationDetails
                      .freeCancellationUntil
                  }
                </p>
              )}
            </div>

            <div className="car" style={{ textAlign: "center", marginTop: 15 }}>
              <img
                src={bookingDetailsData?.car_raw?.fleet_image || ""}
                width={430}
                alt="Car"
              />
            </div>
          </div>
        </div>

        <footer>
          <div style={{ textAlign: "center" }}>
            <h3
              style={{
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                margin: 0,
                fontFamily: '"Poppins", sans-serif',
              }}
            >
              Limowide Limited
            </h3>
            <p
              style={{
                fontSize: 14,
                color: "#000000",
                margin: 0,
                fontFamily: '"Poppins", sans-serif',
              }}
            >
              1 Peach Pl, Wokingham RG40 1LY, United Kingdom.
            </p>
            <p
              style={{
                fontSize: 14,
                color: "#000000",
                margin: 0,
                fontFamily: '"Poppins", sans-serif',
              }}
            >
              <b>Contact our 14/7 Operations Hotline</b> - Tel: +44 7458 148595
            </p>
            <p
              style={{
                fontSize: 14,
                color: "#000000",
                margin: 0,
                fontFamily: '"Poppins", sans-serif',
              }}
            >
              Email: support@limowide.com
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Voucher;
