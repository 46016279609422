import React, { useEffect, useLayoutEffect, useState } from "react";
import BookingV2 from "../Components/BookingV2/index";
import NavBar from "../Components/NavBar";
import { Link } from "react-router-dom";
import {
  IoIosArrowRoundForward,
  IoIosCar,
  IoMdCall,
  IoMdClock,
  IoMdPricetag,
  IoMdBriefcase,
  IoMdBowtie,
} from "react-icons/io";
import TopCitiesV2 from "../Components/TopCitiesV2";
import Footer from "../Components/Footer";
import NavBarFixed from "../Components/NavBarFixed";
import Axios from "../Utils/Axios";
import { Helmet } from "react-helmet-async";

const Home = (props: any) => {
  const { currency, t } = props;
  const [showNormalNav, setShowNormalNav] = useState<boolean>(true);
  const [topCities, setTopCities] = useState<any>([]);

  const banner: any = [
    {
      url: "https://viennaairporttransfers.at/wp-content/uploads/2018/04/vienna_airport_taxi_slider2_done.jpg",
      title: "",
    },
    {
      url: "https://www.freeiconspng.com/uploads/audi-q7-car-png-image-black-jeep-2.png",
    },
  ];
  const banner2: any = [
    {
      url: require("../Assets/airportTranferLimowide.png"),
      title: "section_3_title_1",
      description: "section_3_sub_title_1",
      learnMoreLink: "/popular_locations/airport-transfer",
    },
    {
      url: require("../Assets/chauffeurServiceLimowide.png"),
      title: "section_3_title_3",
      description: "section_3_sub_title_3",
      learnMoreLink: "/popular_locations/chauffeur-service",
    },
    {
      url: require("../Assets/airporttransfer.jpg"),
      title: "section_3_title_2",
      description: "section_3_sub_title_2",
      learnMoreLink: "/popular_locations/best-city-tour-operator",
    },
  ];
  const banner3: any = {
    url: "https://media.istockphoto.com/photos/leather-seats-for-the-interior-of-a-black-car-picture-id186562615?k=20&m=186562615&s=612x612&w=0&h=Ny2gC5U9zhqmRiC7AfprNR61R8bovVJGpFQ1KQmBEtk=",
  };
  // const topCities: any = [
  //   {
  //     cityName: t("rovaniemi"),
  //     routes: "20",
  //     countryCode: "FI",
  //   },
  //   {
  //     cityName: t("stockholm"),
  //     routes: "8",
  //     countryCode: "SE",
  //   },
  //   {
  //     cityName: t("trondheim"),
  //     routes: "8",
  //     countryCode: "NO",
  //   },
  //   {
  //     cityName: t("riyadh"),
  //     routes: "6",
  //     countryCode: "SA",
  //   },
  //   {
  //     cityName: t("jeddah"),
  //     routes: "6",
  //     countryCode: "SA",
  //   },
  //   {
  //     cityName: t("mauritius"),
  //     routes: "5",
  //     countryCode: "MU",
  //   },
  // ];
  const menu2: any = [
    {
      url: require("../Assets/limowide_affordable.png"),
      title: "subtitle_1",
    },
    {
      url: require("../Assets/limowide_fleet.png"),
      title: "subtitle_2",
    },
    {
      url: require("../Assets/limowide_dedication.png"),
      title: "subtitle_3",
    },
    {
      url: require("../Assets/limowide_security.png"),
      title: "subtitle_4",
    },
  ];
  useLayoutEffect(() => {
    function updatePosition() {
      if (window.pageYOffset < 100) {
        setShowNormalNav(true);
      } else {
        setShowNormalNav(false);
      }
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  const getCitites = async (isMounted: boolean) => {
    const citiesResult = await Axios.get(`/api/v1/seo/city/cities-list/`);
    setTopCities(citiesResult.data);
  };
  useEffect(() => {
    let isMounted: boolean = true;
    getCitites(isMounted);
  }, []);
  const currentUrl = window.location.href;
  const addMeta = () => {
    return (
      <>
        <Helmet>
          <title>Book Private Taxi & Airport Transfers Worldwide</title>
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href={currentUrl} />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="WebPage" />
          <meta
            property="og:description"
            content="Limowide is a global platform for ground transportation, providing private taxi, airport transfers, and city tours in over 1,000 cities worldwide. Book convenient and reliable transportation services with Limowide today."
          />

          <meta name="twitter:url" content={currentUrl} />
        </Helmet>
        <Helmet></Helmet>

        <meta name="robots" content="index, follow" />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>Book Private Taxi & Airport Transfers Worldwide</title>
        <meta
          name="description"
          content="Limowide is a global platform for ground transportation, providing private taxi, airport transfers, and city tours in over 1,000 cities worldwide. Book convenient and reliable transportation services with Limowide today."
        />
      </Helmet>
      {addMeta()}
      <div className="top-0 z-40 overflow-x-hidden">
        <div
          className={`bg-no-repeat bg-blend-burn bg-cover h-[800px]`}
          style={{
            backgroundImage: `url(${require("../Assets/airportTranferLimowideHP1.png")})`,
          }}
        >
          <div className="absolute inset-0 bg-gray-900 bg-opacity-75 h-[800px] "></div>
          {showNormalNav ? (
            <NavBarFixed isHome={true} currency={currency} t={t} />
          ) : (
            <NavBarFixed currency={currency} t={t} />
          )}
          {/* Booking Section */}
          <div className="flex flex-row-reverse lg:mr-12 xl:mr-12 md:mr-12 p-4 ">
            <div className="max-w-md right-[0%] mt-24 z-40">
              <BookingV2 currency={currency} t={t} />
            </div>
          </div>
        </div>

        {/* </div> */}
        {menu2?.[0] && (
          <div className="py-4 z-40 bg-gray-800">
            <div className="grid grid-cols-4 p-2">
              {menu2.map((menu: any, index: number) => (
                <div
                  key={`menu1_${index}`}
                  className="col-span-4 sm:col-span-4 md:col-span-1 xl:col-span-1 2xl:col-span-1 p-2"
                >
                  <div className="flex flex-col justify-center items-center">
                    <div className="flex justify-center items-center h-32 w-32 bg-white rounded-full">
                      <img src={menu.url} alt="bg" className={`h-20 w-20`} />
                    </div>
                    <p className="mt-2 text-yellow-600 text-bold text-lg">
                      {t(menu.title)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="bg-white ">
          <div className="grid grid-cols-3 gap-4 p-24 justify-center items-center">
            <div className="lg:col-span-1 col-span-3">
              <h1 className="text-6xl font-bolder text-yellow-600">
                {t("luxary_cars")}
              </h1>
              <p className="text-4xl font-bolder">{t("section_2_title")}</p>
              <p className="mt-4">{t("section_2_sub_title")}</p>
              <div className="flex flex-row justify-around w-40 mt-8 bg-yellow-600 py-4 px-6 text-gray-800">
                <button>{t("read_more")}</button>
                <IoIosArrowRoundForward className="cursor-pointer" size={25} />
              </div>
            </div>
            <div className="lg:col-span-2 col-span-3">
              <img src={banner[1].url} alt="bg" />
            </div>
          </div>
        </div>
        <div className="bg-white lg:px-24 lg:py-8 px-16 py-4">
          <div className="flex justify-center text-center pb-12">
            <h3 className="text-4xl font-bold">{t("our_services")}</h3>
          </div>
          <div className="grid grid-cols-3 gap-4 justify-center">
            {banner2?.[0] &&
              banner2.map((banner: any, index: number) => (
                <div
                  className="lg:col-span-1 col-span-3"
                  key={`banner2_${index}`}
                >
                  <img
                    src={banner.url}
                    alt="b2"
                    className="rounded-xl opacity-75 h-52 w-full"
                  />
                  <p className="mt-2 text-xl font-semibold">
                    {t(banner.title)}
                  </p>
                  <p className="mt-2 text-gray-500">{t(banner.description)}</p>
                  <Link
                    className="underline decoration-solid mt-2 font-semibold"
                    to={banner.learnMoreLink}
                  >
                    {t("learn_more")}
                  </Link>
                </div>
              ))}
          </div>
        </div>

        <div className="bg-gray-100 lg:px-24 lg:pb-20 lg:pt-6 px-16 pb-12 pt-3">
          <div className="flex justify-center text-center py-6">
            <h3 className="text-4xl font-bold">{t("section_3_title_1")}</h3>
          </div>
          <div>
            <p className="my-4 text-xl font-semibold">{t("top_cities")}</p>
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 justify-center">
              {topCities?.[0] &&
                topCities.map((city: any, index: number) => (
                  <TopCitiesV2 city={city} index={index} key={index} />
                ))}
            </div>

            <div className="py-2 flex justify-center items-center">
              <Link
                to="cities"
                className="px-4 py-2 mt-2 items-center flex gap-2 rounded-md bg-gray-800 text-white font-bold  hover:bg-yellow-600"
              >
                View All
              </Link>
            </div>
          </div>
          {/* <div>
            <p className="my-4 text-xl font-semibold">Top routes</p>
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 justify-center">
              {topRoutes?.[0] &&
                topRoutes.map((rotuteDetails: any, index: number) => (
                  <TopRoutes
                    rotuteDetails={rotuteDetails}
                    index={index}
                    key={index}
                  />
                ))}
            </div>
          </div> */}
        </div>
        <div className="lg:px-24 lg:pb-20 lg:pt-16 px-16 pb-12 pt-3 bg-gray-800">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 justify-center">
            <div className="lg:col-span-1 col-span-2">
              <img
                src={require("../Assets/booklimowide.png")}
                alt="section3"
                className="h-[35rem] w-full object-contain"
              />
            </div>
            <div className="lg:col-span-1 col-span-2 lg:px-10 xl:px-10 md:px-10">
              <div>
                <p className="text-white text-4xl font-semibold">
                  {t("section_3_title_a")}{" "}
                  <span className="text-yellow-600">
                    {t("section_3_title_b")}
                  </span>
                </p>
              </div>
              <div className="my-20">
                <div className="flex flex-row">
                  <div className="grid justify-items-center border-r border-gray-500 border-dashed lg:p-4 xl:p-4 md:p-4 p-2 lg:w-72 xl:w-72 md:w-72 w-52">
                    <IoIosCar className="text-center text-yellow-600 cursor-pointer h-12 w-12 lg:h-16 lg:w-16 xl:h-16 xl:w-16 md:w-16 md:h-16" />
                    <p className="text-white font-semibold text-center">
                      {t("section_3_1")}
                    </p>
                  </div>
                  <div className="grid justify-items-center border-r border-gray-500 border-dashed lg:p-4 xl:p-4 md:p-4 p-2 lg:w-72 xl:w-72 md:w-72 w-52">
                    <IoMdCall className="text-yellow-600 cursor-pointer h-12 w-12 lg:h-16 lg:w-16 xl:h-16 xl:w-16 md:w-16 md:h-16" />
                    <p className="text-white font-semibold text-center">
                      {t("section_3_2")}
                    </p>
                  </div>
                  <div className="grid justify-items-center lg:p-4 xl:p-4 md:p-4 p-2 lg:w-72 xl:w-72 md:w-72 w-52">
                    <IoMdClock className="text-yellow-600 cursor-pointer h-12 w-12 lg:h-16 lg:w-16 xl:h-16 xl:w-16 md:w-16 md:h-16" />
                    <p className="text-white font-semibold text-center">
                      {t("section_3_3")}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row mt-16">
                  <div className="grid justify-items-center border-r border-gray-500 border-dashed lg:p-4 xl:p-4 md:p-4 p-2 lg:w-72 xl:w-72 md:w-72 w-52">
                    <IoMdBriefcase className="text-center text-yellow-600 cursor-pointer h-12 w-12 lg:h-16 lg:w-16 xl:h-16 xl:w-16 md:w-16 md:h-16" />
                    <p className="text-white font-semibold text-center">
                      {t("section_3_4")}
                    </p>
                  </div>
                  <div className="grid justify-items-center border-r border-gray-500 border-dashed lg:p-4 xl:p-4 md:p-4 p-2 lg:w-72 xl:w-72 md:w-72 w-52">
                    <IoMdPricetag className="text-yellow-600 cursor-pointer h-12 w-12 lg:h-16 lg:w-16 xl:h-16 xl:w-16 md:w-16 md:h-16" />
                    <p className="text-white font-semibold text-center">
                      {t("section_3_5")}
                    </p>
                  </div>
                  <div className="grid justify-items-center lg:p-4 xl:p-4 md:p-4 p-2 lg:w-72 xl:w-72 md:w-72 w-52">
                    <IoMdBowtie className="text-yellow-600 cursor-pointer h-12 w-12 lg:h-16 lg:w-16 xl:h-16 xl:w-16 md:w-16 md:h-16" />
                    <p className="text-white font-semibold text-center">
                      {t("section_3_6")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
