import React from "react";

function BagIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 21 19"
    >
      <path
        fill="#000"
        d="M18.667 4h-3V2c0-1.11-.89-2-2-2h-6c-1.11 0-2 .89-2 2v2h-3c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm-11-2h6v2h-6V2zm11 15h-16v-2h16v2zm0-5h-16V7c0-.55.45-1 1-1h2v1c0 .55.45 1 1 1s1-.45 1-1V6h6v1c0 .55.45 1 1 1s1-.45 1-1V6h2c.55 0 1 .45 1 1v5z"
      ></path>
    </svg>
  );
}

export default BagIcon;
