import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Axios from "../Utils/Axios";
import { AiOutlineMail } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import Footer from "../Components/Footer";
import { ColorRing } from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { loginSuccess, selectUserSettings } from "../store/authSlice";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  IResolveParams,
} from "reactjs-social-login";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isLoggedIn } from "../store/authSlice";
import { Helmet } from "react-helmet-async";

const loginschema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

const REDIRECT_URI = "https://limowide.com/login";

const LogoContent = (navigate: any) => {
  const userSettings = useSelector(selectUserSettings);

  <>
    <p className="text-4xl font-bolder text-white">
      Welcome back to {userSettings?.site_name}
    </p>
    <img
      src={userSettings?.site_logo}
      className="h-20 cursor-pointer"
      alt={userSettings?.site_name}
      onClick={() => navigate("/")}
    />
  </>;
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
type LoginForm = {
  email: string;
  password: string;
};

const Login = (props: any) => {
  const location = useLocation();
  const navigate: any = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const isLoggedin = useSelector(isLoggedIn);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: yupResolver(loginschema),
  });

  useEffect(() => {
    if (isLoggedin) {
      navigate("/");
    }
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
  }, []);
  const onLoginSuccess = useCallback(
    async ({ provider, data }: IResolveParams) => {
      setProfile(null);
      setProvider(provider);
      const payload = {
        provider: provider,
        access_token: data?.access_token,
        first_name: data?.given_name,
        last_name: data?.family_name,
      };
      const config: any = {
        method: "POST",
        url: "/api/v1/socail/login/",
        data: payload,
      };
      const loginRes: any = await Axios(config).catch((e: any) => {
        setIsLoading(false);
        setError(
          e?.response?.data?.data?.email || e?.response?.data?.data?.password
        );
      });
      if (loginRes?.data?.token?.access_token) {
        setError(null);
        Axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${loginRes?.data?.token?.access_token}`;
        dispatch(loginSuccess(loginRes?.data));
        navigate("/");
      }
    },
    []
  );

  const onSubmit = async (data: any) => {
    try {
      // debugger;
      if (!data?.email || !data?.password) {
        // setError('Email and password must be provided.');
        return null;
      }
      setIsLoading(true);
      const config: any = {
        method: "POST",
        url: "/api/v1/login/",
        data: data,
      };
      const loginRes: any = await Axios(config).catch((e: any) => {
        setIsLoading(false);
        setError(
          e?.response?.data?.data?.email || e?.response?.data?.data?.password
        );
      });
      if (loginRes?.data?.token?.access_token) {
        localStorage.setItem("userType", loginRes?.data?.type);
        setError(null);
        Axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${loginRes?.data?.token?.access_token}`;
        dispatch(loginSuccess(loginRes?.data));
        localStorage.setItem("last_session", new Date().toISOString());
        navigate("/");
      }
    } catch (err: any) {
      // debugger
      setError("Login Failed!");
      setIsLoading(false);
    }
  };

  const userSettings = useSelector(selectUserSettings);

  return (
    <div className="h-screen bg-gray-800 grid grid-cols-2">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <div className="lg:flex hidden flex-col justify-center items-center  text-center bg-gray-800 p-4">
        <p className="text-4xl font-bolder text-white">
          Welcome back to {userSettings?.site_name}
        </p>
        <img
          src={userSettings?.site_logo}
          className="h-20 cursor-pointer"
          alt={userSettings?.site_name}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="col-span-2 lg:col-span-1 flex flex-col justify-center items-center text-center bg-gray-800 p-4">
        <div className="flex-col flex justify-center items-center text-center lg:hidden">
          {LogoContent(navigate)}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white px-6 py-8 rounded-xl mt-6">
            <p className="text-3xl font-bold">Login</p>
            <div className="py-2 flex mt-10">
              <AiOutlineMail className="w-10 h-10" />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ required: "Email is required" }}
                render={({ field }) => (
                  <input
                    type="email"
                    {...field}
                    placeholder="Enter your email"
                    autoComplete="username"
                    className={
                      "py-2 pl-12 w-80 border-b-2 focus:outline-0 focus:shadow-lg " +
                      (errors.email ? "border-red-700" : "border-blue-400")
                    }
                  />
                )}
              />
            </div>
            {errors.email && (
              <p className="text-red-500 mt-2 text-lg capitalize" role="alert">
                Enter valid Email
              </p>
            )}
            <div className="py-2 flex">
              <RiLockPasswordLine className="w-10 h-10" />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: "Password is required" }}
                render={({ field }) => (
                  <input
                    type="password"
                    {...field}
                    placeholder="Enter your password"
                    autoComplete="current-password"
                    className={
                      "py-2  pl-12 w-80 border-b-2 focus:outline-0 focus:shadow-lg " +
                      (errors.password ? "border-red-700" : "border-blue-400")
                    }
                  />
                )}
              />
            </div>
            {errors.password && (
              <p className="text-red-500 mt-2 text-lg capitalize">
                {errors.password?.message}
              </p>
            )}
            <div className="mt-5">
              <div className="flex justify-between items-center w-80">
                <div className="flex space-x-2 items-center">
                  <input type="checkbox" onClick={() => setError(null)} />
                  <p>Remember Me</p>
                </div>
                <a href="/forgot-password" className="text-blue-500">
                  Forget password?
                </a>
              </div>
            </div>
            {error && (
              <div>
                <p className="text-red-500 mt-4 capitalize text-lg">{error}</p>
              </div>
            )}
            {isLoading ? (
              <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{ float: "right", marginRight: "40%" }}
                wrapperClass="blocks-wrapper"
                colors={["#b8c480", "#B2A3B5", "#F4442E", "#51E5FF", "#429EA6"]}
              />
            ) : (
              <input
                className="w-full block mt-6 bg-gradient-to-r bg-gray-800  px-10 py-4 rounded-lg text-white text-lg font-bold hover:bg-yellow-600"
                type="submit"
                value="Login"
              />
            )}

            <button
              onClick={() => navigate("/signup")}
              className="w-full mt-6 rounded-lg bg-gray-600 px-10 py-4 text-white font-bold"
            >
              Signup
            </button>
            <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
              <p className="mx-4 mb-0 text-center font-semibold dark:text-white">
                Or
              </p>
            </div>
            <div className="flex justify-around">
              <p className="m-2 mb-0 text-lg">Sign in with</p>

              <LoginSocialGoogle
                client_id="207724466343-7ubs3lrb7208q2s6evjsh5tlb19s3jt2.apps.googleusercontent.com"
                redirect_uri={REDIRECT_URI}
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"
                onResolve={onLoginSuccess}
                onReject={(err) => {
                  console.log(err);
                }}
              >
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="inline-block rounded-full bg-primary p-2 uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                  <svg
                    width="35px"
                    height="35px"
                    viewBox="0 0 32 32"
                    data-name="Layer 1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.75,16A7.7446,7.7446,0,0,1,8.7177,18.6259L4.2849,22.1721A13.244,13.244,0,0,0,29.25,16"
                      fill="#00ac47"
                    />
                    <path
                      d="M23.75,16a7.7387,7.7387,0,0,1-3.2516,6.2987l4.3824,3.5059A13.2042,13.2042,0,0,0,29.25,16"
                      fill="#4285f4"
                    />
                    <path
                      d="M8.25,16a7.698,7.698,0,0,1,.4677-2.6259L4.2849,9.8279a13.177,13.177,0,0,0,0,12.3442l4.4328-3.5462A7.698,7.698,0,0,1,8.25,16Z"
                      fill="#ffba00"
                    />
                    <polygon
                      fill="#2ab2db"
                      points="8.718 13.374 8.718 13.374 8.718 13.374 8.718 13.374"
                    />
                    <path
                      d="M16,8.25a7.699,7.699,0,0,1,4.558,1.4958l4.06-3.7893A13.2152,13.2152,0,0,0,4.2849,9.8279l4.4328,3.5462A7.756,7.756,0,0,1,16,8.25Z"
                      fill="#ea4435"
                    />
                    <polygon
                      fill="#2ab2db"
                      points="8.718 18.626 8.718 18.626 8.718 18.626 8.718 18.626"
                    />
                    <path
                      d="M29.25,15v1L27,19.5H16.5V14H28.25A1,1,0,0,1,29.25,15Z"
                      fill="#4285f4"
                    />
                  </svg>
                </button>
              </LoginSocialGoogle>
              <LoginSocialFacebook
                appId="1226842592047274"
                fieldsProfile={
                  "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                }
                onLogoutSuccess={onLogoutSuccess}
                redirect_uri={REDIRECT_URI}
                onResolve={({ provider, data }: IResolveParams) => {
                  setProvider(provider);
                  setProfile(data);
                }}
                onReject={(err) => {
                  console.log(err);
                }}
              >
                <button
                  type="button"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  className="inline-block rounded-full bg-primary p-2 uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                >
                  {/* <img alt="google logo" src="//in.bmscdn.com/webin/common/icons/googlelogo.svg" /> */}
                  <svg
                    width="40px"
                    height="40px"
                    viewBox="126.445 2.281 589 589"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx={420.945}
                      cy={296.781}
                      r={294.5}
                      fill="#3c5a9a"
                    />
                    <path
                      d="M516.704 92.677h-65.239c-38.715 0-81.777 16.283-81.777 72.402.189 19.554 0 38.281 0 59.357H324.9v71.271h46.174v205.177h84.847V294.353h56.002l5.067-70.117h-62.531s.14-31.191 0-40.249c0-22.177 23.076-20.907 24.464-20.907 10.981 0 32.332.032 37.813 0V92.677h-.032z"
                      fill="#ffffff"
                    />
                  </svg>
                </button>
              </LoginSocialFacebook>
            </div>
          </div>
        </form>
      </div>
      <div className="bg-gray-800 w-full col-span-2">
        <Footer />
      </div>
    </div>
  );
};

export default Login;
