import React, { useEffect, useState } from "react";
import Select from "react-select/async";
import AutoComplete from 'react-google-autocomplete';
import { Search, SearchLatLng } from "../../Utils/Search";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import { pickupSelected, pickupGeometrySelected, selectPickupInput } from '../../store/searchSlice';

const PickupSelection = (props: any) => {
  const { t, reload } = props;
  const dispatch = useDispatch();
  const value = useSelector(selectPickupInput);
  const [pickupLatLng, setPickupLatLng] = useState<any>("");
  const [isLoading, setIsLoading] = useState<any>(false);
  const handlePickupChange = (value: any) => {
    // console.log(value.value.description);
    const pickup_locationVal = {
      label: value?.label ?? '',
      value: value?.value?.place_id
    };
    // console.log(pickup_locationVal.label);
    dispatch(pickupSelected(pickup_locationVal));
    localStorage.setItem("pickup_location", JSON.stringify(pickup_locationVal));
    if (value != null && value?.label) {
      setTimeout(() => {
        geocodeByAddress(value?.label ?? '')
          .then(results => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            localStorage.setItem("pickup_geometry", JSON.stringify({
              lat: lat, lng: lng
            }));
            dispatch(pickupGeometrySelected({
              lat: lat, lng: lng
            }));
            // dispatch(pickupSelected(pickup_locationVal));
          }).catch(error => console.error('Error', error));
      }, 2000);
    }
  }

  return (
    <div className="border border-gray-300 rounded py-4 px-6 h-32 max-w-md">
      <label className="text-sm">{t("pickup_selection_title")} *</label>
      {/* <Select
        styles={{
          menu: (provided, state) => ({
            ...provided,
            zIndex: 50,
          }),
        }}
        placeholder={t("search_pickup_placeholder")}
        noOptionsMessage={({ inputValue }: any) =>
          `${t("no_matches")} ${inputValue}`
        }
        value={pickupLocation}
        defaultOptions
        isLoading={isLoading}
        loadOptions={searching}
        onChange={setSelectedLocation}
      /> */}
      <GooglePlacesAutocomplete
        apiKey="AIzaSyBPqss2h4k9JImLtg7PkPy7zr0iv2-P_OA"
        selectProps={{
          value,
          onChange: handlePickupChange,
        }}
      />
      {/* <AutoComplete
        className='mt-2 h-50 w-100'
        apiKey={'AIzaSyA4psHg_qdEKq2bD7ya5c7xw-ZjxZobB-s'}
        options={{
          types:["airport","cities"]
        }}
        onPlaceSelected={(place: any) => {
          // var latitude = place.geometry.location.lat();
          // var longitude = place.geometry.location.lng();
          console.log(place);
          // setCoords({
          //   lat: latitude,
          //   lng: longitude
          // })
          // updateWeather();
        }}
      /> */}
      <div>
        <span>{t("pickup_sub_title")}</span>
      </div>
    </div>
  );
};

export default PickupSelection;
