import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import userreducer from "./authSlice";
import bookingreducer from "./bookingSlice";
// import bookingreducer from './bookingreducer';
import searchreducer from "./searchSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // localStorage

const persistConfig = {
  key: "root",
  storage,
};
const rootReducer = combineReducers({
  auth: userreducer,
  search: searchreducer,
  booking: bookingreducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
const persistor = persistStore(store);

export { store, persistor };
