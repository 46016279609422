import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "../../Utils/Axios";
import VerificationInput from "react-verification-input";
import { ArrowLeftIcon } from "../../icons";
interface PingPageProps {
  pinModalOpen: boolean;
  journeyId: string;
  setPinModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDestination: React.Dispatch<React.SetStateAction<boolean>>;
  setDriverArriving: React.Dispatch<React.SetStateAction<boolean>>;
}

const PingPage: React.FC<PingPageProps> = ({
  pinModalOpen,
  journeyId,
  setPinModalOpen,
  setDestination,
  setDriverArriving,
}) => {
  const [pin, setPin] = useState<string>("");
  const [location, setLocation] = useState<any>({});
  // console.log("pin is...", journeyId, pin);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, long: longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);
  const handlePinChange = (value: string) => {
    setPin(value);
  };

  const configJPin: any = {
    method: "POST",
    url: `/api/v1/driverservices/customer-onboard/${journeyId}/`,
    data: { onBoardLocation: `${location.lat}, ${location.long}`, pin },
    // data: { onBoardLocation: `13.082679, 80.2708842`, pin },
  };
  // API for move on Step 2
  const onSubmitPin = async () => {
    const journeyStartResp: any = await Axios(configJPin).catch((e: any) => {});
    console.log("destination reached Resp...", journeyStartResp);
    if (journeyStartResp?.status === 200) {
      setDriverArriving(false);
      localStorage.setItem("driverArriving", "false");
      setDestination(true);
      localStorage.setItem("destination", "true");
      setPinModalOpen(false);
    }
  };

  return (
    <div className="flex flex-col bg-white min-w-[200px] min-h-[200px] px-5 py-8">
      <button
        className="flex items-center gap-3"
        onClick={(e) => setPinModalOpen(false)}
      >
        <div className="flex items-center justify-center h-6 w-7">
          <ArrowLeftIcon />
        </div>
        <h1 className="text-black font-medium sm:text-2xl text-xl">
          Enter PIN No
        </h1>
      </button>
      <div className="flex flex-col gap-10 mt-10">
        <h1 className="text-black font-light text-base">
          Enter customer PIN to start the journey 
        </h1>
        <div className="w-full otp-input">
          <VerificationInput
            length={4}
            inputProps={{ autoComplete: "one-time-code" }}
            placeholder=""
            passwordMode={true}
            onChange={handlePinChange}
          />
        </div>
      </div>
      <button
        className="mt-40 bg-[#EBAD18] py-3 px-5 text-black font-bold text-lg rounded-full text-center"
        onClick={onSubmitPin}
      >
        Continue
      </button>
    </div>
  );
};

export default PingPage;
