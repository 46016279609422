import React from "react";
import Select from "react-select";

const BookingHours = (props: any) => {
  const { selectedDuration, setSelectedDuration, t } = props;
  return (
    <div className="flex flex-col border border-gray-300 rounded py-4 px-6 h-32 sm:col-span-1">
      <label className="text-sm">Duration *</label>
      {/* <input
        className="block w-full border-b-2 border-black focus:outline-none mt-2 font-bold"
        name="mobile"
        type="phone"
        value={selectedDuration || ""}
        onChange={(e: any) => setSelectedDuration(e?.target?.value || 0)}
      /> */}
      <Select
        placeholder={t("booking_hours_placeholder")}
        defaultValue={selectedDuration}
        options={[
          { label: 1, value: 1 },
          { label: 2, value: 2 },
          { label: 3, value: 3 },
          { label: 4, value: 4 },
          { label: 5, value: 5 },
          { label: 6, value: 6 },
          { label: 7, value: 7 },
          { label: 8, value: 8 },
          { label: 9, value: 9 },
          { label: 10, value: 10 },
          { label: 11, value: 11 },
          { label: 12, value: 12 },
          { label: 13, value: 13 },
          { label: 14, value: 14 },
          { label: 15, value: 15 },
          { label: 16, value: 16 },
          { label: 17, value: 17 },
          { label: 18, value: 18 },
          { label: 19, value: 19 },
          { label: 20, value: 20 },
          { label: 21, value: 21 },
          { label: 22, value: 22 },
          { label: 23, value: 23 },
          { label: 24, value: 24 },
        ]}
        onChange={setSelectedDuration}
        className="cursor-pointer" 
      />
      <span>{t("booking_hours")}</span>
    </div>
  );
};

export default BookingHours;
