import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaPencilAlt, FaSave, FaSpinner, FaTrashAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";
import Axios from "../../../Utils/Axios";
import { selectUserSettings, updateUsername } from "../../../store/authSlice";
import { currencyChanged } from "../../../store/searchSlice";
import {
  editAccountByTravelAgentSchema,
  editAccountSchema,
  schemaWithPasswordValidation,
} from "./account.schema";
import countries from "./countries.json";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useUserCountry } from "../../../Utils/user-user-country";

function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });
}

export const Account = (props: any) => {
  const [currencies, setCurrencies] = useState([
    {
      value: "USD",
      label: "🇺🇸",
    },
    {
      value: "INR",
      label: "🇮🇳",
    },
    {
      value: "EUR",
      label: "🇪🇺",
    },
    {
      value: "YEN",
      label: "🇯🇵",
    },
    {
      value: "AED",
      label: "🇦🇪",
    },
    {
      value: "AUD",
      label: "🇦🇺",
    },
    {
      value: "CAD",
      label: "🇨🇦",
    },
    {
      value: "CHF",
      label: "🇨🇭",
    },
    {
      value: "GBP",
      label: "🇬🇧",
    },
    {
      value: "SAR",
      label: "🇸🇦",
    },
    {
      value: "NOK",
      label: "🇳🇴",
    },
    {
      value: "SEK",
      label: "🇸🇪",
    },
    {
      value: "OMR",
      label: "🇴🇲",
    },
    {
      value: "KWD",
      label: "🇰🇼",
    },
    {
      value: "DKK",
      label: "🇩🇰",
    },
    {
      value: "JOD",
      label: "🇯🇴",
    },
    {
      value: "BHD",
      label: "🇧🇭",
    },
    {
      value: "CNY",
      label: "🇨🇳",
    },
  ]);
  const { currency, t } = props;

  const dispatch = useDispatch();
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const auth = useSelector((state: any) => state.auth);
  const userSettings = useSelector(selectUserSettings);
  const companyLogoInputRef = useRef<HTMLInputElement>(null);

  const userCountry = useUserCountry();

  const isTravelAgent = useMemo(
    () => auth?.user?.type === "travelagent",
    [auth]
  );

  const form = useForm({
    resolver: zodResolver(
      schemaWithPasswordValidation(
        isTravelAgent ? editAccountByTravelAgentSchema : editAccountSchema
      )
    ),
  });

  const errors = form.formState.errors;

  const loadUserInfo = async () => {
    const config: any = {
      method: "GET",
      url: `/api/v1/user/detail/`,
    };
    const userResponse: any = await Axios(config).catch((e: any) => {});
    if (userResponse?.data?.data) {
      const user = userResponse?.data?.data;
      SetEmail(user?.email);
      SetPhone(user?.phone);

      const { first_name, last_name, newsletter, ...otherUserDetails } =
        user ?? {};

      form.setValue("firstName", first_name);
      form.setValue("lastName", last_name);
      form.setValue("enabledNewsletter", !!newsletter);

      Object.keys(otherUserDetails).forEach((key) => {
        form.setValue(key, otherUserDetails[key]);
      });

      form.setValue(
        "userCurrency",
        user?.currency?.length ? user.currency : currency
      );
    }
  };
  useEffect(() => {
    loadUserInfo();
  }, []);

  const updateUser = async (
    values: Zod.infer<typeof editAccountSchema> &
      Partial<Zod.infer<typeof editAccountByTravelAgentSchema>>
  ) => {
    const travelAgentExtraPayload: Record<string, any> = {
      city: values.city,
      street_address_1: values.street_address_1,
      street_address_2: values.street_address_2,
      pincode: String(values.pincode),
      vat_id: values.vat_id,
      company_name: values.company_name,
      country: values.country,
    };

    if (values.logo?.startsWith("data")) {
      travelAgentExtraPayload.logo = values.logo;
    }

    const userconfig: any = {
      method: "PUT",
      url: `/api/v1/user/update-detail/`,
      data: {
        first_name: values.firstName,
        last_name: values.lastName,
        phone: values.phone,
        is_newsletter: values.enabledNewsletter,
        currency_code: values.userCurrency,

        ...(isTravelAgent ? travelAgentExtraPayload : {}),
      },
    };

    await Axios(userconfig)
      .catch((e: any) => {
        if (e.response.status === 500)
          toast.error("Internal Server Error", {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          });
      })
      .then((response) => {
        if (response?.status === 200) {
          toast.success("Profile Updated Successfully.", {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          });
          dispatch(
            updateUsername({
              first_name: values.firstName,
              last_name: values.lastName,
            })
          );
          dispatch(currencyChanged(values.userCurrency));
        }
      });

    const pwdconfig: any = {
      method: "POST",
      url: `/api/v1/change-password/`,
      data: {
        old_password: values.password,
        new_password: values.newpassword,
        confirm_password: values.confpassword,
      },
    };

    if (values.password && values.newpassword && values.confpassword) {
      const pwdresp: any = await Axios(pwdconfig)
        .catch((e: any) => {
          if (e?.response?.data) {
            console.log(e);
            toast.error(e?.response?.data?.data?.non_field_errors[0], {
              position: "top-center",
              autoClose: 3000,
              closeOnClick: true,
              transition: Slide,
            });
          }
        })
        .then((response) => {
          if (response) {
            console.log(response);
            toast.error("Password Update Error", {
              position: "top-center",
              autoClose: 3000,
              closeOnClick: true,
              transition: Slide,
            });
          }
        });
      if (pwdresp?.data) {
        toast.success("password Updated Successfully.", {
          position: "top-center",
          autoClose: 3000,
          closeOnClick: true,
          transition: Slide,
        });
      }
    }
  };

  const logo = form.watch("logo");

  return (
    <form
      className="bg-white px-6 py-4"
      onSubmit={form.handleSubmit(async (values) => {
        await updateUser(values as any);
      })}
    >
      <p className="mt-4 text-xl">Your login details</p>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="mt-2 px-2 space-y-2 w-full">
          <p>First Name</p>
          <input
            required
            type="text"
            placeholder="Enter First name"
            className="py-2 pl-2 w-full border border-gray-400"
            {...form.register("firstName")}
          />

          {errors?.firstName && (
            <span className="text-red-500">{errors.firstName.message}</span>
          )}
        </div>

        <div className="mt-2 px-2 space-y-2 w-full">
          <p>last Name</p>
          <input
            required
            type="text"
            placeholder="Enter Last name"
            className="py-2 pl-2 w-full border border-gray-400"
            {...form.register("lastName")}
          />

          {errors?.lastName && (
            <span className="text-red-500">{errors.lastName.message}</span>
          )}
        </div>

        <div className="mt-2 px-2 space-y-2 w-full">
          <p>Email</p>
          <p className="text-gray-500">{email}</p>
          <p className="text-gray-500">
            This is also your {userSettings?.site_name}'s username.
          </p>
        </div>

        <div className="mt-2 px-2 space-y-2 w-full" id="passengerphone">
          <p>Mobile</p>

          <Controller
            control={form.control}
            name="phone"
            rules={{ validate: (value) => isValidPhoneNumber(value) }}
            defaultValue={userCountry?.dial_code}
            render={({ field }) => (
              <PhoneInput
                placeholder="Enter phone number"
                value={field.value}
                onChange={(value) => {
                  if (value) field.onChange(value);
                  else field.onChange("");
                }}
                disabled={phone?.length > 5}
              />
            )}
          />

          {errors?.phone && (
            <span className="text-red-500">{errors.phone.message}</span>
          )}
        </div>
      </div>

      {isTravelAgent && (
        <>
          <p className="mt-4 text-xl">Business Details</p>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="mt-2 px-2 space-y-2 w-full">
              <p>Logo</p>

              {/* {logo?.length && ( */}
              {/* )} */}

              <div className="h-20 w-20 relative rounded-full border border-gray-300 ">
                <img
                  className="w-20 h-20 rounded-full object-cover"
                  src={logo?.length ? logo : "/company-logo-placeholder.jpg"}
                  alt="company logo"
                />

                <button
                  type="button"
                  className="p-2 rounded-full bg-white text-gray-500 absolute -bottom-1.5 -right-1.5 border border-gray-300 "
                  onClick={() => {
                    companyLogoInputRef.current?.click();
                  }}
                >
                  <FaPencilAlt />
                </button>
              </div>

              <input
                // required
                ref={companyLogoInputRef}
                type="file"
                accept="image/jpg, image/png, image/bmp, image/jpeg"
                placeholder="Enter First name"
                className="hidden"
                onChange={async (evt) => {
                  const [logo] = Array.from(evt.currentTarget.files ?? []);

                  form.clearErrors("logo");

                  const maxSize = 1024 * 1024; // 1MB

                  if (logo) {
                    if (logo.size > maxSize) {
                      return form.setError("logo", {
                        message: "Logo size can't be greater than 1MB",
                      });
                    }

                    form.setValue("logo", await fileToBase64(logo));
                  }
                }}
              />

              {errors?.logo && (
                <span className="text-red-500">{errors.logo.message}</span>
              )}
            </div>

            <div className="mt-2 px-2 space-y-2 w-full">
              <p>Company Name</p>
              <input
                required
                type="text"
                placeholder="Enter company name"
                className="py-2 pl-2 w-full border border-gray-400"
                {...form.register("company_name")}
              />
              {errors?.company_name && (
                <span className="text-red-500">
                  {errors.company_name.message}
                </span>
              )}
            </div>

            <div className="mt-2 px-2 space-y-2 w-full">
              <p>VAT ID</p>
              <input
                required
                type="text"
                placeholder="Enter VAT ID"
                className="py-2 pl-2 w-full border border-gray-400"
                {...form.register("vat_id")}
              />
              {errors?.vat_id && (
                <span className="text-red-500">{errors.vat_id.message}</span>
              )}
            </div>
          </div>

          <p className="mt-4 text-xl">Address</p>

          <div className="flex mt-2">
            <p className=" text-gray-500">Change yout address from here.</p>
          </div>

          <div className="flex flex-col mb-2 justify-between">
            <div className="mt-2 px-2 space-y-2 w-full">
              <p>Address Street 1</p>

              <input
                required
                type="text"
                placeholder="Enter address street 1"
                className="py-2 pl-2 w-full border border-gray-400"
                {...form.register("street_address_1")}
              />

              {errors?.street_address_1 && (
                <span className="text-red-500">
                  {errors.street_address_1.message}
                </span>
              )}
            </div>

            <div className="mt-2 px-2 space-y-2 w-full">
              <p>Address Street 2</p>

              <input
                type="text"
                placeholder="Enter address street 2"
                className="py-2 pl-2 w-full border border-gray-400"
                {...form.register("street_address_2")}
              />

              {errors?.street_address_2 && (
                <span className="text-red-500">
                  {errors.street_address_2.message}
                </span>
              )}
            </div>

            <div className="flex">
              <div className="mt-2 px-2 space-y-2 ">
                <p>Country</p>

                <select
                  required
                  id="address_country"
                  {...form.register("country", { value: "" })}
                  className="w-52 text-bold font-4xl p-2  hover:text-yellow-600 cursor-pointer  tracking-wide relative border-2 border-gray-200"
                >
                  {countries.map((country) => (
                    <option
                      key={country.code}
                      className="w-32 hover:text-yellow-600 text-black border-b-2"
                      value={country.name}
                    >
                      {country.name}
                    </option>
                  ))}
                </select>

                {errors?.country && (
                  <span className="text-red-500">{errors.country.message}</span>
                )}
              </div>

              <div className="mt-2 px-2 space-y-2">
                <p>City</p>

                <input
                  required
                  type="text"
                  placeholder="Enter city"
                  className={`py-2 pl-2 w-full border border-gray-400`}
                  {...form.register("city")}
                />

                {errors?.city && (
                  <span className="text-red-500">{errors.city.message}</span>
                )}
              </div>

              <div className="mt-2 px-2 space-y-2">
                <p>Pin Code</p>
                <input
                  required
                  placeholder="Enter pincode"
                  className="py-2 pl-2 w-full border border-gray-400"
                  {...form.register("pincode")}
                />

                {errors?.pincode && (
                  <span className="text-red-500">{errors.pincode.message}</span>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <p className="flex flex-col md:flex-row mt-4 text-xl">Email Newsletter</p>

      <div className="flex items-center">
        <div className="flex mt-2">
          <p className=" text-gray-500">
            Get the {userSettings?.site_name} Newsletter for the best travel
            deals and latest news. And remember, we HATE spam as much as you
            do—we will never, ever, sell or share your email address with
            spammers.
          </p>
        </div>
        <div className="flex mt-2 w-20 justify-center">
          <label className="inline-flex relative items-center cursor-pointer">
            <input
              type="checkbox"
              // checked={enabledNewsletter}
              id="checked-toggle"
              className="sr-only peer"
              // onChange={() => {
              //   setEnableNewsletter((oldState) => !oldState);
              // }}
              {...form.register("enabledNewsletter")}
            />
            <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>
      </div>

      <p className="flex flex-col md:flex-row mt-4 text-xl">Delete Account</p>

      <div className="flex justify-between items-center">
        <div className="flex mt-2">
          <p className="text-gray-500">
            Deleting your account will remove all the saved personal information
            & your trip details.
          </p>
        </div>

        <div className="flex mt-2 ml-2 justify-center">
          <button className="w-full flex rounded-lg bg-red-600 px-4 py-2 text-white font-bold items-center space-x-2">
            <FaTrashAlt className="w-4 h-4" />
            <span>Delete Account</span>
          </button>
        </div>
      </div>

      <p className="flex flex-col md:flex-row mt-4 text-xl">Change Password</p>

      <div className="flex justify-between items-center">
        <div className="flex mt-2">
          <p className="text-gray-500">
            Change your old password to a new password, we recommend doing this
            often.
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between">
        <div className="mt-2 px-2 space-y-2 w-full">
          <p>Old password</p>
          <input
            type="password"
            className="py-2 pl-2 w-full border border-gray-400"
            {...form.register("password")}
          />

          {errors?.password && (
            <span className="text-red-500">{errors.password.message}</span>
          )}
        </div>

        <div className="mt-2 px-2 space-y-2 w-full">
          <p>Set new password</p>
          <input
            type="password"
            className="py-2 pl-2 w-full border border-gray-400"
            {...form.register("newpassword")}
          />

          {errors?.newpassword && (
            <span className="text-red-500">{errors.newpassword.message}</span>
          )}
        </div>

        <div className="mt-2 px-2 space-y-2 w-full">
          <p>Retype new password</p>
          <input
            type="password"
            className="py-2 pl-2 w-full border border-gray-400"
            {...form.register("confpassword")}
          />

          {errors?.confpassword && (
            <span className="text-red-500">{errors.confpassword.message}</span>
          )}
        </div>
      </div>

      <p className="mt-4 text-xl">Set Currency</p>
      <p className=" text-gray-500">Set your currency for your referrence.</p>

      <div className="flex-row mt-2">
        <p>Currency Symbol</p>
        <div className="mt-2">
          <select
            id="language"
            {...form.register("userCurrency")}
            className="w-52 text-bold font-4xl p-2  hover:text-yellow-600 cursor-pointer  tracking-wide relative border-2 border-gray-200"
          >
            {currencies.map((currency: any, index: number) => (
              <option
                key={index}
                className="w-32 hover:text-yellow-600 text-black border-b-2"
                value={currency.value}
              >
                {currency.label} {currency.value}
              </option>
            ))}
          </select>

          {errors?.userCurrency && (
            <span className="text-red-500">{errors.userCurrency.message}</span>
          )}
        </div>
      </div>
      <div className="flex mt-2 justify-center">
        <button
          className="flex rounded-lg bg-yellow-600 px-4 py-2 text-white font-bold items-center space-x-2"
          // onClick={() => updateUser()}
          disabled={form.formState.isSubmitting}
          style={form.formState.isSubmitting ? { filter: "grayscale(1)" } : {}}
        >
          {form.formState.isSubmitting ? (
            <FaSpinner className="w-4 h-4 animate-spin" />
          ) : (
            <FaSave className="w-4 h-4" />
          )}

          <span>Update</span>
        </button>
      </div>
    </form>
  );
};
