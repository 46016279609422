function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 82 82"
    >
      <path
        fill="#EBAD18"
        d="M40.945 6.824c-14.33 0-27.297 10.987-27.297 27.979 0 11.328 9.11 24.737 27.297 40.262C59.13 59.54 68.24 46.131 68.24 34.803c0-16.992-12.966-27.979-27.296-27.979zm0 34.12c-3.753 0-6.824-3.07-6.824-6.824 0-3.753 3.07-6.824 6.824-6.824 3.753 0 6.824 3.071 6.824 6.825 0 3.753-3.07 6.824-6.824 6.824z"
      ></path>
    </svg>
  );
}

export default LocationIcon;
