import React, { useState, useEffect } from "react";
import {
  RiMapPinRangeFill,
  RiTimeFill,
  RiCarFill,
  RiBuilding4Fill,
} from "react-icons/ri";
import PickupSelection from "./PickupSelection";
import DropoffSelection from "./DropoffSelection";
import PickupDateTime from "./PickupDateTime";
import BookingHours from "./BookingHours";
import Counter from "../Counter";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectBookingType, selectPickupDate, selectPickupTime, selectNoofpass, selectNoofBags, selectDuration, selectPickupInput, selectDropoffInput, selectPickupGeo, selectDropoffGeo } from '../../store/searchSlice';
import { bookingTypeChanged, noofBagsSelected, durationSelected, noofPassSelected, pickupDateSelected, pickupTimeSelected } from '../../store/searchSlice';
const Booking = (props: any) => {
  const { t } = props;
  const navigate: any = useNavigate();
  const currentBookingTab = useSelector(selectBookingType);
  const selectedDate = useSelector(selectPickupDate);
  const selectedTime = useSelector(selectPickupTime);
  const selectedDuration = useSelector(selectDuration);
  const noOfPass = useSelector(selectNoofpass);
  const noOfBags = useSelector(selectNoofBags);
  const pickupInput = useSelector(selectPickupInput);
  const dropoffInput = useSelector(selectDropoffInput);
  const pickupGeo = useSelector(selectPickupGeo);
  const dropoffGeo = useSelector(selectDropoffGeo);
  const [voucher, setVoucher] = useState<any>("");
  const [reload, setReload] = useState<boolean>(false);
  const params = new URLSearchParams();

  const dispatch = useDispatch();

  const setCurrentBookingTab = (value: string) => {
    dispatch(bookingTypeChanged(value));
  };
  const setSelectedDate = (value: string) => {
    dispatch(pickupDateSelected(value));
    if (!value) return;
  };
  const setSelectedTime = (value: string) => {
    dispatch(pickupTimeSelected(value));
    if (!value) return;
  };
  const setSelectedDuration = (value: string) => {
    dispatch(durationSelected(value));
    if (!value) return;

  };

  const setNoOfPass = (value: string) => {
    dispatch(noofPassSelected(value));
  };

  const setNoOfBags = (value: string) => {
    dispatch(noofBagsSelected(value));
  };

  const activeClass: string =
    "justify-center items-center border-b-4 border-blue-900 grow hover:cursor-pointer active inline-flex text-sm font-medium text-center bg-gray-100 group";
  const normalClass: string =
    "justify-center items-center grow hover:cursor-pointer inline-flex text-sm font-medium text-center text-blue-900 bg-gray-100 hover:text-gray-700 group";

  useEffect(() => {
    const cookieVoucher: any = localStorage.getItem("voucher");
    if (cookieVoucher) {
      setVoucher(cookieVoucher);
    }

  }, []);


  useEffect(() => {
    if (!voucher) return;
    localStorage.setItem("voucher", voucher.toString());
  }, [voucher]);

  /** Adding passengers */
  const addPassengers = () => {
    let current: any = noOfPass;
    if (current < 10) current = parseInt(current) + 1;
    setNoOfPass(current);
    return null;
  };
  /** Remove passengers */
  const removePassengers = () => {
    let current: any = noOfPass;
    if (current > 1) current = parseInt(current) - 1;
    setNoOfPass(current);
    return null;
  };
  /** Adding passengers */
  const addBags = () => {
    let current: any = noOfBags;
    if (current < 10) current = parseInt(current) + 1;
    setNoOfBags(current);
    return null;
  };
  /** Remove passengers */
  const removeBaggs = () => {
    let current: any = noOfBags;
    if (current > 0) current = parseInt(current) - 1;
    setNoOfBags(current);
    return null;
  };
  // #dca94b
  /** update Voucher */
  const updateVoucher = (e: any) => {
    setVoucher(e.target.value);
  };
  const isValid: any = () => {
    let valid: boolean = true;
    if (
      !selectedDate ||
      !selectedTime ||
      !noOfPass ||
      !pickupInput.label 
    )
      valid = false;
    return valid;
  };

  return (
    <div>
      <div className="border-b border-gray-200 dark:border-gray-700">
        <ul className="flex -mb-px grow select-none">
          <li
            className={
              currentBookingTab === "p_to_p"
                ? `${activeClass} rounded-tl-md`
                : `${normalClass} rounded-tl-md`
            }
            onClick={(e: any) => {
              e.preventDefault();
              setCurrentBookingTab("p_to_p");
            }}
          >
            <div className="flex flex-col items-center pt-4 pb-2">
              <RiMapPinRangeFill
                size={25}
                className={`${currentBookingTab === "p_to_p" && "text-[#dca94b]"
                  }`}
              />
              <p>{t("booking_type_1")}</p>
            </div>
          </li>
          <li
            className={
              currentBookingTab === "intercity" ? activeClass : normalClass
            }
            onClick={(e: any) => {
              e.preventDefault();
              setCurrentBookingTab("intercity");
            }}
          >
            <div className="flex flex-col items-center pt-4 pb-2">
              <RiCarFill
                size={25}
                className={`${currentBookingTab === "intercity" && "text-[#dca94b]"
                  }`}
              />
              <p>{t("booking_type_2")}</p>
            </div>
          </li>
          <li
            className={
              currentBookingTab === "city_tour" ? activeClass : normalClass
            }
            onClick={(e: any) => {
              e.preventDefault();
              setCurrentBookingTab("city_tour");
            }}
          >
            <div className="flex flex-col items-center pt-4 pb-2">
              <RiBuilding4Fill
                size={25}
                className={`${currentBookingTab === "city_tour" && "text-[#dca94b]"
                  }`}
              />
              <p>{t("booking_type_3")}</p>
            </div>
          </li>
          <li
            className={
              currentBookingTab === "hourly"
                ? `${activeClass} rounded-tr-md`
                : `${normalClass} rounded-tr-md`
            }
            onClick={(e: any) => {
              e.preventDefault();
              setCurrentBookingTab("hourly");
            }}
          >
            <div className="flex flex-col items-center pt-4 pb-2">
              <RiTimeFill
                size={25}
                className={`${currentBookingTab === "hourly" && "text-[#dca94b]"
                  }`}
              />
              <p>{t("booking_type_4")}</p>
            </div>
          </li>
        </ul>
      </div>
      <div className="p-4 bg-white rounded-b-md select-none max-w-md">
        <div className="p-2 h-full">
          <div className="grid grid-flow-row grid-cols-1 gap-4">
            <PickupSelection t={t} reload={reload} />
            {(currentBookingTab === "hourly" || currentBookingTab === "city_tour") && (
              <div>
                <BookingHours
                  reload={reload}
                  t={t}
                  selectedDuration={selectedDuration}
                  setSelectedDuration={setSelectedDuration}
                />
              </div>
            )}
            {(currentBookingTab === "p_to_p" || currentBookingTab === "intercity") && (
              <div>
                <DropoffSelection t={t} reload={reload} setReload={setReload} />
              </div>
            )}
            <PickupDateTime
              t={t}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              setSelectedDate={setSelectedDate}
              setSelectedTime={setSelectedTime}
            />
          </div>
          <div className="grid grid-flow-row mt-2 grid-cols-1">
            <div className="flex justify-center items-center">
              <p className="w-full">
                {t("voucher_code")} ({t("optional")}):
              </p>
              <input
                type="text"
                placeholder={t("voucher_code_placeholder")}
                className="block w-full border-b-2 border-black focus:outline-none mt-2 font-bold"
                onChange={updateVoucher}
              />
            </div>
            <div className="flex justify-between items-center">
              <p>{t("passengers")}: </p>
              <Counter
                count={noOfPass}
                add={addPassengers}
                remove={removePassengers}
                setCount={setNoOfPass}
              />
              <p>{t("bags")} :</p>
              <Counter
                count={noOfBags}
                add={addBags}
                remove={removeBaggs}
                setCount={setNoOfBags}
              />
            </div>
            <div className="mt-4">
              <button
                className={`w-full block bg-gradient-to-r bg-gray-800  px-4 py-2 rounded-lg text-white text-lg font-bold ${isValid() ? "hover:bg-yellow-600" : "opacity-50 cursor-not-allowed"
                  }`}
                onClick={() => {
                  localStorage.removeItem("booking_id");
                  params.append("date", selectedDate);
                  params.append("time", selectedTime);
                  params.append("pickup", pickupInput.label);
                  params.append("dropoff", dropoffInput.label);
                  params.append("pass", noOfPass);
                  params.append("bags", noOfBags);
                  params.append("pickup_lat", pickupGeo?.lat);
                  params.append("drop_lat", dropoffGeo?.lat);
                  params.append("pickup_lng", pickupGeo?.lng);
                  params.append("drop_lng", dropoffGeo?.lng);
                  params.append("duration", selectedDuration.value);
                  params.append("type", currentBookingTab);
                  navigate("/booking?" + params.toString());
                  console.log(params)
                }}
                disabled={!isValid()}
              >
                {t("start_booking")}
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;

// const memoizedBooking = React.memo(Booking);

// export default memoizedBooking;