import React, { useState } from "react";
import { ArrowRightIcon } from "../../icons";
interface AnimatedButtonProps {
  onClick: () => void;
  shouldReturn: boolean;
  setShouldReturn: React.Dispatch<React.SetStateAction<boolean>>;
  clicked: boolean;
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;

  text: string;
}
const AnimatedButton: React.FC<AnimatedButtonProps> = ({
  onClick,
  shouldReturn,
  setShouldReturn,
  clicked,
  setClicked,
  text,
}) => {
  //   const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => {
      onClick();
      if (shouldReturn) {
        setTimeout(() => {
          setClicked(false);
          setShouldReturn(false);
        }, 300);
      }
    }, 300);
  };

  return (
    <button
      className={`button inline-block relative overflow-hidden p-1 bg-[#EBAD18] w-full h-12 rounded-full gap-2 ${
        clicked ? "clicked" : ""
      }`}
      onClick={handleClick}
    >
      <div className="arrow-icon flex items-center justify-center h-10 w-10 bg-white rounded-full p-2">
        <ArrowRightIcon />
      </div>
      <h1 className="text-black font-semibold text-center justify-center flex flex-1">
        {text}
      </h1>
    </button>
  );
};

export default AnimatedButton;
