import React, { useMemo, useState } from "react";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { RiArrowLeftRightLine } from "react-icons/ri";

import { Search, SearchLatLng } from "../../Utils/Search";
import { useSelector, useDispatch } from 'react-redux';
import { dropOffSelected, dropoffGeometrySelected, exchangeLocation, selectDropoffInput } from '../../store/searchSlice';

const DropoffSelection = (props: any) => {
  const { t, reload, setReload, hideInterChange } = props;
  // const [dropoffLocation, setDropoffLocation] = useState<any>("");
  const [isLoading, setIsLoading] = useState<any>(false);
  // const [value, setValue] = useState<any>(loadDropOff());
  const dispatch = useDispatch();
  // const dropoffLocation = useSelector((state: any) => state.search.dropoffLocation);
  const value = useSelector(selectDropoffInput);
  const handleLocationChange = (value: any) => {
    const dropoff_locationVal = {
      label: value?.label ?? '',
      value: value?.value?.place_id
    };
    dispatch(dropOffSelected(dropoff_locationVal));
    localStorage.setItem("dropoff_location", JSON.stringify(dropoff_locationVal));
    if (value != null && value?.label) {
      geocodeByAddress(value?.label ?? '')
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          localStorage.setItem("dropoff_geometry", JSON.stringify({
            lat: lat, lng: lng
          }))
          dispatch(dropoffGeometrySelected({
            lat: lat, lng: lng
          }));
        }
        );
    }
  }

  const searchAPI: any = async (searchString: string) => {
    let result: any = await Search({ q: searchString });
    if (result?.[0]) {
      result = result.map((location: any) => {
        return { value: location.id, label: location.description };
      });
    }
    setIsLoading(false);
    return result;
  };
  const latLngAPI: any = async (id: string) => {
    const result: any = await SearchLatLng({ id: id });
    if (result?.geometry) {
      localStorage.setItem("dropoff_geometry", JSON.stringify(result.geometry));
    }
    return result;
  };
  const searching: any = async (searchString: string) => {
    if (!searchString) {
      return false;
    }
    setIsLoading(true);
    return await searchAPI(searchString.toLowerCase());
  };

  const interchangeLocation = () => {
    dispatch(exchangeLocation());
    setReload(!reload);
    const pickup_location = localStorage.getItem("pickup_location");
    const pickup_geometry = localStorage.getItem("pickup_geometry");
    const dropoff_location = localStorage.getItem("dropoff_location");
    const dropoff_geometry = localStorage.getItem("dropoff_geometry");
    if (dropoff_location) {
      localStorage.setItem("pickup_location", dropoff_location);
    }
    if (pickup_location) {
      localStorage.setItem("dropoff_location", pickup_location);
    }
    if (dropoff_geometry) {
      localStorage.setItem("pickup_geometry", dropoff_geometry);
    }
    if (pickup_geometry) {
      localStorage.setItem("dropoff_geometry", pickup_geometry);
    }
  };
  return (
    <div className="border border-gray-300 rounded py-4 px-6 h-32 relative lg:col-span-1 max-w-md">
      <label className="text-sm">{t("dropoff_selection_title")} *</label>
      {!hideInterChange && (<div className="flex rounded-full border border-gray-300 w-14 h-14 text-gray-400 justify-center items-center absolute bg-white lg:-left-12 lg:m-auto left-[45%] inset-x-0 -top-[35px] rotate-90">
        <RiArrowLeftRightLine
          size={25}
          onClick={interchangeLocation}
          className="cursor-pointer"
        />
      </div>)}
      {/* <Select
        styles={{
          menu: (provided, state) => ({
            ...provided,
            zIndex: 50,
          }),
        }}
        placeholder={t("search_dropoff_placeholder")}
        noOptionsMessage={({ inputValue }: any) =>
          `${t("no_matches")} ${inputValue}`
        }
        value={dropoffLocation}
        defaultOptions
        isLoading={isLoading}
        loadOptions={searching}
        onChange={setSelectedLocation}
      /> */}
      <GooglePlacesAutocomplete
        apiKey="AIzaSyBPqss2h4k9JImLtg7PkPy7zr0iv2-P_OA"
        selectProps={{
          value,
          onChange: handleLocationChange,
        }}
      />

      <div>
        <span>{t("pickup_sub_title")}</span>
      </div>
    </div>
  );
};

export default DropoffSelection;
