import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Axios from "../Utils/Axios";
import { RiLockPasswordLine } from "react-icons/ri";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { selectUserSettings } from "../store/authSlice";

const LogoContent = (navigate: any) => {
  const userSettings = useSelector(selectUserSettings);

  return (
    <>
      <p className="text-4xl font-bolder text-white">
        Welcome back to {userSettings?.site_name}
      </p>
      <img
        src={userSettings?.site_logo}
        className="h-20 cursor-pointer"
        alt={userSettings?.site_name}
        onClick={() => navigate("/")}
      />
    </>
  );
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const ResetPassword = (props: any) => {
  const navigate: any = useNavigate();
  const email = useState<any>(sessionStorage.getItem("reset_pwd_mail"));
  const [error, setError] = useState<any>(null);
  const strongPasswordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  let query = useQuery();
  const [resetStatus, setResetStatus] = useState<boolean>(false);
  useEffect(() => {
    const passwordQuery = query.get("password");
    const passwordQuery2 = query.get("confirmPassword");
  }, [query]);

  useEffect(() => {
    if (!email[0]) navigate("/forgot-password");
  }, []);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleOtpChange = (e: any) => {
    setOtp(e.target.value.replace(/\D/, ""));
  };

  const resetPassword: any = async (e: any) => {
    e.preventDefault();
    if (!otp) {
      setError("OTP is mandatory");
      return;
    }
    if (!password) {
      setError("Password is mandatory");
      return;
    }
    if (!confirmPassword) {
      setError("Confirm Password is mandatory");
      return;
    }
    if (password !== confirmPassword) {
      setError("Password and Confirm Password not matching");
      return false;
    }
    if (!strongPasswordRegex.test(password)) {
      setError("Your password must be 8 characters long");
      return;
    }
    const config: any = {
      method: "POST",
      url: "/api/v1/reset-password/",
      data: {
        email: email[0],
        token: otp,
        password: password,
        confirm_password: confirmPassword,
      },
    };
    try {
      const loginRes: any = await Axios(config)
        .then((res: any) => {
          setResetStatus(true);
        })
        .catch((e: any) => {
          setError(Object.values(e.response.data.data)[0]);
        });
    } catch (err: any) {
      setError("Reset Failed!");
    }
  };

  const userSettings = useSelector(selectUserSettings);

  return (
    <div className="h-screen bg-gray-800 grid grid-cols-2">
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <div className="lg:flex hidden flex-col justify-center items-center  text-center bg-gray-800 p-4">
        <p className="text-4xl font-bolder text-white">
          Welcome back to {userSettings?.site_name}
        </p>
        <img
          src={userSettings?.site_logo}
          className="h-20 cursor-pointer"
          alt={userSettings?.site_name}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="col-span-2 lg:col-span-1 flex flex-col justify-center items-center text-center bg-gray-800 p-4">
        <div className="flex-col flex justify-center items-center text-center lg:hidden">
          {LogoContent(navigate)}
        </div>
        {!resetStatus && (
          <form>
            <div className="bg-white px-6 py-8 rounded-xl mt-6">
              <p className="text-3xl font-bold">Set your New Password</p>
              <div className="py-10 flex">
                <RiLockPasswordLine className="w-10 h-10" />
                <input
                  required
                  type="text"
                  value={otp}
                  name="otpToReset"
                  autoComplete="one-time-code"
                  placeholder="Enter OTP which you got on email ID"
                  className={
                    "py-2  pl-12 w-80 border-b-2 focus:outline-0 focus:shadow-lg " +
                    (error && !otp ? "border-red-700" : "border-blue-400")
                  }
                  onChange={handleOtpChange}
                  maxLength={6}
                  onBlur={() => {
                    setError("");
                    if (!otp) {
                      setError("OTP is mandatory");
                    }
                  }}
                />
              </div>
              <div className="py-10 flex">
                <RiLockPasswordLine className="w-10 h-10" />
                <input
                  required
                  type="password"
                  value={password}
                  name="password"
                  autoComplete="new-password"
                  placeholder="Enter your New password"
                  className={
                    "py-2  pl-12 w-80 border-b-2 focus:outline-0 focus:shadow-lg " +
                    (error && !strongPasswordRegex.test(password)
                      ? "border-red-700"
                      : "border-blue-400")
                  }
                  onChange={(e: any) => {
                    setPassword(e.target.value.slice(0, 20));
                  }}
                  onBlur={() => {
                    setError("");
                    if (!strongPasswordRegex.test(password)) {
                      setError("Your password must be 8 characters long");
                    }
                  }}
                  maxLength={20}
                />
              </div>

              <div className="py-2 flex">
                <RiLockPasswordLine className="w-10 h-10" />
                <input
                  required
                  type="password"
                  name="confirmPassword"
                  autoComplete="new-password"
                  placeholder="Confirm your new password"
                  className={
                    "py-2  pl-12 w-80 border-b-2 focus:outline-0 focus:shadow-lg " +
                    (error && password !== confirmPassword
                      ? "border-red-700"
                      : "border-blue-400")
                  }
                  onChange={(e: any) => {
                    setConfirmPassword(e.target.value.slice(0, 20));
                  }}
                  maxLength={20}
                  onBlur={() => {
                    setError("");
                    if (password !== confirmPassword) {
                      setError("Passwords do not match");
                    }
                  }}
                />
              </div>

              {error && (
                <div>
                  <p className="grow-0	text-red-500 mt-4">{error}</p>
                  {error === "Your password must be 8 characters long" && (
                    <div>
                      <p className="text-red-500 mt-2">
                        1 uppercase & 1 lowercase character
                      </p>
                      <p className="text-red-500 mt-2">
                        1 number & Special Character
                      </p>
                    </div>
                  )}
                </div>
              )}

              <button
                onClick={resetPassword}
                className="w-full mt-6 rounded-lg px-10 py-4 text-white font-bold bg-gray-800 hover:bg-yellow-600"
              >
                Reset Password
              </button>
            </div>
          </form>
        )}
        {resetStatus && (
          <div className="bg-white px-6 py-8 rounded-xl mt-6">
            <div className="flex place-content-center mt-5 py-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                id="body_1"
                width={102}
                height={77}
              >
                <g transform="matrix(1.54 0 0 1.54 12.499998 -0)">
                  <path
                    d="M50 25C 50.000004 31.90356 47.559227 37.796116 42.67767 42.67767C 37.796116 47.559227 31.90356 50.000004 25 50C 18.096441 50.000004 12.203884 47.559227 7.3223305 42.67767C 2.4407768 37.796116 0 31.90356 0 25C 0 18.096441 2.4407768 12.203884 7.3223305 7.3223305C 12.203884 2.4407768 18.096441 0 25 0C 31.90356 0 37.796116 2.4407768 42.67767 7.3223305C 47.559227 12.203884 50.000004 18.096441 50 25C 50.000004 25.290918 49.994926 25.581747 49.98477 25.872488"
                    stroke="none"
                    fill="#25AE88"
                    fillRule="nonzero"
                  />
                  <path
                    d="M38 15L22 33L12 25"
                    stroke="#FFFFFF"
                    strokeWidth={2}
                    strokeLinecap="square"
                    fill="none"
                  />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                  <g />
                </g>
              </svg>
            </div>
            <p className="text-3xl font-bold">Password Changed</p>
            <p className="text-1xl p-2">
              Your password has been changed successfully
            </p>
            <div className="py-10 flex"></div>
            <button
              onClick={() => {
                sessionStorage.clear();
                navigate("/login");
              }}
              className="w-full mt-6 rounded-lg px-10 py-4 text-white font-bold bg-gray-800 hover:bg-yellow-600"
            >
              Back To Login
            </button>
          </div>
        )}
      </div>
      <div className="bg-gray-800 w-full col-span-2">
        <Footer />
      </div>
    </div>
  );
};

export default ResetPassword;
