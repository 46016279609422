import React, { useEffect, useState } from "react";
import NavBarFixed from "../Components/NavBarFixed";
import Footer from "../Components/Footer";
import Axios from "../Utils/Axios";
import { Link } from "react-router-dom";
import Loading from "../Components/Loading";

type Props = {
  currency: any;
  t: any;
};

export interface IListItem {
  thumbnail_image: string;
  country: number;
  country_name: string;
  cover_image: string;
  cities: Array<string[]>;
}

function CountryCityList(props: Props) {
  const [countryList, setList] = useState<IListItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Axios.get("/api/v1/seo/city/countries-list/")
      .then((res) => {
        setList(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  console.log(countryList);

  return (
    <div className="min-h-screen bg-white pt-[100px]">
      <NavBarFixed currency={props.currency} t={props.t} />

      <div className="flex flex-col gap-10 py-8 px-4 ">
        {isLoading ? (
          <div className="h-full justify-center items-center flex">
            {/* <p className="font-bold">Loading...</p> */}
            <Loading />
          </div>
        ) : (
          countryList.map((data) => (
            <div key={data.country_name}>
              <h3 className="font-bold text-xl pb-4">{data.country_name}</h3>

              <div className="flex gap-10 w-full flex-wrap ">
                {data.cities.map((city) => {
                  const [path, name, url] = city;

                  return (
                    <Link
                      to={`/popular_locations/${path?.toLocaleLowerCase()}`}
                      key={url}
                    >
                      <div className="relative rounded-md overflow-hidden">
                        <img
                          src={url}
                          alt="b2"
                          className="h-56 w-56 object-cover "
                        />

                        <div className="absolute bottom-0 flex justify-center w-full items-center py-2 bg-gradient-to-t from-neutral-800 to-transparent">
                          <p className="mt text-md font-semibold text-white ">
                            {name}
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          ))
        )}
      </div>

      <Footer />
    </div>
  );
}

export default CountryCityList;
