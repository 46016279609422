import { useState } from "react";
import {
  BsFillPeopleFill,
  BsFillPersonFill,
  BsTelephoneFill,
} from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { RiLuggageCartFill, RiMapPinLine } from "react-icons/ri";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { selectUser } from "../../store/authSlice";

const Trip = (props: any) => {
  const { trip, type, cancelBooking, bookAgain, modifyBooking } = props;

  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [userType, setUserType] = useState(localStorage.getItem("userType"));
  

  const renderButton = () => {
    switch (type) {
      case "upcoming":
        return (
          <div className="col-span-12   md:col-span-7 flex space-x-2">
            {/* <button
              className="bg-blue-600 rounded-md  px-4 py-2 text-white font-bold text-sm"
              onClick={() => {
                modifyBooking(trip);
              }}
            >
              Modify Booking
            </button> */}
            <span className="text-sm text-gray-600">
              Free Cancelation Until:{" "}
              {trip.cancellationDetails.freeCancellationUntil}
            </span>

            {user.type === "travelagent" && (
              <button
                className="bg-blue-600 rounded-md  px-4 py-2 text-white font-bold text-sm"
                onClick={() => {
                  navigate("/voucher", {
                    state: {
                      ...trip,
                      firstName: user.first_name,
                      lastName: user.last_name,
                      bookingId: trip.booking_id,
                      pickupDate: trip.booked_on,
                      mobile: trip.passenger_mobile,
                      passenger: trip.num_passengers,
                      luggage: trip.num_bags,
                      distance: `${parseInt(trip.distance)/1000 || "N/A"} KM` ,
                      flightNumber: trip.flight,
                      specialInstructions:trip.special_instructions,
                      car:trip.category,
                      car_raw:{fleet_image:`${trip.carImage}`}
                    },
                  });
                }}
              >
                Download Voucher
              </button>
            )}
            {userType === "driver" && (
              <button
                className="bg-[#b4853a] disabled:bg-stone-400 rounded-md  px-4 py-2 text-white font-bold text-sm"
                onClick={() => {
                  navigate(`/jobs/${trip?.execution?.executionCode}`);
                }}
                disabled={!trip?.execution?.canStartJourney}
              >
                Start Journey
              </button>
            )}
            {userType === "driver" ? null : (
              <button
                className="bg-red-600 disabled:bg-stone-400 rounded-md  px-4 py-2 text-white font-bold text-sm"
                onClick={() => {
                  // cancelBooking(trip.booking_id);
                  setIsConfirmModalOpened(true);
                }}
                disabled={!trip.cancellationDetails.cancellable}
              >
                Cancel
              </button>
            )}
          </div>
        );
      case "past":
        return (
          <div className="col-span-12 md:col-span-7 flex">
            <button
              className="bg-green-600 rounded-md  px-4 py-2 text-white font-bold text-sm"
              onClick={() => {
                bookAgain(trip);
              }}
            >
              Rebook
            </button>
          </div>
        );
      default:
        return <div className="col-span-12"></div>;
    }
  };

  return (
    <>
      <ReactModal
        isOpen={isConfirmModalOpened}
        className="p-5 h-screen w-screen z-[9999] flex justify-center items-center"
      >
        <div
          className="bg-white border border-gray-300 rounded-md p-4"
          style={{ maxWidth: 400 }}
        >
          <h1 className="text-lg font-bold">
            Are you sure you want to cancel this booking?
          </h1>

          <div className="flex gap-2 mt-4">
            <button
              onClick={() => {
                setIsConfirmModalOpened(false);
              }}
              className="px-4 w-full py-2 rounded-md bg-gray-800 text-white font-bold  hover:bg-yellow-600"
            >
              Discard
            </button>

            <button
              onClick={() => {
                setIsCanceling(true);

                cancelBooking(trip.booking_id).finally(() => {
                  setIsCanceling(false);
                });
              }}
              disabled={isCanceling}
              className="px-4 w-full flex justify-center items-center gap-2 py-2 rounded-md text-white font-bold disabled:bg-gray-500 bg-red-600 hover:bg-red-700"
            >
              {isCanceling && <FaSpinner className="w-4 h-4 animate-spin" />}
              Confirm
            </button>
          </div>
        </div>
      </ReactModal>

      <div className="flex-row bg-white">
        <div className="flex-row border-b p-4 space-y-2">
          <div>
            <p className="font-bold text-sm text-cyan-600">
              {trip?.booking_id}
            </p>
          </div>
          <div className="flex justify-between">
            <div className="flex-row justify-center items-center">
              <div className="flex justify-center">
                <img
                  src={require("../../Assets/limousine-icon.png")}
                  alt={"limousine-icon"}
                  className="w-18 h-10"
                />
              </div>
              <p className="text-sm">
                {trip?.category ? trip?.category.toUpperCase() : "-"}
              </p>
            </div>
            <div className="flex justify-center items-center">
              <div>
                <p className="text-sm">Booked On</p>
                <p className="text-sm text-bold text-gray-500">
                  {trip?.booking_date}
                </p>
              </div>
            </div>
            <div className="flex justify-center items-center p-4">
              {userType === "driver" ? null : (
                <p className="text-md font-bold text-red-600">
                  {trip?.fare_human}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className=" p-4">
          <div className="grid grid-cols-12 justify-between">
            <div className="col-span-12 md:col-span-9 lg:col-span-9 flex-row space-y-2">
              <p>
                <span className="font-bold text-sm">Booking Date:</span>
                <span className="ml-2">{trip?.booked_on}</span>
              </p>
              <div className="flex">
                <RiMapPinLine className="text-yellow-600" size={40} />
                <div className="flex-row">
                  <p className="font-bold text-sm">
                    {trip?.arrival_time}
                    {"  "}
                    {trip.pickup}
                  </p>
                  <p className="font-bold text-sm">
                    {trip?.destination_time}
                    {"  "}
                    {trip.destination}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 lg:col-span-3 flex-row">
              <div className="flex  space-x-2 items-center">
                <BsFillPersonFill className="text-yellow-600" size={16} />
                <p className="text-sm">{trip.passenger_name}</p>
              </div>
              <div className="flex  space-x-2 items-center">
                <BsFillPeopleFill className="text-yellow-600" size={16} />
                <p className="text-sm">{trip.num_passengers}</p>
              </div>
              <div className="flex  space-x-2 items-center">
                <RiLuggageCartFill className="text-yellow-600" size={16} />
                <p className="text-sm">{trip.num_bags}</p>
              </div>

              <div className="flex  space-x-2 items-center">
                <BsTelephoneFill className="text-yellow-600" size={16} />
                <p className="text-sm">{trip.passenger_mobile}</p>
              </div>
              {userType === "driver" ? null : (
                <div className="flex  space-x-2 items-center">
                  <HiMail className="text-yellow-600" size={16} />
                  <p className="text-sm">{trip.passenger_email}</p>
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 justify-between p-2">
            <div className="col-span-12 md:col-span-5 lg:col-span-5">
              <p className="text-sm text-gray-600">
                Driver and Vehicle details will be updated on execution date.
              </p>
            </div>
            {renderButton()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Trip;
