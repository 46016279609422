import { useEffect, useRef, useState } from "react";
import { BiSort, BiTransferAlt } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { MdLuggage } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  durationSelected,
  exchangeLocation,
  noofBagsSelected,
  noofPassSelected,
  pickupDateSelected,
  pickupTimeSelected,
  selectDropoffGeo,
  selectDropoffInput,
  selectDuration,
  selectNoofBags,
  selectNoofpass,
  selectPickupDate,
  selectPickupGeo,
  selectPickupInput,
  selectPickupTime,
} from "../store/searchSlice";
import BookingHours from "./BookingV2/BookingHours";
import DropoffSelection from "./BookingV2/DropoffSelection";
import PickupDateTime from "./BookingV2/PickupDateTime";
import PickupSelection from "./BookingV2/PickupSelection";
import Counter from "./Counter";

const BookingOverview = (props: any) => {
  const { currency, t, locationDetails, modifyBooking } = props;
  const [reload, setReload] = useState<boolean>(false);
  const noOfPass = useSelector(selectNoofpass);
  const noOfBags = useSelector(selectNoofBags);
  const selectedDate = useSelector(selectPickupDate);
  const selectedTime = useSelector(selectPickupTime);
  const pickupInput = useSelector(selectPickupInput);
  const dropoffInput = useSelector(selectDropoffInput);
  const pickupGeo = useSelector(selectPickupGeo);
  const dropoffGeo = useSelector(selectDropoffGeo);
  const selectedDuration = useSelector(selectDuration);

  const count = useRef(0).current++;

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate: any = useNavigate();

  // useEffect(() => {
  //   if (count > 0) {
  //     let search = {
  //       date: selectedDate,
  //       time: selectedTime,
  //       pickup: pickupInput.label,
  //       dropoff: dropoffInput.label,
  //       pass: noOfPass,
  //       bags: noOfBags,
  //       pickup_lat: pickupGeo?.lat,
  //       drop_lat: dropoffGeo?.lat,
  //       pickup_lng: pickupGeo?.lng,
  //       drop_lng: dropoffGeo?.lng,
  //       duration: selectedDuration,
  //     };
  //     setModifiedData(()=>search)

     
  //   }
  // }, [
  //   dropoffGeo?.lat,
  //   dropoffGeo?.lng,
  //   dropoffInput.label,
  //   noOfBags,
  //   noOfPass,
  //   pickupGeo?.lat,
  //   pickupGeo?.lng,
  //   pickupInput.label,
  //   selectedDate,
  //   selectedDuration,
  //   selectedTime,
  // ]);

  const addPassengers = () => {
    let current: any = noOfPass;
    if (current < 10) current = parseInt(current) + 1;
    setNoOfPass(current);
    return null;
  };

  const removePassengers = () => {
    let current: any = noOfPass;
    if (current > 1) current = parseInt(current) - 1;
    setNoOfPass(current);
    return null;
  };

  const setNoOfPass = (value: string) => {
    dispatch(noofPassSelected(value));
  };

  const setSelectedDate = (value: string) => {
    dispatch(pickupDateSelected(value));
    if (!value) return;
  };

  const setSelectedTime = (value: string) => {
    dispatch(pickupTimeSelected(value));
    if (!value) return;
  };

  const addBags = () => {
    let current: any = noOfBags;
    if (current < 10) current = parseInt(current) + 1;
    setNoOfBags(current);
    return null;
  };

  const removeBaggs = () => {
    let current: any = noOfBags;
    if (current > 0) current = parseInt(current) - 1;
    setNoOfBags(current);
    return null;
  };

  const setNoOfBags = (value: string) => {
    dispatch(noofBagsSelected(value));
  };

  const setSelectedDuration = (value: string) => {
    dispatch(durationSelected(value));
    if (!value) return;
  };

  const interchangeLocation = () => {
    dispatch(exchangeLocation());
    setReload(!reload);
  };

  return (
    <div className="flex responsive">
      <div className="w-screen m-4 shadow-2xl rounded-xl pt-4 border-gray-200 dark:border-gray-700">
        <div className="flex flex-col md:flex-row center px-4 py-2">
          <div className="basis-3/12 mb-4">
            <PickupSelection t={t} />
          </div>
          {(locationDetails?.booking_type === "p_to_p" ||
            locationDetails?.booking_type === "intercity") && (
            <div className="basis-20 flex flex-col items-center place-content-center pl-4 md:mb-4">
              <span className="text-center">
                <BiTransferAlt
                  className="text-yellow-600 cursor-pointer mr-1 hidden md:block"
                  size={25}
                  onClick={interchangeLocation}
                />
                <BiSort
                  className="text-yellow-600 cursor-pointer mr-1 block md:hidden"
                  size={25}
                  onClick={interchangeLocation}
                />
              </span>
            </div>
          )}
          {(locationDetails?.booking_type === "p_to_p" ||
            locationDetails?.booking_type === "intercity") && (
            <div className="basis-3/12 mb-4">
              <DropoffSelection
                t={t}
                reload={reload}
                setReload={setReload}
                hideInterChange={true}
              />
            </div>
          )}
          {(locationDetails?.booking_type === "city_tour" ||
            locationDetails?.booking_type === "hourly") && (
            <div className="basis-3/12 mb-4 mx-2">
              <BookingHours
                reload={reload}
                t={t}
                selectedDuration={selectedDuration}
                setSelectedDuration={setSelectedDuration}
              />
            </div>
          )}
          <div className="basis-4/12 mb-4 mx-2">
            <PickupDateTime
              t={t}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              setSelectedDate={setSelectedDate}
              setSelectedTime={setSelectedTime}
            />
          </div>
          <div className="basis-1/12 flex flex-col items-center place-content-center mb-4 mx-2">
            <div className="flex mt-4">
              <span>
                <BsFillPersonFill
                  className="text-yellow-600 cursor-pointer mt-2 mr-1"
                  size={25}
                />
              </span>
              <span>
                <Counter
                  count={noOfPass}
                  add={addPassengers}
                  remove={removePassengers}
                  setCount={setNoOfPass}
                />
              </span>
            </div>
          </div>
          <div className="basis-1/12 flex flex-col items-center place-content-center mb-4 mx-2">
            <div className="flex mt-3">
              <span>
                <MdLuggage
                  className="text-yellow-600 cursor-pointer mt-3 mr-1"
                  size={25}
                />
              </span>
              <span className="text-left w-auto font-bold break-words">
                <Counter
                  count={noOfBags}
                  add={addBags}
                  remove={removeBaggs}
                  setCount={setNoOfBags}
                />
              </span>
            </div>
          </div>
          <div className="basis-2/12 flex flex-col items-center place-content-center mb-4 mx-2">
            <button
              className={
                "w-full block bg-gradient-to-r bg-gray-800  px-4 py-2 rounded-lg text-white text-lg font-bold hover:bg-yellow-600"
              }
              onClick={() => {
                let search = {
                  date: selectedDate,
                  time: selectedTime,
                  pickup: pickupInput.label,
                  dropoff: dropoffInput.label,
                  pass: noOfPass,
                  bags: noOfBags,
                  pickup_lat: pickupGeo?.lat,
                  drop_lat: dropoffGeo?.lat,
                  pickup_lng: pickupGeo?.lng,
                  drop_lng: dropoffGeo?.lng,
                  duration: selectedDuration,
                };
                modifyBooking(search);
              }}
            >
              {" "}
              {t("modify_booking")}
            </button>
          </div>
          {/* <div className="flex mb-4">
                            <span className="col-span-1">
                                <BiTimeFive
                                    className="text-yellow-600 cursor-pointer mr-1"
                                    size={25}
                                />
                            </span>
                            <span className="w-24 text-left ml-2">
                                <p>{t("duration")} :</p>
                            </span>
                            <span className="text-left">
                                <p className="ml-1 font-bold">
                                    {locationDetails?.duration}
                                </p>
                            </span>
                        </div> */}
          {/* <div className="flex mb-4">
                            <span>
                                <FaRoad
                                    className="text-yellow-600 cursor-pointer mr-1"
                                    size={25}
                                />
                            </span>
                            <span className="w-24 text-left ml-2">
                                <p>{t("distance")} : </p>
                            </span>
                            <span className=" text-left">
                                <p className="ml-1 font-bold">
                                    {locationDetails?.distance}
                                </p>
                            </span>
                        </div> */}
          {/* <div className="flex mb-4">
                            <span>
                                <FaRegMoneyBillAlt
                                    className="text-yellow-600 cursor-pointer mr-1"
                                    size={25}
                                />
                            </span>
                            <span className="w-24 ml-2 text-left">
                                <p>{t("currency")} : </p>
                            </span>
                            <span className="text-left">
                                <p className="ml-1 font-bold">{currency}</p>
                            </span>
                        </div> */}
        </div>
      </div>

      {/* <div className="border-b border-gray-200 dark:border-gray-700">
                    <div className="flex flex-row px-6 py-4">
                        <div className="flex items-center mb-2">
                            <BsFillCheckCircleFill
                                className="text-yellow-600 cursor-pointer"
                                size={20}
                            />
                            <p className="ml-2">Flat rate</p>
                        </div>
                        <div className="flex items-center mb-2">
                            <BsFillCheckCircleFill
                                className="text-yellow-600 cursor-pointer"
                                size={20}
                            />
                            <p className="ml-2">Free Cancellation *</p>
                        </div>
                        <div className="flex items-center mb-2">
                            <BsFillCheckCircleFill
                                className="text-yellow-600 cursor-pointer"
                                size={20}
                            />
                            <p className="ml-2">Certified & Reliable drivers</p>
                        </div>
                    </div>
                </div> */}
    </div>
  );
};
export default BookingOverview;
