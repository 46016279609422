import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import NavBarFixed from "../../Components/NavBarFixed";
import Footer from "../../Components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "../../Utils/Axios";
import { Slide, toast } from "react-toastify";
import { selectUser } from "../../store/authSlice";
import { useSelector } from "react-redux";
import { MultiValueGeneric } from "react-select/dist/declarations/src/components/MultiValue";

const FlightPassenger = (props: any) => {
  const Navigate = useNavigate();
  const location = useLocation();
  const userSettings = useSelector(selectUser);

  const queryParams = new URLSearchParams(location.search);
  const childAsString = queryParams.get("child");
  const adultAsString = queryParams.get("adult");
  const flightrequestno = queryParams.get("flightrequestno");
  const agencyrequestno = queryParams.get("agencyRequestNo");
  // Parse the strings into integers
  const child = childAsString ? parseInt(childAsString, 10) : 0;
  const adult: number = adultAsString ? parseInt(adultAsString, 10) : 0;
  const [adultPossibleArray, setAdultPossibleArray] = useState<any[]>(() => {
    let val = [];
    for (let i = 0; i < adult; i++) {
      val.push({ first_name: "",last_name: "",gender:"",email:userSettings?.email,mobile:userSettings?.phone,  is_child: false });
    }
    return val;
  });
  const [childPossibleArray, setChildPossibleArray] = useState<any[]>(() => {
    let val = [];
    for (let i = 0; i < child; i++) {
      val.push({ first_name: "",last_name: "",gender:"", child_dob:"", is_child: true });
    }
    return val;
  });

  const handleInputChangeAdult = (e: any, i: number) => {
    const { value, name } = e.target;

    let val = [...adultPossibleArray];

    val.forEach((data: any, ind: number) => {
      if (ind === i) {
        data[name] = value;
      }
    });

    setAdultPossibleArray([...val]);
  };
  const handleInputChangeChild = (e: any, i: number) => {
    const { value, name } = e.target;

    let val = [...childPossibleArray];
    val.forEach((data: any, ind: number) => {
      if (ind === i) {
        data[name] = value;
      }
    });

    setChildPossibleArray([...val]);
  };

  const handleClick = () => {
    const allPaasengers = [...adultPossibleArray, ...childPossibleArray];
    let query = {
      passengers: allPaasengers,
      flightrequestno: flightrequestno,
      agencyrequestno: agencyrequestno,
    };

    let noError = true;

    for (let i = 0; i < allPaasengers.length; i++) {
      for (const key in allPaasengers[i]) {
        if (allPaasengers[i][key] === "") {
          noError = false;
          break;
        }
      }
    }

    if (noError) {
      Axios.post("/api/v1/limoairport/flightbooked/", query)
        .then((res) => {
          toast.success("ticket is booked", {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            transition: Slide,
          });
      Navigate("/flight-booking");

        })
        .catch((err) => {console.log(err)
        });
    } else {
      toast.error("Please fill all fields", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        transition: Slide,
      });
    }
  };

  const minDateValid = ()=>{
    // Get the current date
  const currentDate = new Date();

// Calculate the minimum date 15 years from now
  const minDate = new Date();
  minDate.setFullYear(currentDate.getFullYear() - 15)

 

// Extract year, month, and day from the Date object
const year = minDate.getFullYear();
const month = String(minDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
const day = String(minDate.getDate()).padStart(2, '0');

// Create the "yyyy-mm-dd" formatted string
const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
  }

  const maxDateValid =()=>{
    var yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
const year = yesterday.getFullYear();
const month = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-based
const day = String(yesterday.getDate()).padStart(2, '0');

// Create the "yyyy-mm-dd" formatted string
const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
  }

useEffect(()=>{
  console.log(adultPossibleArray,"childPossibleArray")
},[adultPossibleArray])

  return (
    <>
      <Helmet>
        <title>Flight Booking</title>
      </Helmet>

      <NavBarFixed currency={props.currency} t={props.t} />

      <main className="mt-[100px] bg-white p-10">
        <div>
          <div className="flex justify-between">
            <div className="text-3xl font-bold">Add Passengers</div>
            <div>
              <button
                onClick={(e) => handleClick()}
                type="button"
                className="text-white dark:bg-gray-800 bg-teal-400 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 "
              >
                Book
              </button>
            </div>
          </div>
          <div>
            <div className="flex flex-wrap ">
              {adultPossibleArray &&
                adultPossibleArray.map((_: any, i: number) => {
                  return (
                    <div className="p-4 max-w-sm">
                      <div className="flex rounded-lg h-full dark:bg-gray-800 bg-teal-400 p-5 flex-col">
                        <div className="">
                          <div className=" text-white text-sm font-bold mb-2">
                            Passenger {i + 1}
                          </div>
                          <div className="mb-4">
                            <label className=" text-white text-sm font-bold mb-2">
                              First Name:
                            </label>
                            <input
                              type="text"
                              name="first_name"
                              onChange={(e) => handleInputChangeAdult(e, i)}
                              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <label className="text-red-500">
                              {/* {errors && !newData.fare && "Field is required !!"} */}
                            </label>
                          </div>
                          <div className="mb-4">
                            <label className=" text-white text-sm font-bold mb-2">
                              Last Name:
                            </label>
                            <input
                              type="text"
                              name="last_name"
                              onChange={(e) => handleInputChangeAdult(e, i)}
                              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <label className="text-red-500">
                              {/* {errors && !newData.fare && "Field is required !!"} */}
                            </label>
                          </div>
                          <div className="mb-4">
                            <label className="text-white text-sm font-bold mb-2">
                              Gender:
                            </label>
                            <select
                              name="gender"
                              onChange={(e) => handleInputChangeAdult(e, i)}
                              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                              <option>Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            <label className="text-red-500">
                              {/* {errors && !newData.fare && "Field is required !!"} */}
                            </label>
                          </div>
                          <div className="mb-4">
                            <label className=" text-white text-sm font-bold mb-2">
                              Email:
                            </label>
                            <input
                              type="email"
                              name="email"
                              defaultValue={adultPossibleArray[i]?.email}
                            
                              onChange={(e) => handleInputChangeAdult(e, i)}
                              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <label className="text-red-500">
                              {/* {errors && !newData.fare && "Field is required !!"} */}
                            </label>
                          </div>

                          <div className="mb-4">
                            <label className=" text-white text-sm font-bold mb-2">
                              Mobile Number:
                            </label>
                            <input
                              type="text"
                              name="mobile"
                              defaultValue={adultPossibleArray[i]?.mobile}

                              onChange={(e) => handleInputChangeAdult(e, i)}
                              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <label className="text-red-500">
                              {/* {errors && !newData.fare && "Field is required !!"} */}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="flex flex-wrap ">
              {childPossibleArray &&
                childPossibleArray.map((_: any, i: number) => {
                  return (
                    <div className="p-4 max-w-sm">
                      <div className="flex rounded-lg h-full dark:bg-gray-800 bg-teal-400 p-5 flex-col">
                        <div className="">
                          <div className=" text-white text-sm font-bold mb-2">
                            Child Passenger {i + 1}
                          </div>
                          <div className="mb-4">
                            <label className=" text-white text-sm font-bold mb-2">
                              First Name:
                            </label>
                            <input
                              type="text"
                              name="first_name"
                              onChange={(e) => handleInputChangeChild(e, i)}
                              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <label className="text-red-500">
                              {/* {errors && !newData.fare && "Field is required !!"} */}
                            </label>
                          </div>
                          <div className="mb-4">
                            <label className=" text-white text-sm font-bold mb-2">
                              Last Name:
                            </label>
                            <input
                              type="text"
                              name="last_name"
                              onChange={(e) => handleInputChangeChild(e, i)}
                              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <label className="text-red-500">
                              {/* {errors && !newData.fare && "Field is required !!"} */}
                            </label>
                          </div>
                          <div className="mb-4">
                            <label className="text-white text-sm font-bold mb-2">
                              Gender:
                            </label>
                            <select
                              name="gender"
                              onChange={(e) => handleInputChangeChild(e, i)}
                              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                              <option>Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                            <label className="text-red-500">
                              {/* {errors && !newData.fare && "Field is required !!"} */}
                            </label>
                          </div>
                          <div className="mb-4">
                            <label className=" text-white text-sm font-bold mb-2">
                              DOB:
                            </label>
                            <input
                              type="date"
                              name="child_dob"
                              min={minDateValid()}
                              max={maxDateValid()}
                              onChange={(e) => handleInputChangeChild(e, i)}
                              className="form-input mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <label className="text-red-500">
                              {/* {errors && !newData.fare && "Field is required !!"} */}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </main>

      <div className="bg-gray-800 w-full col-span-2">
        <Footer />
      </div>
    </>
  );
};

export default FlightPassenger;
