import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AiFillCar, AiFillSetting } from "react-icons/ai";
import { BsAirplaneFill } from "react-icons/bs";
import { FaSignOutAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import NavBarFixed from "../Components/NavBarFixed";
import { Account } from "../Components/Profile/Account/Account";
import Flight from "../Components/Profile/Flight/Flight";
import Trips from "../Components/Profile/Trips";
import ScrollTop from "../Components/ScrollTop";
import { logout, selectUser } from "../store/authSlice";
import { resetSearch } from "../store/searchSlice";

const Profile = (props: any) => {
  const { currency, t } = props;
  const navigate: any = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const location = useLocation();
  const [userType, setUserType] = useState(localStorage.getItem("userType"));
  console.log("user...", user);
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);

  const tabMap: any = {
    1: "Account",
    2: user?.type === "driver" ? "My Jobs" : "My Trips",
    // 3: "Web master",
    // 4: "Admin Panel",
    5: "Sign Out",
    6: "Flight",
  };

  const [tab, setTab] = useState(1);

  useEffect(() => {
    if (location.state?.tab) {
      if (location.state.tab === "trips") {
        setTab(2);
      }
    }
  }, [location.state]);

  const signout = () => {
    localStorage.clear();
    dispatch(logout());
    dispatch(resetSearch());
    navigate("/login");
  };

  const pickTabs = (tab: string) => {
    switch (tab) {
      case "Account":
        return <Account currency={currency} t={t} />;
      case "My Jobs":
        return <Trips />;
      case "My Trips":
        return <Trips />;
      case "Flight":
        return user?.type !== "customer" && <Flight />;
      default:
        return <></>;
    }
  };

  return (
    <div>
      <ScrollTop />
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <NavBarFixed currency={currency} t={t} />
      <div className="bg-gray-200 mt-24 h-full">
        <div className="grid grid-cols-12 p-2 h-full">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-4 lg:col-span-4 py-2 px-4 bg-gray-300">
            <div
              className={`p-4 flex space-x-2 ${
                tab === 1 && "bg-white"
              } rounded-lg cursor-pointer`}
              onClick={() => setTab(1)}
            >
              <AiFillSetting className="text-yellow-600 w-12" size={25} />
              <div className="flex-row space-y-1">
                <p className="font-bold">Account</p>
                <p>
                  Manage account details, Change Password, Newseletter, Set
                  Currency etc.
                </p>
              </div>
            </div>
            <div
              className={`p-4 flex space-x-2 ${
                tab === 2 && "bg-white"
              } rounded-lg cursor-pointer`}
              onClick={() => setTab(2)}
            >
              <AiFillCar className="text-yellow-600  w-12" size={25} />
              <div className="flex-row space-y-1">
                <p className="font-bold">
                  {user?.type === "driver" ? "My Jobs" : "My Trips"}
                </p>
                <p>
                  {user?.type === "driver"
                    ? "See your Jobs details, upcoming and cancelled jobs list and other details"
                    : "See your Trips details, Past, Upcoming, Cancelled Booking list with driver specifications and other details"}
                </p>
              </div>
            </div>

            <div
              className={`p-4 flex space-x-2 ${
                tab === 6 && "bg-white"
              } rounded-lg cursor-pointer`}
              onClick={() => setTab(6)}
            >
              <BsAirplaneFill
                className="text-yellow-600  w-12"
                style={{ width: "25px", height: "25px" }}
              />
              <div className="flex-row space-y-1">
                <p className="font-bold">Flight Requests</p>
                <p>See you flight requests.</p>
              </div>
            </div>

            <div
              className={`p-4 flex space-x-2 ${
                tab === 3 && "bg-white"
              }  rounded-lg cursor-pointer`}
              // onClick={() => setTab(3)}
              onClick={signout}
            >
              {/* <MdOutlineSettingsAccessibility
                className="text-yellow-600  w-12 cursor-pointer"
                size={25}
              />
              <div className="flex-row space-y-1">
                <p className="font-bold">Webmaster</p>
                <p>
                  Its only for you , your personal and handle the sites from
                  backend
                </p>
              </div>
            </div>
            <div
              className={`p-4 flex space-x-2 ${
                tab === 4 && "bg-white"
              } rounded-lg cursor-pointer`}
              onClick={() => navigate("/adminpanel/index")}
            > */}
              {/* <MdManageAccounts
                className="text-yellow-600  w-12 cursor-pointer"
                size={25}
              />
              <div className="flex-row space-y-1">
                <p className="font-bold">Admin Panel</p>
                <p>
                  This is for only staff user, manage and see the bookings of
                  all users, and agency
                </p>
              </div>
            </div>
            <div
              className={`p-4 flex space-x-2  rounded-lg cursor-pointer`}
              onClick={signout}
            > */}
              <FaSignOutAlt
                className="text-yellow-600  w-12 cursor-pointer"
                style={{ width: "25px", height: "25px" }}
              />
              <div className="flex-row space-y-1">
                <p className="font-bold">Sign Out</p>
                <p>Log out from the current sessions</p>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-12 xl:col-span-8 lg:col-span-8 py-4 px-6 bg-gray-100">
            <p className="font-bold text-2xl">{tabMap[tab]}</p>
            {pickTabs(tabMap[tab])}
          </div>
        </div>
      </div>
      <div className="bg-gray-800 w-full col-span-2">
        <Footer />
      </div>
    </div>
  );
};

export default Profile;
