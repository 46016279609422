import React from "react";

function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#000"
        d="M11.56 16.9c-.96 1-2.06 2.03-3.31 3.1-5.33-4.55-8-8.48-8-11.8 0-4.98 3.8-8.2 8-8.2 4.19 0 7.99 3.21 8 8.17l2.09-2.09 1.41 1.42-4.5 4.5-4.5-4.5 1.41-1.41 2.09 2.08C14.24 4.55 11.59 2 8.25 2c-3.35 0-6 2.57-6 6.2 0 2.34 1.95 5.44 6 9.14.64-.59 1.23-1.16 1.77-1.71a2.5 2.5 0 01-.27-1.12 2.5 2.5 0 015 0 2.492 2.492 0 01-3.19 2.39z"
      ></path>
    </svg>
  );
}

export default LocationIcon;
