import React from "react";
import animation from "../Assets/animation_lmteny3f.json";
import Lottie from "lottie-react";

type Props = {};

function Loading({}: Props) {
  return (
    <div className="h-full w-full flex justify-center items-center">
      <Lottie
        style={{ height: "inherit" }}
        height={200}
        animationData={animation}
        loop={true}
      />
    </div>
  );
}

export default Loading;
