import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Axios from "./Utils/Axios";
import { selectUserSettings, updateUserSettings } from "./store/authSlice";
import { store } from "./store/store";
import Loading from "./Components/Loading";

export async function getUserSettings() {
  let data: Record<string, any> = {};

  await Axios.get("/api/v1/configs")
    .then((res) => {
      data = res.data?.data ?? {};
    })
    .catch();

  // await Axios.get("http://ip-api.com/json/?fields=country").then((res) => {
  //   data.country = res.data.country;
  // });

  store.dispatch(
    updateUserSettings({
      stripe_key: data.spk ?? "",
      ...data,
    })
  );

  return data;
}

function Layout() {
  const userSettings = useSelector(selectUserSettings);

  useEffect(() => {
    if (!userSettings) {
      getUserSettings();
    }
  }, [userSettings]);

  return (
    <>
      {/* {!!userSettings ? ( */}
      <Outlet />
      {/* ) : (
        <div className="flex justify-center items-center h-screen w-screen ">
          <Loading />
        </div>
      )} */}
    </>
  );
}

export default Layout;
