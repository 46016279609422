import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BookingV2 from "../Components/BookingV2";
import NavBarFixed from "../Components/NavBarFixed";
import ScrollTop from "../Components/ScrollTop";
import countriesList from "../Assets/countries.json";
import Footer from "../Components/Footer";
import City from "../Components/City";
import Axios from "../Utils/Axios";
import { Helmet } from "react-helmet-async";

const Cities = ({ currency, t }: any) => {
  let { city, country = "SE" } = useParams();
  const [flag, setFlag] = useState<any>(null);
  const [cityDetails, setCityDetails] = useState<any>({});
  const [showNormalNav, setShowNormalNav] = useState<boolean>(true);
  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const getCityDetails = async (isMounted: boolean, city: any) => {
    try {
      let localdata = localStorage.getItem(city);
      if (localdata && localdata != "undefined") {
        setCityDetails(JSON.parse(localdata));
      } else {
        const cityResult = await Axios.get(`/api/v1/seo/city/${city}/`);
        if (!cityResult.data || cityResult.data.length === 0) {
          navigate("/");
        }
        setCityDetails(cityResult.data[0]);
        localStorage.setItem(city, JSON.stringify(cityResult.data[0]));
      }
    } catch (error) {
      console.log("ERRROR_>>>", error);
    }
  };

  useEffect(() => {
    let isMounted: boolean = true;
    getCityDetails(isMounted, city);
    let countries: any = countriesList;
    let flag: any = countries[country]?.image;
    if (flag) {
      setFlag(flag);
    }
  }, [country, city]);

  useLayoutEffect(() => {
    function updatePosition() {
      if (window.pageYOffset < 100) {
        setShowNormalNav(true);
      } else {
        setShowNormalNav(false);
      }
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);
  const getBgImage = () => {
    let imgSrc =
      cityDetails?.cover_image ??
      require("../Assets/airportTranferLimowideHP1.png");
    // if (city === "rovaniemi" || city === "riyadh") {
    //   imgSrc = require("../Assets/airportTranferLimowideHP2.png");
    // }
    // if (city === "stockholm" || city === "jeddah") {
    //   imgSrc = require("../Assets/chauffeurServiceLimowide.png");
    // }
    // if (city === "trondheim" || city === "mauritius") {
    //   imgSrc = require("../Assets/airportTranferLimowide.png");
    // }
    return imgSrc;
  };

  const addMeta = () => {
    return (
      <>
        <Helmet>
          <title>{cityDetails.page_title}</title>
          <meta name="robots" content="index, follow" />

          <meta name="title" content={cityDetails.page_title} />

          <meta name="description" content={cityDetails.meta_description} />

          <meta
            name="keywords"
            content={cityDetails.meta_keywords && cityDetails.meta_keywords}
          />

          <link rel="canonical" href={currentUrl} />

          <meta property="og:title" content={cityDetails.page_title} />

          <meta property="og:locale" content="en_US" />

          <meta property="og:type" content="WebPage" />

          <meta
            property="og:description"
            content={cityDetails.meta_description}
          />

          <meta name="twitter:title" content={cityDetails.page_title} />

          <meta
            name="twitter:description"
            content={cityDetails.meta_description}
          />

          <meta name="twitter:url" content={currentUrl} />
          <meta property="og:image" content={cityDetails?.cover_image || ""} />
          {/* <meta
            name="description"
            content={cityDetails.meta_description}
            data-react-helmet="true"
          /> */}
        </Helmet>
        {/* <Helmet>
            {cityDetails.meta_description && <meta
            name="description"
            content={
              cityDetails.meta_description
            }
          />}</Helmet> */}
        <Helmet></Helmet>

        <meta name="robots" content="index, follow" />
        <Helmet>
          {cityDetails.meta_title && (
            <meta
              name="og:title"
              content={cityDetails.meta_title && cityDetails.meta_title}
            />
          )}
        </Helmet>
        <Helmet>
          {cityDetails.page_description && (
            <meta
              property="og:description"
              content={
                cityDetails.page_description && cityDetails.page_description
              }
            />
          )}
        </Helmet>
      </>
    );
  };

  return (
    <div className="h-screen bg-white">
      {addMeta()}
      <ScrollTop />
      <div
        className={`bg-no-repeat relative bg-blend-burn bg-cover h-[800px]`}
        style={{
          backgroundImage: `url(${getBgImage()})`,
        }}
      >
        <div className="absolute inset-0 bg-gray-900 bg-opacity-75 h-[800px]"></div>
        {/* {showNormalNav ? (
          <NavBar currency={currency} t={t} />
        ) : ( */}
        <NavBarFixed currency={currency} t={t} />
        {/* )} */}

        <div className="flex flex-row-reverse lg:mr-12 xl:mr-12 md:mr-12 p-4">
          <div className="max-w-md right-[0%] mt-24 z-40">
            <BookingV2 t={t} />
          </div>
        </div>
      </div>
      <div className="">
        {cityDetails.id && <City cityDetails={cityDetails} city={city} />}
      </div>
      <Footer />
    </div>
  );
};

export default Cities;
