import React from "react";

function ArrowLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="18"
      fill="none"
      viewBox="0 0 26 18"
    >
      <path
        fill="#000"
        d="M25.75 7.583H5.676l5.072-5.085L8.75.5.25 9l8.5 8.5 1.998-1.998-5.072-5.085H25.75V7.583z"
      ></path>
    </svg>
  );
}

export default ArrowLeftIcon;
