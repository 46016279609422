import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { MdCall, MdDownload, MdEmail, MdPrint } from "react-icons/md";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Footer from "../Components/Footer";
import NavBarFixed from "../Components/NavBarFixed";
import { selectUser, selectUserSettings } from "../store/authSlice";
import { Link } from "react-router-dom";
import Voucher from "./Voucher";

const BookingSuccess = ({ currency, t, state }: any) => {
  const navigate = useNavigate();

  const printReportRef: any = useRef();
  const user = useSelector(selectUser);
  //const [bookingDetails, setBookingDetails] = useState<any>({});
  // const bookingDetail = localStorage.getItem("booking_details");
  // const flightNumber = localStorage.getItem("flightNumber");
  // const car_waiting_time = localStorage.getItem("car_waiting_time");
  const location = useLocation();
  const bookingDetailsData: any = location.state;
  // console.log(bookingDetailsData)

  // useEffect(() => {
  //   // console.log(bookingDetails)
  //   if (bookingDetail) {
  //     try {
  //       setBookingDetails(JSON.parse(bookingDetail));
  //       console.log(setBookingDetails)
  //     } catch (err: any) { }
  //   }
  // }, [bookingDetail]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [bookingDetailsData]);

  const handlePrintClick = useReactToPrint({
    content: () => printReportRef.current,
  });

  const userSettings = useSelector(selectUserSettings);

  return (
    <div className="bg-gray-800 min-h-screen">
      <Helmet>
        <title>Booking Success</title>
      </Helmet>
      <NavBarFixed t={t} />
      <div
        // ref={printReportRef}
        className="bg-white w-full h-full grid grid-cols-12 px-10 py-5 gap-4 lg:gap-8 md:gap-8 mt-24"
      >
        <div className="col-span-12 xl:col-span-8 lg:col-span-8 md:col-span-8">
          <div className="mb-4">
            <div className="flex justify-left mb-2">
              <h2 className="font-bold xl:text-2xl lg:text-2xl md:text-2xl text-xl">
                Booking Confirmation
              </h2>
            </div>
            <div>
              <p className="text-md">
                Thank you for booking with {userSettings?.site_name}. Please
                find all your bookings in{" "}
                <Link
                  to={"/account"}
                  state={{ tab: "trips" }}
                  className="text-yellow-600"
                >
                  Trips
                </Link>{" "}
                to access driver details and to change & cancel your bookings. A
                confirmation email has been sent to your registered E-mail
                address.
              </p>
            </div>
          </div>

          {/* <div className="flex flex-col px-4 py-4 lg:px-8 lg:py-8 xl:px-8 md:px-8 xl:py-8 md:py-8 bg-gray-800 text-white rounded-md mb-4">
            <p className="text-gray-400 text-md mb-2">
              {" "}
              Booking Id:{" "}
              <span className="text-2xl  text-yellow-600">
                {bookingDetailsData.bookingId}
              </span>{" "}
            </p>

            <p className="text-gray-400 text-md">
              Your PIN for this trip:{" "}
              <span className="text-2xl mb-2 text-yellow-600">
                {bookingDetailsData.pin}
              </span>
            </p>
          </div> */}

          <div className="flex flex-col bg-gray-800 text-white rounded-md">
            <div className="flex justify-between !pb-0 px-4 py-4 lg:px-8 lg:py-8 xl:px-8 md:px-8 xl:py-8 md:py-8">
              <div>
                <p className="xl:text-2xl lg:text-2xl md:text-2xl text-lg">
                  Save or Print summary of booking
                </p>
              </div>

              <div className="flex gap-4">
                <MdPrint
                  onClick={handlePrintClick}
                  size="30"
                  className="cursor-pointer"
                />

                {user.type === "travelagent" && (
                  <MdDownload
                    onClick={() => {
                      navigate("/voucher", { state: bookingDetailsData });
                    }}
                    size="30"
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>

            <div
              className="flex min-h-screen flex-col px-4 py-4 lg:px-8 lg:py-8 xl:px-8 md:px-8 xl:py-8 md:py-8 bg-gray-800 text-white rounded-md"
              ref={printReportRef}
            >
              <div className="flex flex-grow mt-4 items-center">
                <div className="mr-2 flex xl:w-8 xl:h-8 lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6 items-center justify-center rounded-full border-2 border-yellow-600">
                  <span className="font-bold text-md xl:text-xl lg:text-xl md:text-xl">
                    1
                  </span>
                </div>
                <p className="text-md xl:text-xl lg:text-xl md:text-xl">
                  Where & when
                </p>
              </div>

              <div className="px-4">
                <div className="flex justify-start items-center space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36 flex-none">
                    Pickup Location
                  </p>
                  <p className="text-md">
                    {" "}
                    {bookingDetailsData.pickupLocation}
                  </p>
                </div>
                <div className="flex justify-start  space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36 flex-none">
                    Drop Location
                  </p>
                  <p className="text-md">{bookingDetailsData.dropLocation}</p>
                </div>
                <div className="flex justify-start  space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36">
                    When
                  </p>
                  <p className="text-md">{`${bookingDetailsData.pickupDate} at ${bookingDetailsData.pickupTime}`}</p>
                </div>

                <div className="flex justify-start  space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36">
                    Included Waiting time
                  </p>
                  <p className="text-md">
                    {Number(bookingDetailsData.waitingTime) > 0
                      ? `${bookingDetailsData.waitingTime} mins`
                      : ""}
                  </p>
                </div>
                <div className="flex justify-start  space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36">
                    Distance
                  </p>
                  <p className="text-md">
                    {bookingDetailsData.distance
                      ? `${bookingDetailsData.distance} `
                      : ""}
                  </p>
                </div>
                <div className="flex justify-start  space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36">
                    Estimated Duration
                  </p>
                  <p className="text-md">
                    {/* {bookingDetails.duration > 0
                    ? moment()
                        .startOf("day")
                        .seconds(bookingDetails.duration)
                        .format(`H [hrs and] mm [mins]`)
                    : ""} */}
                    {bookingDetailsData.duration
                      ? bookingDetailsData.duration
                      : ""}
                  </p>
                </div>
                <div className="flex justify-start  space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36">
                    Passengers
                  </p>
                  <p className="text-md">{bookingDetailsData.passenger}</p>
                </div>
                <div className="flex justify-start  space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36">
                    Luggage
                  </p>
                  <p className="text-md">{bookingDetailsData.luggage}</p>
                </div>
                <div className="flex justify-start  space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36">
                    Flight number
                  </p>
                  <p className="text-md">
                    {bookingDetailsData.flightNumber || ""}
                  </p>
                </div>

                <div className="flex justify-start  space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36">
                    Booking Id
                  </p>
                  <p className="text-md">
                    {bookingDetailsData.bookingId ?? ""}
                  </p>
                </div>

                <div className="flex justify-start  space-x-4 mt-2">
                  <p className="text-gray-400 text-md w-24 xl:w-36 lg:w-36 md:w-36">
                    Pin for trip
                  </p>
                  <p className="text-md">{bookingDetailsData.pin ?? ""}</p>
                </div>
              </div>

              <div className="flex flex-grow mt-8 ">
                <div className="mr-2  flex xl:w-8 xl:h-8 lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6 items-center justify-center rounded-full border-2 border-yellow-600">
                  <span className="font-bold text-md xl:text-xl lg:text-xl md:text-xl">
                    2
                  </span>
                </div>
                <p className="text-md xl:text-xl lg:text-xl md:text-xl">
                  Choosen Car
                </p>
              </div>

              <div className="px-4">
                <div className="flex justify-start items-center  space-x-4 mt-2">
                  <p className="text-gray-400 text-sm w-24 xl:w-36 lg:w-36 md:w-36">
                    Vehicle
                  </p>
                  <p className="text-md">
                    {bookingDetailsData?.car?.replace("_", " ")}
                  </p>
                </div>
              </div>

              <div className="flex flex-grow mt-8 ">
                <div className="mr-2  flex xl:w-8 xl:h-8 lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6 items-center justify-center rounded-full border-2 border-yellow-600">
                  <span className="font-bold text-md xl:text-xl lg:text-xl md:text-xl">
                    3
                  </span>
                </div>
                <p className="text-md xl:text-xl lg:text-xl md:text-xl">
                  Passenger details & Payments
                </p>
              </div>

              <div className="px-4">
                <div className="flex justify-start  items-center space-x-4 mt-2">
                  <p className="text-gray-400 text-sm w-24 xl:w-36 lg:w-36 md:w-36">
                    Name
                  </p>
                  <p className="text-md">{`${bookingDetailsData.firstName} ${bookingDetailsData.lastName}`}</p>
                </div>
                <div className="flex justify-start  items-center space-x-4 mt-2">
                  <p className="text-gray-400 text-sm w-24 xl:w-36 lg:w-36 md:w-36">
                    Mobile
                  </p>
                  <p className="text-md">{bookingDetailsData.mobile}</p>
                </div>
                <div className="flex justify-start items-center  space-x-4 mt-2">
                  <p className="text-gray-400 text-sm w-24 xl:w-36 lg:w-36 md:w-36">
                    E-mail
                  </p>
                  <p className="text-md">{bookingDetailsData.email}</p>
                </div>
                {/* <div className="flex justify-start items-center space-x-4 mt-2">
                <p className="text-gray-400 text-sm w-24 xl:w-36 lg:w-36 md:w-36">
                  Pick-up sign
                </p>
                <p className="text-md">
                  {bookingDetailsData.pickup_sign || ""}
                </p>
              </div> */}
                <div className="flex justify-start items-center space-x-4 mt-2">
                  <p className="text-gray-400 text-sm w-24 xl:w-36 lg:w-36 md:w-36">
                    Special Instructions
                  </p>
                  <p className="text-md">
                    {bookingDetailsData.specialInstructions ?? ""}
                  </p>
                </div>
              </div>

              <div className="flex flex-grow mt-8 ">
                <div className="mr-2  flex xl:w-8 xl:h-8 lg:w-8 lg:h-8 md:w-8 md:h-8 w-6 h-6 items-center justify-center rounded-full border-2 border-yellow-600">
                  <span className="font-bold text-md xl:text-xl lg:text-xl md:text-xl">
                    4
                  </span>
                </div>
                <p className="text-md xl:text-xl lg:text-xl md:text-xl">
                  Cancellation Policy
                </p>
              </div>

              {!!bookingDetailsData.cancellationDetails ? (
                <div className="px-4">
                  <div className="flex justify-start  items-center space-x-4 mt-2">
                    <p className="text-gray-400 text-sm w-24 xl:w-36 lg:w-36 md:w-36">
                      Free cancellation before
                    </p>
                    <p className="text-md">
                      {
                        bookingDetailsData.cancellationDetails
                          .freeCancellationUntil
                      }
                    </p>
                  </div>
                </div>
              ) : (
                <div className="px-4">
                  <div className="flex justify-start  items-center space-x-4 mt-2">
                    <p className="text-gray-400 text-sm w-24 xl:w-36 lg:w-36 md:w-36">
                      Cancellable
                    </p>
                    <p className="text-md">Not Cancellable</p>
                  </div>
                </div>
              )}

              <div className="flex items-center w-full p-8">
                <p className="text-2xl font-bold font-mono">
                  <strong>Fare :</strong> {bookingDetailsData.fare}
                </p>
              </div>
            </div>
          </div>

          {Boolean(bookingDetailsData) && (
            <>
              {/* <button
                onClick={() => {
                  navigate(-1);
                  window.scroll(0, 0);
                }}
                className="px-4 py-2 mt-2 rounded-md bg-gray-800 text-white font-bold  hover:bg-yellow-600"
              >
                Download Voucher
              </button> */}

              <button
                onClick={() => {
                  navigate(-1);
                  window.scroll(0, 0);
                }}
                className="px-4 py-2 mt-2 rounded-md bg-gray-800 text-white font-bold  hover:bg-yellow-600"
              >
                Book Again
              </button>
            </>
          )}
        </div>
        <div className="col-span-12 xl:col-span-4 lg:col-span-4 md:col-span-4 rounded-md">
          <div className="md:mt-[16%] mt-4 bg-white p-5 rounded-md whiteBox">
            <h1 className="font-bold text-2xl">NEED HELP?</h1>
            <p className="text-gray-500">
              If you need any assistance with regards to your booking please do
              not hesitate to contact us:
            </p>
            <div className="grid grid-cols-1 sm:gird-cols-2">
              <div className="cursor-pointer flex items-center space-x-4 mt-4">
                <MdCall size={35} />
                <p>
                  <a href="tel:+447458 148595">+44 7458 148595</a>
                </p>
              </div>
              <div className="cursor-pointer flex items-center space-x-4 mt-4">
                <MdEmail size={35} />
                <p>support@limowide.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BookingSuccess;
