import React from "react";

function PicLocIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#000"
        d="M10 6.667a3.332 3.332 0 100 6.666 3.332 3.332 0 100-6.666zm7.45 2.5a7.495 7.495 0 00-6.617-6.617V.833H9.167V2.55A7.495 7.495 0 002.55 9.167H.833v1.666H2.55a7.495 7.495 0 006.617 6.617v1.717h1.666V17.45a7.495 7.495 0 006.617-6.617h1.717V9.167H17.45zM10 15.833A5.829 5.829 0 014.167 10 5.829 5.829 0 0110 4.167 5.83 5.83 0 0115.833 10 5.83 5.83 0 0110 15.833z"
      ></path>
    </svg>
  );
}

export default PicLocIcon;
