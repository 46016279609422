import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import countriesList from "../Assets/countries.json";
import langObj from "../Assets/languages.json";
import i18next from "i18next";
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector, useDispatch } from "react-redux";
import { logout, selectUserSettings } from "../store/authSlice";
import { currencyChanged } from "../store/searchSlice";
import { isLoggedIn, selectUser } from "../store/authSlice";
import { resetSearch } from "../store/searchSlice";
export const currencyList = [
  {
    value: "USD",
    label: "🇺🇸",
  },
  {
    value: "INR",
    label: "🇮🇳",
  },
  {
    value: "EUR",
    label: "🇪🇺",
  },
  {
    value: "YEN",
    label: "🇯🇵",
  },
  {
    value: "AED",
    label: "🇦🇪",
  },
  {
    value: "AUD",
    label: "🇦🇺",
  },
  {
    value: "CAD",
    label: "🇨🇦",
  },
  {
    value: "CHF",
    label: "🇨🇭",
  },
  {
    value: "GBP",
    label: "🇬🇧",
  },
  {
    value: "SAR",
    label: "🇸🇦",
  },
  {
    value: "NOK",
    label: "🇳🇴",
  },
  {
    value: "SEK",
    label: "🇸🇪",
  },
  {
    value: "OMR",
    label: "🇴🇲",
  },
  {
    value: "KWD",
    label: "🇰🇼",
  },
  {
    value: "DKK",
    label: "🇩🇰",
  },
  {
    value: "JOD",
    label: "🇯🇴",
  },
  {
    value: "BHD",
    label: "🇧🇭",
  },
  {
    value: "CNY",
    label: "🇨🇳",
  },
];

const NavBarFixed = ({ currency, t, setCur, isHome }: any) => {
  const navigate: any = useNavigate();
  const languages = langObj.list;
  const [language, setLanguage] = useState<any>("");
  const [currencyVal, setCurrencyVal] = useState<any>(currency);
  const [flag, setFlag] = useState<any>(null);
  const user = useSelector(selectUser);
  const isLoggedin = useSelector(isLoggedIn);
  const dispatch = useDispatch();
  const userSettings = useSelector(selectUserSettings);

  useEffect(() => {
    let countries: any = countriesList;
    const lng = localStorage.getItem("language") || "en";
    const cur = localStorage.getItem("currencyValue") || "EUR";
    i18next.changeLanguage(lng);
    const countryCode: string =
      languages.find((lan: any) => lan.code === lng)?.country_code || "US";
    let matchingFlag: any = countries[countryCode]?.image;
    setLanguage(lng);
    setFlag(matchingFlag);
  }, [currencyVal]);
  const signout = () => {
    dispatch(logout());
    dispatch(resetSearch());
    navigate("/login");
  };
  const login = () => {
    dispatch(logout());
    navigate("/login");
  };
  return (
    <div className={`fixed top-0 ${isHome ? "" : "bg-gray-800"} z-50`}>
      <div className="py-2 lg:mx-2 xl:mx-2 md:mx-2 px-2 text-white w-screen">
        <div className="ml-5 mr-7 flex flex-row items-center justify-between">
          <img
            onClick={() => navigate("/")}
            src={userSettings?.site_logo}
            className="lg:h-20 xl:h-20 md:h-20 h-16 cursor-pointer"
            alt={userSettings?.site_name}
          />
          <div className="flex flex-row justify-items-center">
            {user?.type === "customer" ? (
              <div className="mr-2 lg:flex xl:flex md:flex hidden">
                <Link to="/becomepartner">
                  <h1>{t("nav_menu_1")}</h1>
                </Link>
              </div>
            ) : null}
            {user?.type === "customer" ? (
              <div className="flex items-center cursor-pointer">
                <div className="px-7">
                  <Link to="/flight-booking">
                    <h1>Flight Booking</h1>
                  </Link>
                </div>
              </div>
            ) : null}

            <div>
              <div className="text-bold font-4xl cursor-pointer">
                <select
                  name="currency"
                  id="currency"
                  value={currencyVal}
                  onChange={(e: any) => {
                    const currencySelectedOptionValue = e.target.value;
                    setCurrencyVal(currencySelectedOptionValue);
                    dispatch(currencyChanged(currencySelectedOptionValue));
                    localStorage.setItem(
                      "currencyValue",
                      currencySelectedOptionValue
                    );
                    // i18next.changeLanguage(lng);
                    // const countryCode: string =
                    //   currencyList.find((lan: any) => lan.value === lng)
                    //     ?.label || "EUR";
                    // let matchingFlag: any = countries[countryCode]?.image;
                    // setFlag(matchingFlag);
                  }}
                  className="lg:w-36 xl:w-36 md:w-36 w-24 text-bold font-4xl lg:px-7 xl:px-7 md:px-7 px-2  hover:text-yellow-600 cursor-pointer  tracking-wide relative z-50 bg-transparent border-none hover:border-none"
                >
                  {currencyList.map((lng: any, index: number) => (
                    <option
                      key={index}
                      className={`w-20  hover:text-yellow-600 text-black ${
                        index < currencyList.length - 1 && "border-b-2"
                      }`}
                      onClick={() => setCurrencyVal(currencyList[index])}
                      value={lng.value}
                    >
                      {lng.value} {lng.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {!isLoggedin && (
              <div className="mr-2 lg:flex xl:flex md:flex hidden ">
                <Link to="/signup">
                  <h1>{t("nav_menu_7")}</h1>
                </Link>
              </div>
            )}
            <div>
              <ul className="w-full z-50 pr-3">
                <li className="group dropdown inline text-bold font-4xl lg:px-7 xl:px-7 md:px-7 px-2 hover:text-gray-700 cursor-pointer  tracking-wide relative">
                  {!isLoggedin && <a>{t("nav_menu_2")}</a>}
                  {isLoggedin && user && (
                    <a>
                      {user?.first_name} {user?.last_name}
                    </a>
                  )}
                  <div className="group-hover:block w-max dropdown-menu top-5 absolute hidden h-auto lg:left-4 xl:left-4 md:left-4 -left-15 z-50 pr-5">
                    <ul className="block w-full bg-white shadow px-4 py-2">
                      <li
                        className="py-1 lg:hidden xl:hidden md:hidden"
                        onClick={() => navigate("/becomepartner")}
                      >
                        <a className="text-black block hover:text-yellow-600 cursor-pointer border-b-2"></a>
                      </li>
                      {!isLoggedin && (
                        <li className="py-1">
                          <a
                            className="text-black block  cursor-pointer hover:text-yellow-600"
                            onClick={login}
                          >
                            {t("nav_menu_3")}
                          </a>
                        </li>
                      )}
                      {!isLoggedin && (
                        <li className="py-1">
                          <a
                            className="text-black block  cursor-pointer hover:text-yellow-600"
                            target="_blank"
                            href="https://service.limowide.com/accounts/signin"
                            rel="noreferrer"
                          >
                            {t("nav_menu_6")}
                          </a>
                        </li>
                      )}
                      {isLoggedin && (
                        <>
                          <li
                            className="py-1"
                            onClick={() => navigate("/account")}
                          >
                            <a className="text-black block hover:text-yellow-600 cursor-pointer">
                              {t("nav_menu_5")}
                            </a>
                          </li>
                          <li className="py-1" onClick={signout}>
                            <a className="text-black block hover:text-yellow-600 cursor-pointer">
                              {t("nav_menu_4")}
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBarFixed;
